
import { filter, pluck } from 'rxjs/operators';
// Core
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Definitions
import { Participant, ITheme } from '../../../shared/definitions';
import { Collection } from '../../../store/';

@Component({
  selector: 'snippet-participant',
  templateUrl: './snippet-participant.component.html'
})
export class SnippetParticipantComponent implements OnChanges {
  @Input() participantsCount:     number;
  @Input() participantsMax:       number;
  @Input() participantCollection: Collection<Participant>;
  @Input() viewType:              string;

  participants:          Participant[];
  fiveFirstParticipants: Participant[];
  theme:                 ITheme;

  constructor(private _route: ActivatedRoute) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.participantCollection) {
      this.participantCollection
        .entities.pipe(
        filter(_ => _ ? true : false))
        .subscribe(
          (res) => {
            this.participants = res;
            this.fiveFirstParticipants = this.participants.slice(0, 5);
          },
          (error) => console.log(error)
        );
    }
  }

  loadNext(): void {
    this.participantCollection.collectNext();
  }

  /**
   * Take a paricipant object and returns his short name.
   * A short name is his first name, and the first letter of
   * his last name.
   * Not optimized at all. Need to create a custom pipe. DRY
   */
  getParticipantShortName(p: Participant): string {
    if (p === undefined) {
      return '';
    }
    const { first_name, last_name } = p.member;
    return `${first_name} ${last_name ? last_name[0] : ''}`;
  }
}
