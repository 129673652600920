
import { pluck } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITheme } from '../../../shared/index';

// Only for local usage
interface Options {
  max_participants?:          number;
  meet_point?:                string;
  display_price?:             string;
  price?:                     any;
  payment_codes?:             Array<string>;
  organizator?:               string;
  language?:                  string;
  participants_counter?:      'show'|'hide'|'default';
}

@Component({
  selector: 'snippet-price',
  templateUrl: './snippet-price.component.html'
})
export class SnippetPriceComponent implements OnChanges {
  @Input() meetOptions: Options;


  paiementType:  'open-price' | 'on-site-payment' | 'on-site-conso' | 'paid';
  paiementValue: string | 'open-price'; // The value is a number returned as a string OR 'open-price'
  theme:         ITheme;

  constructor(private _route: ActivatedRoute) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnChanges(change: SimpleChanges) {
    // console.log('change widget price :', change); // Debug purpose only
    if (change.meetOptions
      && change.meetOptions.currentValue
      && change.meetOptions.currentValue.display_price) {
      this.paiementType = change.meetOptions.currentValue.display_price;
      this.paiementValue = change.meetOptions.currentValue.price;
    }
    // console.log('widget price :', this); // Debug purpose only
  }
}
