<div
  *ngIf="group"
  class="header premium-account hide-m"
  [style.background-color]="colors.header_background"
>
  <div class="row align-middle content-width">
    <div class="col col-2 flex">
      <div *ngIf="!externalLogo" class="logo-header">
        <a
          *ngIf="!isPremium"
          [routerLink]="['/']"
          [queryParams]="{ pk_campaign: 'platform', pk_source: 'k-logo', pk_medium: 'custom-header' }"
          target="_blank"
          class="logo-noprem"
        >
          <img src="/assets/images/logo/logo-blue.svg" alt="Kawaa" class="img-contain"/>
        </a>
        <a [routerLink]="['/home', group.id]">
          <img [src]="logoSlug" [alt]="group.name" class="img-contain"/>
        </a>
      </div>
      <a
        *ngIf="externalLogo"
        [attr.href]="externalLogo.config.link"
        [attr.target]="externalLogo.config?.open_in_new_tab ? '_blank' : '_self'"
        rel="noopener"
        class="logo-header"
      >
        <img [src]="externalLogo.config.slug" class="img-auto"/>
      </a>
    </div>

    <div class="col col-10">
      <nav class="nav-header">
        <ul>
          <ng-container *ngFor="let section of sections">
            <li
              *ngIf="section.flags != 'external_logo' && section.status == 'listed' && !section.access"
              class="section-link"
            >
              <a (click)="onSelectSection(group, section)" href="javascript:void(0)">
                <span
                  class="details-link"
                  [ngClass]="{ 'selected-nav-header': selectedSection == section.flags && selectedSection != 'external_link' }"
                  [style.color]="colors.header_text"
                >
                  {{ section.title }}
                </span>
              </a>
            </li>
            <li
              *ngIf="section.flags != 'external_logo' && section.status == 'listed' && section.access && allowAccess && (isLoggedIn$ | async)"
              class="section-link"
            >
              <a (click)="onSelectSection(group, section)" class="button cta outline" [style.borderColor]="colors.header_text" href="javascript:void(0)">
                <span
                  class="details-link"
                  [ngClass]="{ 'selected-nav-header': selectedSection == section.flags && selectedSection != 'external_link' }"
                  [style.color]="colors.header_text"
                >
                  {{ section.title }}
                </span>
              </a>
            </li>
          </ng-container>
          <li
            class="login-link m-m-l"
            *ngIf="!(isLoggedIn$ | async)"
            (click)="onAuth('login')"
          >
            <a
              [style.background-color]="colors.header_btn_background"
              href="javascript:void(0)"
            >
              <span [style.color]="colors.header_btn_text">
                {{ 'HEADER.login' | translate }}
              </span>
            </a>
          </li>
          <header-menu
            *ngIf="(isLoggedIn$ | async)"
            [group]="group"
            class="m-m-l"
          ></header-menu>
        </ul>
      </nav>
    </div>
  </div>
</div>


<!-- Mobile header -->
<div
  *ngIf="group"
  class="w100 header-mobile show-m"
  [style.background-color]="colors.header_background"
  [style.border-color]="colors.header_text"
>
  <div class="flex align-middle p-tb-10 p-lr-10">
    <div (click)="showNavLeft = !showNavLeft; showNavRight = false" class="w20">
      <div
        class="open-nav"
        [style.border-color]="colors.header_text"
        [style.color]="colors.header_text"
      >
        <i *ngIf="!showNavLeft" class="fa fa-bars" aria-hidden="true"></i>
        <i *ngIf="showNavLeft" class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>

    <div class="logo-header-mobile w60">
      <a *ngIf="isPremium" (click)="onSelectSection(group, { flags: 'home' })" href="javascript:void(0)">
        <img [src]="logoSlug" alt="Logo" class="img-contain"/>
      </a>
      <div *ngIf="!isPremium" class="premium-account flex h100">
        <a [routerLink]="'/'" class="logo-noprem">
          <img src="/assets/images/logo/logo-blue.svg" alt="Kawaa" class="img-contain"/>
        </a>
        <a [routerLink]="['/home', group.id]" class="m-xs-lr">
          <img [src]="logoSlug" [alt]="group.name" class="img-contain"/>
        </a>
      </div>
    </div>

    <div (click)="showNavRight = !showNavRight; showNavLeft = false" class="w20">
      <div
        class="open-nav text-large push-right"
        [style.border-color]="colors.header_text"
        [style.color]="colors.header_text"
      >
        <i *ngIf="!showNavRight" class="fal fa-ellipsis-h-alt"></i>
        <i *ngIf="showNavRight" class="fal fa-ellipsis-v-alt p-lr-5"></i>
      </div>
    </div>
  </div>
</div>

<div
  class="mobile-nav-overlay show-m"
  [ngClass]="{ 'nav-links-overlay-appear': showNavLeft || showNavRight }"
  (click)="showNavLeft = false; showNavRight = false"
></div>
<nav
  class="mobile-nav show-m"
  [ngClass]="{ 'nav-links-mobile-appear': showNavLeft }"
>
  <ul class="section-top" *ngIf="!(isLoggedIn$ | async)">
    <li [style.background-color]="colors.primary">
      <a
        href="javascript:void(0)"
        class="text-white"
        (click)="onAuth('register')"
      >
        {{ 'HEADER.register' | translate }}
      </a>
    </li>
    <li>
      <a
        href="javascript:void(0)"
        (click)="onAuth('login')"
        class="text-dark-grey"
      >
        {{ 'HEADER.login' | translate }}
      </a>
    </li>
  </ul>

  <header-menu *ngIf="showNavLeft" [group]="group"></header-menu>
</nav>

<nav
  class="mobile-nav-right show-m"
  [ngClass]="{ 'nav-links-mobile-appear': showNavRight }"
>
  <ul class="section-top">
    <li *ngFor="let section of sections">
      <a
        *ngIf="section.flags !== 'external_logo' && section.status == 'listed'"
        href="javascript:void(0)"
        (click)="onSelectSection(group, section)"
        [style.color]="colors.primary"
        [ngClass]="{ 'selected': selectedSection == section.flags }"
      >
        {{ section.title }}
      </a>
    </li>
  </ul>
</nav>
