import { Pipe } from '@angular/core';

/**
 * We are using external service : cloudimage.io
 * Please consider reading the following links :
 * - Doc : https://docs.cloudimage.io/
 * - Home : https://cloudimage.io
 */

/**
 * Note : if no url is provided (it happens in case of asynchronous loading),
 * it will return an empty string.
 */
@Pipe({ name: 'imageResize' })
export class ImageResizePipe {
  /**
   * Cloud img token related to my test account (linked at guillaume.lodi@gmail.com)
   */
  token = 'a51pi2qnn';

  /**
   * Keep image proportions and resizing it from the width.
   */
  transform(imgUrl: string, width: number, height: number): string {
    if (!imgUrl) {
      return '';
    } else if (!width || !height) {
      throw new Error('width and height must be defined');
    } else {
      return `https://${this.token}.cloudimg.io/crop/${width}x${height}/x/${imgUrl}`;
    }
  }
}

/**
 * Note : if no url is provided (it happens in case of asynchronous loading),
 * it will return an empty string.
 */
@Pipe({ name: 'imageResizeWidth' })
export class ImageResizeWidthPipe {
  /**
   *  Cloud img token related to my test account (linked at guillaume.lodi@gmail.com)
   */
  token = 'a51pi2qnn';

  /**
   *  Cloud img token related to my test account (linked at guillaume.lodi@gmail.com)
   */
  transform(imgUrl: string, width: number): string {
    if (!imgUrl) {
      return '';
    } else if (!width) {
      throw new Error('width and height must be defined');
    } else {
      return `https://${this.token}.cloudimg.io/width/${width}/x/${imgUrl}`;
    }
  }
}
