import { Component }  from '@angular/core';

@Component({
  template: `
    <div class="about text-light-yellow fade-in-anim">
      <h1 class="about-title">
        Qui sommes-nous ?
      </h1>
      <div class="paragraph text-light-yellow">
        <p>
          Oui, kawaa crée du lien social à tous les étages.
        </p>
        <p>
          Agréée entreprise solidaire depuis 2014, kawaa s'est engagée à une lucrativité limitée et une gouvernance participative.
        </p>
        <p>
          Tu l'as compris, notre approche est intergénérationnelle et inclusive. On utilise le numérique non pas comme une fin en soi mais comme un moyen de se rencontrer, en vrai. « Mieux que facebook, un kawaa entre voisins ! »
          Chacun de nos projets est co-construit avec les collectivités locales, les acteurs de l'économie sociale et solidaire et tous ceux qui veulent agir au service du bien commun sur leur territoire.
        </p>
        <p>
          L'équipe kawaa est co-dirigée par deux associés. <a class="link" href="https://www.linkedin.com/in/k%C3%A9vin-andr%C3%A9-63560b49/">Kévin</a>, ancien Professeur en innovation sociale et auteur d'une thèse sur l'éthique du care, et <a href="https://www.linkedin.com/in/alexis-motte-2a9299/">Alexis</a> qui a aussi cofondé Mobilitis, société experte dans la transformation des environnements de travail.
        </p>
        <div class="flex row-wrap">
          <div class="pdf-button text-very-large text-light-yellow" (click)="onPdfDownloadClicked()">
            <div class="text">
              télécharger la présentation kawaa
            </div>
            <div class="borders">
              <div class="border-t-light-yellow button-type"></div>
              <div class="border-r-light-yellow button-type"></div>
              <div class="border-b-light-yellow button-type"></div>
              <div class="border-l-light-yellow button-type"></div>
            </div>
          </div>
          <div class="pdf-button text-very-large text-light-yellow" (click)="onNextKawaaClicked()">
            <div class="text">
              les prochains kawaa
            </div>
            <div class="borders">
              <div class="border-t-light-yellow button-type"></div>
              <div class="border-r-light-yellow button-type"></div>
              <div class="border-b-light-yellow button-type"></div>
              <div class="border-l-light-yellow button-type"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})

export class AboutComponent {
  onPdfDownloadClicked() {
    const link = document.createElement("a");
    link.download = 'kawaa-presentation.pdf';
    link.href = 'https://drive.google.com/file/d/12Mm4mHGompSziIgip2KE-TGlW2cIy5q7/view';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onNextKawaaClicked() {
    const link = document.createElement("a");
    link.href = 'https://paris.kawaa.co/cree-ton-kawaa/';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
