<div *ngIf="bannerState != 'accepted'" class="cookies-banner content-width">
  <div *ngIf="bannerState == 'reduced'" class="cookies-banner-reduce appear">
		<div class="cookies-banner-text">
      Nous utilisons des cookies pour faire fonctionner ce site internet, pour vous proposer des contenus adaptés à vos centres d’intérêt et pour améliorer votre expérience. En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies.
		</div>
		<div class="cookie-buttons">
      <button (click)="bannerState = 'expanded'" class="outline-bw small button m-xs-r">
				Paramètres
			</button>
      <button (click)="onConsent({ functional: true, analytics: true })" class="btn-black-white small button">
				Accepter
			</button>
		</div>
	</div>

  <div *ngIf="bannerState == 'expanded'" class="cookies-banner-expand appear">
		<div class="cookie-intro">
			<div class="text-larger bold m-xs-b">Paramétrer les cookies</div>
			<div class="p-static">
				<div class="text-white bold">Nous nous engageons à ne pas commercialiser vos données avec des tiers afin de préserver vos informations personnelles.</div>
			</div>
			<div class="p-static">
				<div class="text-light-grey">Lorsque vous naviguez sur un site internet, des cookies sont déposés sur votre navigateur. Il s'agit de petits fichiers de données, déposés pour permettre au site internet de fonctionner correctement. Certains cookies sont nécessaires et donc obligatoires. D’autres sont paramétrables, ils peuvent être désactivés. Cliquez sur les cookies non essentiels pour les désactiver.</div>
			</div>
		</div>

		<div class="cookie-toggles m-m-t">
			<div class="cookie-toggle">
				<div class="toggle-title">
					<div class="text-large bold">1. Cookies essentiels</div>
					<div class="toggle-element">
						<input type="checkbox" id="essential-cookies" class="toggle" checked disabled/>
						<label for="essential-cookies"></label>
					</div>
				</div>
				<div class="p-static w80">
					<div class="text-light-grey text-base">
						Les cookies essentiels vous permettent de naviguer sur notre plateforme et d’utiliser nos services et nos fonctions. Ces cookies sont obligatoires et sans eux, notre plateforme ne fonctionnera pas comme prévu, et nous ne serons pas à même de vous fournir certaines fonctions et caractéristiques.
					</div>
				</div>
			</div>
			<div class="cookie-toggle m-m-t">
				<div class="toggle-title">
					<div class="text-large bold">2. Cookies analytics</div>
					<div class="toggle-element">
            <input type="checkbox" id="analytics-cookies" class="toggle" checked #toggleAnalytics />
						<label for="analytics-cookies"></label>
					</div>
				</div>
				<div class="p-static w80">
					<div class="text-light-grey text-base">
						Pour mesurer l'audience et pour présenter des statistiques de fréquentation, nous utilisons Matomo Analytics (anciennement Piwik), un logiciel open-source hébergé sur nos serveurs qui nous permet d’être le propriétaire des données d’analyse que nous récoltons (contrairement à Google Analytics). Nous avons configuré Matomo Analytics afin de ne pas collecter votre adresse IP et protéger vos données.
					</div>
				</div>
			</div>
		</div>
		<div class="cookie-validate-button m-m-t">
      <button (click)="bannerState = 'reduced'" class="outline-bw small button m-xs-r">
				Annuler
			</button>
      <button (click)="onConsent({ functional: true, analytics: toggleAnalytics.checked })" class="btn-black-white small button">
				Sauvegarder
			</button>
		</div>
	</div>
</div>
