<!--
  Desktop view
-->
<div
  *ngIf="viewType == 'desktop'"
  id="lieu"
  class="row m-x-b"
>
  <h3
    class="section-title m-x-t m-m-b"
    [style.border-bottom-color]="theme?.colors?.primary"
  >
    {{ 'MEET_PAGE.place' | translate }}
  </h3>

  <div class="col col-12 place-meet-section">
    <div class="row">
      <div
        class="col place-meet-img"
        [ngClass]="(place) ? 'col-7' : 'col-9'"
      >
        <button
          *ngIf="place"
          class="button small switch-map-img"
          (click)="hideMap = !hideMap"
        >
          <span *ngIf="hideMap">
            <i class="fa fa-location-arrow" aria-hidden="true"></i>
            {{ 'MEET_PAGE.map' | translate }}
          </span>
          <span *ngIf="!hideMap">
            {{ 'MEET_PAGE.photo' | translate }}
          </span>
        </button>

        <!-- Place picture -->
        <div
          *ngIf="place && hideMap"
          class="place-img"
        >
          <img
            [src]="placePictureSlug"
            [alt]="placePictureAlt"
            (error)="hideMap = false"
          />
        </div>

        <!-- Map -->
        <div
          class="place-map"
          *ngIf="!hideMap"
        >
          <meet-leaflet-map
            [lat]="locationLat"
            [lng]="locationLng"
            [viewType]="viewType"
          ></meet-leaflet-map>
        </div>
      </div>

      <div
        class="col place-meet-desc"
        [ngClass]="(place) ? 'col-5' : 'col-3'"
      >
        <div class="row">
          <!-- >>> place information -->
          <div
            *ngIf="place"
            class="col col-12 place-meet-legend"
          >
            <a (click)="navigateToPlace(place)">
              <h4 class="title-h4">
                <span class="link-effect">
                  {{ place.name }}
                </span>
              </h4>
            </a>
            <p>
              {{ placeShortDescription }}
            </p>

            <div class="stats-module">
              <div *ngIf="meets_count > 0" class="stats">
                <div class="stats-number">{{ meets_count }}</div>
                <div class="stats-legend">
                  {{ 'MEET_PAGE.meet' | translate | pluralize:meets_count }}
                </div>
              </div>

              <div *ngIf="place.members_count > 0" class="stats">
                <div class="stats-number">{{ place.members_count }}</div>
                <div class="stats-legend">
                  {{ 'MEET_PAGE.member' | translate | pluralize:place.members_count }}
                </div>
              </div>
            </div>
          </div>
          <!-- <<< place information -->

          <!-- >>> location information -->
          <div class="col col-12 location-meet">
            <h4 class="title-h4">
              <span>{{ 'MEET_PAGE.address' | translate }}</span>
            </h4>
            <div class="row">
              <div
                class="col"
                [ngClass]="(place) ? 'col-6' : 'col-12'"
              >
                <ul>
                  <li>{{ locationName }}</li>
                  <li>{{ locationAddress }}</li>
                  <li>{{ locationCity }}</li>
                  <li>{{ locationZipCode }}</li>
                </ul>
              </div>
              <div
                class="col"
                [ngClass]="{
                  'col-6 text-right pushBottom': place,
                  'col-12 m-s-t': !place
                }"
              >
                <a [href]="locationMapsUrl" target="_blank" rel="noopener">
                  <button class="button small inverted">
                    {{ 'MEET_PAGE.itinerary' | translate }}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <!-- >>> location information -->

        </div>
      </div>
    </div>
  </div>
</div>

<!--
  Mobile view
-->
<div
  *ngIf="viewType == 'mobile'"
  id="lieu"
  class="row m-m-t"
>
  <div class="col col-12 place-meet-section">
    <div class="row">
      <div
        class="col place-meet-img"
        [ngClass]="(place) ? 'col-7' : 'col-9'"
      >
        <button
          *ngIf="place"
          class="button small switch-map-img"
          (click)="hideMap = !hideMap"
        >
          <i class="fa fa-map-o" aria-hidden="true"></i>
          {{ ((hideMap) ? 'MEET_PAGE.map' : 'MEET_PAGE.photo') | translate }}
        </button>

        <!-- Place picture -->
        <div
          *ngIf="place && hideMap"
          class="place-img"
        >
          <img
            [src]="placePictureSlug"
            [alt]="placePictureAlt"
            (error)="hideMap = false"
          />
          <!-- TODO - not yet data with group title on back -->
          <!-- <div class="place-img-legend">
              L'entrée du Sensespace
          </div> -->
        </div>

        <!-- Map -->
        <div
          class="place-map"
          *ngIf="!hideMap"
        >
          <meet-leaflet-map
            [lat]="locationLat"
            [lng]="locationLng"
            [viewType]="viewType"
          ></meet-leaflet-map>
        </div>
      </div>

      <div class="place-meet-desc-mobile">
        <div class="address">
          {{ locationName }},
          {{ locationAddress }},
          {{ locationZipCode }}
          {{ locationCity }}
        </div>
        <a [href]="locationMapsUrl" target="_blank" rel="noopener" class=btn>
          <!-- <div class="btn"> -->
            <i class="fa fa-location-arrow" aria-hidden="true"></i>
          <!-- </div> -->
        </a>
      </div>
    </div>
  </div>
</div>
