import { Component, Input, Output, EventEmitter } from '@angular/core';

type IStatus = 'neutral' | 'error-ext'| 'error-size' | 'success';

@Component({
  templateUrl: './input-file.component.html',
  selector: 'k-input-file'
})
export class InputFileComponent {
  /**
   * This will be added as the ID of the input
   */
  @Input() inputId: string = '';

  /**
   * It must be extention separated by commas.
   * It could be extentions AND MIME types,
   * But it must NOT be only MIME extenstions.
   */
  @Input() accept: string;

  /**
   * Emitted only when file is valid.
   */
  @Output() change = new EventEmitter<Event>();

  /**
   * (Optional) file size limit
   */
  @Input() maxSize?: number;

  status: IStatus = 'neutral';
  fileName: string;

  onChange($event): void {
    $event.stopPropagation();

    const target = $event.target as HTMLInputElement;

    if (!target.files.length) {
      this.fileName = '';
      this.status = 'neutral';
      return;
    }

    const file = target.files.item(0);

    this.fileName = file.name;
    this.status = this.getStatus(file);

    if (this.status == 'success') {
      this.change.next($event);
    }
  }

  private getStatus(file: File): IStatus {
    const { name, size } = file;
    const ext = name.split('.')[name.split('.').length - 1];

    if (this.maxSize && size > this.maxSize) {
      return 'error-size';
    }

    if (this.accept.indexOf(`.${ext}`) == -1) {
      return 'error-ext';
    }

    return 'success';
  }
}
