
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth.service';
import { AuthRedirectService } from './auth-redirect.service';


@Injectable()
export class IsUserLoggedGuard implements CanActivate {

  constructor(
    private _router:              Router,
    private _translate:           TranslateService,
    private _authService:         AuthService,
    private _authRedirectService: AuthRedirectService,
    ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any{
    let authRedirData = { redirectUrl: state.url, warningMessage: 'need-authentication' };

    if (route.data.authRedir) {
      authRedirData = { ...authRedirData, ...route.data.authRedir };
    }

    if (route.data.hasOwnProperty('lang')) {
      this._translate.use(route.data.lang);
    }

    return this._authService.getLoginStatus().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        }
        else {
          this._authRedirectService.authenticateThenGoTo(authRedirData, route.queryParams['_']);
          return false;
        }
      }));
  }
}
