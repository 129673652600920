<ng-container *ngIf="showKawaaPrivacy">
  <div
    class="p-t-60 p-b-60"
    [class.bg-login]="!isGroup"
  >
    <div class="content-width">
      <div class="row m-x-b">
        <div
          *ngIf="!isGroup"
          class="col col-6 content-login"
        >
          <div class="hide-m">
            <h2 class="inter-title-static">{{ 'AUTH.SECTIONS.title' | translate }}</h2>
            <p class="p-static m-xs-b">{{ 'AUTH.SECTIONS.subtitle' | translate }}</p>
          </div>
          <div class="text-center hide-m">
            <img src="/assets/images/auth/k-people.svg" class="login-img img-contain push-bottom">
          </div>
        </div>
        <!-- >>> Login box -->
        <auth-box
          class="col col-4"
          [class.offset-4]="isGroup"
          [class.push-right]="!isGroup"
        ></auth-box>
        <!-- <<< Login box -->
      </div>
    </div>
  </div>

  <div class="m-x-b">
    <div class="content-width">
      <article class="p-t-60">
        <div class="row gutters align-middle flex">
          <div class="col col-5 push-left m-x-b">
            <object type="image/svg+xml" data="/assets/images/auth/k-data-use.svg" class="hide-m data-safe">
              <img class="data-safe" src="/assets/images/auth/k-data-use.svg">
            </object>
            <h3 class="inter-title-static text-dark-black">{{ 'AUTH.SECTIONS.data-collection-title' | translate }}</h3>
            <p class="p-static m-xs-b">{{ 'AUTH.SECTIONS.data-collection-text' | translate }}</p>
            <a class="link-underline text-primary" href="https://www.kawaa.co/st/conditions-utilisation#collecte-donnees" target="_blank" rel="noopener">
              {{ 'AUTH.SECTIONS.data-collection-cta' | translate }}
            </a>
          </div>

          <div class="col col-5 push-right m-x-b">
            <object type="image/svg+xml" data="/assets/images/auth/k-data-recup.svg" class="hide-m data-safe">
              <img class="data-safe" src="/assets/images/auth/k-data-recup.svg">
            </object>
            <h3 class="inter-title-static text-dark-black">{{ 'AUTH.SECTIONS.data-recup-title' | translate }}</h3>
            <p class="p-static m-xs-b">{{ 'AUTH.SECTIONS.data-recup-text' | translate }}</p>
            <a class="link-underline text-primary" href="https://www.kawaa.co/st/conditions-utilisation#utilisation-donnees" target="_blank" rel="noopener">
              {{ 'AUTH.SECTIONS.data-recup-cta' | translate }}
            </a>
          </div>
        </div>
      </article>

      <div class="w100 bg-lighter-grey block-banner">
        <div class="row gutters align-middle p-lr-60">
          <div class="col col-2 push-left text-center">
            <object type="image/svg+xml" data="/assets/images/auth/k-data-protect.svg" class="hide-m data-safe">
              <img class="data-safe" src="/assets/images/auth/k-data-protect.svg">
            </object>
          </div>

          <div class="col col-10 push-right">
            <h3 class="inter-title-static text-dark-black">{{ 'AUTH.SECTIONS.data-control-title' | translate }}</h3>
            <p class="p-static m-xs-b">{{ 'AUTH.SECTIONS.data-control-text' | translate }}</p>
            <a class="link-underline text-primary" href="https://www.kawaa.co/st/conditions-utilisation#controle-donnees" target="_blank" rel="noopener">
              {{ 'AUTH.SECTIONS.data-control-cta' | translate }}
            </a>
          </div>
        </div>
      </div>

      <article class="m-x-b p-t-60">
        <div class="row">
          <div class="col col-10 push-center">
            <p class="p-static text-center italic-text">
              {{ 'AUTH.SECTIONS.rgpd-1' | translate }}
              <br />
              {{ 'AUTH.SECTIONS.rgpd-2' | translate }}
            </p>
          </div>
        </div>
      </article>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showKawaaPrivacy">
  <div class="row">
    <auth-box class="col col-4 offset-4 m-m-tb"></auth-box>
  </div>
</ng-container>
