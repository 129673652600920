import { enableProdMode }             from '@angular/core';
import { platformBrowserDynamic }    from '@angular/platform-browser-dynamic';

import { AppModule }                 from './app/app.module';

import 'zone.js/dist/zone';
import 'leaflet';
import 'leaflet.markercluster';

declare var env: any;

if (env.environment == 'production') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  preserveWhitespaces: false
});
