<div
  *ngIf="
    config?.by_city || config?.by_date || config?.by_tag
  "
  [class.bg-shadow]="(viewType$ | async) === 'desktop'"
  [class.white-bg]="(viewType$ | async) === 'desktop'"
  class="align-middle flex"
>
  <div
    (clickOutside)="onClickedOutside($event)"
    [ngClass]="
        filter !== '' ? 'col-12' : 'col-4' &&
        showTags ? 'col-10' : 'col-4'
      "
    [class.row-wrap]="(viewType$ | async) === 'mobile'"
    class="align-middle p-tb-20 m-s-l flex"
  >
    <div
      class="pos-r"
      [ngClass]="{ 'col col-2': (viewType$ | async) === 'mobile'}"
    >
      <div
        (click)="showFilters = !showFilters"
        class="flex pointer h100 align-middle m-xs-r justify-space-evenly"
        [ngClass]="{
          'thin-button': showFilters,
          'btn-dropdown-big': (viewType$ | async) === 'desktop',
          'btn-dropdown-small': (viewType$ | async) === 'mobile'
        }"
        [ngStyle]="{
          'background-color': showFilters ? colors.primary : '',
          'color': showFilters ? '#FFFFFF' : colors.primary
        }"
      >
        <p [ngClass]="{ 'p-lr-10 p-tb-5 border-radius': (viewType$ | async) === 'mobile' && !showFilters}">
          <i
            [ngClass]="{ 'push-center p-tb-5': (viewType$ | async) === 'mobile'}"
            class="m-xs-r far pointer fa-sliders-h"
          ></i>
          <span [ngClass]="{ 'hide': (viewType$ | async) === 'mobile'}">
            <span class="text-big">
              {{ 'SEARCHBAR.filter' | translate }}
            </span>
            <span *ngIf="filter == 'position'" class="link-underline bold">
              {{ 'SEARCHBAR.position' | translate }}
            </span>
            <span *ngIf="filter == 'themes'" class="link-underline text-big bold">
              {{ 'SEARCHBAR.themes' | translate }}
            </span>
            <span *ngIf="filter == 'calendar'" class="link-underline text-big bold">
              {{ 'SEARCHBAR.calendar' | translate }}
            </span>
            <i class="fal m-xxs-l" [ngClass]="!showFilters ? 'fa-chevron-down' : 'fa-chevron-up'"></i>
          </span>
        </p>
      </div>
      <!-- Dropdown filter category -->
      <div *ngIf="showFilters" class="dropdown-filter m-xxs-t">
        <div
          *ngIf="config?.by_city"
          (click)="onClickShowPosition()"
          class="flex m-xs-l push-center border-primary border-radius p-tb-10 p-lr-15 w80 m-s-t m-xs-b text-big pointer"
          [ngStyle]="{
            'background-color': activeFilters.position ? colors.primary : '',
            'color': activeFilters.position ? '#FFFFFF' : colors.primary,
            'border-color': colors.primary
          }"
        >
          <p class="dropdown-custom-info m-s-r">
            {{ 'SEARCHBAR.position' | translate }}
          </p>
          <i class="fas fa-map-marker-alt push-right"></i>
        </div>
        <div
          *ngIf="config?.by_date"
          (click)="onClickShowCalendar()"
          class="flex m-xs-l push-center border-primary border-radius p-tb-10 p-lr-15 w80 m-xs-tb text-big pointer"
          [ngStyle]="{
            'background-color': activeFilters.calendar ? colors.primary : '',
            'color': activeFilters.calendar ? '#FFFFFF' : colors.primary,
            'border-color': colors.primary
          }"
        >
          <p class="dropdown-custom-info m-s-r">
            {{ 'SEARCHBAR.calendar' | translate }}
          </p>
          <i class="far fa-calendar-alt push-right"></i>
        </div>
        <div
          *ngIf="tagButtons.length > 0 || tagImages.length > 0"
          (click)="onClickShowThemes()"
          class="flex m-xs-l push-center border-primary border-radius p-tb-10 p-lr-15 w80 m-xs-t m-s-b text-big pointer"
          [ngStyle]="{
            'background-color': activeFilters.themes ? colors.primary : '',
            'color': activeFilters.themes ? '#FFFFFF' : colors.primary,
            'border-color': colors.primary
          }"
        >
          <p class="dropdown-custom-info m-s-r">
            {{ 'SEARCHBAR.themes' | translate }}
          </p>
          <i class="far fa-pennant push-right"></i>
        </div>
      </div>
    </div>
    <ng-container class="col col-2" *ngIf="filter == 'calendar'">
      <div class="pos-r">
        <div
          class="flex m-xs-l pointer h100 align-middle"
          (click)="showCalendarDetails = !showCalendarDetails"
          [ngClass]="showCalendarDetails ? 'thin-button' : ''"
          [ngStyle]="{
            'background-color': showCalendarDetails ? colors.primary : '',
            'color': showCalendarDetails ? '#FFFFFF' : colors.primary
          }"
        >
          <i class="far fa-pennant m-xs-r"></i>
          <p *ngIf="show == '' && !isDatePicked" class="text-big m-xs-r">
            {{ 'SEARCHBAR.all-periods' | translate }}
          </p>
          <p *ngIf="show == 'today'" class="text-big m-xs-r">
            {{ 'SEARCHBAR.today' | translate }}
          </p>
            <p *ngIf="show == 'thisweek'" class="text-big m-xs-r">
            {{ 'SEARCHBAR.thisweek' | translate }}
          </p>
          <p *ngIf="show !== 'month' && isDatePicked" class="text-big m-xs-r">
            {{ actualPeriod | format_date }}
          </p>
          <p *ngIf="show == 'month' && !isDatePicked" class="text-big m-xs-r">
            {{ 'SEARCHBAR.choose-date' | translate }}
          </p>
          <i class="fal m-xxs-l" [ngClass]="!showCalendarDetails ? 'fa-chevron-down' : 'fa-chevron-up'"></i>

        </div>
        <div
          *ngIf="showCalendarDetails"
          class="dropdown-filter m-xxs-t"
          [ngClass]="{'dropdown-filter-mobile': (viewType$ | async) === 'mobile'}"
        >
          <select
            (click)="onClickThisShowMonth()"
            class="push-center bold w80 m-s-t"
            [ngStyle]="{
              'height': '33px',
              'display': show == 'month' ? 'none' : '',
              'color': isDatePicked ? '#FFFFFF' : colors.primary,
              'border-color': colors.primary,
              'background-color': isDatePicked ? colors.primary : ''
            }"
          >
            <i class="far fa-calendar-alt push-left"></i>
            <option *ngIf="!isDatePicked" hidden>
              {{ 'SEARCHBAR.choose-date' | translate }}
            </option>
            <option *ngIf="isDatePicked" hidden>
              {{ actualPeriod | format_date }}
            </option>
          </select>
          <mat-calendar
            *ngIf="show == 'month'"
            [selected]="actualPeriod"
            (selectedChange)="getNewDate(periodMeets, $event)">
          </mat-calendar>
          <div
            *ngIf="show !== 'month'"
            (click)="onClickShowToday()"
            class="flex m-xs-l push-center border-primary border-radius p-tb-5 p-lr-15 w80 m-s-t text-big pointer"
            [ngStyle]="{
              'background-color': show == 'today' ? colors.primary : '',
              'color': show == 'today' ? '#FFFFFF' : colors.primary,
              'border-color': colors.primary
            }"
          >
            <p class="dropdown-custom-info">
              {{ 'SEARCHBAR.today' | translate }}
            </p>
          </div>
          <div
            *ngIf="show !== 'month'"
            (click)="onClickShowThisWeek()"
            class="flex m-xs-l push-center border-primary border-radius p-tb-5 p-lr-15 w80 m-s-t m-s-b text-big pointer"
            [ngStyle]="{
              'background-color': show == 'thisweek' ? colors.primary : '',
              'color': show == 'thisweek' ? '#FFFFFF' : colors.primary,
              'border-color': colors.primary
            }"
          >
            <p class="dropdown-custom-info">
              {{ 'SEARCHBAR.thisweek' | translate }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filter == 'themes'">
      <div
        [ngClass]="{
        'col col-3': (viewType$ | async) === 'desktop',
        'col col-8': (viewType$ | async) === 'mobile'
        }"
        class="pos-r"
      >
        <div
          class="flex form-item pos-r no-margin align-self-center"
          (click)="showThemesDetails = !showThemesDetails"
        >
          <select
            [ngStyle]="{
              'background-color': showThemesDetails ? colors.primary : '',
              'color': showThemesDetails ? '#FFFFFF' : '',
              'border-color': colors.primary
            }"
          >
            <option
              *ngIf="!selectedTags"
              label="{{ 'SEARCHBAR.select-themes' | translate }}"
              hidden
            ></option>
            <option
              *ngIf="selectedTags > 0"
              label="{{selectedTags}} {{ 'SEARCHBAR.selected-themes' | translate | pluralize:selectedTags }}"
              hidden
            ></option>
          </select>
        </div>
        <!-- themes dropdown -->
        <div *ngIf="showThemesDetails" class="dropdown-filter m-xxs-t">
          <div class="flex-column" *ngIf="tagButtons.length > 0">
            <button
              class="m-xxs-tb text-left borderless tag-btn button outline"
              *ngFor="let tag of tagButtons"
              style="border-width: 1px"
              [style.color]="tag.selected ? '#FFFFFF' : colors.primary"
              [style.background-color]="tag.selected ? colors.primary : '#FFFFFF'"
              (click)="setTag(tag)"
            >
              <span>
                {{ tag.name }}
              </span>
            </button>
          </div>
          <div class="flex-column" *ngIf="tagImages.length > 0">
            <div class="text-left">
              <div
                class="m-xxs-tb m-xxs-lr pointer search-item"
                *ngFor="let tagImg of tagImages"
                (click)="setTag(tagImg)"
                [ngStyle]="{
                  'background-color': tagImg.selected ? colors.primary : '',
                  'color': tagImg.selected ? '#FFFFFF' : colors.primary
                }"
              >
              <div class="flex" style="height: 40px">
                <img class="img-contain" [src]="tagImg.slug" style="width: 40px">
                <span class="text-small align-self-center p-l-10">
                  {{ tagImg.title }}
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </ng-container>
    <div
      class="col col-4 m-m-lr"
      [ngClass]="{'col-9 no-margin': (viewType$ | async) === 'mobile'}"
      [ngStyle]="{
        'display': filter == 'position' ? 'inline-block' : 'none'
      }"
    >
      <search-by-city
        class="w35"
        [colors]="colors"
        (searchedCity)="onSearchCity($event)"
      ></search-by-city>
    </div>
    <div
      *ngIf="showTags"
      [ngClass]="{
        'col-4': (viewType$ | async) === 'desktop' && filter == 'position' && showTags,
        'col-9': showTags && filter !== 'position' && filter !== 'themes' && filter !== 'calendar' ,
        'm-s-r': (viewType$ | async) === 'desktop' && filter == 'position' || filter == 'themes' || filter == 'calendar',
        'p-t-10 no-margin col-10': (viewType$ | async) === 'mobile',
        'col-11': (viewType$ | async) === 'mobile' && filter == 'position',
        'no-padding': filter !== 'position' && filter !== 'themes' && filter !== 'calendar'
      }"
      class="col col-6 m-m-l align-self-center"
      [class.push-right]="(viewType$ | async) === 'desktop'"
    >
      <search-by-tag
        [predefinedTags]="predefinedTags"
        [predefinedTagsWithImage]="predefinedTagsWithImage"
        [colors]="colors"
        (searchedTags)="onSearchTags($event)"
        class="no-margin"
      ></search-by-tag>
    </div>
  </div>
</div>
