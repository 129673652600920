import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth';
import { AuthRedirectService } from 'src/app/auth/auth-redirect.service';
import { Group, IHeaderSection, Member, User } from 'src/app/shared/definitions';
import { AppStore, Collection } from 'src/app/store/';

@Component({
  selector: 'home-header',
  templateUrl: './home-header.component.html',
})
export class HomeHeaderComponent implements OnInit {
  @Input() group: Group;
  @Output() opened = new EventEmitter<any>();

  isLoggedIn$: Observable<boolean>;
  groupsCollection: Collection<Group>;
  urlLocation: string = window.location.origin;
  userId: number;
  sections: IHeaderSection[] = [];
  externalLogo: IHeaderSection;

  active = false;
  allowAccess: boolean = false;

  constructor(
    private _appStore: AppStore,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _authRedirectService: AuthRedirectService
  ) {
    this.isLoggedIn$ = this._authService.getLoginStatus();
  }

  ngOnInit() {
    this._appStore
      .load('Group', '3558')
      .entity.pipe(first())
      .subscribe((group: Group) => {
        this.group = group;
      });

    this._authService.getUser().subscribe((usr: User) => {
      if (usr && usr.id) this.userId = usr.id;
    });

    this.isLoggedIn$.subscribe((status) => {
      this.getGroupMember();
    });
  }

  getGroupMember() {
    if (this.group) {
      this._appStore.load('Members', this.group.id.toString()).entities.subscribe((members: Member[]) => {
        members.find((member: Member) => {
          if (member.user_id == this.userId) {
            this.allowingAccessToRestrictedPage(member);
          } else if (!this.allowAccess && this._route.snapshot.firstChild.params.page_name == 'espace-membre') {
            this._router.navigate(['/home', this.group.id], { queryParams: { _: this.group.id } });
          }
        });
      });
    }
  }

  allowingAccessToRestrictedPage(member: Member) {
    if (this.sections) {
      this.sections.forEach((section: IHeaderSection) => {
        if (section.access) {
          this.allowAccess = section.access.some((role) => member.role == role);
        }
      });
    }
  }

  navigateOutsideIn(route) {
    this._router.navigate([route]).then(() => {
      window.location.reload();
    });
  }

  redirectIfNotAllowed() {
    this.isLoggedIn$.subscribe((status) => {
      if (!status) {
        this._authRedirectService.authenticateThenGoBack({ tab: 'login' }, this._route.snapshot.queryParams['_']);
      }
      if (status && !this.allowAccess) {
        this._router.navigate(['/home', this.group.id], { queryParams: { _: this.group.id } });
      }
    });
  }

  onBurgerClicked() {
    this.active = !this.active;
    this.opened.emit();
  }

  onAuth(tab: 'register' | 'login') {
    const link = document.createElement('a');
    if (process.env.NODE_ENV === 'development') {
      link.href = `${window.location.origin}/home/20/k-auth?tab=login&redirectUrl=%2Fhome%2F20`;
    } else {
      link.href = `${window.location.origin}/home/4555/k-auth?tab=login&redirectUrl=%2Fhome%2F4555`;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // To replace with the following later on if proper authentication is used
    // if (!this._route.snapshot.queryParams.tab) {
    //   this._authRedirectService.authenticateThenGoBack({ tab }, this._route.snapshot.queryParams['_']);
    // }
  }
}
