<div
  *ngIf="(membersCollection?.meta | async)?.total > 0"
  class="row align-middle content-width"
>
  <h3 class="section-title m-m-b" [style.border-bottom-color]="colors?.primary">
    {{ sectionTitle | customTranslate }}
    <span>({{ (membersCollection?.meta | async)?.total }})</span>
  </h3>

  <div class="col col-10 offset-1 m-x-b">
    <div class="row">
      <div
        *ngFor="let member of membersCollection.entities | async; trackBy: trackByFn"
        class="m-xs-t m-s-r face pos-r"
      >
        <abbr
          class="effect"
          style="top: 95px"
          [attr.info-tip]="getMemberShortName(member)"
        ></abbr>
        <div
          (click)="onClickMember(member)"
          class="face-img pointer"
        >
          <img
            class="circle-radius borders-all"
            style="width: 90px; height: 90px"
            [lazyLoad]="member?.avatar?.slug"
            [defaultImage]="'/assets/images/avatar_default.jpg'"
            [alt]="getMemberShortName(member)"
            (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
          />
        </div>
      </div>

      <div
        *ngIf="(membersCollection?.meta | async)?.next"
        class="circle-radius borders-all align-middle text-center flex m-xs-t m-m-r"
        style="width: 90px; height: 90px; cursor: pointer"
        [style.border-color]="colors?.primary"
        (click)="loadNext()"
      >
        <p
          [style.color]="colors?.primary"
          [style.font-size]="'48px'"
        >+</p>
      </div>

    </div>
  </div>
</div>
