import { Component }  from '@angular/core';

@Component({
  template: `
    <div class="fade-in-anim">
      <div class="page-title hidden-if-not-mobile text-yellow p-lr-40">
        café
        <svg class="icon" fill="currentColor">
          <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
        </svg>
        cuisine
      </div>
      <div class="main-image flex">
        <img src="assets/images/home/two-desserts.png" alt="a person holding two desserts">
      </div>
      <div class="post-block">
        <div class="post bg-light-yellow text-post-yellow">
          <div class="text">
            <p class="paragraph">
              Envie d'un bon café ? Une petite faim ? Bienvenue au kawaa. A midi, nous vous préparons des recettes à base de légumes de saison cultivés près de chez nous par des producteurs responsables. Mais vous verrez, notre goût du partage déborde largement des assiettes. Au kawaa, chaque plat est l'occasion de se rencontrer !
            </p>
          </div>
          <div class="borders">
            <div class="border-t-post-yellow"></div>
            <div class="border-r-post-yellow"></div>
            <div class="border-b-post-yellow"></div>
            <div class="border-l-post-yellow"></div>
          </div>
        </div>
      </div>
      <div class="flex image-banner">
        <div class="left-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/cucumber-dish.jpeg" alt="a person holding a cucumber dish">
        </div>
        <div class="right-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/serving-coffee.jpeg" alt="a person pouring two coffees">
        </div>
      </div>
    </div>
  `
})

export class CoffeeKitchenComponent {
}
