import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { IAnswerField, Resource, ResourceService } from '../../index';

@Component({
  selector: 'display-file-answer',
  templateUrl: './display-file-answer.component.html'
})
export class DisplayFileInfoComponent implements OnChanges {
  @Input()
  fileInfo: IAnswerField;

  public readonly file$: Subject<Resource>;

  constructor(private resourceService: ResourceService, private http: HttpClient) {
    this.file$ = new Subject();
  }

  ngOnChanges(changes: SimpleChanges) {
    const fileInfo = changes.fileInfo.currentValue as IAnswerField;

    const value = fileInfo.value;

    if (!value.startsWith('file#')) {
      throw new Error('Unexpected value ' + value);
    }

    const fileId = parseInt(value.slice(5));

    if (!Number.isInteger(fileId)) {
      throw new Error('Invalid value ' + value);
    }

    this.resourceService.getResource(fileId)
      .pipe(first())
      .toPromise()
      .then((res) => this.file$.next(res));
  }

  download(resource: Resource) {
    return this.http
      .get(resource.slug, { responseType: 'blob', observe: 'response' })
      .subscribe(respone => {

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(respone.body);
        link.download = resource.title;
        link.click();
      });
  }
}
