<!-- Participant view -->
<div *ngIf="(viewType$ | async) === 'desktop' && step !== 'in-future' && step !== 'past'" class="m-m-t">
  <div class="col col-11 align-center">
    <img src="/assets/images/kvisio/saloon.png" alt="Salon virtuel">
  </div>
</div>

<div class="row" [ngClass]="{'p-t-30': step !== 'past'}">
  <div *ngIf="(viewType$ | async) === 'desktop' && step !== 'past'">
    <div class="col col-11 gutters align-center">
      <p class="text-center text-large bold m-s-b">
        {{ 'VISIO_ONBOARDING.how-to-prepare-event' | translate }}
      </p>
      <div [ngClass]="{ 'row-wrap m-s-lr': (viewType$ | async) === 'mobile' }" class="flex">
        <div class="flex-column borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4">
          <img 
            src="/assets/images/kvisio/computers.png"
            style="height: 90px;"
            [ngClass]="{ 
              'w30': (viewType$ | async) === 'mobile',
              'col col-6': (viewType$ | async) === 'desktop'
            }"
            alt="Ordinateur" 
            class="img-contain push-center m-s-t"
          >
          <p class="p-static m-m-t text-center w80 push-center" style="height: 90px;">
            {{ 'VISIO_ONBOARDING.rule-computer' | translate }}
          </p>
        </div>
        <div 
          [ngClass]="{ 'm-s-lr': (viewType$ | async) === 'desktop' }"
          class="flex-column borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4"
        >
          <img 
            src="/assets/images/kvisio/navigator.png"
            style="height: 90px;"
            [ngClass]="{ 
              'w30': (viewType$ | async) === 'mobile',
              'col col-6': (viewType$ | async) === 'desktop'
            }"
            alt="Navigateur" 
            class="img-contain push-center m-s-t"
          >
          <p class="p-static m-m-t text-center" style="height: 90px;">
            {{ 'VISIO_ONBOARDING.rule-navigator' | translate }}
          </p>
        </div>
        <div class="flex-column borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4">
          <img 
            src="/assets/images/kvisio/stuff.png"
            style="height: 90px;"
            [ngClass]="{ 
              'w30': (viewType$ | async) === 'mobile',
              'col col-6': (viewType$ | async) === 'desktop'
            }"
            alt="Clavier, micro, webcam" 
            class="img-contain push-center m-s-t"
          >
          <p class="p-static m-m-t text-center overflow" style="height: 90px;">
            {{ 'VISIO_ONBOARDING.rule-stuff' | translate }}
            <span class="text-small italic-text">
              {{ 'VISIO_ONBOARDING.rule-stuff-bis' | translate }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="step == 'past'" class="col col-11 align-center">
    <p class="text-center text-large m-s-t">
      <b>{{ 'VISIO.end' | translate }}</b>
    </p>
    <p class="text-center text-large m-xs-t m-m-b">
      Merci d’avoir participé à cet événement 🙂
    </p>
    <div
      class="bg-color-profile push-center border-radius borders-all m-s-t"
      [ngClass]="{
        'p-tb-30 p-lr-60': (viewType$ | async) === 'desktop',
        'p-lr-15 p-tb-20': (viewType$ | async) === 'mobile'
      }"
    >
      <p class="p-static p-b-10">
        Vous pouvez poursuivre les discussions restées en suspend sur l’espace
        <a
          [routerLink]="['/admin', 'rencontres', meet.id, 'discussions']"
          queryParamsHandling="preserve"
          class="bold link-underline"
        >“Discussions”</a>
         de la page de l’événement : 
         <span class="italic-text">
           “{{ meet?.name }}”
         </span>
      </p>
      <p class="p-static m-m-t">
        Nous espérons vous revoir prochainement sur un salon virtuel ou dans la vie réelle !
      </p>
    </div>
  </div>
  <!-- Mobile -->
  <div *ngIf="(viewType$ | async) === 'mobile' && step !== 'past'" class="col col-11">
    <p class="bold text-bigger">
      Comment rejoindre le salon virtuel sur mobile ?
    </p>
    <p class="m-xs-tb">
      Vous pouvez télécharger l’application 
      <a
        [href]="'https://w2atb.app.goo.gl/?link=https%3A%2F%2Fmeet.jit.si%2F' + conf_name + '-kawaa&apn=org.jitsi.meet&ibi=com.atlassian.JitsiMeet.ios&isi=1165103905&ius=org.jitsi.meet&efr=1'"
        class="link-underline m-xs-t"
      >
        jitsi
      </a>
       et rejoindre le salon virtuel.
    </p>
    <a
      [href]="'https://w2atb.app.goo.gl/?link=https%3A%2F%2Fmeet.jit.si%2F' + conf_name + '-kawaa&apn=org.jitsi.meet&ibi=com.atlassian.JitsiMeet.ios&isi=1165103905&ius=org.jitsi.meet&efr=1'"
      class="w-auto m-m-b flex align-center"
    >
      <img
        [src]="!isIphone ? '/assets/images/kvisio/google-play.png' : '/assets/images/kvisio/app-store.png'"
        class="img-contain"
        style="height: 50px; width: 165px;"
        alt="Telecharger"
      >
    </a>
    <div class="flex align-middle m-m-b">
      <span class="col bg-dark-grey" style="height: 1px;"></span>
      <p class="m-xxs-lr text-dark-grey">ou</p>
      <span class="col bg-dark-grey" style="height: 1px;"></span>
    </div>
    <p class="p-t-10 m-s-b">
      Vous pouvez accèder au salon virtuel avec un appel téléphonique (gratuit)
    </p>
    <p>
      Appelez ce numéro :
    </p>
    <div class="col col-11 bg-color-profile borders-all border-radius m-xxs-t">
      <div class="flex p-tb-10 p-lr-10 align-middle">
        <p *ngIf="step !== 'started'" class="text-dark-grey m-s-r text-base italic-text">
          Disponible dès l'ouverture du salon virtuel
        </p>
        <a
          *ngIf="step == 'started'"
          class="m-s-r text-big italic-text link-underline pointer"
          href="tel:+33184886478"
        >
          +33.1.84.88.6478
        </a>
        <div
          class="push-right border-radius pointer"
          (click)="callConference()"
          [ngClass]="{
            'bg-success': step == 'started',
            'bg-grey disabled': step !== 'started'
          }"
        >
          <i class="fas fa-phone p-tb-10 p-lr-10 text-white"></i>
        </div>
      </div>
    </div>
    <p class="m-xs-t">
      Et renseignez ce code :
    </p>
    <div class="col col-11 bg-color-profile borders-all border-radius m-xxs-t">
      <div class="flex p-tb-10 p-lr-10 align-middle">
        <div class="p-tb-10">
          <p *ngIf="step !== 'started'" class="text-dark-grey m-s-r text-base italic-text">
            Disponible dès l'ouverture du salon virtuel
          </p>
          <p *ngIf="step == 'started'" class="m-s-r text-big">
            {{ confCode }}
          </p>
        </div>
      </div>
    </div>
    <p class="text-base text-dark-grey italic-text m-xxs-t">
      Tapez le code suivi par #
    </p>
  </div>

  <div class="col col-11 bg-color-profile push-center border-radius borders-all m-s-t m-x-b">
    <p class="p-static text-center p-tb-30 p-lr-10">
      {{ 'VISIO_ONBOARDING.need-help' | translate }}
      <b>
        <a
        href="https://aide.kawaa.co/category/179-les-evenements-virtuels---kawaa-live"
        target="_blank"
        class="link-underline pointer"
      >
        {{ 'VISIO_ONBOARDING.faq' | translate }}
      </a>
    </b>
      {{ 'VISIO_ONBOARDING.or' | translate }}
      <b>
        <span (click)="contactUs()" class="link-underline pointer">
          {{ 'VISIO_ONBOARDING.contact-us' | translate }}
        </span>
      </b>
    </p>
  </div>
</div>

