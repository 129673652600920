import { Component, Input }  from '@angular/core';
import { IColors, IPostAction, Post }  from '../../definitions/';
import { Observable } from 'rxjs';
import { ViewTypeService, IViewType } from '../../view-type.service';

@Component({
  selector: 'block-post-article',
  templateUrl: './block-post-article.component.html',
  host: {
    class: 'w100'
  }
})

export class BlockPostArticleComponent {

  @Input() post:          Post;
  @Input() colors:        IColors = { primary: '' };

  viewType$:              Observable<IViewType>;

  postClasses:            string[] = ['content-width', 'p-lr-15', 'p-tb-15'];
  resourceClasses:        string[] = ['col-4'];
  textClasses:            string[] = ['p-static'];
  contentClasses:         string[] = ['col-7', 'm-xs-tb'];
  actionClasses:          string[] = ['button'];

  actionStyles:           { [prop: string]: string } = {};

  constructor(private _viewType: ViewTypeService) {
    this.viewType$ = this._viewType.get();
  }

  ngOnChanges() {
    if (!this.post)
      return ;

    if (this.post.options && this.post.options.post_classes) {
      this.postClasses = this.post.options.post_classes;
    }

    if (this.post.options && this.post.options.resource_classes) {
      this.resourceClasses = this.post.options.resource_classes;
    }

    if (this.post.options && this.post.options.text_classes) {
      this.textClasses = this.post.options.text_classes;
    }

    if (this.post.options && this.post.options.action_classes) {
      this.actionClasses = this.post.options.action_classes;
    }

    if (this.post.options && this.post.options.content_classes) {
      this.contentClasses = this.post.options.content_classes;
    }
    else if (!this.post.resources || this.post.resources.length == 0) {
      this.contentClasses = ['col-12'];
    }

    if (this.post.options && this.post.options.action_styles && this.colors) {
      this.actionStyles = { 'background-color': this.colors.primary, ...this.post.options.action_styles };
    }
    else if (this.colors) {
      this.actionStyles = { 'background-color': this.colors.primary };
    }
  }
}
