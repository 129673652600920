<div class="header hide-m" [ngClass]="{ 'ka-nav-header': onHome }">
  <div class="row align-middle content-width">
    <div class="col col-1">
      <div class="logo-header">
        <a [routerLink]="'/'">
          <img
            *ngIf="!onHome"
            src="/assets/images/logo/logo-blue.svg"
            alt="Kawaa"
            class="img-contain k-logo"
          >
          <img
            *ngIf="onHome"
            src="/assets/images/logo/logo-white.svg"
            alt="Kawaa"
            class="img-contain k-logo"
          >
        </a>
      </div>
    </div>

    <div class="col col-11">
      <nav class="nav-header">
        <ul>
          <li class="section-link">
            <a
              href="https://kawaa.co/home/3558"
              target="_blank"
              class="align-middle"
            >
              <span class="details-link">
                Le lieu Kawaa
              </span>
            </a>
          </li>
          <li class="section-link">
            <a
              routerLink='/st/realisations'
              class="align-middle"
              routerLinkActive="link-underline"
              #useCase="routerLinkActive"
              [class.link-underline-rollover]="!useCase.isActive"
            >
              <span class="details-link">
                {{ 'HEADER.use-case' | translate }}
              </span>
            </a>
          </li>
          <li class="section-link">
            <a
              routerLink='/st/plateforme'
              class="align-middle"
              routerLinkActive="link-underline"
              #features="routerLinkActive"
              [class.link-underline-rollover]="!features.isActive"
            >
              <span class="details-link">
                Plateforme
              </span>
            </a>
          </li>
          <!-- <li class="section-link">
            <a
              routerLink='/st/nos-offres'
              class="align-middle"
              routerLinkActive="link-underline"
              #offer="routerLinkActive"
              [class.link-underline-rollover]="!offer.isActive"
            >
              <span class="details-link">
                {{ 'HEADER.offer' | translate }}
              </span>
            </a>
          </li> -->
          <button
            class="m-m-l"
            *ngIf="!(isLoggedIn$ | async)"
            (click)="onAuth('login')"
            [ngClass]="{'btn-main-invert': onHome }"
          >
            {{ 'HEADER.login' | translate }}
          </button>
          <li class="m-m-l" *ngIf="(isLoggedIn$ | async)">
            <header-menu></header-menu>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<!-- Mobile header -->
<div class="w100 header-mobile show-m">
  <div class="flex align-middle p-tb-10 p-lr-20">
    <div (click)="showNav = !showNav" class="w30 text-left">
      <div class="open-nav text-dark-grey">
        <i *ngIf="!showNav" class="fa fa-bars" aria-hidden="true"></i>
        <i *ngIf="showNav" class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>

    <div class="w40 text-center">
      <div class="logo-header-mobile">
        <a routerLink="/">
          <img src="/assets/images/logo/logo-blue.svg" alt="Logo" class="img-contain"/>
        </a>
      </div>
    </div>

    <div class="w30 push-right text-right flex">
      <a
        routerLink="/fr/decouvrir/rencontres"
        class="search-mobile-nav text-dark-grey"
      >
        <i class="fas fa-search" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>

<div
  class="mobile-nav-overlay show-m"
  [ngClass]="{ 'nav-links-overlay-appear': showNav }"
  (click)="showNav = !showNav"
></div>
<nav
  class="mobile-nav show-m"
  [ngClass]="{ 'nav-links-mobile-appear': showNav }"
>
  <ul class="section-top">
    <li (click)="showNav = !showNav">
      <a routerLink='/home/3558'>
        <span class="details-link">Le lieu Kawaa</span>
      </a>
    </li>
    <li  (click)="showNav = !showNav" class="section-link">
      <a routerLink='/st/realisations'>
        <span class="details-link">Réalisations</span>
      </a>
    </li>
    <li (click)="showNav = !showNav" class="section-link">
      <a routerLink='/st/plateforme'>
        <span class="details-link">Plateforme</span>
      </a>
    </li>
    <!-- <li (click)="showNav = !showNav" class="section-link">
      <a routerLink='/st/nos-offres'>
        <span class="details-link">Nos offres</span>
      </a>
    </li> -->
  </ul>
  <ul class="section-bottom" *ngIf="!(isLoggedIn$ | async)">
    <li class="bg-primary">
      <a
        href="javascript:void(0)"
        class="text-white"
        (click)="onAuth('register')"
      >
        {{ 'HEADER.register' | translate }}
      </a>
    </li>
    <li>
      <a
        href="javascript:void(0)"
        (click)="onAuth('login')"
        class="text-dark-grey"
      >
        {{ 'HEADER.login' | translate }}
      </a>
    </li>
  </ul>

  <header-menu *ngIf="showNav"></header-menu>
</nav>
