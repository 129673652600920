<!-- Loader -->
<snippet-loader *ngIf="!min && !max && !fix"></snippet-loader>

<!-- Widget -->
<div
  *ngIf="min || max || fix"
  class="row"
>
  <div class="col col-12 aside-meet-article format-meet">
      <h3 class="section-title" [style.border-bottom-color]="theme?.colors?.primary">
        {{ 'MEET_PAGE.meet-format' | translate }} 
      </h3>
      <div class="aside-meet-content">
        <a (click)="navigateToFormat(format)">
          <h4 class="title-h4">
            <span class="link-effect">{{ format?.name }}</span>
            <div
              class="link-indicator"
              [ngStyle]="{
                'color': theme?.colors?.primary,
                'border-color': theme?.colors?.primary
              }"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </h4>
        </a>
        <p>{{ format?.description }}</p>

         <div class="stats-module m-xs-t">
          <!--<!-- Fix duration-->
          <div *ngIf="fix" class="stats">
            <div class="stats-number">{{ fix }}</div>
            <div class="stats-legend">minutes</div>
          </div>
          <!--<!-- Min OR max duration-->
          <div *ngIf="(min || max) && !(min && max)" class="stats">
            <div class="stats-number">{{ min || max }}</div>
          </div>
          <!-- Min AND max duration-->
          <div *ngIf="min && max" class="stats">
            <div class="stats-legend">
              {{ 'MEET_PAGE.between' | translate }}
              <span class="stats-number">{{ min }}</span>
              {{ 'MEET_PAGE.and' | translate }}
              <span class="stats-number">{{ max }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
