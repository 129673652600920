import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LinkyModule } from 'angular-linky';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { ClickOutsideModule } from 'ng-click-outside';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { TranslateModule } from '@ngx-translate/core';
import {
  SearchByTagComponent,
  SearchByCityComponent,
  TagsComponent,
  CropperComponent,
  ResourceService,
  BlockPostArticleComponent,
  BlockPostBannerComponent,
  PhotoGalleryComponent,
  SearchbarComponent,
  InputFileComponent,
  InputMailsComponent,
  CreateQuestionsFormComponent,
  AskQuestionsFormComponent,
  DisplayAnswersComponent,
  OffersShowcaseComponent,
  GetDemoInputComponent,
  WysiwygComponent,
  DisplayFileInfoComponent,
} from './components/';

import {
  DateStdPipe,
  FormatDatePipe,
  FormatTimePipe,
  ValuesPipe,
  SingularizePipe,
  PluralizePipe,
  SanitizeHtmlPipe,
  MeetDatePipe,
  AlphabeticalOrderPipe,
  MeetTimePipe,
  CustomTranslatePipe,
  ImageResizePipe,
  ImageResizeWidthPipe
} from './pipes/';

import {
  FillSvgColorsDirective,
  FillElementColorsDirective,
  ShowHideDirective,
  InfiniteScrollDirective,
  AnimationScrollDirective,
  CountToDirective,
} from './directives/';

import { ViewTypeService } from './view-type.service';
import { GooglePlacesService } from 'src/app/shared/services/google-places.service';

const declarationAndExports = [
  // Pipes
  SanitizeHtmlPipe,
  MeetDatePipe,
  MeetTimePipe,
  DateStdPipe,
  FormatDatePipe,
  FormatTimePipe,
  ValuesPipe,
  AlphabeticalOrderPipe,
  SingularizePipe,
  PluralizePipe,
  CustomTranslatePipe,
  ImageResizePipe,
  ImageResizeWidthPipe,
  // Components
  SearchByTagComponent,
  SearchByCityComponent,
  CropperComponent,
  TagsComponent,
  PhotoGalleryComponent,
  BlockPostArticleComponent,
  BlockPostBannerComponent,
  SearchbarComponent,
  WysiwygComponent,
  InputFileComponent,
  InputMailsComponent,
  CreateQuestionsFormComponent,
  AskQuestionsFormComponent,
  DisplayAnswersComponent,
  DisplayFileInfoComponent,
  OffersShowcaseComponent,
  GetDemoInputComponent,
  // Directives
  FillSvgColorsDirective,
  FillElementColorsDirective,
  ShowHideDirective,
  InfiniteScrollDirective,
  AnimationScrollDirective,
  CountToDirective,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LazyLoadImageModule.forRoot({ preset: scrollPreset }),
    TranslateModule,
    ClickOutsideModule,
    LinkyModule,
  ],
  declarations: [
    ...declarationAndExports
  ],
  providers: [
    ResourceService,
    ViewTypeService,
    GooglePlacesService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  exports: [
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    FormsModule,
    RouterModule,
    LazyLoadImageModule,
    TranslateModule,
    LinkyModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ...declarationAndExports,
  ],
})
export class SharedModule { }
