<div class="row align-middle hide-m" *ngIf="config?.search_bar">
  <div class="col col-12 search-discover search-discover-map m-s-tb">
    <div class="search-bar w100 search-list">
      <div *ngIf="config.search_bar.by_city && config.search_bar.by_tag">
        <searchbar
          [colors]="colors"
          [config]="{ by_city: true, by_tag: true }"
          (onNewCity)="getSearchedCity($event)"
          (onNewTags)="getSearchedTags($event)"
        ></searchbar>
      </div>
    </div>
  </div>
</div>

<div class="group-places-map">
  <div class="marker-legend hide-m" *ngIf="markerLayersCategories?.length > 1">
    <ng-container *ngFor="let category of markerLayersCategories">
      <div
        *ngIf="category.symbol && category.title"
        class="m-xs-b p-tb-5 p-l-5 border-radius pointer"
        [style.border-color]="category.color"
        [style.color]="category.selected ? '#FFFFFF' : category.color"
        [style.background-color]="category.selected ? category.color : '#FFFFFF'"
        (click)="onSelectCategory(category)"
      >
        <span class="bold text-small">
          <i [ngClass]="['fa', category.symbol]" aria-hidden="true"></i>
          {{ category.title | customTranslate }}
        </span>
      </div>

    </ng-container>
  </div>

  <div
    id="leafletMap"
    #leafletMap
    class="custom-map-leaflet"
    (click)="originGroup = null"
  >
  </div>

  <div *ngIf="originGroup" class="translation-x-over group-place-info place-info-window">
    <a class="close-place" (click)="onClosePlace()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>

    <ng-container *ngIf="!showMeets && originGroup">
      <div
        class="content-img"
        [ngStyle]="{
          'background-image': 'url(' + (originGroup.photos && originGroup.photos[0] ? originGroup.photos[0].slug : '' ) + ')'
        }"
      >
        <div class="overlay-place"></div>
        <h2
          class="link-effect"
          (click)="onOpenSelectedLink()"
        >
          {{ originGroup.name }}
        </h2>
      </div>

      <div class="content-text">
        <div class="content-desc">
          <p class="p-static" [innerHtml]="originGroup?.description | linky | sanitizeHtml"></p>
        </div>

        <group-details-block-info [group]="originGroup"></group-details-block-info>
      </div>
    </ng-container>

    <div class="m-s-tb text-center">
      <button
        (click)="onOpenSelectedLink()"
        [style.border-color]="colors.primary"
        [style.color]="colors.primary"
        class="button outline m-s-r"
      >
        {{ 'GROUP_LINKS_MAP.open' | translate }}
      </button>
      <button
       *ngIf="!showMeets && !creationBox?.hide"
        (click)="onCreateMeet(originGroup)"
        [style.background-color]="colors.primary"
        class="m-s-r"
      >
        {{ creationBox.text | customTranslate }}
      </button>
      <button
        *ngIf="!showMeets && originGroup.meets_count > 0"
        (click)="onShowMeets(originGroup)"
        [style.border-color]="colors.primary"
        [style.color]="colors.primary"
        class="button outline"
      >
        {{ 'GROUP_LINKS_MAP.meets' | translate }}
        <i class="m-xs-l far fa-angle-down" aria-hidden="true"></i>
      </button>

      <button
        *ngIf="showMeets"
        class="button outline"
        (click)="onCloseMeets()"
      >
        {{ 'GROUP_LINKS_MAP.desc' | translate }}
        <i class="m-xs-l fa fa-angle-up" aria-hidden="true"></i>
      </button>
    </div>

    <meets-list
      *ngIf="showMeets"
      [meetsCollection]="meetsCollection"
      [creationBox]="creationBox"
      (selectMeet)="selectMeet.next($event.meet)"
      (create)="onCreateMeet(originGroup)"
      class="group-place-meet"
    >
    </meets-list>

  </div>
</div>
