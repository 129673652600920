
import { mergeMap, map, filter } from 'rxjs/operators';
import { Renderer2, ElementRef, OnInit,
  Directive, Inject, forwardRef,
  Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { AppStore } from '../../store/app.store';

@Directive({
    selector: '[fillColors]'
})

export class FillElementColorsDirective implements OnInit {

  @Input('fillColors') param: string;

  constructor(
    private _renderer:        Renderer2,
    private _route:           ActivatedRoute,
    private _el:              ElementRef,
    @Inject(forwardRef(() => AppStore)) private _appStore:            AppStore
  ) {
  }

  ngOnInit(): void {
    this._route.queryParams.pipe(
      filter(params => params['_'] ? true : false),
      map(params => params['_']),
      mergeMap(id => this._appStore.load('Groups', id).entity),)
      .subscribe(group => {
        if (group.options && group.options.colors) {
          let properties = this.param.split(',');
          properties.forEach(property => {
            this._renderer.setStyle(this._el.nativeElement, property, group.options.colors.primary);
          });
        }
      });
  }

  private hexToRgbaColor(color: string, opacity: number): string {
    if (color == '')
      return '';

    const tranformToHex = (n: string) => parseInt('0x' + n);

    const first = tranformToHex(color.substr(1, 2)).toString();
    const second = tranformToHex(color.substr(3, 2)).toString();
    const third = tranformToHex(color.substr(5, 2)).toString();

    return first + ',' + second + ',' + third + ',' + opacity.toString();
  }

}
