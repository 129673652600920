<!-- Loader -->
<snippet-loader *ngIf="!tags"></snippet-loader>

<!-- Widget -->
<div *ngIf="tags" class="row">
  <div class="col col-12 aside-meet-article">
    <h3 class="section-title" [style.border-bottom-color]="theme?.colors?.primary">
      {{ 'Tag' | pluralize }}
      <span *ngIf="tags?.length > 1">({{ tags?.length }})</span>
    </h3>
    <div class="aside-meet-content tag-meet">
      <span
        *ngFor="let tag of tags"
        class="tag-component"
        [style.border-color]="theme?.colors?.primary"
      >
        {{ tag.name }}
      </span>
    </div>
  </div>
</div>
