<ng-container *ngIf="otherExtraInfos?.length > 0">
  <div class="w100 flex row align-center m-s-b" style="align-items: baseline">
    <ng-container *ngFor="let info of otherExtraInfos" [ngSwitch]="typeOf(info.value)">

      <ng-template ngSwitchCase="array">
        <div class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
          <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title }}</b></p>
          <div class="row gutters auto text-center">
            <div
              *ngFor="let value of info.value"
              class="borders-all bg-white p-tb-5 p-lr-5 m-xxs-b m-xxs-r border-radius text-base"
            >
              {{ value }}
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template ngSwitchCase="file">
        <div *ngIf="info.value.length" class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
          <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title }}</b></p>
          <div class="row gutters auto text-center">
            <p class="p-tb-5 p-lr-5 m-xxs-b text-base">
              <display-file-answer [fileInfo]="info"></display-file-answer>
            </p>
          </div>
        </div>
      </ng-template>

      <ng-template ngSwitchCase="string">
        <div *ngIf="info.value.length < 75" class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
          <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title }}</b></p>
          <div class="row gutters auto text-center">
            <p
              class="p-tb-5 p-lr-5 m-xxs-b text-base"
              [innerHtml]="info.value | sanitizeHtml"
            >
            </p>
          </div>
        </div>
        <div *ngIf="info.value.length >= 75" class="col col-12 m-s-tb bg-lighter-grey">
          <div class="col col-10 offset-1 p-tb-15">
            <h4 class="subtitle" [style.color]="color">{{ info.title }}</h4>
            <p
              class="p-tb-5 p-static"
              [innerHtml]="info.value | sanitizeHtml"
            >
            </p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>

<div
 *ngIf="opening
 || group?.location?.address
 || group?.location?.city
 || contactPerson
 || telephone
 || website
 || email"
  class="row"
>
  <div class="w100 p-t-30 p-b-30 dynamic-content bg-lighter-grey m-s-tb">
    <div class="align-middle icon-section-meet">

      <div *ngIf="opening" class="icon-infos m-s-b">
        <i class="fa fa-clock-o meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <div>
          <p>{{ opening }}</p>
        </div>
      </div>

      <div *ngIf="group?.location?.address || group?.location?.city" class="icon-infos m-s-b">
        <i class="fa fa-map-marker meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <div>
          <p>{{ group?.location?.address }}</p>
          <p>{{ group?.location?.zip_code }} {{ group?.location?.city }}</p>
        </div>
      </div>

      <div *ngIf="contactPerson" class="icon-infos m-s-b">
        <i class="fa fa-user meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <div>
          <p>{{ contactPerson }}</p>
        </div>
      </div>

      <div *ngIf="telephone" class="icon-infos m-s-b">
        <i class="fa fa-phone meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <div>
          <p>{{ telephone }}</p>
        </div>
      </div>

      <div *ngIf="website" class="icon-infos m-s-b">
        <i class="fa fa-external-link meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <div>
          <a [href]="websiteUrl" target="_blank" rel="noopener" [style.color]="color">{{ website.length > 35 ? (website | slice:0:35) + '...' : website }}</a>
        </div>
      </div>

      <div *ngIf="email" class="icon-infos m-s-b">
        <!-- <pre>{{ info | json }}</pre> -->
        <i class="fa fa-envelope meet-template-i text-very-large" [style.color]="color" aria-hidden="true"></i>
        <p>
          <a [href]="'mailto:' + email" [style.color]="color">{{ email }}</a>
        </p>
      </div>

    </div>
  </div>
</div>
