
import { pluck } from 'rxjs/operators';
// Angular imports
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Interface imports
import { Meet, Group, Location, ITheme } from '../../../../shared/';
import { AppStore } from 'src/app/store/app.store';

declare var L: any;

@Component({
  selector: 'meet-place',
  templateUrl: './meet-place.component.html',
})
export class MeetPlaceComponent implements OnChanges {
  @Input() meet:           Meet;
  @Input() isMeetFetching: boolean;
  @Input() viewType:       string;

  // !!! In db, a group and a place are the same. !!!
  // If a group is a place too, Group.place: boolan = true.

  /**
   * Data used in the template
   */
  map:                    L.Map;

  place:                  Group;

  placeShortDescription:  string;
  placePictureSlug:       string;
  placePictureAlt:        string;

  locationName:           string;
  locationAddress:        string;
  locationCity:           string;
  locationZipCode:        string;
  locationMapsUrl:        string; // Google maps url
  locationLat:            number;
  locationLng:            number;

  // By default we display map. If there is an image to display instead, we set
  // this value to false in ngOnChanges.
  hideMap:                boolean = false;

  theme:     ITheme;

  constructor(
    private _route:     ActivatedRoute,
    private _router:    Router,
    private _appStore:  AppStore
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }


  ngOnChanges(change: SimpleChanges): void {
    if (change.meet && change.meet.currentValue) {
      if (change.meet.currentValue.place) {
        if (this.viewType == 'desktop') {
          this.hideMap = true;
        }
        this.updatePlaceValues(change.meet.currentValue.place);
        this.updateLocationValues(change.meet.currentValue.place.location);
      }
      else if (change.meet.currentValue.location) {
        this.updateLocationValues(change.meet.currentValue.location);
      }
    }
  }

  updatePlaceValues(p: Group): void {
    this.place = p;
    this.placeShortDescription = this.getShortDescription(p.description);
    if (p.photos && p.photos.length > 0) {
      this.placePictureSlug = p.photos[0].slug;
    }
    this.placePictureAlt = p.name.toLowerCase().replace(' ', '_');
  }

  updateLocationValues(l: Location): void {
    this.locationName = l.name;
    this.locationAddress = l.address;
    this.locationCity = l.city;
    this.locationZipCode = l.zip_code;
    this.locationMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${l.lat},${l.lng}`;
    this.locationLat = l.lat;
    this.locationLng = l.lng;
    // console.log('updateLocationValues()'); // Debug purpose only
  }

  getShortDescription(desc: string): string {
    if (desc.length < 120)
      return desc;
    return desc.slice(0, desc.slice(0, 120).lastIndexOf(' ')) + ' ...';
  }

  navigateToPlace(place: Group) {
    const principalGroupId = this._route.parent.snapshot.params['id'];
    console.log(principalGroupId);

    if (!principalGroupId) {
      if (place.options && place.options.master_group_id) {
        this.navigateToGroupLink(`${place.options.master_group_id}`, `${place.id}`);
      }
      else {
        this._router.navigate(['/home', place.id])
      }
    }
    else if (place.id == parseInt(principalGroupId)) {
      this._router.navigate(['/home', place.id])
    }
    else {
      this.navigateToGroupLink(principalGroupId, `${place.id}`);
    }
  }

  navigateToGroupLink(principalGroupId: string, placeId: string) {
    this._appStore.load('GroupLink', principalGroupId, { origin_group_id: placeId })
      .entityByProps
      .subscribe(
        link => this._router.navigate(['/home', principalGroupId, link.flag, placeId]),
        error => this._router.navigate(['/home', principalGroupId, 'basic', placeId])
      );
  }
}
