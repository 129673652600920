
import { pluck } from 'rxjs/operators';
// Angular imports
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// Interfaces
import { Meet, Participant, Member, ITheme, User } from '../../../../shared';
import { ActivatedRoute } from '@angular/router';
import { AppStore } from '../../../../store/index';

@Component({
  selector: 'meet-description',
  templateUrl: './meet-description.component.html',
})

export class MeetDescriptionComponent {
  @Input() meet:                   Meet;
  @Input() isMeetFetching:         boolean = true;

  @Input() organizators:           Participant[];
  @Input() isOrganizatorsFetching: boolean = true;

  @Input() viewType:               string;

  // Data binded in the template
  meetDescription:              string = '';
  organizatorId:                number = 0;
  organizatorName:              string = '';
  organizatorPictureSlug:       string = '';
  nbMeetOrganizedByOrganisator: number = 0; // default at 0, so the template wont display it

  // Others
  theme: ITheme;

  constructor(
    private _route:     ActivatedRoute,
    private _appStore:  AppStore
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnChanges(change: SimpleChanges): void {
    // console.log('meet-description change', change); // Debug purpose

    if (change.meet && change.meet.currentValue) {
      this.meetDescription = change.meet.currentValue.description;
      if (change.meet.currentValue.options && change.meet.currentValue.options.organizator) {
        this.organizatorName = change.meet.currentValue.options.organizator;
      }
    }

    if (change.organizators && change.organizators.currentValue && change.organizators.currentValue[0]) {
      const o: Member = change.organizators.currentValue[0].member;

      //this.nbMeetOrganizedByOrganisator = o.organizations_count;
      this.organizatorPictureSlug = o.avatar.slug;
      this.organizatorId = o.user_id;
      this.setOrganizatorInfo(this.organizatorId);
      //if (!this.organizatorName) // Dirty cause it won't update if the org set a new profile name.
        //this.organizatorName = `${o.first_name} ${o.last_name && o.last_name[0]}`;
    }
    // console.log('meet-description this', this); // Debug purpose
  }

  setOrganizatorInfo(userId: number) {
    this._appStore.load('User', userId.toString())
      .entity
      .subscribe((o: User) => {
        if (!this.organizatorName)
          this.organizatorName = `${o.first_name} ${o.last_name && o.last_name[0]}`;
        this.nbMeetOrganizedByOrganisator = o.organized_meets_count.total;
      });
  }
}
