import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main.component';
import { HomeComponent } from 'src/app/main/home';
import { DiscoverComponent, DiscoverMeetsComponent, DiscoverGroupsComponent,
  DiscoverPlacesComponent } from './discover/';
import { MeetPageComponent, MeetFeedbackComponent } from '../meets/';
import { authRoutes } from '../auth/';
import { FormatDetailsComponent } from '../formats/';
import { PageNotFoundComponent } from '../core/page-not-found.component';
import { UrlTranslationGuard } from '../core/url-translation.guard';
import { ThemeResolver } from '../core/theme.resolver';

const mainRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { meta: { title: 'Kawaa - On a tant à partager' } }
  },
  {
    path: 'fr',
    component: MainComponent,
    data: { lang: 'fr', meta: { title: 'Kawaa - On a tant à partager' } } ,
    canActivate: [ UrlTranslationGuard ],
    children: [
      ...authRoutes,
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: '', pathMatch: 'full', redirectTo: '/' },
      {
        path: 'decouvrir',
        component: DiscoverComponent,
        children: [
          {
            path: 'rencontres',
            component: DiscoverMeetsComponent,
            data: { meta: { title: 'Rencontres - Kawaa' } }
          },
          {
            path: 'groupes',
            component: DiscoverGroupsComponent,
            data: { meta: { title: 'Groupes - Kawaa' } }
          },
          {
            path: 'lieux',
            component: DiscoverPlacesComponent,
            data: { meta: { title: 'Lieux - Kawaa' } }
          },
        ],
      },
      {
        path: 'rencontre/:id',
        canActivate: [ UrlTranslationGuard ],
        resolve: { theme: ThemeResolver },
        component: MeetPageComponent
      },
      {
        path: 'avis/:id',
        canActivate: [ UrlTranslationGuard ],
        component: MeetFeedbackComponent
      },
      {
        path: 'format/:id',
        component: FormatDetailsComponent
      },
   ]
  },
  {
    path: 'en',
    component: MainComponent,
    data: { lang: 'en', meta: { title: 'Kawaa - We have so much to share' } },
    children: [
      ...authRoutes,
      { path: '', pathMatch: 'full', redirectTo: '/' },
      { path: 'page-not-found', component: PageNotFoundComponent },
      {
        path: 'discover',
        component: DiscoverComponent,
        children: [
          {
            path: 'events',
            component: DiscoverMeetsComponent,
            data: { meta: { title: 'Events - Kawaa' } }
          },
          {
            path: 'groups',
            component: DiscoverGroupsComponent,
            data: { meta: { title: 'Groups - Kawaa' } }
          },
          {
            path: 'places',
            component: DiscoverPlacesComponent,
            data: { meta: { title: 'Places - Kawaa' } }
          }
        ]
      },
      {
        path: 'event/:id/:title',
        component: MeetPageComponent
      },
      {
        path: 'event/:id',
        component: MeetPageComponent
      },
      {
        path: 'feedbacks/:id',
        component: MeetFeedbackComponent
      },
      {
        path: 'format/:id',
        component: FormatDetailsComponent
      }
   ]
  },
  {
    component: MainComponent,
    path: '',
    children: [
      { path: 'st', loadChildren: () => import('./static/static.module').then(m => m.StaticModule) }
    ]
  }
];

export const mainRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(mainRoutes);
