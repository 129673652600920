import { Component, Input, SimpleChanges,
  OnChanges, ViewChild, ElementRef,
  Inject }  from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IColors, IPostAction, Group, Post }  from '../shared/';
import { AppStore }  from '../store/';

@Component({
  selector: 'group-block-posts',
  templateUrl: './group-block-posts.component.html'
})

export class GroupBlockPostsComponent implements OnChanges {

  @Input() group:         Group;
  @Input() flags:         string;
  @Input() colors:        IColors = { primary: '' };

  @ViewChild('dynamicContent', { static: true }) dynamicContentEl:       ElementRef;
  dynamicContentRange:    Range;

  posts:                  Post[] = [];
  coverPost:              Post;

  coverPostImgSlug:       string = '';
  coverPostAlignment:     string = 'text-left';
  coverPostShowContent:   boolean = false;
  coverPostClasses:       string[] = ['content-width'];
  coverResourceClasses:   string[] = ['m-m-t', 'landscape-3-1', 'border-radius-t-r', 'border-radius-t-l'];

  constructor(
    @Inject(DOCUMENT)
    private _document:        any,
    private _appStore:        AppStore
  ) {
    this.dynamicContentRange = _document.createRange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.group && this.flags) {
      this.setPosts(this.group.id.toString(), this.flags);
    }
  }

  setPosts(groupId: string, flags: string) {
    this._appStore.load('GroupPosts', groupId, { flags })
      .entities
      .subscribe((posts: Post[]) => {
        this.posts = posts
          .filter((post: Post) => post.category != 'cover')
          .map((post: Post) => {
            if (post.category == 'custom') {
              post.content = post.content.replace(/[\n|\r]/gi, '');
            }
            return post;
          });
        this.setCoverPost(posts);
        this.setDynamicContentCustomPost(posts);
      });
  }

  setCoverPost(posts: Post[]) {
    const post: Post = posts.find(_ => _.category == 'cover');
    if (!post)
      return ;

    this.coverPost = post;

    if (post.resources && post.resources.length > 0) {
      this.coverPostImgSlug = this.coverPost.resources[0].slug;
    }

    // set props from options
    const { options } = post;
    if (!options)
      return ;

    if (options.post_classes) {
      this.coverPostClasses = options.post_classes;
    }
    if (options.resource_classes) {
      this.coverResourceClasses = options.resource_classes;
    }

    if (options.title != 'hide' && post.title != '')
      this.coverPostShowContent = true;
    if (options.actions || post.content != '')
      this.coverPostShowContent = true;

    if (options.orientation == 'center') {
      this.coverPostAlignment = 'text-center';
    }
    else if (options.orientation == 'right') {
      this.coverPostAlignment = 'text-right';
    }
  }

  setDynamicContentCustomPost(posts: Post[]) {
    const post: Post = posts.find(_ => _.category == 'custom');
    if (!post || !post.options)
      return ;

    const { dynamic_content } = post.options;
    if (!dynamic_content)
      return ;

    const el = this.dynamicContentEl.nativeElement;

    if (el) {
      const content = this.dynamicContentRange.createContextualFragment(dynamic_content);
      el.appendChild(content);
    }
    else {
      this.dynamicContentRange.selectNodeContents(el);
      this.dynamicContentRange.deleteContents();
    }
  }
}
