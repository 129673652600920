import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth';
import { IColors, Group, IHeaderSection, Post, User, Member } from '../shared';
import { AuthRedirectService } from '../auth/auth-redirect.service';
import { AppStore } from '../store/app.store';

@Component({
  selector: 'custom-header',
  templateUrl: './custom-header.component.html'
})

export class CustomHeaderComponent implements OnInit, OnChanges {

  @Input() group: Group;

  colors:           IColors = { primary: '#000000' };
  isLoggedIn$:      Observable<boolean>;

  logoSlug:         string = '/assets/images/avatar_groupe.jpg';
  externalLogo:     IHeaderSection;
  sections:         IHeaderSection[] = [];
  selectedSection:  string;
  isPremium:        boolean = true;
  userId:           number;
  member:           Member;
  sectionAccessList:  string[];
  allowAccess:      boolean = false;
  showNavLeft:      boolean = false;
  showNavRight:     boolean = false;

  constructor(
    private _appStore:      AppStore,
    private _route:         ActivatedRoute,
    private _router:        Router,
    private _authService:   AuthService,
    private _authRedirectService: AuthRedirectService,
  ) {
    this.isLoggedIn$ = this._authService.getLoginStatus();
  }

  ngOnInit() {
    this.selectedSection = this._route.snapshot.firstChild.params.page_name || 'home';
    this._authService.getUser()
      .subscribe((usr: User) => {
        if (usr && usr.id) {
          this.userId = usr.id;
        }
      });

    this.isLoggedIn$.subscribe(status => {
      if (this._route.snapshot.firstChild.params.page_name == 'espace-membre') {
        if (status) {
          this.getGroupMember();
        }
        else {
          this.redirectIfNotAllowed();
        }
      }
      else {
        this.getGroupMember();
      }
    });
  }

  redirectIfNotAllowed() {
    this.isLoggedIn$.subscribe(status => {
      console.log('connected:' + status);
      console.log('AllowAccess: ' + this.allowAccess);
      if (!status) {
        this._authRedirectService.authenticateThenGoBack({ tab: 'login' }, this._route.snapshot.queryParams['_']);
      }
      if (status && !this.allowAccess) {
        this._router.navigate(['/home', this.group.id], { queryParams: { '_': this.group.id } });
      }
    });
  }

  ngOnChanges(changes) {
    if (!this.group)
      return ;

    if (this.group) {
      this.loadSections(this.group.id.toString());
    }

    if (this.group.avatar && this.group.avatar.slug) {
      this.logoSlug = this.group.avatar.slug;
    }

    if (this.group && this.group.options && this.group.options.colors) {
      this.colors = this.group.options.colors;
    }
    this.colors.header_background = this.colors.header_background ? this.colors.header_background : '';
    this.colors.header_text = this.colors.header_text ? this.colors.header_text : this.colors.primary;
    this.colors.header_btn_background = this.colors.header_btn_background ? this.colors.header_btn_background : this.colors.primary;
    this.colors.header_btn_text = this.colors.header_btn_text ? this.colors.header_btn_text : '#FFFFFF';
  }

  loadSections(id: string) {
    this._appStore.load('GroupPosts', id, { category: 'header' })
      .entities
      .subscribe(
        (posts: Post[]) => {
          if (posts.length > 0 && posts[0].options && posts[0].options.header_sections) {
            this.sections = posts[0].options.header_sections;
            this.externalLogo = this.sections.find(section => section.flags == 'external_logo');
          }
          else {
            this.isPremium = false;
          }
        },
        (error: string) => console.log(error)
      );
  }

  getGroupMember() {
    this._appStore.load('Members', this.group.id.toString())
    .entities
    .subscribe((members: Member[]) => {
      members.find((member: Member) => {
        if (member.user_id == this.userId) {
          this.allowingAccessToRestrictedPage(member);
        }
        else if (!this.allowAccess && this._route.snapshot.firstChild.params.page_name == 'espace-membre') {
          this._router.navigate(['/home', this.group.id], { queryParams: { '_': this.group.id } });
        }
      });
    });
  }

  allowingAccessToRestrictedPage(member: Member) {
    if (this.sections) {
      this.sections.forEach((section: IHeaderSection) => {
        if (section.access) {
          this.allowAccess = section.access.some(role =>  member.role == role);
        }
      });
    }
  }

  onSelectSection(group: Group, section: IHeaderSection): void {
    if (!section) return;

    this.selectedSection = section.flags;

    if (section.flags == 'meet_create') {
      this._router.navigate(['/admin', 'rencontres', 'creer', { groupId: group.id }], { queryParams: { '_': group.id } });
    }
    else if (section.flags == 'external_link') {
      this.navigateExtern(section);
    }
    else {
      this.navigateInHome(section, group);
    }

    this.showNavRight = false;
  }

  navigateExtern(section: IHeaderSection): void {
    const { config } = section;

    if (config && config.link && config.open_in_new_tab) {
      window.open(config.link, '_blank');
    }
    else if (config && config.link && !config.open_in_new_tab) {
      window.open(config.link, '_self');
    }
  }

  navigateInHome(section: IHeaderSection, group: Group): void {
    this.showNavLeft = false;
    const segments: UrlSegment[] = this._route.snapshot.url;

    const flags: string = section.flags;
    const fragment: string = section.anchor;

    if (segments.length > 2 && flags == 'home') {
      this._router.navigate(['..'], { relativeTo: this._route, fragment: fragment });
    }
    else if (segments.length == 2 && flags == 'home') {
      this._router.navigate(['.'], { relativeTo: this._route, fragment: fragment });
    }
    // is on one of sub pages
    else if (segments.length > 2) {
      this._router.navigate(['..', flags], { relativeTo: this._route, fragment: fragment });
    }
    // is in Admin
    else {
      this._router.navigate(['/home', group.id, flags], { relativeTo: this._route, fragment: fragment });
    }
  }

  onAuth(tab: 'register'|'login') {
    this.showNavLeft = false;

    if (!this._route.snapshot.queryParams.tab) {
      this._authRedirectService.authenticateThenGoBack({ tab }, this._route.snapshot.queryParams['_']);
    }
  }
}
