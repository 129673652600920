<div [ngClass]="postClasses">

  <div class="w100" *ngIf="post?.options?.orientation == 'left' || (viewType$ | async) == 'mobile'">
    <div class="row align-middle">
      <div
        *ngFor="let resource of post.resources"
        [animation-scroll]="'block-appear-l-r'"
        [ngClass]="resourceClasses"
        class="col push-left"
      > 
        <img style="display: block" *ngIf="resource?.nature == 'photo'" [src]="resource.slug" [alt]="resource.title" class="img-auto img-contain">
        <a *ngIf="resource?.nature == 'doc'" [href]="resource.slug" target="_blank" rel="noopener">{{ resource.legend }}</a>
      </div>

      <div
        [animation-scroll]="'block-appear-b-t'"
        [ngClass]="contentClasses"
        class="col push-right"
      >
        <div class="m-m-b" *ngIf="post?.options?.title != 'hide'">
          <h3 class="section-title-small fit-content" [ngStyle]="{'border-color': colors.primary}">
            {{ post.title }}
          </h3>
        </div>
        <p
          [ngClass]="textClasses"
          [ngStyle]="post.options.text_styles"
          [innerHtml]="post.content | linky | sanitizeHtml"
        ></p>
        <a
          *ngFor="let action of post.options?.actions"
          [href]="action.url"
          [target]="action.open_in_new_tab ? '_blank' : '_self'"
          [ngClass]="actionClasses"
          [ngStyle]="actionStyles"
          class="m-s-r m-s-tb"
        >
          {{ action.text }}
        </a>
      </div>
    </div>
  </div>

  <div class="w100" *ngIf="post?.options?.orientation == 'right' && (viewType$ | async) == 'desktop'">
    <div class="row align-middle">
      <div
        [animation-scroll]="'block-appear-b-t'"
        [ngClass]="contentClasses"
        class="col push-left"
      >
        <div class="m-m-b" *ngIf="post?.options?.title != 'hide'">
          <h3 class="section-title-small fit-content" [ngStyle]="{'border-color': colors.primary}">
            {{ post.title }}
          </h3>
        </div>
        <p
          [ngClass]="textClasses"
          [ngStyle]="post.options.text_styles"
          [innerHtml]="post.content | linky | sanitizeHtml"
        ></p>
        <a
           *ngFor="let action of post.options?.actions"
           [href]="action.url"
           [target]="action.open_in_new_tab ? '_blank' : '_self'"
           [ngClass]="actionClasses"
           [ngStyle]="actionStyles"
           class="m-s-r m-s-tb"
           >
            {{ action.text }}
        </a>
      </div>
      <div
        *ngFor="let resource of post.resources"
        [animation-scroll]="'block-appear-r-l'"
        class="col push-right"
        [ngClass]="resourceClasses"
      > 
        <img style="display: block" *ngIf="resource?.nature == 'photo'" [src]="resource.slug" [alt]="resource.title" class="img-auto img-contain">
        <a *ngIf="resource?.nature == 'doc'" [href]="resource.slug" target="_blank" rel="noopener">{{ resource.legend }}</a>
      </div>
    </div>
  </div>

</div>
