import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Meet, Group } from '../../shared/';

@Component({
  templateUrl: './discover-places.component.html'
})

export class DiscoverPlacesComponent {

  constructor(
    private _router:       Router
  ) { }

  onSelectMeet(meet: Meet) {
    this._router.navigate(['fr', 'rencontre', meet.id]);
  }

  onCreateMeet(place: Group) {
    this._router.navigate(['/admin', 'rencontres', 'creer', { placeId: place.id }]);
  }
}
