import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { Location } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ViewTypeService, IViewType } from 'src/app/shared/view-type.service';
import { IColors, IArea } from 'src/app/shared/definitions';
import { SearchByTagComponent } from 'src/app/shared/components/searchbar/search-by-tag.component';
import { getFormatedDate } from 'src/app/shared/helpers/common.funcs';
import { query } from '@angular/animations';

interface TagImage {
  name: string,
  title: string,
  slug: string,
  size: number[],
  selected?: boolean
};

@Component({
  templateUrl: './searchbar.component.html',
  selector: 'searchbar'
})

export class SearchbarComponent {
  @ViewChild(SearchByTagComponent) child: SearchByTagComponent;

  @Input()  predefinedTags:          string[];
  @Input()  predefinedTagsWithImage: { name: string, title: string, slug: string, size: number[] }[] = [];
  @Input()  colors:                  IColors;
  @Input()  config:                  { by_tag?: boolean, by_city?: boolean, by_date?: boolean } = {};

  @Output() onNewCity:              EventEmitter<IArea> = new EventEmitter<IArea>();
  @Output() onNewTags:              EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() onNewPeriodMeets:       EventEmitter<string> = new EventEmitter<string>();


  actualPeriod:        Date = new Date();
  showFilters:         boolean = false;
  showThemesDetails:   boolean = false;
  showTags:            boolean = true;
  showCalendarDetails: boolean = false;
  show:                string = '';
  filter:              string = '';
  isDatePicked:        boolean = false;
  viewType$:           Observable<IViewType> = this._viewType.get();
  tagButtons:          { name: string, selected: boolean }[] = [];
  tagImages:           TagImage[] = []; // Default configurated themes
  selectedTagImages:   Set<string> = new Set<string>();                                                          // Selected themes
  activeFilters:       { position: boolean, themes: boolean, calendar: boolean } = { position: false, themes: false, calendar: false };
  selectedTags:        number = 0;

  constructor(
    private _viewType: ViewTypeService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.predefinedTags && changes.predefinedTags.currentValue) {
      this.tagButtons = this.predefinedTags.map(tag => ({ name: tag, selected: false }));
    }
    if (this.predefinedTagsWithImage) {
      this.tagImages = this.predefinedTagsWithImage;
    }

    this._activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.tagImages.forEach((obj: TagImage) => obj.selected = false);
      this.selectedTagImages.clear();
      this.selectedTags = 0;

      if (!queryParams.themes || (Array.isArray(queryParams.themes) && !queryParams.themes.length)) return;

      const params = Array.isArray(queryParams.themes) ? queryParams.themes : [queryParams.themes];

      params.forEach((param: string) => {
        const tagImage = this.tagImages.find(el => el.name === `#${param}`);
        if (tagImage) {
          tagImage.selected = true;
          !this.selectedTagImages.has(param) && this.selectedTagImages.add(tagImage.name);
          this.selectedTags++;
        }
      })
      this.onNewTags.emit(Array.from(this.selectedTagImages));
     });
  }

  public ngAfterViewInit(): void {
    this.child.totalTagsSelected = this.selectedTags;
    this.selectedTagImages.forEach((tag: string) => this.child.tagLabels.push({ name: tag, show: false }));
  }

  public ngOnInit(): void {
    if (this.tagImages.length > 0 || this.tagButtons.length > 0) {
      this.filter = 'themes';
    }
  }

  public setPeriodMeets(timeScope: string): void {
    if (this.show == 'today' || this.show == 'thisweek') {
      this.activeFilters.calendar = true;
      this.onNewPeriodMeets.emit(timeScope);
    } else {
      this.onNewPeriodMeets.emit('');
    }
  }

  public onClickShowToday(): void {
    if(this.show == 'today') {
      this.show = '';
      this.activeFilters.calendar = false;
      this.setPeriodMeets('');
    } else {
      this.show = 'today';
      this.showCalendarDetails = false;
      this.isDatePicked = false;
      this.setPeriodMeets('today');
    }
  }

  public onClickShowThisWeek(): void {
    if (this.show == 'thisweek') {
      this.show = '';
      this.activeFilters.calendar = false;
      this.setPeriodMeets('');
    } else {
      this.show = 'thisweek';
      this.showCalendarDetails = false;
      this.isDatePicked = false;
      this.setPeriodMeets('thisweek');
    }
  }

  onClickThisShowMonth(): void {
    if (this.isDatePicked) {
      this.isDatePicked = false;
      this.activeFilters.calendar = false;
      this.onNewPeriodMeets.emit('');
    } else {
      this.show = 'month';
    }
  }

  public onClickShowThemes(): void {
    this.filter = 'themes';
    this.showFilters = !this.showFilters;
  }

  public onClickShowPosition(): void {
    this.filter = 'position';
    this.showFilters = !this.showFilters;
  }

  public onClickShowCalendar(): void {
    this.filter = 'calendar';
    this.showFilters = !this.showFilters;
  }

  public onClickedOutside(e: Event): void {
    if (this.showFilters) {
      this.showFilters = !this.showFilters;
    }
    if (this.showThemesDetails) {
      this.showThemesDetails = !this.showThemesDetails;
    }
    if (this.showCalendarDetails) {
      this.showCalendarDetails = !this.showCalendarDetails;
    }
    if(this.show == 'month') {
      this.show = '';
    }
  }

  public setTag(btn: { name: string, selected: boolean }): void {
    this.child.setTagButton(btn);

    this.selectedTags = this.child.totalTagsSelected;
    this.activeFilters.themes = this.selectedTags > 0 ? true : false;

    const tag = btn.name;
    btn.selected ? this.selectedTagImages.add(tag) : this.selectedTagImages.delete(tag);

    this._applyFiltersToRoute();
  }

  public getNewDate(period: string, date: Date): void {
    period = getFormatedDate(date,'YYYY-MM-DD');
    this.onNewPeriodMeets.emit(period);
    this.actualPeriod = date;
    this.isDatePicked = true;
    this.activeFilters.calendar = period ? true : false;
  }

  public onSearchTags(tags: string[]): void {
    this.onNewTags.emit(tags);
  }

  public onSearchCity(area: IArea): void {
    this.activeFilters.position = area.city ? true : false;
    this.onNewCity.emit(area);
  }

  private _applyFiltersToRoute(): void {
    let queryParams = new HttpParams();

    this.selectedTagImages.forEach((tag: string) => {
      queryParams = queryParams.append('themes', tag.replace(/\#/, ''));
    });

    const url = this._router.createUrlTree([], {relativeTo: this._activatedRoute, queryParams: { themes: queryParams.getAll('themes') } }).toString();
    this._location.replaceState(url);
  }
}
