import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

export interface IConfig {
  kwebUrl: string;
  hostUrl: string;
  apiEndpoint: string;
  apiKey: string;
}

declare var env: any;

@Injectable()
export class ApiConfig {
  public apiKey: string;
  public getPath: (string) => string;
  public headers: () => HttpHeaders;

  constructor() {
    const config: IConfig = {
      kwebUrl: env.kwebUrl,
      hostUrl: env.hostUrl,
      apiEndpoint: env.apiUrl,
      apiKey: env.apiKey
    };

    this.apiKey = config.apiKey;
    this.getPath = (path) => config.apiEndpoint + path;
    this.headers = () => {
      const h = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
      if (config.apiKey)
        h['X-Api-Key'] = config.apiKey;
      return new HttpHeaders(h);
    };
  }
}
