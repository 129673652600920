import { DOCUMENT, Location, PopStateEvent } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, UrlTree } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { GtmService } from './core';
import { MetaService } from './core/meta.service';
import { TrackingService } from './core/tracking.service';

@Component({
  selector: 'k-app',
  template: `
    <router-outlet></router-outlet>
    <loader></loader>
    <k-notification></k-notification>
    <k-cookies-banner></k-cookies-banner>
  `,
})
export class AppComponent implements OnInit {
  lastPoppedUrl: string;
  yScrollStack: number[] = [];

  constructor(
    @Inject(DOCUMENT)
    private _document: any,
    private _translate: TranslateService,
    private _location: Location,
    private _router: Router,
    private _route: ActivatedRoute,
    private _metaService: MetaService,
    private _trackingService: TrackingService,
    private gtmService: GtmService
  ) {
    _translate.setDefaultLang('fr');
    _translate.use('fr');
  }

  ngOnInit() {
    this._trackingService.init();
    this.gtmService.init();

    this._location.subscribe((popStateEvent: PopStateEvent) => {
      const tree = this._router.parseUrl(popStateEvent.url);
      this.lastPoppedUrl = this.getCleanUrl(tree);
    });

    this._router.events.pipe(filter((ev: any) => ev instanceof NavigationStart)).subscribe((ev: any) => {
      const tree = this._router.parseUrl(ev.url);
      const currentUrl = this.getCleanUrl(tree);

      if (currentUrl != this.lastPoppedUrl && !this.hasMatrixParam(ev.url)) {
        this.yScrollStack.push(window.scrollY);
      }
    });

    this._router.events.pipe(filter((ev: any) => ev instanceof NavigationEnd)).subscribe((ev: any) => {
      const tree = this._router.parseUrl(ev.url);
      const currentUrl: string = this.getCleanUrl(tree);
      const urlSegments: string[] = currentUrl.split('/');

      if (tree.fragment) {
        this.scrollToElement(tree.fragment);
      } else if (currentUrl == this.lastPoppedUrl) {
        this.lastPoppedUrl = undefined;
        this.scrollTo(this.yScrollStack.pop());
      } else if (!this.hasMatrixParam(ev.url)) {
        this.scrollTo(0);
      }
    });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._metaService.findLastChild(this._route))
      )
      .subscribe((routeData: any) => {
        if (routeData.meta) {
          this._metaService.updateMetaTags(routeData.meta);
        }
      });
  }

  getCleanUrl(tree: UrlTree): string {
    if (tree.root.children['primary']) return '/' + tree.root.children['primary'].segments.map((_) => _.path).join('/');
    else return '/';
  }

  scrollToElement(elementId: string, ms: number = 500) {
    const el = this._document.getElementById(elementId);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (ms < 4000) {
      // retry several times to find the element
      setTimeout((_) => this.scrollToElement(elementId, ms + 500), ms + 500);
    } else {
      console.error('unable to scroll');
    }
  }

  scrollTo(y: number) {
    if (window) window.scrollTo(0, y);
  }

  scrollParentWindow(fragment: string): void {
    // when in a iframe
    if (!window || !window.parent) return;

    let y: number;
    if (fragment == 'confirmation')
      // on meet-page -> participation
      y = 300;
    else if (fragment == 'lieu')
      // on meet-page -> see place
      y = 900;
    else y = -1000;

    window.parent.postMessage(`kawaaIframeScroll=${y}`, '*');
  }

  hasMatrixParam(url: string): boolean {
    const path: string[] = url.split('/');
    const lastIndex: number = path.length - 1;

    if (path[lastIndex] && path[lastIndex].indexOf(';') != -1) {
      return true;
    } else {
      return false;
    }
  }
}
