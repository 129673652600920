import { Injectable } from '@angular/core';
import { NavigationStart, Router, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { GtmService } from './gtm.service';

declare var _paq: any;

@Injectable()
export class TrackingService {
  currentCustomVariable: string;
  currentUrl: string;

  constructor(private _router: Router, private _auth: AuthService, private _gtmService: GtmService) {}

  init() {
    this._router.events.pipe(filter((ev: any) => ev instanceof NavigationStart)).subscribe((ev: any) => {
      const tree: UrlTree = this._router.parseUrl(ev.url);
      const segmentsPath: string[] = this._getSegmentsPath(tree);

      if (segmentsPath[0] == 'home' || segmentsPath[0] == 'widget') {
        this._setCustomVariable(segmentsPath[1]);
      } else if (tree.queryParams['_']) {
        this._setCustomVariable(tree.queryParams['_']);
      } else {
        this._setCustomVariable('main');
      }

      this._trackUrlChange(segmentsPath, ev.url);
    });
  }

  initGiveConsent() {
    try {
      // require user consent before processing data
      _paq.push(['requireConsent']);
      _paq.push(['trackPageView']);
    } catch (err) {
      console.log('_paq is not defined');
    }
  }

  trackConsent(consentGiven: boolean) {
    try {
      if (consentGiven) {
        // user has given consent to process their data
        _paq.push(['setConsentGiven']);
        this._gtmService.addGoogleScripts();
      } else {
        _paq.push(['forgetConsentGiven']);
        this._gtmService.removeGoogleScripts();
      }
    } catch (err) {
      this._gtmService.removeGoogleScripts();
      console.log('_paq is not defined');
    }
  }

  trackEvent(category: string, action: string, name: string, value?: string) {
    try {
      if (value) _paq.push(['trackEvent', category, action, name, value]);
      else _paq.push(['trackEvent', category, action, name]);
    } catch (err) {
      console.log('_paq is not defined');
    }
  }

  private _setCustomVariable(name: string) {
    if (this.currentCustomVariable != name) {
      this.currentCustomVariable = name;

      try {
        // see more on https://matomo.org/docs/custom-variables/
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['setCustomVariable', 1, name]);
      } catch (err) {
        console.log('_paq is not defined');
      }
    }
  }

  private _trackUrlChange(segmentsPath: string[], fullUrl: string) {
    // do not take into account login/register routes
    if (segmentsPath && segmentsPath.indexOf('k-auth') != -1) {
      return;
    }

    try {
      if (this._auth.user && this._auth.user.id) {
        _paq.push(['setUserId', `${this._auth.user.id}`]);
      }

      const url: string = '/' + segmentsPath.join('/');

      if (this.currentUrl && this.currentUrl != url) {
        _paq.push(['setReferrerUrl', this.currentUrl]);
      }

      if (this.currentUrl != url) {
        if (fullUrl.includes('pk_') || fullUrl.includes('utm_')) {
          _paq.push(['setCustomUrl', fullUrl]);
        } else {
          _paq.push(['setCustomUrl', url]);
        }

        _paq.push(['trackPageView']);
      }

      this.currentUrl = url;
    } catch (err) {
      console.log('_paq is not defined');
    }
  }

  private _getSegmentsPath(tree: UrlTree): string[] {
    if (tree.root && tree.root.children && tree.root.children['primary']) {
      return tree.root.children['primary'].segments.map((_) => _.path);
    } else {
      return [];
    }
  }
}
