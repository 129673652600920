<!-- Default -->
<button
  *ngIf="state == 'default'"
  class="going-btn"
  (click)="onParticipate()"
>
  {{ 'MEET_PAGE.going' | translate }}
</button>

<!-- On invitation -->
<button
  *ngIf="state == 'meet-on-invitation'"
  class="going-btn"
  (click)="onParticipate()"
>
  {{ 'MEET_PAGE.on-invitation-meet' | translate }}
</button>

<!-- Participant is invited -->
<button
  *ngIf="state == 'invited'"
  class="going-btn"
  (click)="onParticipate()"
>
  {{ 'MEET_PAGE.participant-invited' | translate }}
</button>

<!-- On waiting list -->
<div
  *ngIf="state == 'on-waiting-list'"
  class="already-going-btn"
  [ngStyle]="{ 'border-color': 'grey', 'color': 'grey' }"
  (click)="showDropdownActions = !showDropdownActions"
>
  {{ 'MEET_PAGE.on-waiting-list' | translate }}
  <i *ngIf="viewType == 'desktop'" aria-hidden="true" class="fa fa-angle-down"></i>
  <i *ngIf="viewType == 'mobile'" aria-hidden="true" class="fa fa-angle-up"></i>
  <button
    *ngIf="showDropdownActions"
    class="leave-btn"
    [ngStyle]="{'top': viewType == 'mobile' ? '-67px' : 'null'}"
    (click)="onLeave()"
  >
    {{ 'MEET_PAGE.not-going' | translate }}
  </button>
</div>

<!-- Participant is suspended -->
<div
  *ngIf="state == 'suspended'"
  class="already-going-btn"
  [ngStyle]="{ 'border-color': 'red', 'color': 'red' }"
>
  {{ 'MEET_PAGE.participant-suspended' | translate }}
</div>

<!-- Participant is refused -->
<div
  *ngIf="state == 'refused'"
  class="already-going-btn"
  [ngStyle]="{ 'border-color': 'red', 'color': 'red' }"
>
  {{ 'MEET_PAGE.participant-refused' | translate }}
</div>

<!-- Already going -->
<div
  *ngIf="state == 'already-going'"
  class="already-going-btn"
  (click)="showDropdownActions = !showDropdownActions"
>
  <span>
    <ng-container *ngIf="meet?.mode === 'hybrid'">
      <i *ngIf="userAsParticipant?.location === 'online'" class="far fa-video-camera"></i>
      <i *ngIf="userAsParticipant?.location === 'onsite'" class="far fa-map-marker-alt"></i>
    </ng-container>
    <ng-container *ngIf="meet?.mode !== 'hybrid'">✓</ng-container>
    {{ 'MEET_PAGE.going' | translate }}
  </span>
  <i *ngIf="viewType == 'desktop'" aria-hidden="true" class="fa fa-angle-down"></i>
  <i *ngIf="viewType == 'mobile'" aria-hidden="true" class="fa fa-angle-up"></i>
  <button
    *ngIf="showDropdownActions"
    class="leave-btn button-big w100"
    [ngStyle]="{'top': viewType == 'mobile' ? '-67px' : 'null'}"
    (click)="onLeave()"
  >
    {{ 'MEET_PAGE.not-going' | translate }}
  </button>
</div>

<!-- Passed meet -->
<button
  *ngIf="state == 'past-meet'"
  class="passed-meet-btn"
>
  {{ 'MEET_PAGE.past-meet' | translate }}
</button>
<button
  *ngIf="state == 'in-progress'"
  class="going-btn"
  (click)="onParticipate()"
>
  {{ 'MEET_PAGE.in-progress-meet' | translate }}
</button>

<!-- Manage meet -->
<button
  *ngIf="state == 'manage-meet'"
  class="manage-meet"
  (click)="onManage()"
>
  {{ 'MEET_PAGE.manage-meet' | translate }}
</button>

<!-- Refused meet -->
<button
  *ngIf="state == 'refused-meet'"
  class="passed-meet-btn"
>
  {{ 'MEET_PAGE.refused-meet' | translate }}
</button>

<!-- Erased meet -->
<button
  *ngIf="state == 'erased-meet'"
  class="passed-meet-btn"
>
  {{ 'MEET_PAGE.erased-meet' | translate }}
</button>

<!-- Draft meet -->
<button
  *ngIf="state == 'draft-meet'"
  class="passed-meet-btn"
>
  {{ 'MEET_PAGE.draft-meet' | translate }}
</button>

<!--
  Share button, only visible in mobile view
-->
<snippet-share
  *ngIf="viewType == 'mobile'"
  [meet]="meet"
  [viewType]="viewType"
></snippet-share>
