import {
  ElementRef,
  Directive,
  Output,
  HostListener,
  EventEmitter
} from '@angular/core';


@Directive({
    selector: '[infinite-scroll]'
})

export class InfiniteScrollDirective {

  @Output() scrolledDown: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public el: ElementRef){
  }

  @HostListener('window:scroll', ['$event']) onScroll(e) {
    let bottom = this.el.nativeElement.getBoundingClientRect().bottom;
    let windowHeight = window.innerHeight;

    if (windowHeight > bottom)
      this.scrolledDown.emit(true);
    else
      this.scrolledDown.emit(false);
  }

}
