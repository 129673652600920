import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth';
import { AuthRedirectService } from '../auth/auth-redirect.service';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html'
})

export class MainHeaderComponent {

  @Input() onHome:  boolean;

  isLoggedIn$:      Observable<boolean>;

  showNav:          boolean = false;

  constructor(
    private _route:     ActivatedRoute,
    private _auth:      AuthService,
    private _authRedirectService: AuthRedirectService,
  ) {
    this.isLoggedIn$ = this._auth.getLoginStatus();
  }

  onAuth(tab: 'login'|'register') {
    this.showNav = false;
    if (!this._route.snapshot.queryParams.tab) {
      this._authRedirectService.authenticateThenGoBack({ tab }, this._route.snapshot.queryParams['_']);
    }
  }
}
