import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { MeetsModule } from 'src/app/meets';
import { GroupsModule } from 'src/app/groups';

import {
  HomepageComponent,
  CoffeeKitchenComponent,
  WorkshopsMeetsComponent,
  SharedOfficeComponent
} from 'src/app/main/home';

import { homeRouting } from 'src/app/main/home/home.routing';
import { FormatsModule } from 'src/app/formats/formats.module';

import { AuthModule } from 'src/app/auth';
import { HeadersFootersModule } from 'src/app/headers-footers/headers-footers.module';

@NgModule({
  imports:      [
    SharedModule,
    MeetsModule,
    AuthModule,
    GroupsModule,
    FormatsModule,
    HeadersFootersModule,
    homeRouting,
  ],
  declarations: [
    HomepageComponent,
    CoffeeKitchenComponent,
    WorkshopsMeetsComponent,
    SharedOfficeComponent
  ],
})
export class HomeModule { }
