import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStore } from '../store/app.store';
import { Group } from '../shared/definitions';

@Component({
  selector: 'custom-footer',
  templateUrl: './custom-footer.component.html'
})
export class CustomFooterComponent implements OnInit {

  customCguLink:  string;

  constructor(
    private _route:    ActivatedRoute,
    private _appStore: AppStore
  ) { }

  ngOnInit() {
    const masterGroupId: string = this._route.snapshot.params['id'] || this._route.snapshot.queryParams['_'];

    if (masterGroupId) {
      this._appStore.load('Group', masterGroupId)
        .entity
        .subscribe((group: Group) => {
          if (group.options && group.options['cgu_link']) {
            this.customCguLink = group.options['cgu_link'];
          }
        });
    }
  }
}
