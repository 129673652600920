import { Component, OnInit }  from '@angular/core';
import { MetaService } from 'src/app/core';
import { Group } from 'src/app/shared/definitions';
import { AuthService } from 'src/app/auth';

@Component({
  template: `
    <div class="home-page home-background">
      <home-header></home-header>
      <router-outlet></router-outlet>
      <home-footer></home-footer>
    </div>
  `
})

export class HomeComponent implements OnInit {
  group:    Group;

  constructor(
    private _authService: AuthService,
    private _metaService: MetaService,
  ) {
  }

  ngOnInit() {
    this._authService.universalAuth();
    this._metaService.setHomepageFavicon()
  }

  ngOnDestroy() {
    this._metaService.removeHomepageFavicon()
    this._metaService.setDefaultFavicon()
  }
}
