
import { of as observableOf,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Component, Input, OnChanges }  from '@angular/core';
import { IColors, ITranslatedValue, Group,
  Comment, Member, User }  from '../shared/definitions';
import { AppStore, Collection }  from '../store/';
import { AuthService } from '../auth/auth.service';
import { AuthRedirectService } from '../auth/auth-redirect.service';

@Component({
  selector: 'group-comments',
  templateUrl: './group-comments.component.html'
})

export class GroupCommentsComponent implements OnChanges {

  @Input() group:         Group;
  @Input() colors:        IColors = { primary: '' };
  @Input() sectionTitle:  ITranslatedValue;

  user$:                  Observable<User>;

  comments$:              Observable<Comment[]> = observableOf([]);
  collection:             Collection<Comment>;

  constructor(
    private _appStore:              AppStore,
    private _authService:           AuthService,
    private _authRedirectService:   AuthRedirectService,
  ) {
    this.user$ = this._authService.getUser();
  }

  ngOnChanges() {
    if (this.group) {
      this.collection = this._appStore.load('GroupComments', this.group.id.toString());
      this.comments$ = this.collection
        .entities.pipe(
        map(comments => comments.sort( (a, b) => new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1 )));
    }

    if (!this.sectionTitle)
      this.sectionTitle = { fr: 'Discussions', en: 'Discussions' };
  }

  getProfilePic(obj: Member | User): string {
    if (obj && obj.avatar && obj.avatar.slug)
      return obj.avatar.slug;
    else if (obj && obj.avatar && obj.avatar.file && obj.avatar.file.url)
      return obj.avatar.file.url;
    else
      return '/assets/images/avatar_default.jpg';
  }

  postComment(content: string) {
    if (content && content.trim() != '')
      this._appStore.create('GroupComments', this.group.id.toString(), { content: content }, { group_id: this.group.id });
  }

  onAuth() {
    this._authRedirectService.authenticateThenGoBack(
      { warningMessage: 'need-authentication' },
      this.group.id
    );
  }

  loadNext() {
    this.collection.collectNext();
  }
}
