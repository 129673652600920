
import { filter, pluck } from 'rxjs/operators';
import { Component, OnInit, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ITheme, IColors, AnimationFormat,
  Group, Meet, Resource } from '../shared/';
import { humanizeDuration, humanizeDurations, } from '../shared/helpers/common.funcs';
import { AppStore, Collection } from '../store/';

class Video extends Resource {
  public active:          boolean;
  public sanitizedUrl:    any;
}

@Component({
  selector: 'format-details',
  templateUrl: '../../../src/app/formats/format-details.component.html',
  styles: [`
    .download-label {
      z-index: 1;
      position: absolute;
      width: 400px;
      top: -10px;
      border: 1px solid #e4e4e4;
      border-radius: 3px 3px 3px;
      padding: 16px;
      box-shadow: 2px 2px 5px;
      background-color: #fbfbfb;
      text-align: left;
    }
  `]
})

export class FormatDetailsComponent implements OnInit  {

  format:               AnimationFormat;

  meetsCollection:      Collection<Meet>;

  meets_count:          number;
  participants_count:   number;

  videos:               Video[];

  customGroupId:        number;
  colors:               IColors = { primary: '' };

  constructor(
    private _route:           ActivatedRoute,
    private _router:          Router,
    private _sanitizer:       DomSanitizer,
    private _appStore:        AppStore,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this._route.data.pipe(pluck('theme'),
      filter(data => data ? true : false),)
      .subscribe((theme: ITheme) => {
        this.colors = theme.colors;
        this.customGroupId = theme.group_id;
      });

    const id = this._route.snapshot.params['id'];
    this._appStore.load('AnimationFormat', id).entity
      .subscribe(format => {
        this.format = format;
        this.videos = this.fromResourcesToVideos(format.videos);
      });

    this.meetsCollection = this._appStore.load('AnimationFormatMeets', id);
  }

  setCoverPhoto(format: AnimationFormat): string {
    if (format && format.cover_photo) {
      return format.cover_photo.slug;
    }
    else {
      return '/assets/images/static/format-temps-parler.jpg';
    }
  }

  setDuration(format: AnimationFormat): string {
    if (!format || !format.modalities || !format.modalities.duration)
    return '';

    let { min, max, fix } = format.modalities.duration;

    if (!min && !max && !fix)
      return '';

    if (min && max) {
      return humanizeDurations(min, max);
    }
    else if (min)
      return 'Environ ' + humanizeDuration(min);
    else if (max)
      return 'Environ ' + humanizeDuration(max);
    else if (fix)
      return humanizeDuration(fix);
    else
      return '';
  }

  setParticipants(format: AnimationFormat): string {
    const participants = format.modalities.participants;

    if (participants.min && participants.max) {
      return `Entre ${participants.min} et ${participants.max} participants`;
    }
    else if (participants.min) {
      return `${participants.min} participants min`;
    }
    else if (participants.max) {
      return `${participants.max} participants max`;
    }
    else if (participants.fix) {
      return `${participants.fix} participants`;
    }
  }

  fromResourcesToVideos(resources: Resource[] = []): Video[] {
    return resources.map((_, i) => {
      return <Video>{
        title:          _.title,
        legend:         _.legend,
        sanitizedUrl:   this._sanitizer.bypassSecurityTrustResourceUrl(_.slug),
        active:         i == 0 ? true : false
      };
    });
  }

  onClickMeet(event: { meet: Meet, offset: number }) {
    const meet: Meet = event.meet;
    const parentRouteSegment: UrlSegment = this._route.snapshot.parent.url[0];

    if (this.customGroupId)
      this._router.navigate(['/home', this.customGroupId, 'rencontre', meet.id.toString()], { relativeTo: this._route });
    else
      this._router.navigate(['/fr', 'rencontre', meet.id.toString()], { relativeTo: this._route });
  }

  onClickVideo(clickedVideoIndex: number): void {
    this.videos.forEach((_, i) => {
      if (i == clickedVideoIndex)
        _.active = true;
      else
        _.active = false;
    });
  }

  onCreateMeet(): void {
    let params = { animationFormatId: this.format.id };
    let queryParams = {};

    if (this.customGroupId) {
      queryParams['_'] = this.customGroupId;
      params['groupId'] = this.customGroupId;
    }

    const parentRouteSegment: UrlSegment = this._route.snapshot.parent.url[0];

    this._router.navigate(['/admin', 'rencontres', 'creer', params], { queryParams });
  }

  private hexToRgbaColor(color: string, opacity: number): string {
    if (color == '')
      return '';

    const tranformToHex = (n: string) => parseInt('0x' + n);

    const first = tranformToHex(color.substr(1, 2)).toString();
    const second = tranformToHex(color.substr(3, 2)).toString();
    const third = tranformToHex(color.substr(5, 2)).toString();

    return first + ',' + second + ',' + third + ',' + opacity.toString();
  }
}
