import { Component, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { log } from 'util';

declare var L: any;
declare var env: any;

@Component({
  selector: 'meet-leaflet-map',
  template: `
  <div *ngIf="viewType == 'mobile'" id="leafletMap" style="height: 200px"></div>
  <div *ngIf="viewType == 'desktop'" id="leafletMap" style="height: 350px"></div>`,
})
export class MeetLeafletMapComponent implements AfterViewInit, OnChanges {
  @Input() lat:      number;
  @Input() lng:      number;
  @Input() viewType: string;

  map: any;

  ngAfterViewInit() {
    // console.log("afterViewInit", this);
    if (this.lat && this.lng) {
      if (this.map)
        this.setView();
      else
        this.initMap();
    }
  }

  ngOnChanges(change: SimpleChanges) {
    // console.log('OnChanges', this);
    const latFc = change.lat && change.lat.firstChange;
    const lngFc = change.lng && change.lng.firstChange;
    const vFc = change.viewType && change.viewType.firstChange;

    // If first change, the view is'nt yet initalized
    if (latFc === true || lngFc === true || vFc === true) {
      return ;
    }
    // Otherwise, we can set the map
    if (this.lat && this.lng) {
      if (this.map)
        this.setView();
      else
        this.initMap();
    }
  }

  setView() {
    if (this.map && this.lat && this.lng)
      this.map.setView([this.lat, this.lng], 13);
  }

  initMap(): void {
    if (!this.lat || !this.lng) {
      console.error('Error: Could not init leaflet map, no lat or lng passed');
      return ;
    }
    if (this.map) {
      console.error('Error: Map is already initialized');
      return ;
    }

    this.map = L.map('leafletMap', { scrollWheelZoom: false }).setView([this.lat, this.lng], 13);

    L.tileLayer(`https://api.mapbox.com/styles/v1/kawaa/${env.provider.mapbox.id}/tiles/256/{z}/{x}/{y}?access_token={token}`, {
      id: 'mapbox.light',
      token: env.provider.mapbox.secret,
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, Imagery © <a href="http://mapbox.com">Mapbox</a>'
    }).addTo(this.map);

    const icon = L.icon({
      iconUrl: '/assets/images/map/map-marker-check.svg',
      iconSize: [37, 45]
    });

    L.marker([this.lat, this.lng], { icon }).addTo(this.map);
    this.map.invalidateSize();
  }
}
