import { QueryForm } from './query-form';
import { Meet } from './meet';
import { Group } from './group';
import { Post } from './post';

export interface IArea {
  lat?:       number;
  lng?:       number;
  range?:     number;
  zoom?:      number;
  city?:      string;
}

export interface IBreadcrumb {
  title:          string;
  url:            (string|number)[];
}

export interface IColors {
  primary:                string;
  header_background?:     string;
  header_text?:           string;
  header_btn_background?: string;
  header_btn_text?:       string;
  overlay_meet_box?:      string;
}

export interface IHeaderSection {
  // flags' names that have special meaning:
  // 'page', 'home', 'external_link', 'external_logo', 'meet_create'
  flags:               string;
  status:              'listed'|'unlisted';
  title?:              string;
  anchor?:             string;
  config?:      {
    open_in_new_tab?:  boolean;
    link?:             string;
    slug?:             string;
  };
  // eg. access = ['admin', 'staff']
  access?:             string[];
}

export interface IPageSection {
  category:     'group_details'|
                'group_meets'|
                'group_links'|
                'group_formats'|
                'group_block_posts'|
                'group_links_map'|
                'group_photos'|
                'group_comments'|
                'group_query_forms_reports'|
                'group_query_forms'|
                'group_members'|
                'group_meets_carousel'|
                'group_stats';
  title?:       any;
  flags?:       string;
  section_id?:  string;
  config?: 			any;
}

export interface ISearchBar {
  byCity?:            boolean;
  byArrondissement?:  boolean;
  byLanguage?:        boolean;
}

export interface ISuccess {
  success: boolean;
  error?:  string;
}

export interface ITimeWindow {
  start?:     string;
  end?:       string;
}

export interface ITranslatedValue {
  fr:   string;
  en?:  string;
}

export interface IChart {
  title?:     string;
  data?:      number[];
  labels?:    string[];
}

export interface IAnswerField {
  name:     string;
  title:    string;
  value:    string;
  policy:   'private'|'public';
}

export interface IQuestionField {
  name:               string;
  title:              string;
  question:           string;
  value:              any;
  required:           boolean;
  kind:               'select'|'input'|'textarea'|'multi_choice'|'date'|'slider'|'attachment';
  policy:             'private'|'public';
  field_type?:        'text'|'number'|'email'|'tel';
  attachment_accept?: string;
  note?:              string;
  option_values?:     string[];
  min_length?:        number;
  max_length?:        number;
}

export class AnimationFormat {
  constructor (
    public id:                    number,
    public name:                  string,
    public description:           string,
    public cover_photo:           Resource,
    public participants_count:    number,
    public meets_count:           number,
    public groups_count:          number,
    public tags:                  Tag[],
    public photos:                Resource[],
    public video:                 Resource[],
    public docs:                  Resource[],
    public posts:                 Post[],
    public modalities:            {
      difficulty: number,
      duration: { min?: number, max?: number, fix?: number },
      participants: { min?: number, max?: number, fix?: number }
    }
  ) {}
}

export class Comment {
  constructor (
    public id:          number,
    public created_at:  string,
    public content:     string,
    public member:      Member
  ) {}
}

export class Label {
  constructor(
    public title:     string,
    public flag:      string,
    public color:     string,
    public sybmol:    string
  ) {}
}

export class Location {
  constructor(
    public lat:           number,
    public lng:           number,
    public city:          string,
    public id:            number,
    public name:          string,
    public address:       string,
    public country:       string,
    public zip_code:      string,
    public timezone:      string,
    public place_id:      number,
    public symbol?: 			string,
    public color?: 				string,
    public flag?: 				string,
    public meets_count?:  number,
    public participants_count?:  number,
    public title?:         { fr: string, en?: string },
  ) {}
}

export class Area {
  constructor(
  public lat?:       number,
  public lng?:       number,
  public range?:     number,
  public zoom?:      number,
  public city?:      string,
  ) {}
}

export class Marker {
  constructor(
    public id?: number,
    public lat?: number,
    public lng?: number,
    public iconUrl?: string,
    public title?: string,
    public address?: string,
    public location?: Location,
    public place?:    Group,
    public selected?: boolean
  ) {}
}

export class Meetship {
  constructor(
    public id:        number,
    public meet:      Meet,
    public group_id:  number,
    public visible:   boolean,
    public status:    string,
    public created_at:string
  ) {}
}

export class Member {
  constructor (
    public group_id:              number,
    public status?:               string,
    public email?:                string,
    public role?:                 string,
    public user_id?:              number,
    public first_name?:           string,
    public last_name?:            string,
    public id?:                   number,
    public nickname?:             string,
    public avatar?:               any,
    public organizations_count?:  number,
    public registrations_count?:  number,
    public created_at?:           string,
    public query_forms?:          QueryForm[]
  ) {
    this.role = 'member';
  }
}

export class Meta {
  constructor(
    public limit?:    number,
    public next?:     string,
    public offset?:   number,
    public previous?: string,
    public total?:    number
  ){}
}

export class Invitation {
  constructor(
    public id:                  number,
    public status:              string,
    public member:              Member,
    public transactional_info:  { type: string, datetime: string, event_id: string }[]
  ) {}
}

export class Participant {
  constructor (
    public status?:           string,
    public organizator?:      boolean,
    public member?:           Member,
    public meet_id?:          number,
    public id?:               number,
    public role?:             string,
    public created_at?:       string,
    public presence_status?:  string,
    public query_form?:       QueryForm,
    public location?:         'online' | 'onsite',
    public options?:          {
      presence_status?: string
    }
  ) {
    this.role = 'none';
  }
}

export class Provider {
  constructor (
    public pid?:          number,
    public provider?:     string
  ) {}
}

export class Resource {
  constructor (
    public slug:          string,
    public id?:           number,
    public title?:        string,
    public legend?:       string,
    public category?:     'default_group_photo'|'default_group_avatar'|'group_photo',
    public nature?:       'photo'|'video'|'doc'
  ) {}
}

export class Tag {
  constructor (
    public id:            number,
    public name:          string
  ) {}
}
