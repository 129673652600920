import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ApiConfig } from './api-config';
import { CookiesBannerComponent } from './cookies-banner/cookies-banner.component';
import { GtmService } from './gtm.service';
import { HelpscoutService } from './helpscout.service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { MetaService } from './meta.service';
import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from './notification/notification.service';
import { PageNotFoundComponent } from './page-not-found.component';
import { ThemeResolver } from './theme.resolver';
import { TrackingService } from './tracking.service';
import { UrlTranslationGuard } from './url-translation.guard';

@NgModule({
  imports: [SharedModule],
  declarations: [LoaderComponent, PageNotFoundComponent, NotificationComponent, CookiesBannerComponent],
  providers: [
    ApiConfig,
    MetaService,
    NotificationService,
    LoaderService,
    UrlTranslationGuard,
    HelpscoutService,
    TrackingService,
    GtmService,
    ThemeResolver,
  ],
  exports: [LoaderComponent, PageNotFoundComponent, NotificationComponent, CookiesBannerComponent],
})
export class CoreModule {}
