
import { of as observableOf, combineLatest as observableCombineLatest,  Observable } from 'rxjs';

import { refCount, publishReplay, map } from 'rxjs/operators';
import { Injectable }  from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { ApiConfig }  from '../core/';
import { Group }  from '../shared/';

export interface IHomeButton {
  text: string;
  url: string;
  open_in_new_tab: boolean;
}

export interface IHomeContent {
  baseline: string;
  goal: string;
  section_1: {
    text: string,
    button: IHomeContent
  };
  section_2: {
    text: string,
    button: IHomeContent
  };
  section_3: {
    text: string,
    button: IHomeContent
  };
  section_4: {
    text: string,
    button: IHomeContent
  };
  section_5: {
    text: string,
    button: IHomeContent
  };
  groups: number[];
}

@Injectable()
export class MainService {

  headers:      HttpHeaders;
  collections:  { path: string, obs: Observable<any> }[] = [];

  constructor(
    private http: HttpClient,
    private api: ApiConfig
  ) {
    this.headers = this.api.headers();
  }

  getHomeContent(local: string): Observable<IHomeContent> {
    const collection = this.collections.find(_ => _.path == 'options/web/home');

    if (!collection) {
      const obs = this.http.get(this.api.getPath('options/web/home'), { headers: this.headers }).pipe(
        map((res: any) => res.data));

      this.collections.push({ path: 'options/web/home', obs: obs });
      return obs.pipe(map((data: { fr: IHomeContent, en: IHomeContent }) => data[local]));
    }

    return collection.obs.pipe(map(data => data[local]));
  }

  getGroups(group_ids: number[]): Observable<Group[]> {
    if (group_ids) {
      const groups = group_ids.map(id => this.getGroup(id));
      return observableCombineLatest(groups);
    }
    else {
      return observableOf([]);
    }
  }

  getGroup(id: number): Observable<Group> {
    const collection = this.collections.find(_ => _.path == `groups/${id}`);

    if (!collection) {
      const obs = this.http.get<Group>(this.api.getPath(`groups/${id}`), { headers: this.headers }).pipe(
        publishReplay(1),
        refCount(),);

      this.collections.push({ path: `groups/${id}`, obs: obs });
      return obs;
    }

    return collection.obs;
  }

  getPrices(): Observable<any[]> {
    const collection = this.collections.find(_ => _.path == 'options/all/pricing');

    if (!collection) {
      const obs = this.http.get<{ data: any[] }>(this.api.getPath('options/all/pricing'), { headers: this.headers }).pipe(
        publishReplay(1),
        refCount(),
        map(res => res.data),);

      this.collections.push({ path: 'options/all/pricing', obs: obs });
      return obs;
    }

    return collection.obs;
  }

  mailchimpNewsletterSub(email: string) {
    const url: string = `https://mc.us4.list-manage.com/subscribe/post-json?u=71a6453a63cd08103eeb5c057&id=36097edb9a&EMAIL=${email}`;
    return this.http.jsonp<{ result: string, msg: string }>(url, 'c')
  }
}
