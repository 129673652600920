<!-- Loader -->
<snippet-loader *ngIf="!participants?.length"></snippet-loader>

<!-- Desktop -->
<div
  *ngIf="participants?.length && viewType == 'desktop'"
  class="row"
>
  <div class="col col-12 aside-meet-article">
    <h3
      class="section-title"
      [style.border-bottom-color]="theme?.colors?.primary"
    >
      {{ 'SNIPPET_PARTICIPANT.participants' | translate }}
      <span>({{ participantsCount }})</span>
    </h3>
    <div class="aside-meet-content participant-meet row">

      <div
        *ngFor="let participant of participants"
        class="face col col-3"
      >
        <abbr
          class="effect"
          [attr.info-tip]="getParticipantShortName(participant)"
        ></abbr>
        <div
          class="face-img"
          [routerLink]="['/profile', participant.member.user_id, 'organisations']"
          [style.border-color]="theme?.colors?.primary"
          [queryParams]="{'_': theme?.group_id}"
        >
          <img
            [lazyLoad]="participant?.member?.avatar?.slug | imageResizeWidth:90"
            [alt]="getParticipantShortName(participant)"
          >
        </div>
      </div>
      <div class="align-center">
        <a
          *ngIf="(participantCollection.meta | async).next"
          (click)="loadNext()"
          [style.color]="theme?.colors?.primary"
          href="javascript:void(0)"
          class="link-effect"
        >
          {{ 'SNIPPET_PARTICIPANT.show-more' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!-- Mobile -->
<div
  *ngIf="participants?.length && viewType == 'mobile'"
  class="row"
>
  <div class="col col-12 participants-mobile">
    <div class="description w55">
      {{ 'SNIPPET_PARTICIPANT.there-are' | translate }} {{ participantsCount }}
      <span *ngIf="participantsMax">
        / {{ participantsMax }}
      </span>
      <span> {{ ('SNIPPET_PARTICIPANT.participants' | translate).toLowerCase() }}</span>
      <br>
      <em>{{ 'SNIPPET_PARTICIPANT.some-of-them' | translate }}</em>
    </div>

    <div class="all-faces w40">
      <div
        *ngFor="let participant of fiveFirstParticipants"
        class="face"
      >
        <div class="face-img">
          <img
            [lazyLoad]="participant?.member?.avatar?.slug  | imageResizeWidth:90"
            [alt]="getParticipantShortName(participant)"
          >
        </div>
      </div>
    </div>
  </div>
</div>
