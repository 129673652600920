import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { IAnswerField, IQuestionField } from 'src/app/shared/definitions/others';
import { getFormatedDate } from 'src/app/shared/helpers/common.funcs';
import { ResourceService } from '../cropper/resource.service';
import { first } from 'rxjs/operators';

interface IFormControlGroup extends FormGroup {
  controls: {
    [key: string]: any;
  };

  get(path: Array<string | number> | string): any | null;
}

@Component({
  selector: 'ask-questions-form',
  templateUrl: './ask-questions-form.component.html',
  styles: [`
    :host {
      width: 100%;
    }
    label {
      color: #000
    }
  `]
})
export class AskQuestionsFormComponent implements OnChanges, OnDestroy {

  @Input() questions: IQuestionField[];

  @Input() parentForm: FormGroup;
  @Input() parentFormIsSubmitted: boolean;

  formGroup: IFormControlGroup;

  constructor(private _fb: FormBuilder, private resourceService: ResourceService) { }

  ngOnChanges(changes) {
    if (changes.questions && changes.questions.currentValue) {
      this.formGroup = this.createQuestionsFormGroup(this.questions);
      if (this.parentForm.controls.questions) {
        this.parentForm.removeControl('questions');
      }
      this.parentForm.addControl('questions', this.formGroup);
      this.parentForm.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.parentForm.removeControl('questions');
  }

  createQuestionsFormGroup(questions: IQuestionField[]): IFormControlGroup {
    const formGroup = this._fb.group({}) as IFormControlGroup;

    const validatorFn = (field: IQuestionField) => (fa: FormArray) => {
      // count 'true' : selected options
      const count: number = fa.value.reduce((acc, v) => v ? acc + 1 : acc, 0);

      if (field.required && fa.value.indexOf(true) == -1) {
        return { required: { valid: false } };
      }
      else if (field.min_length && count < field.min_length) {
        return { minLength: { valid: false } };
      }
      else if (field.max_length && count > field.max_length) {
        return { maxLength: { valid: false } };
      }
      else {
        return null;
      }
    };

    questions.forEach((field: IQuestionField) => {
      const validators: ValidatorFn[] = [];

      if (field.required) {
        validators.push(Validators.required);
      }

      if (field.max_length) {
        validators.push(Validators.maxLength(field.max_length));
      }

      if (field.min_length) {
        validators.push(Validators.minLength(field.min_length));
      }

      if (field.field_type == 'email') {
        validators.push(Validators.email);
      }

      if (field.field_type == 'tel') {
        validators.push(Validators.pattern('\\+?[0-9 ]+'));
      }

      if (field.kind == 'multi_choice') {
        const optValues = field.option_values.map(value => this._fb.control(field.value.includes(value)));
        const control: FormArray = this._fb.array(optValues, validatorFn(field));
        formGroup.addControl(field.name, control);
      }

      else if (field.kind == 'date') {
        formGroup.addControl(field.name, this._fb.control(new Date(field.value), validators));
      }

      else {
        formGroup.addControl(field.name, this._fb.control(field.value, validators));
      }
    });

    return formGroup;
  }

  prepareToSubmit(inputObject: { [fieldName: string]: any }): IAnswerField[] {
    if (!inputObject) return [];

    return Object.keys(inputObject).map((fieldName: string) => {
      const questionField: IQuestionField = this.questions.find((e: IQuestionField) => e.name == fieldName);
      return {
        name: fieldName,
        value: this.setValueToSubmit(inputObject, questionField),
        title: (questionField.title ? questionField.title : fieldName.replace(/_/gi, ' ')),
        policy: (questionField.policy ? questionField.policy : 'public')
      };
    });
  }

  /**
   * Handle file upload
   */
  async onFileChange($event: Event, ctx: { status: string, control: FormControl, file }) {
    const target = $event.target as HTMLInputElement;

    if (!target.files.length) {
      return;
    }

    ctx.status = 'Envoi de votre fichier en cours...';
    const file = target.files.item(0);

    let blob;
    try {
      blob = new Blob([await file.arrayBuffer()], { type: file.type });
    } catch (err) {
      ctx.status = 'Impossible de lire le fichier';
    }

    const opts = { category: 'group_doc', title: file.name };

    // Delete old file
    if (ctx.file) {
      await this.resourceService.deleteResource(ctx.file).pipe(first()).toPromise();
    }

    try {
      const res = await this.resourceService.createResource(blob, opts).pipe(first()).toPromise();
      ctx.control.setValue('file#' + res.id);
      ctx.file = res.id;
      ctx.status = '';
    } catch (err) {
      console.error('File upload error:', err);
      ctx.status = "Une erreur s'est produite lors de l'envoi du fichier";
    }
  }

  setValueToSubmit(inputObject: { [fieldName: string]: any }, questionField: IQuestionField) {
    const fieldName: string = questionField.name;

    if (questionField.kind == 'multi_choice') {
      const toSubmit = [];
      inputObject[fieldName].forEach((selected, i) => {
        if (selected)
          toSubmit.push(questionField.option_values[i]);
      });
      return toSubmit;
    }
    else if (questionField.kind == 'slider') {
      return questionField.option_values[inputObject[fieldName] - 1];
    }
    else if (questionField.kind == 'date') {
      return getFormatedDate(inputObject[fieldName]);
    }
    else {
      return inputObject[fieldName];
    }
  }
}
