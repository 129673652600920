<ng-container *ngFor="let section of sections">
  <group-details
    *ngIf="section.category == 'group_details'"
    [group]="group"
    [sectionTitle]="section.title"
    [config]="section.config"
    [id]="section.section_id"
  ></group-details>

  <group-meets
    class="content-width"
    *ngIf="section.category == 'group_meets'"
    [group]="group"
    [config]="section.config"
    [sectionTitle]="section.title"
    (selectMeet)="onSelectMeet($event)"
    (createMeet)="onCreateMeet(group)"
    [id]="section.section_id"
  ></group-meets>

  <group-links
    class="content-width"
    *ngIf="section.category == 'group_links'"
    [group]="group"
    [config]="section.config"
    [flags]="section.flags"
    [sectionTitle]="section.title"
    [id]="section.section_id"
  ></group-links>

  <group-formats
    class="content-width"
    *ngIf="section.category == 'group_formats'"
    [group]="group"
    [colors]="colors"
    (selectFormat)="onSelectFormat(group, $event)"
    [id]="section.section_id"
  ></group-formats>

  <group-block-posts
    *ngIf="section.category == 'group_block_posts'"
    [group]="group"
    [flags]="section.flags"
    [colors]="colors"
    [id]="section.section_id"
  ></group-block-posts>

  <group-links-map
    class="content-width"
    style="position: relative; display: grid; margin-bottom: 20px"
    *ngIf="section.category == 'group_places_map' || section.category == 'group_links_map'"
    [group]="group"
    [config]="section.config"
    [flags]="section.flags"
    (selectMeet)="onSelectMeet($event)"
    (createMeet)="onCreateMeet($event)"
    (selectGroupLink)="onSelectGroupLink(group, $event)"
    [id]="section.section_id"
  ></group-links-map>

  <group-photos
    *ngIf="section.category == 'group_photos'"
    [group]="group"
    [config]="section.config"
    [sectionTitle]="section.title"
    [colors]="colors"
  ></group-photos>

  <group-comments
    *ngIf="section.category == 'group_comments'"
    [group]="group"
    [sectionTitle]="section.title"
    [colors]="colors"
    [id]="section.section_id"
  ></group-comments>

  <group-query-forms-reports
    *ngIf="section.category == 'group_query_forms_reports'"
    [group]="group"
    [sectionTitle]="section.title"
    [config]="section.config"
  ></group-query-forms-reports>

  <group-query-forms
    *ngIf="section.category == 'group_query_forms'"
    [group]="group"
    [config]="section.config"
  ></group-query-forms>

  <group-members
    *ngIf="section.category == 'group_members'"
    [group]="group"
    [sectionTitle]="section.title"
    [colors]="colors"
  ></group-members>

  <group-meets-carousel
    *ngIf="section.category == 'group_meets_carousel'"
    [config]="section.config"
  ></group-meets-carousel>

  <group-stats
    *ngIf="section.category == 'group_stats'"
    [group]="group"
    [config]="section.config"
  ></group-stats>

</ng-container>
<div class="content-width" id="socialboard" #board></div>
