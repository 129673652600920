
import { throwError as observableThrowError, of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import { map, share, mergeMap, catchError } from 'rxjs/operators';
import { Injectable }  from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse }  from '@angular/common/http';
import { CookieService }  from 'ngx-cookie';
import { ISuccess, User, Group, Meet, Meta, Member, Participant }  from '../shared/definitions';
import { ApiConfig }  from '../core/api-config';
import { attach } from '../shared/helpers/common.funcs';

export interface IAuthSuccess {
  success:  boolean;
  auth_key: string;
  user:     User;
}

@Injectable()
export class AuthService {

  headers:              HttpHeaders;

  private isLoggedIn$:  BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private user$:        BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(
    private http:          HttpClient,
    private api:           ApiConfig,
    private cookieService: CookieService
    ) {
    this.headers = this.api.headers();
  }

  get isLoggedIn() {
    return this.isLoggedIn$.getValue();
  }

  get user() {
    return this.user$.getValue();
  }

  getLoginStatus(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  getUser(): Observable<User> {
    return this.user$.asObservable();
  }

  reloadUser() {
    this.http.get<User>(this.api.getPath('account?admin=true'), { headers: this.headers })
      .subscribe((user: User) => {
        this.user$.next(user);
      });

  }

  init(data: { auth_key: string, user: User }) {
    this.headers = this.headers.append('X-Auth-Key', data.auth_key);
    localStorage.setItem('ngStorage-oauthToken', JSON.stringify(data.auth_key));
    this.user$.next(data.user);
    this.isLoggedIn$.next(true);
  }

  getAccountGroups(by: any): Observable<{meta: Meta, groups: Group[]}> {
    if (this.isLoggedIn) {
      const params = Object.keys(by).reduce((memo, key) => memo + attach(key, by[key]), '');
      return this.http.get<{ meta: Meta, groups: Group[] }>(this.api.getPath(`account/groups?${params}`), { headers: this.headers }).pipe(share());
    }
    else {
      return observableOf({meta: {}, groups: []});
    }
  }

  universalAuth(): Observable<ISuccess> {
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token && token !== 'undefined' && !this.isLoggedIn$.getValue()) {
      this.isLoggedIn$.next(true);
      this.headers = this.headers.set('X-Auth-Key', JSON.parse(token));
      this.http.get(this.api.getPath('account/login'), { headers: this.headers })
        .subscribe(
          (user: User) => {
            this.user$.next(user);
          },
          ({ error }) => {
            if (error.error == 'Authentication failure: X-Auth-Key is invalid or missing')
              this.logout();
          }
        );
    }

    return observableOf({ success: true });
  }

  parisConnectAuth(): Observable<ISuccess> {
    let cookie = this.cookieService.get('JSESSIONID');
    if (!cookie) {
      const now = new Date();
      cookie = Math.random().toString(36).substr(2);
      this.cookieService.put('JSESSIONID', cookie, { expires: new Date(now.setFullYear(now.getFullYear() + 2)) });
    }

    if (this.isLoggedIn$.getValue()) {
      return observableOf({ success: true });
    }

    return this.http.get('https://moncompte.paris.fr/moncompte/rest/banner/api/1/informations', { withCredentials: true }).pipe(
      catchError(e => {
        return observableOf({ status: 'KO', result: null });
      }),
      mergeMap((data: { status: string, result: any }) => {
        if (data.status == 'OK') {
          const formatted = {
            email: data.result.mail,
            first_name: data.result.firstname,
            last_name: data.result.lastname,
            id: cookie
          };
          this.providerLogin('paris', formatted);
          return observableOf({ success: true });
        }
        if (data.status == 'KO') {
          return this.universalAuth();
        }
      }),);
  }

  howtankConnectAuth(group: Group): Observable<ISuccess> {
    const visas: string = group.options && group.options.howtank_visas ? group.options.howtank_visas : 'howtank_global_fr';
    const host: string = group.options && group.options.own_url ? group.options.own_url : 'https://www.kawaa.co/';

    return this.http.get<ISuccess>(`/auth/howtank?visas=${visas}&group_id=${group.id}`, { withCredentials: true }).pipe(
      map((data: any) => {
        if (data.message == 'Unauthenticated!' || data.message == 'Unauthorized!') {
          window.location.href = `${host}redir/${group.id}/${visas}?returnUri=${window.location}`;
          return { success: false, error: data.message };
        }
        else {
          this.providerLogin('howtank', data);
          return { success: true };
        }
      }));
  }

  providerLogin(provider: string, data: Object): Observable<IAuthSuccess> {
    let obs = this.http.post<IAuthSuccess>(
      this.api.getPath('account/provider_login'),
      { provider, data },
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );

    obs.subscribe(
      (data: IAuthSuccess) => {
        this.init(data);
      },
      error => console.error(error)
    );

    return obs;
  }

  updateAccount(toUpdate: Object) {
    const obs = this.http.put<IAuthSuccess>(
      this.api.getPath('account/'),
      toUpdate,
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );

    obs.subscribe(
      (data: IAuthSuccess) => {
        if (data.success) {
          this.user$.next(data.user);
        }
      },
      error => console.error(error)
    );

    return obs;
  }

  deleteAccount() {
    const obs = this.http.delete(
      this.api.getPath('account/'),
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );

    obs.subscribe(
      data => this.logout(),
      error => console.error(error)
    );

    return obs;
  }

  login(user: User): Observable<IAuthSuccess> {
    let obs = this.http.post<IAuthSuccess>(
      this.api.getPath('account/login?admin=true'),
      { email: user.email, password: user.password },
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );

    obs.subscribe(
      (data: IAuthSuccess) => {
        this.init(data);
      },
      error => console.error(error)
    );
    return obs;
  }

  register(user: User): Observable<IAuthSuccess> {
    const newUser = {
      email: user.email,
      password: user.password,
      first_name: user.first_name,
      last_name: user.last_name,
      status: user.status,
      locale: user.locale,
      telephone: user.telephone,
      // Add setting object (consent and groupId key)
    };

    const obs = this.http.post<IAuthSuccess>(
      this.api.getPath('users'),
      newUser,
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );

    obs.subscribe(
      (data: IAuthSuccess) => {
        if (data.success) {
          this.init(data);
        }
      },
      error => console.error(error)
    );

    return obs;
  }

  forgotPassword(email: string, group?: string) {
    return this.http.post(
      this.api.getPath('account/forgot_password'),
      { email, group },
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error))
    );
  }

  resetPassword(password: string, token: string) {
    return this.http.put<IAuthSuccess>(
      this.api.getPath('account/forgot_password'),
      { password, token },
      { headers: this.headers }
    ).pipe(
      share(),
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error)),
      map((res: IAuthSuccess) => {
        this.init({ auth_key: res.auth_key, user: res.user });
        return res;
      })
    );
  }

  logout() {
    localStorage.removeItem('ngStorage-oauthToken');
    this.headers = this.headers.delete('x-auth-key');
    this.isLoggedIn$.next(false);
    this.user$.next(new User());
  }

  checkAccessTo(id: string, type: 'Meet'|'Group'): Observable<boolean> {
    const params: string = type == 'Meet' ? `meet=${id}` : `group=${id}`;
    return this.http.get<boolean>(this.api.getPath(`account/access?${params}`), { headers: this.headers })
      .pipe(
        share(),
        catchError((err: HttpErrorResponse) => observableThrowError(err.error.error)),
      );
  }
}
