<div #leafletMap class="custom-map-leaflet"></div>

<div class="carousel-meets-map" *ngIf="showCarousel">
  <div class="carousel-overlay"></div>
  <div class="carousel-btn-close" (click)="closeCarousel()">
    <i class="fa fa-times-circle" aria-hidden="true"></i>
  </div>
  <div
    class="swipe-box"
    *ngFor="let meet of meetsCollection?.entities | async; let idx = index; let isFirst = first; let isLast = last"
    (click)="closeCarousel()"
    [class.visible-box]="showedMeetIdx == idx"
    [class.hidden-box]="showedMeetIdx != idx"
  >

  <div class="action-slide action-slide-left"
    *ngIf="!isFirst"
    (click)="swipe(idx, isFirst, isLast, 'left'); $event.stopPropagation()">
    <i class="fa fa-angle-left" aria-hidden="true"></i>
  </div>
  <div class="action-slide action-slide-right"
    *ngIf="!isLast"
    (click)="swipe(idx, isFirst, isLast, 'right'); $event.stopPropagation()">
    <i class="fa fa-angle-right" aria-hidden="true"></i>
  </div>

    <div class="sml carousel-box">
      <meet-details-box
        [meet]="meet"
        (selectMeet)="emitSelectedMeet($event.meet)"
      ></meet-details-box>
    </div>
  </div>
</div>
