
import { mergeMap } from 'rxjs/operators';
import {
  Component,
  OnInit
}  from '@angular/core';
import {
  ActivatedRoute,
  Router
}  from '@angular/router';

import {
  Observable
}  from 'rxjs';
import {
  IArea,
  Group,
  IColors
}  from '../../shared/';
import {
  Collection,
  AppStore
}  from '../../store/';
import {
  ViewTypeService,
  IViewType
} from '../../shared/view-type.service';

@Component({
  selector: 'discover-groups',
  templateUrl: './discover-groups.component.html'
})

export class DiscoverGroupsComponent implements OnInit {

  groups$:                Observable<Group[]>;
  collection:             Collection<Group>;

  tags:                   string[] = [];
  area:                   IArea = { lat: 48.86, lng: 2.33, range: 5 };
  colors:                 IColors = { primary: '#AB86A5'};

  viewType$: Observable<IViewType> = this._viewType.get();

  constructor(
    private _router:       Router,
    private _route:        ActivatedRoute,
    private _appStore:     AppStore,
    private _viewType: ViewTypeService
  ) { }

  ngOnInit() {
    this.groups$ = this._route.params.pipe(
      mergeMap((params: any) => {
        let options: any = { place: 0 };

        if ('lat' in params && 'lng' in params) {
          options.lat = params.lat;
          options.lng = params.lng;
        }

        if ('range' in params)
          options.range = params.range;

        if ('city' in params)
          this.area.city = params.city;

        this.area = Object.assign(this.area, options);

        this.tags = 'tags' in params ? params.tags.split('^') : [];
        if (this.tags.length > 0)
          options.q = this.tags.join('^');

        this.collection = this._appStore.load('Groups', null, options);
        return this.collection.entities;
      }));
  }

  getSearchedTags(event: string[]) {
    let params: any = Object.assign({}, this._route.snapshot.params);
    if (event && event.length > 0) {
      params.tags = event.join('^');
    }
    else if (event.length == 0) {
      delete params['tags'];
    }

    this._router.navigate(['.', params], { relativeTo: this._route });
  }

  getSearchedCity(event) {
    let params: any = Object.assign({}, this._route.snapshot.params);
    if (event.lat && event.lng && event.city) {
      params.lat = event.lat.toFixed(3);
      params.lng = event.lng.toFixed(3);
      params.range = 5;
      params.city = event.city;
    }
    else {
      delete params.lat;
      delete params.lng;
      delete params.range;
      delete params.city;
    }

    this._router.navigate(['.', params], { relativeTo: this._route });
  }

  loadNextGroups() {
    this.collection.collectNext();
  }
}
