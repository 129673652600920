import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Ttab } from './auth-box.component';
import { IColors } from '../shared';
import { ViewTypeService, IViewType } from '../shared/view-type.service';

@Component({
  selector: 'auth-box-tabs',
  templateUrl: './auth-box-tabs.component.html'
})
export class AuthBoxTabsComponent implements OnInit {
  @Input()  activeTab:  Ttab;
  @Input()  colors:     IColors = { primary: '' };
  @Output() activeTabChange = new EventEmitter<Ttab>();

  viewType$:            Observable<IViewType>;

  constructor(
    private _viewType:  ViewTypeService,
  ) {}

  ngOnInit(): void {
    this.viewType$ = this._viewType.get();
  }

  changeActiveTab(newActiveTab: Ttab): void {
    if (newActiveTab === this.activeTab)
      return;

    this.activeTab = newActiveTab;
    this.activeTabChange.emit(newActiveTab);
  }
}
