
import { throwError as observableThrowError,  BehaviorSubject,  Observable, of as observableOf } from 'rxjs';

import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Injectable }  from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { ApiConfig }  from '../core/';
import { IPageSection, Meta, Location, Member, }  from '../shared/';
import { AppStore }  from '../store/';

@Injectable()
export class GroupsService {

  private _headers:         HttpHeaders;
  private _member$:         BehaviorSubject<Member> = new BehaviorSubject<Member>(null);

  constructor(
    private _http:        HttpClient,
    private _api:         ApiConfig,
    private _appStore:    AppStore
  ) {
    this._headers = this._api.headers();
  }

  get myMember$(): Observable<Member> {
    return this._member$.asObservable();
  }

  get myMember(): Member {
    return this._member$.getValue();
  }

  getLinksLocations(groupId: string, queries: any): Observable<Location[]> {
    return this._appStore.load('GroupLinksLocations', groupId, queries).entities;
  }

  getGroupsLocations(queries: any): Observable<Location[]> {
    return this._appStore.load('GroupsLocations', null, queries).entities;
  }

  loadWidgetPageSections(groupId: string, page: 'widget-meets'|'widget-group-links'): Observable<IPageSection[]> {
    return this._http.get<{ meta: Meta, sections: IPageSection[] }>(this._api.getPath(`groups/${groupId}/pages/sections?name=${page}`), { headers: this._headers })
      .pipe(
        catchError(res => {
          if (res.status == 500) {
            return observableThrowError(res.statusText);
          }
          else {
            return observableThrowError(res.error.error);
          }
        }),
        map(res => res.sections),
      );
  }

  loadMyMember(groupId: string): void {
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token) {
      this._http.get<Member>(this._api.getPath(`groups/${groupId}/member`), { headers: this._headers.append('X-Auth-Key', JSON.parse(token)) })
        .subscribe(
          (member: Member) => this._member$.next(member),
          error => this._member$.next(null)
        );
    }
    else {
      this._member$.next(null);
    }
  }

  createMyMember(groupId: string, queryFormId?: string) {
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token) {
      const obs = this._http.post<Member>(
        this._api.getPath(`groups/${groupId}/member`),
        {
          role: 'member',
          status: 'active',
          query_form_id: queryFormId
        },
        { headers: this._headers.append('X-Auth-Key', JSON.parse(token)) }
      ).pipe(
        publishReplay(1),
        refCount()
      );

      obs.subscribe(
        (member: Member) => this._member$.next(member),
        error => console.log(error)
      );
      return obs;
    }
    else {
      this._member$.next(null);
      return observableOf(null);
    }
  }

  updateMyMemberStatus(groupId: string, status: string) {
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token) {
      const obs = this._http.put<Member>(
        this._api.getPath(`groups/${groupId}/member`),
        { status },
        { headers: this._headers.append('X-Auth-Key', JSON.parse(token)) }
      ).pipe(
        publishReplay(1),
        refCount()
      );

      obs.subscribe(
        (member: Member) => this._member$.next(member),
        error => console.log(error)
      );
      return obs;
    }
    else {
      this._member$.next(null);
      return observableOf(null);
    }
  }

  deleteMyMember(groupId: string): void {
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token) {
      this._http.delete<{ success: boolean, member: Member }>(
        this._api.getPath(`groups/${groupId}/member`),
        { headers: this._headers.append('X-Auth-Key', JSON.parse(token)) }
      )
        .subscribe(
          ({ success, member }) => this._member$.next(null),
          error => console.log(error)
        );
    }
    else {
      this._member$.next(null);
    }
  }
}
