import { Component, Input, OnChanges } from '@angular/core';
import { Group, IAnswerField } from '../../shared/index';

@Component({
  selector: 'group-details-block-info',
  templateUrl: './group-details-block-info.component.html'
})

export class GroupDetailsBlockInfo implements OnChanges {
  @Input() group:     Group;
  @Input() color:     string;

  opening:                    string;
  contactPerson:              string;
  telephone:                  string;
  websiteUrl:                 string; // website + protocol
  website:                    string;
  email:                      string;

  otherExtraInfos:    IAnswerField[] = [];

  ngOnChanges() {
    if (this.group) {
      this.opening = null;
      this.contactPerson = null;
      this.telephone = null;
      this.websiteUrl = null;
      this.website = null;
      this.email = null;
      this.otherExtraInfos = [];
      this.setExtraInfos(this.group);
    }
  }

  typeOf(e: any): 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' | 'array'  | 'file' {
    if (typeof e == 'object' && e instanceof Array) {
      return 'array';
    }

    if (typeof e == 'string' && e.startsWith('file#')) {
      return 'file';
    }

    return typeof e;
  }

  setExtraInfos(group: Group) {
    if (!group.extra_infos)
      return;

    group.extra_infos
      .filter((info: IAnswerField) => info.policy != 'private')
      .forEach((info: IAnswerField) => {
        if (info.name == 'opening' && info.value) {
          this.opening = info.value;
        }
        else if (info.name == 'contact_person' && info.value) {
          this.contactPerson = info.value;
        }
        else if (info.name == 'website' && info.value) {
          this.website = info.value;
          this.websiteUrl = info.value.match('^.*\/\/.*') ? info.value : '//' + info.value;
        }
        else if ((info.name == 'telephone' || info.name == 'phone') && info.value) {
          this.telephone = info.value;
        }
        else if (info.name == 'email' && info.value) {
          this.email = info.value;
        }
        else if (['facebook', 'twitter', 'linkedin', 'instagram'].indexOf(info.name) == -1 && info.value) {
          this.otherExtraInfos.push(info);
        }
    });
  }
}
