import { HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';

import { NgxAirtableModule } from 'ngx-airtable';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AuthModule } from './auth/';
import { CoreModule } from './core/core.module';
import { CustomModule } from './custom/custom.module';
import { HomeModule } from './main/home/home.module';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';
import { AppStore } from './store/';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
  if (
    process.env.NODE_ENV === 'staging' ||
    process.env.NODE_ENV === 'production'
  ) {
    // configure Bugsnag asap
    const bugsnagClient = Bugsnag.start({
      apiKey: '0d0ceb8d269cb3d24214f26cf32c60d7',
    });

    return new BugsnagErrorHandler(bugsnagClient);
  } else {
    return new ErrorHandler();
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/src/app/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    AuthModule,
    HomeModule,
    MainModule,
    CustomModule,
    NgxAirtableModule.forRoot({ apiKey: 'keywtCPNiYOFH5Vjz' }),
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    GooglePlaceModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    AppStore,
  ],
})
export class AppModule {}
