import { Component, ViewChild, ElementRef, Input, OnInit, AfterViewInit, forwardRef, Inject, ViewEncapsulation } from '@angular/core';
import { Group } from '../../definitions/group';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification/notification.service';
import { AppStore } from 'src/app/store';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs/index';

import { ViewTypeService, IViewType } from 'src/app/shared/view-type.service';


interface ITextFormat {
  colors?:   string[];
  isBold?:   boolean;
  isItalic?: boolean;
  type?:     'h2'|'p';
}

interface IWysiwygUi {
  inputLink?:    boolean;
  selectedLink?: boolean;
  dropdown?:     boolean;
  colors?:       boolean;
  isColorSelected?: boolean;
}

@Component({
  templateUrl: './wysiwyg.component.html',
  styleUrls:   ['./wysiwyg.component.sass'],
  selector:    'wysiwyg-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})

export class WysiwygComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('textarea', { static: true }) textarea: ElementRef;
  @ViewChild('wysiwyg')  wysiwyg:  ElementRef;

  @Input()        textareaContent: string;

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  selection:      any;
  selectedEl:     any;
  hrefDomElement: any;
  textSelection:  string;
  urlTyped:       string;
  urlInvalid:     boolean = false;
  show:           IWysiwygUi = { inputLink: false, selectedLink: false, dropdown: false, colors: false, isColorSelected: false };
  textFormat:     ITextFormat = { colors: [''], isBold: false, isItalic: false, type: 'p' };
  viewType$:      Observable<IViewType> = this._viewType.get();

  constructor(
    @Inject(DOCUMENT)
    private _doc:                 any,
    private _notificationService: NotificationService,
    private _appStore:            AppStore,
    private _route:               ActivatedRoute,
    private _viewType:            ViewTypeService,
  ){
    _doc.execCommand('defaultParagraphSeparator', false, 'div');
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.textareaContent = value;
      this.textarea.nativeElement.innerHTML = this.textareaContent;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {
    const masterGroupId = this._route.snapshot.queryParams['_'];
    if (masterGroupId) {
      this._appStore.load('Group', masterGroupId)
        .entity
        .subscribe((group: Group) => {
          if (group && group.options.colors) {
            const color = group.options.colors;
            this.textFormat.colors = [
              color.primary,
              color.header_background,
              color.header_btn_background,
              color.header_text ? color.header_text : '#2E2B28',
              color.header_btn_text
            ].filter((x, i, a) => a.indexOf(x) == i); // return only unique colors
          }
        });
    }
    else {
      this.textFormat.colors = ['#2E2B28', '#1BA2A8'];
    }
  }

  ngAfterViewInit() {
    this.textarea.nativeElement.contentEditable = true;
    this.textarea.nativeElement.addEventListener('input', (el) =>  {
      if (el.srcElement || el.target) {
        this.textareaContent = (el.srcElement || el.target).innerHTML;
        this.propagateChange(this.textareaContent);
      }
    });

    this.textarea.nativeElement.addEventListener('paste', (ev) => {
      const win = window as any;
      let data: any;

      // IE
      if (win.clipboardData && win.clipboardData.getData) {
        data = win.clipboardData.getData('Text');
        this.textareaContent = data;
        this.propagateChange(this.textareaContent);
        this.textarea.nativeElement.innerHTML = this.textareaContent;
      }
      else if (ev.clipboardData && ev.clipboardData.getData) {
        data = ev.clipboardData.getData('text/plain');
        // Insert the filtered content
        this._doc.execCommand('insertHTML', false, data);
      }

      // Prevent the standard paste behavior
      ev.preventDefault();
    });
  }

  displayWysiwyg(show: boolean) {
    if (show) {
      this.wysiwyg.nativeElement.classList.remove('hide');
      this.wysiwyg.nativeElement.classList.add('show');
    }
    else {
      this.wysiwyg.nativeElement.classList.remove('show');
      this.wysiwyg.nativeElement.classList.add('hide');
      this.show = { dropdown: false, colors: false };
    }
  }

  getInputPosition() {
    const editorTopPositon = this.textarea.nativeElement.getBoundingClientRect().top;
    const editorLeftPositon = this.textarea.nativeElement.getBoundingClientRect().left;

    this.wysiwyg.nativeElement.style.top = this.selection.getBoundingClientRect().y - editorTopPositon - 40 + 'px';
    this.wysiwyg.nativeElement.style.left = this.selection.getBoundingClientRect().x - editorLeftPositon  - 70 + 'px';
  }

  onMouseUp(e) {
    this.textSelection = window.getSelection().toString();
    this.selection = window.getSelection().getRangeAt(0);
    this.getInputPosition();
    if (this.textSelection.length > 0) {
      this.isContentEdited(e);
    }
    else if (this.textSelection.length === 0) {
      this.textFormat = { ...this.textFormat, isBold: false, isItalic: false, type: 'p' };
      this.displayWysiwyg(false);
    }
  }

  onClick(e: any) {
    this.propagateTouch(true);

    this.hrefDomElement = e.srcElement || e.target;
    this.selection = window.getSelection().getRangeAt(0);
    this.getInputPosition();
    // console.log(e);
    if (this.hrefDomElement.nodeName == 'A') {
      this.urlTyped = this.hrefDomElement.attributes.getNamedItem('href').value;
      this.textSelection = this.hrefDomElement.innerText;
      this.show = { ...this.show, selectedLink: true, inputLink: true };
      this.displayWysiwyg(true);
    }
    else {
      this.show = { ...this.show, selectedLink: false, inputLink: false };
      this.urlTyped = '';
    }
  }

  isContentEdited(e) {
    this.selectedEl = e.srcElement || e.target;
    const selectedElParentName = this.selectedEl.parentNode.nodeName;

    if (selectedElParentName == 'B' || selectedElParentName == 'I' ) {
      this.textFormat = {...this.textFormat, isBold: true, isItalic: true};
    }
    else if (this.selectedEl.nodeName == 'H2') {
      this.textFormat.type = 'h2';
    }
    else if (this.selectedEl.nodeName == 'P') {
      this.textFormat.type = 'p';
    }
    else if (this.selectedEl.nodeName == 'B') {
      this.textFormat.isBold = true;
    }
    else if (this.selectedEl.nodeName == 'I') {
      this.textFormat.isItalic = true;
    }
    else if (this.selectedEl.nodeName == 'A') {
      this.show.inputLink = true;
    }
    else if (this.selectedEl.nodeName == 'SPAN') {
       this.show.isColorSelected = true;
    }
    this.displayWysiwyg(true);
  }

  formatText(style: string, value?: any) {
    if (style !== 'createLink') {
      this._doc.execCommand(style, false, this.textSelection);
      this.show.inputLink = false;
    }
    else if (style == 'createLink') {
      this.show.inputLink = true;
    }
    if (style == 'bold') {
      this.textFormat.isBold = !this.textFormat.isBold;
      this.displayWysiwyg(false);
    }
    else if (style == 'italic') {
      this.textFormat.isItalic = !this.textFormat.isItalic;
      this.displayWysiwyg(false);
    }
    else if (style == 'Paragraphe') {
      const newParagraph = `<span style="font-weight: normal !important" class="text-base">${this.textSelection}</span>`;
      this.restoreSelection();
      this._doc.execCommand('insertHTML', false, newParagraph);
      this.textFormat.type = 'p';
    }
    else if (style == 'Titre') {
      const newTitle = `<h2 style="text-transform: none">${this.textSelection}</h2>`;
      this.textFormat.type = 'h2';
      this.restoreSelection();
      this._doc.execCommand('insertHTML', false, newTitle);
    }
    else if (style == 'customColor') {
      this.restoreSelection();
      if ((this.selectedEl.nodeName == 'H2' || this.selectedEl.nodeName == 'B') || this.selectedEl.nodeName == 'I') {
        this.selectedEl.style.color = value;
      }
      else {
        const coloredText = `<span class="text-base" style="color: ${value}">${this.textSelection}</span>`;
        this._doc.execCommand('insertHTML', false, coloredText);
      }
      this.displayWysiwyg(false);
    }
  }

  createUrl(url: string) {
    // If user click on link, modify only the href
    if (this.hrefDomElement.parentNode.nodeName == 'A' || this.hrefDomElement.nodeName == 'A') {
      if (this.hrefDomElement.attributes.getNamedItem('href').value.length > 0) {
        this.isValidUrl(url);
        this.displayWysiwyg(false);
      }
    }
    // Verify if content does not have a <b> or <i> tag because execCommand duplicate the content
    else if ((this.textFormat.isBold || this.textFormat.isItalic)) {
      this._doc.execCommand('removeFormat', false, this.selection);
      this.isValidUrl(url);
    }
    else {
      this.isValidUrl(url);
    }
  }

  removeUrl(url) {
    if (url.length == 0) {
      this.show.inputLink = false;
      this.displayWysiwyg(false);
      this.urlTyped = '';
    }
    else {
      this.restoreSelection();
      if (this.selection.startContainer.parentNode.nodeName !== 'DIV') {
        this.selection.startContainer.parentNode.remove();
        this._doc.execCommand('insertHTML', false, this.textSelection);
      }
      this.show = { ...this.show, selectedLink: false, inputLink: false };
      this.displayWysiwyg(false);
    }
  }

  isValidUrl(url) {
    const id = Date.now().toString();
    const splitUrl = url.split('.');
    // If el is a link, check if url is ok and parse it, else send error & keep the older valid url
    if(this.hrefDomElement.parentNode.nodeName == 'A' || this.hrefDomElement.nodeName == 'A') {
      if(splitUrl.length > 1 && splitUrl.every(el => el.length > 0)) {
        this.hrefDomElement.attributes.getNamedItem('href').value = url;
        this.urlInvalid = false;
        this.displayWysiwyg(false);
      }
      else {
        this.urlInvalid = true;
        this._notificationService.setNotification({
          type: 'error',
          content: 'WYSIWYG.error-link'
        });
      }
    }
    else if (splitUrl.length > 1 && splitUrl.every(el => el.length > 0)) {
      if (url.startsWith('http')) {
        url = `<a id="${id}" target="_blank" rel="noopener" class="link-underline" href="${url}">${this.textSelection}</a>`;
      }
      else {
        url = `<a id="${id}" target="_blank" rel="noopener" class="link-underline" href="https://${url}/">${this.textSelection}</a>`;
      }
      this.restoreSelection();
      this._doc.execCommand('insertHTML', false, url);
      this.displayWysiwyg(false);
      this.urlTyped = null;
      this.urlInvalid = false;
    }
    else {
      this.urlInvalid = true;
      this._notificationService.setNotification({
        type: 'error',
        content: 'WYSIWYG.error-link'
      });
    }
    this.restoreSelection();
  }

  restoreSelection() {
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(this.selection);
  }
}
