import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Group, User, IColors, Meta } from '../shared';
import { AuthService } from '../auth';
import { NotificationService } from '../core/notification/notification.service';
import { AuthRedirectService } from '../auth/auth-redirect.service';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html'
})

export class HeaderMenuComponent implements OnChanges {

  @Input() group:   Group;
  @Input() home = false;

  user$:              Observable<User>;
  isLoggedIn$:        Observable<boolean>;
  groupsCollection$:  Observable<{ meta: Meta, groups: Group[] }>;
  userName:           string;

  colors:       IColors = { primary: '#1AA2A8', header_text: '#000' };
  toggle:       boolean;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _authRedirectService: AuthRedirectService,
    private _notificationService: NotificationService
  ) {
    this.groupsCollection$ = this._authService.getAccountGroups({ role: 'administrator', limit: 3, offset: 0 });
    this.isLoggedIn$ = this._authService.getLoginStatus();
    this.user$ = this._authService.getUser();
  }

  ngOnInit() {
    this.user$.subscribe(user => {
      if (user.first_name && user.last_name)
        this.userName = user.first_name + ' ' + user.last_name[0] + '.';
      else if (user.first_name)
        this.userName = user.first_name;
    });
  }

  ngOnChanges() {
    if (this.home) {
      this.colors.header_text = '#fff';
    } else if (this.group && this.group.options && this.group.options.colors) {
      this.colors = this.group.options.colors;
    }
  }

  onLogout() {
    this.toggle = !this.toggle;

    if (this.group && !this.home) {
      this._router.navigate(['/home', this.group.id]);
    }
    else {
      this._router.navigate(['/']);
    }

    this._authService.logout();

    this._notificationService.setNotification({
      type: 'success',
      content: 'HEADER_DROPDOWN.logout'
    });
  }
}
