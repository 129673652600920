import {
  Component,
 }                from '@angular/core';

import { LoaderService }    from './loader.service';

@Component({
  selector: 'loader',
  template: `
    <div class="loading-modal" *ngIf="show">
      <div class="k-loader-content">
        <div class="k-loader-position">
          <div class="k-loader"></div>
        </div>
      </div>
    </div>
  `
})

export class LoaderComponent {
  show: boolean = false;

  constructor(
    private loaderService: LoaderService
  ) {

  }

  ngOnInit() {
    this.loaderService.getEvent()
    .subscribe(event => {
      this.show = event;
    });
  }

}
