<div
  *ngFor="let info of answers; let last = last"
  [ngSwitch]="typeOf(info.value)"
  [ngClass]="{
    'border-b': !last
  }"
  class="p-tb-10"
>
  <div class="bold p-b-5">
    {{ info.title }}
  </div>

  <ng-template ngSwitchCase="array">
    <div
      *ngFor="let value of info.value"
      class="p-t-5"
    >
      <i class="fal fa-check-square m-xxs-r"></i>
      {{ value }}
    </div>
  </ng-template>

  <ng-template ngSwitchCase="file">
    <div class="p-t-5">
      <display-file-answer [fileInfo]="info"></display-file-answer>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="string">
    <div class="p-t-5" [innerHtml]="info.value | linky | sanitizeHtml"></div>
  </ng-template>
</div>
