<!-- Loader -->
<snippet-loader *ngIf="!groups"></snippet-loader>

<!-- Snippet -->
<div
  *ngIf="groups"
  class="row"
>
  <div class="col col-12 aside-meet-article">
    <h3 class="section-title" [style.border-bottom-color]="theme?.colors?.primary">
      {{ title | customTranslate }}
    </h3>
    <div class="aside-meet-content">

      <!-- Pictures -->
      <div class="groups-list">

        <span
          *ngFor="let group of groups"
          (mouseenter)="selectGroup(group)"
          (click)="navigateToGroup(selectedGroup)"
          class="group"
          [class.group-selected]="group.id == selectedGroup.id"
          [ngStyle]="{
            'border-color': (group.id == selectedGroup.id) ? group.options.colors?.primary : null,
            'color': theme?.colors?.primary || null,
            'background-color': group.options.colors?.header_background
          }"
        >
          <img [src]="group.avatar?.slug | imageResizeWidth:110" [alt]="group.name" />
        </span>

      </div>

      <!-- Description and stats -->
      <a (click)="navigateToGroup(selectedGroup)">
        <h4 class="title-h4">
          <span class="link-effect">
            {{ selectedGroup?.name }}
          </span>
          <div
            class="link-indicator"
            [ngStyle]="{
              'color': theme?.colors?.primary,
              'border-color': theme?.colors?.primary
            }"
          >
            <i class="fal fa-angle-right" aria-hidden="true"></i>
          </div>
        </h4>
      </a>
      <div class="stats-module">
        <div class="stats" *ngIf="selectedGroup?.meets_count > 0">
          <div class="stats-number">
            {{ selectedGroup.meets_count }}
          </div>
          <div class="stats-legend">
            {{ 'MEET_PAGE.meet' | translate | pluralize:selectedGroup?.meets_count }}
          </div>
        </div>
        <div class="stats" *ngIf="selectedGroup?.members_count > 1">
          <div class="stats-number">
            {{ selectedGroup.members_count }}
          </div>
          <div class="stats-legend">
            {{ 'MEET_PAGE.member' | translate | pluralize:selectedGroup?.members_count }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
