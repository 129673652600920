<!--
  Header
-->
<!-- Desktop -->
<ng-container *ngIf="(viewType$ | async) === 'desktop'">
  <div class="align-middle content-width">
    <div class="w100 m-m-t">
      <div
        class="landscape-3-1 border-radius-t-r border-radius-t-l border-t border-l-r"
        [ngStyle]="setGroupBackground(group)"
      >
      </div>
      <div class="banner-content row gutter auto borders-all border-radius-b-l border-radius-b-r align-middle p-tb-10 p-lr-20 m-m-b">
        <div class="col text-left">
          <div class="flex align-middle">
            <img
              class="little-square-img img-contain border-primary borders-all m-s-r"
              [alt]="group?.name"
              [src]="group?.avatar?.slug"
              [style.border-color]="color"
            >
            <div
              *ngIf="sectionTitle"
              class="borders-all border-radius-b-l border-radius-b-r p-tb-5 p-lr-10 m-s-r"
            >
              {{ sectionTitle | customTranslate }}
            </div>
            <h1 class="text-dark text-very-large">{{ group?.name }}</h1>
          </div>
        </div>
        <div class="col text-right">
          <button
            *ngIf="(!(member$ | async) || (member$ | async)?.status != 'active') && joinCta && !joinCta.hide"
            [style.background]="color"
            [ngClass]="{ 'btn-validate': !color }"
            class="button cta button-big flipIn"
            (click)="joinGroup()"
          >
            <span class="p-lr-30">{{ joinCta.naming | customTranslate }}</span>
          </button>
          <button
            *ngIf="(member$ | async)?.role == 'member' && (member$ | async)?.status == 'active' && joinCta && !joinCta.hide"
            (mouseover)="onHoverCTA = true"
            (mouseleave)="onHoverCTA = false"
            (click)="leaveGroup()"
            class="button button-big flipIn"
            [ngClass]="{
              'btn-validate outline': !onHoverCTA,
              'btn-danger': onHoverCTA
            }"
          >
            <span *ngIf="onHoverCTA" class="p-lr-30 text-big">
              <i class="fas fa-times"></i>
              {{ 'GROUP_PAGE.leave' | translate }}
            </span>
            <span *ngIf="!onHoverCTA" class="p-lr-30 text-big">
              <i class="fas fa-check"></i>
            </span>
          </button>
          <button
            *ngIf="(member$ | async)?.role == 'administrator'"
            class="button cta button-big btn-white-black outline flipIn"
            (click)="manageGroup()"
          >
            <span class="p-lr-30">{{ 'GROUP_PAGE.manage' | translate }}</span>
          </button>

          <group-details-share *ngIf="!hideShare" class="m-s-l"></group-details-share>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Mobile -->
<ng-container *ngIf="(viewType$ | async) === 'mobile'">
  <!-- Header with group picture, group logo, group name -->
  <div class="landscape-3-1" [ngStyle]="setGroupBackground(group)"></div>
  <div class="flex align-middle p-tb-10 p-lr-20 border-l-r border-t">
    <img
      class="little-square-img img-contain border-primary borders-all m-s-r"
      [alt]="group?.name"
      [src]="group?.avatar?.slug"
      [style.border-color]="color"
    >
    <div
      *ngIf="sectionTitle"
      class="borders-all border-radius-b-l border-radius-b-r p-tb-10 p-lr-10">
      {{ sectionTitle | customTranslate }}
    </div>

    <group-details-share *ngIf="!hideShare" class="m-xs-l push-right"></group-details-share>
  </div>
  <div class="flex align-middle p-tb-10 p-lr-20 borders-all text-center">
    <h1 class="text-dark text-bigger">{{ group?.name }}</h1>
  </div>

  <!-- Cta and share button -->
  <div
    *ngIf="joinCta && !joinCta.hide || (member$ | async)?.role == 'administrator'"
    class="w100 bg-white text-center p-tb-10"
  >
    <div class="w80 row content-width">
      <button
        *ngIf="(!(member$ | async) || (member$ | async)?.status != 'active') && joinCta && !joinCta.hide"
        class="w100 button cta button-big btn-validate flipIn"
        (click)="joinGroup()"
      >
        <span class="p-lr-30">{{ joinCta.naming | customTranslate }}</span>
      </button>
      <button
        *ngIf="(member$ | async)?.role == 'member' && (member$ | async)?.status == 'active' && joinCta && !joinCta.hide"
        class="w100 button cta button-big btn-validate outline flipIn disabled"
      >
        <span class="p-lr-30 text-big"> ✓ </span>
      </button>
      <button
        *ngIf="(member$ | async)?.role == 'administrator'"
        class="w100 button cta button-big btn-white-black outline flipIn"
        (click)="manageGroup()"
      >
        <span class="p-lr-30">{{ 'GROUP_PAGE.manage' | translate }}</span>
      </button>
    </div>
  </div>
</ng-container>

<div *ngIf="group?.description" class="row content-width">
  <div
    class="w100 bg-lighter-grey m-s-b"
    [ngClass]="{
      'block-banner': (viewType$ | async) == 'desktop'
    }"
  >
    <div
      [ngClass]="{
        'col col-10 align-middle offset-1': (viewType$ | async) == 'desktop',
        'p-lr-20 p-tb-20 m-m-t': (viewType$ | async) == 'mobile'
      }"
    >
      <ng-container *ngIf="sliceDescription">
        <span class="dynamic-content p-static" [innerHtml]="group.description | slice:0:1500 | linky | sanitizeHtml"></span>
        <span *ngIf="group.description.length > 1500">
          ...
          <br>
          <br>
          <a
            class="link-effect text-large"
            (click)="sliceDescription = false"
            [style.color]="color"
          >
            {{ 'GROUP_PAGE.show-more' | translate }}
          </a>
        </span>
      </ng-container>
      <ng-container *ngIf="!sliceDescription">
        <p class="dynamic-content p-static" [innerHtml]="group?.description | linky | sanitizeHtml"></p>
      </ng-container>
    </div>
    <div *ngIf="facebookUrl || twitterUrl || linkedinUrl || instagramUrl" class="col col-10 push-center m-s-t">
      <a *ngIf="facebookUrl" [href]="facebookUrl" target="_blank" rel="noopener" class="m-xs-r">
        <i class="fa fa-facebook-square meet-template-i text-very-large text-facebook" aria-hidden="true"></i>
      </a>
      <a *ngIf="twitterUrl" [href]="twitterUrl" target="_blank" rel="noopener" class="m-xs-r">
        <i class="fa fa-twitter-square meet-template-i text-very-large text-twitter" aria-hidden="true"></i>
      </a>
      <a *ngIf="linkedinUrl" [href]="linkedinUrl" target="_blank" rel="noopener" class="m-xs-r">
        <i class="fa fa-linkedin-square meet-template-i text-very-large text-linkedin" aria-hidden="true"></i>
      </a>
      <a *ngIf="instagramUrl" [href]="linkedinUrl" target="_blank" rel="noopener" class="m-xs-r">
        <i class="fa fa-instagram meet-template-i text-very-large text-instagram" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>

<div class="content-width">
  <group-details-block-info
    [group]="group"
    [color]="color"
  ></group-details-block-info>
</div>
