import { Component, Input, Output,
  EventEmitter, OnChanges } from '@angular/core';
import { IQuestionField } from 'src/app/shared/definitions/others';

@Component({
  selector: 'create-questions-form',
  templateUrl: './create-questions-form.component.html'
})

export class CreateQuestionsFormComponent implements OnChanges {

  @Input()  queries:    IQuestionField[] = [];
  @Output() newQueries: EventEmitter<IQuestionField[]> = new EventEmitter<IQuestionField[]>();

  accordion:      boolean[] = [];

  ngOnChanges(changes) {
    if (changes['queries']['currentValue'] && changes['queries']['currentValue'].length > 0) {
      this.queries = this.queries.map((q: IQuestionField) => {
        // if there's no option_values on input query,
        // add one in case user switches input question to multi_choice or list
        if (!q.option_values) {
          q.option_values = ['', ''];
        }
        return q;
      });
    }
  }

  onAddNewQuestion() {
    let len = this.queries.push({
      kind: 'input',
      question: '',
      title: '',
      name: '',
      value: '',
      option_values: ['', ''],
      required: false,
      policy: 'private'
    });

    this.accordion[len - 1] = true;
  }

  onDeleteQuestion(query: IQuestionField) {
    let i = this.queries.indexOf(query);
    this.queries.splice(i, 1);

    this.emitQueries();

    this.accordion.forEach((el: boolean) => el = false);
  }

  onSaveQuestion(i: number) {
    this.emitQueries();

    this.accordion[i] = false;
  }

  emitQueries() {
    const toEmit: IQuestionField[] = this.queries.map((query: IQuestionField) => {
      let q: IQuestionField = {
        kind: query.kind,
        question: query.question,
        title: query.question,
        name: query.question.toLowerCase().split(' ').join('-'),
        value: query.kind == 'input' ? '' : [],
        required: query.required,
        field_type: 'text',
        policy: 'private'
      };

      if (query.kind != 'input') {
        q.option_values = query.option_values;
      }

      return q;
    });

    this.newQueries.emit(toEmit);
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }
}
