
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export type IViewType = 'desktop' | 'mobile';

/**
 * Simply return an observable which you can subscribe to get the viewType
 * which represented by `IviewType`.
 */
@Injectable()
export class ViewTypeService {
  viewType: Observable<IViewType>;

  private init(): void {
    if (!window)
      throw 'Cannot initialize viewType in viewTypeService because window is not defined';

    this.viewType = Observable.create((observer) => {
      window.addEventListener('resize', () => this.set(observer));
      this.set(observer);
    });
  }

  private set(observer) {
    const viewTypeNewValue = (window.innerWidth > 768) ? 'desktop' : 'mobile';
    observer.next(viewTypeNewValue);
  }

  /**
   * The viewType observable will push these value :
   * - 'mobile' if window.width < 768
   * - 'desktop' otherwise
   */
  get(): Observable<IViewType> {
    if (!this.viewType) {
      try {
        this.init();
      } catch (e) {
        console.error(e);
        return observableOf(<IViewType>'desktop'); // In case of SSR. It should be modified because it's dirty.
      }
    }
    return this.viewType;
  }
}
