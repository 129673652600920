import { Component, Inject, OnInit }  from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/definitions/user';
import { Meet } from 'src/app/shared/definitions/meet';
import { AppStore } from 'src/app/store/app.store';
import { Observable } from 'rxjs';
import { IViewType, ViewTypeService } from 'src/app/shared/view-type.service';
import { Participant } from 'src/app/shared/definitions/others';
import { MeetService } from '../../meet.service';
import { HttpClient } from '@angular/common/http';


const JitsiMeetExternalAPI = require('./jitsi-meet-api.js');

@Component({
  templateUrl: './visio.component.html'
})

export class VisioComponent implements OnInit {

  jitsiApi:              any;
  meet:                  Meet;
  participant:           Participant;

  step:                 'in-future'|'today'|'starting-soon'|'started'|'past';
  formatedCounter:       { days: string, hours: string, minutes: string, seconds: string } = { days: '', hours: '', minutes: '', seconds: '' };

  viewType$:             Observable<IViewType> = this._viewType.get();

  onBoardingValidated:   boolean = false;
  disableButton:         boolean = true;
  showPopUpPhone:        boolean = false;
  showConfInfo:          boolean = false;
  isIphone:              boolean;
  isNavigatorValid:      boolean;
  confCode:              number;

  constructor(
    @Inject(DOCUMENT)
    private _document:    any,
    private _router:      Router,
    private _route:       ActivatedRoute,
    private _appStore:    AppStore,
    private _viewType:    ViewTypeService,
    private _auth:        AuthService,
    private _meetService: MeetService,
    private _http:        HttpClient
    ) {}

  ngOnInit() {
    const meetId = this._route.snapshot.paramMap.get('id');

    this._appStore.load('Meet', meetId)
      .entity
      .subscribe((meet: Meet) => {
        this.meet = meet;
        if (meet && meet.id) {
          this.meetLiveCounter(meet.datetime);
          this.getConfCode(meet.id);
        }
      });

    this._meetService.loadMyParticipant(meetId)
      .subscribe(res => {
        if (res && res.id) {
          this.participant = res;
        }
        else {
          this.participant = new Participant();
          this.participant.role = 'participant';
        }
      });
    this.isIphone = navigator.userAgent.indexOf('iPhone') != -1;
    this.checkNavigator();
  }

  initConference() {
    const options = {
      roomName: `e${this.meet.id}-kawaa`,
      parentNode: this._document.querySelector('#visioboard'),
      noSSL: false,
      configOverwrite: {
        enableClosePage: false,
        disableAudioLevels: true,
        channelLastN: 5,
        defaultLanguage: 'fr',
        callStatsID: '689834693',
        callStatsSecret: 'xEGawABbfjld:Jj5ShFFJFRaoks53AT+0vlddXqLb7Q9H/T9Socs08bM=',
        enableDisplayNameInStats: true
      },
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
          'fodeviceselection', 'hangup', 'profile', 'info', 'chat',
          'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
          'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
          'tileview', 'download', 'help', 'mute-everyone'
        ],
        DEFAULT_REMOTE_DISPLAY_NAME: 'kawaami',
        DEFAULT_LOCAL_DISPLAY_NAME: 'moi',
        SHOW_JITSI_WATERMARK: true,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_POWERED_BY: false,
      }
    };

    this.jitsiApi = new JitsiMeetExternalAPI('meet.jit.si', options);

    this._auth.getUser()
      .subscribe((user: User) => {
        if (user.id) {
          this.jitsiApi.executeCommand('email', user.email);
          this.jitsiApi.executeCommand('avatarUrl', user.avatar.slug);
          this.jitsiApi.executeCommand('displayName', user.nickname);
        }
      });

    if (this.meet) {
      this.jitsiApi.executeCommand('subject', this.meet.name);
    }

    this.jitsiApi.on('tileViewChanged', (isTileView) => {
      if (!isTileView) {
        this.jitsiApi.executeCommand('toggleTileView');
      }
    });

    this.jitsiApi.on('videoConferenceLeft', () => {
      this._router.navigate(['../..', 'rencontre', this.meet.id], { relativeTo: this._route });
    });
  }

  meetLiveCounter(eventDate: string) {
    const countDownDate = new Date(eventDate).getTime();

    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.formatedCounter.days = days.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
      this.formatedCounter.hours = hours.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
      this.formatedCounter.minutes = minutes.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });
      this.formatedCounter.seconds = seconds.toLocaleString('fr-FR', { minimumIntegerDigits: 2, useGrouping: false });

      this.getOnboardingStep(distance, x);
    }, 1000);
  }

  getOnboardingStep(distance: number, interval: NodeJS.Timeout) {
    const d = distance / 1000;

    // 1 day = 86 000;  15 min = 900; 5 hours = 18 000;
    if (d > 86000) {
      this.step = 'in-future';
    }
    else if (d < 86000 && d > 900) {
      this.step = 'today';
      this.disableButton = true;
    }
    else if (d < 900 && d > 0) {
      this.step = 'starting-soon';
      this.disableButton = this.participant.role != 'organizator';
    }
    else if (d < 0 && d > -18000) {
      this.step = 'started';
      this.disableButton = false;
      clearInterval(interval);
    }
    else {
      this.step = 'past';
      clearInterval(interval);
    }
  }

  startVisio() {
    this.onBoardingValidated = true;
    this.initConference();
  }

  getConfCode(meetId: number) {
    this._http.get(`https://jitsi-api.jitsi.net/conferenceMapper?conference=e${meetId}-kawaa@conference.meet.jit.si`)
      .subscribe((res: {id: number}) => {
        if (res && res.id) {
          this.confCode = res.id;
        }
      });
  }

  callConference() {
    window.open('tel:+33184886478');
  }

  checkNavigator() {
    const usrAg = navigator.userAgent;

    if (usrAg.indexOf('Firefox') > -1 || usrAg.indexOf('Chrome') > -1) {
      this.isNavigatorValid = true;
    }
    else {
      this.isNavigatorValid = false;
    }
  }
}
