<div class="row align-middle content-width">
  <div class="col col-12 search-discover m-m-b">
    <div class="search-bar w100 search-list">
      <searchbar
        [colors]="colors"
        [config]="{
          by_tag: true,
          by_city: true
        }"
        (onNewCity)="getSearchedCity($event)"
        (onNewTags)="getSearchedTags($event)"
      ></searchbar>
  </div>
</div>

<div class="row align-middle content-width">
  <div class="w100 group-content group-content-discover">
    <div class="m-m-b">
      <div class="group-box-container">
        <div
          class="group-box-card"
          *ngFor="let group of groups$ | async"
          [routerLink]="['/home', group.id]"
          [ngClass]="{'flex': (viewType$ | async) === 'mobile'}"
        >
          <div
            [ngClass]="{
              'group-box-banner': (viewType$ | async) === 'desktop',
              'no-banner p-tb-5 p-lr-5': (viewType$ | async) === 'mobile'
            }"
            [ngStyle]="{
              'background-image': 'url(' + group.photos[0]?.slug + ')'
            }"
          >
            <div class="group-box-label" *ngIf="group.flags">
              {{ group.flags }}
            </div>
            <div
              [ngClass]="{
                'group-box-logo': (viewType$ | async) === 'desktop',
                'group-mobile-img': (viewType$ | async) === 'mobile'
              }"
              [ngStyle]="{
                'background-color': group.options?.colors?.header_background,
                'border-color': group.options?.colors?.primary
              }"
            >
              <!-- Img width is 80 but we do a little higher for hdpi devices -->
              <img
                [alt]="group.name"
                [src]="group.avatar?.slug | imageResizeWidth:100"
                class="img-contain"
              />
            </div>
          </div>
          <div
            [ngClass]="{
              'group-box-title': (viewType$ | async) === 'desktop',
              'm-xs-tb m-xs-lr': (viewType$ | async) === 'mobile'
            }"
          >
            <p>
              {{ group.name }}
            </p>
            <p class="text-small text-dark-grey show-m">
              {{ group.meets_count }} {{ 'DISCOVER_GROUPS.meets' | translate }} ~ {{ group.members_count }} {{ 'DISCOVER_GROUPS.members' | translate }}
            </p>
          </div>
          <div class="group-box-stats row auto hide-m" *ngIf="group.meets_count > 10 || group.members_count > 10">
            <div class="col meet-stats">
              <span class="stat-number">
                {{ group.meets_count }}
              </span>
              <span class="stat-legend">
                {{ 'DISCOVER_GROUPS.meets' | translate }}
              </span>
            </div>
            <div class="col member-stats">
              <span class="stat-number">
                {{ group.members_count }}
              </span>
              <span class="stat-legend">
                {{ 'DISCOVER_GROUPS.members' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      *ngIf="(collection?.meta | async)?.next"
      [ngStyle]="{
        'background-color': colors.primary, 'border-color': colors.primary, 'color': 'white'
      }"
      class="see-more-link"
      (click)="loadNextGroups()"
    >
      {{ 'DISCOVER_GROUPS.see-more' | translate }}
    </a>
  </div>
</div>
