import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { IViewType, ViewTypeService } from 'src/app/shared/view-type.service';
import { HelpscoutService } from 'src/app/core/helpscout.service';
import { Meet } from 'src/app/shared';

@Component({
  templateUrl: './onboarding-participant.component.html',
  selector: 'onboarding-participant'
})

export class OnBoardingParticipantComponent  {
  @Input() step:     string;
  @Input() meet:     Meet;
  @Input() confCode: number;

  conf_name:     string;
  viewType$:     Observable<IViewType> = this._viewType.get();
  isIphone:      boolean;

  constructor(
    private _viewType: ViewTypeService,
    private _helpscout: HelpscoutService,
  ) { }

  ngOnInit() {
    this.conf_name = 'e' + this.meet.id;
    this.isIphone = navigator.userAgent.indexOf('iPhone') != -1;
  }

  callConference() {
    window.open('tel:+33184886478');
  }

  contactUs() {
    this._helpscout.open();
  }
}
