import { of as observableOf,  Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges, Inject, Renderer2, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup }  from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsService } from './groups.service';
import { AppStore } from '../store/';
import { Group, QueryForm, Member, AskQuestionsFormComponent } from '../shared/';
import { AuthService } from '../auth/auth.service';
import { AuthRedirectService } from '../auth/auth-redirect.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'group-query-forms',
  templateUrl: './group-query-forms.component.html'
})

export class GroupQueryFormsComponent implements OnInit, OnChanges {

  @ViewChild(AskQuestionsFormComponent) questionsForm: AskQuestionsFormComponent;

  @Input() group:   Group;
  @Input() config:  {
    // 'quiz': responses are anonymous and they get attached to Group
    // 'survey': responses are not anonymous and they get attached to Member
    query_form_category: 'quiz'|'survey'
    rsvp_cta: string,
    rsvp_title: string,
    rsvp_subtitle: string,
    rsvp_note: string,
    rsvp_after: string,
    text_color: string,
    color: string
  };

  queryForm:        QueryForm;
  form:             FormGroup;

  groupId:          string;
  member$:          Observable<Member> = observableOf(null);

  showForm:         boolean;
  submitted:        boolean;
  color:            string = '';

  constructor(
    @Inject(DOCUMENT)
    private _doc:                 any,
    private _renderer:            Renderer2,
    private _authService:         AuthService,
    private _authRedirectService: AuthRedirectService,
    private _groupsService:       GroupsService,
    private _route:               ActivatedRoute,
    private _router:              Router,
    private _fb:                  FormBuilder,
    private _appStore:            AppStore
  ) { }

  ngOnInit() {
    this.groupId = this._route.snapshot.params.id || this._route.parent.snapshot.params.id;

    this._route.fragment
      .subscribe(fragment => {
        if (fragment == 'group-query-form' && this._authService.isLoggedIn) {
          this.showForm = true;
          const el = this._doc.getElementById('group-query-form');
          this._renderer.setStyle(el, 'display', 'initial');
        }
        else {
          this.showForm = false;
        }
      });

    this._groupsService.loadMyMember(this.groupId);
    this.member$ = this._groupsService.myMember$;
  }

  ngOnChanges(changes) {
    if (this.group && this.config) {
      const category = this.config.query_form_category ? this.config.query_form_category : 'quiz';
      this._appStore.load('GroupQueryForms', `${this.group.id}`, { kind: 'blueprint', category })
        .entities
        .pipe(
          filter(_ => _.length > 0)
        )
        .subscribe((queryForms: any) => {
          this.queryForm = queryForms[0];
          this.form = this._fb.group({});
        });
    }

    if (this.config && this.config.color) {
      this.color = this.config.color;
    }
    else if (this.group && this.group.options && this.group.options.colors) {
      this.color = this.group.options.colors.primary;
    }
  }

  openForm() {
    this._router
      .navigate(['.'], { relativeTo: this._route, replaceUrl: true, fragment: 'group-query-form' })
      .then(() => {
        if (!this._authService.isLoggedIn) {
          this._authRedirectService.authenticateThenGoBack(
            { warningMessage: 'need-authentication-to-join-group', tab: 'register' },
            this._route.snapshot.queryParams['_']
          );
        }
      });
  }

  onSubmit(form: FormGroup) {
    if (!form.valid)
      return ;

    const queries = this.questionsForm.prepareToSubmit(form.value.questions);

    let toCreate: any = {
      queries,
      kind: 'report',
      category: this.queryForm.category,
      blueprint_id: this.queryForm.id,
    };

    if (this.config && this.config.query_form_category == 'quiz') {
      toCreate.target_type = 'Group';
      toCreate.target_id = this.queryForm.group.id;
    }

    this._appStore.create('QueryForm', null, toCreate)
      .subscribe(
        (queryForm: QueryForm) => {
          this._router.navigate(['.'], { relativeTo: this._route, replaceUrl: true });
          this.joinGroup(`${queryForm.id}`);
        },
        error => {
          console.log(error);
        }
      );
  }

  joinGroup(queryFormId: string) {
    const member: Member = this._groupsService.myMember;

    if (member && member.status != 'active') {
      this._groupsService.updateMyMemberStatus(this.groupId, 'active')
        .subscribe(_ => {
          this._appStore.reload('Members', this.groupId);
          this._appStore.reload('AccountGroups', null);
        });
    }
    else {
      this._groupsService.createMyMember(this.groupId, queryFormId)
        .subscribe(_ => {
          this._appStore.reload('Members', this.groupId);
          this._appStore.reload('AccountGroups', null);
        });
    }
  }
}
