import { Component, Input } from '@angular/core';
import { HelpscoutService } from 'src/app/core/helpscout.service';

@Component({
  selector: 'k-get-demo-input',
  template: `
    <div class="k-get-demo-input flex row-wrap">
      <input
        #email
        class="col col-6"
        type="email"
        placeholder="Votre email"
      >
      <button class="text-big col" (click)="onGetDemo(email.value)">
        Nous vous contacterons
      </button>
    </div>
  `,
  styles: [`
  `]
})

export class GetDemoInputComponent {

  constructor(
    private _helpscout: HelpscoutService
  ) { }

  onGetDemo(email: string) {
    this._helpscout.prefillAndOpen({
      subject: 'Demande de démo',
      text: "Bonjour, j'aimerais prendre RDV pour une démo de la plateforme Kawaa...",
      email: email
    });
  }
}
