import {
  Pipe,
  PipeTransform
}  from '@angular/core';
import {
  TranslateService
}  from '@ngx-translate/core';

@Pipe({
  name: 'customTranslate'
})
export class CustomTranslatePipe implements PipeTransform  {

  private _lang: string;

  constructor(private _translate: TranslateService) {
    this._lang = _translate.currentLang;
  }

  transform(toTraslate: any): string {
    if (!toTraslate) return '';

    if (toTraslate[this._lang])
      return toTraslate[this._lang];
    else if (toTraslate.fr)
      return toTraslate.fr;
    else
      return toTraslate;
  }
}

