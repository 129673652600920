<ng-container [formGroup]="formGroup">
  <div
    class="form-item w100 w100-m m-s-b"
    *ngFor="let field of questions"
  >
    <h2 class="text-large m-xs-b m-x-t">
      {{ field.question }} {{ field.required ? '*' : '' }}
    </h2>
    <p
      *ngIf="field.kind != 'separator'"
      [innerHtml]="field.note"
      class="m-s-b m-xs-t"
    >
    </p>

    <ng-container *ngIf="formGroup.get(field.name) as formControl">
      <div
        class="flex bold-weight-text m-xxs-tb text-right"
        *ngIf="formControl.invalid && (formControl.touched || parentFormIsSubmitted)"
      >
        <div class="border-radius p-tb-5 p-lr-5 text-smaller" style="background-color: red; color: white">
          <i class="far fa-exclamation-triangle"></i>
          <ng-container *ngIf="formControl.hasError('required')">
            {{ 'GROUP_CREATE.err-required' | translate }}
          </ng-container>
          <ng-container *ngIf="formControl.hasError('maxlength') || formControl.hasError('maxLength')">
            {{ 'GROUP_CREATE.err-max-length' | translate: { max: field.max_length } }}
          </ng-container>
          <ng-container *ngIf="formControl.hasError('minlength') || formControl.hasError('minLength')">
            {{ 'GROUP_CREATE.err-min-length' | translate: { min: field.min_length } }}
          </ng-container>
          <ng-container *ngIf="formControl.hasError('email')">
            {{ 'GROUP_CREATE.err-email' | translate }}
          </ng-container>
          <ng-container *ngIf="formControl.hasError('pattern')">
            {{ 'GROUP_CREATE.err-tel' | translate  }}
          </ng-container>
        </div>
      </div>
    </ng-container>


    <ng-template [ngIf]="field.kind == 'attachment'">
      <ng-container *ngIf="{ status: null, control: formGroup.get(field.name) } as ctx">
        <k-input-file
          [accept]="field.attachment_accept"
          [maxSize]="1024 * 1024"
          (change)="onFileChange($event, ctx)">
        </k-input-file>
        <p *ngIf="ctx.status">{{ ctx.status }}</p>
      </ng-container>
    </ng-template>


    <div *ngIf="field.kind == 'separator'">
      <h2 class="m-xxs-b" [innerHtml]="field.value"></h2>
      <p *ngIf="field.note" [innerHtml]="field.note"></p>
    </div>
    <input
      *ngIf="field.kind == 'input'"
      [id]="field.name"
      [type]="field.field_type"
      [formControlName]="field.name"
      (keydown.enter)="$event.preventDefault()"
    >
    <wysiwyg-input
      *ngIf="field.kind == 'textarea'"
      [id]="field.name"
      [formControlName]="field.name"
      rows=4
    ></wysiwyg-input>
    <select
      *ngIf="field.kind == 'select'"
      [id]="field.name"
      [formControlName]="field.name"
    >
      <option
        class="form-item w30"
        *ngFor="let option of field?.option_values"
        [value]="option"
      >
        {{ option }}
      </option>
    </select>
    <div
      *ngIf="field.kind == 'date'"
    >
      <input
        [id]="field.name"
        [formControlName]="field.name"
        matInput
        [matDatepicker]="openPicker"
        (click)="openPicker.open()"
      >
      <mat-datepicker #openPicker></mat-datepicker>
    </div>
    <div
      *ngIf="field.kind == 'multi_choice'"
      [id]="field.name"
      class="flex row-wrap m-s-b"
    >
      <div
        class="m-xs-t checkbox-grid"
        *ngFor="let option of formGroup.get(field.name).controls; let i = index"
      >
        <input
          type="checkbox"
          [formControl]="option"
          [id]="field.name + i"
        >
        <label [for]="field.name + i">
          <span>{{ field.option_values[i] }}</span>
        </label>
      </div>
    </div>
    <div class="m-x-tb" *ngIf="field.kind == 'slider'">
      <div class="false-slider">
        <input
          type="range"
          list="tickmarks"
          min="1"
          [max]="field.option_values.length"
          [formControlName]="field.name"
          [id]="field.name"
        >
        <div class="false-tickmarks">
          <div class="ticks" *ngFor="let option of field.option_values">
            <div class="false-tick-indicator"></div>
            <div class="false-tick-value">
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
