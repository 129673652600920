
import { map, filter } from 'rxjs/operators';
import { Observable ,  BehaviorSubject }  from 'rxjs';
import { Pool }  from './app.store-pool';
import { AnimationFormat, Comment, Group,
  GroupLink, GroupLinkTemplate, Location,
  Meet, Meetship, Member,
  Participant, Post, QueryForm,
  Meta, User, Tag, Resource, Invitation, MeetTemplate }  from '../shared/definitions';

export class Collection<T extends User| AnimationFormat| Member| Group| Meet| Participant| Meetship| MeetTemplate| GroupLink| QueryForm| Location| Comment| Invitation| Post| Resource| GroupLinkTemplate| Tag> {

  private _meta$:     BehaviorSubject<Meta> = new BehaviorSubject<Meta>({
    limit:    11,
    next:     null,
    offset:   0,
    previous: null,
    total:    0
  });

  private _finished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public  id:         string,
    public  kind:       string,
    public  properties: Object,
    public  pool:       Pool<T>,
    private _req:       (string) => Observable<any>
  ) { }

  get entities(): Observable<T[]> {
    return this.pool.elements$
      .pipe(
        map((elements) => {
          const toEmit = elements
            .filter(el => {
              // second condition -> test: create meet and add it to its collection
              return this._compareProperties(el['properties'], this.properties) || this._compareProperties(el, this.properties);
            })
            .reduce((all, el) => {
              return all.map(_ => _.id).indexOf(el.id) == -1 ? all.concat(el) : all;
            }, []);

          return toEmit;
        })
      );
  }

  get entity(): Observable<T> {
    return this.pool.elements$
      .pipe(
        filter(elements => elements.length > 0),
        map(elements => elements.find(el => `${el.id}` == this.id && (this._compareProperties(el['properties'], this.properties) || this._compareProperties(el, this.properties)))),
        filter(_ => _ ? true : false),
      );
  }

  get entityByProps(): Observable<T> {
    return this.pool.elements$
      .pipe(
        filter(elements => elements.length > 0),
        map(elements => elements.find(el => this._compareProperties(el['properties'], this.properties))),
        filter(_ => _ ? true : false),
      );
  }

  get meta(): Observable<Meta> {
    return this._meta$.asObservable();
  }

  private get _meta(): Meta {
    return this._meta$.getValue();
  }

  get doneCollecting() {
    return this._finished$.asObservable();
  }

  collectSingle() {
    this._req('')
      .subscribe(
        (data: T) => {
          data['properties'] = Object.assign({}, this.properties);
          this.pool.add(data);
        },
        ({ error }) => this.pool.throwError(error.error)
      );
  }

  collect(limit: number, offset: number) {
    this._collect({ limit, offset });
  }

  collectNext(): void {
    if (this._meta.next)
      this._collect({ limit: this._meta.limit, offset: this._meta.offset + this._meta.limit });
  }

  collectAll(): void {
    this._req('&limit=0&offset=0')
      .subscribe(
        data => {
          // change just 'total' so that it doesn't affect the property 'next'
          const total = data.meta.total;
          this._collect({ limit: total, offset: 0 });
        },
        ({ error }) => this.pool.throwError(error.error)
      );
  }

  recollect(): void {
    this._collect({ limit: 12, offset: 0 });
  }

  recollectMeta(): void {
    this._req('&limit=0&offset=0')
      .subscribe(
        data => {
          // change just 'total' so that it doesn't affect the property 'next'
          const total = data.meta.total;
          this._meta$.next({ ...this._meta, total });
        },
        ({ error }) => this.pool.throwError(error.error)
      );
  }

  private _compareProperties(elementProperties, collectionProperties): boolean {
    return Object.keys(collectionProperties).every(collectionKey => {
      return collectionProperties[collectionKey] == elementProperties[collectionKey];
    });
  }

  private _collect({ limit, offset }) {
    this._req('&limit=' + +limit + '&offset=' + +offset)
      .subscribe(
        data => {
          const [meta, entities] = Object.keys(data);
          const toAdd = data[entities].map(entity => {
            entity.properties = Object.assign({}, this.properties);
            return entity;
          });

          this._meta$.next(data.meta);

          this.pool.add(toAdd);

          if (data.meta.next == null) {
            this._finished$.next(true);
          }
        },
        ({ error }) => this.pool.throwError(error.error)
      );
  }
}
