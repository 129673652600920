import { Observable,  BehaviorSubject }  from 'rxjs';
import { AnimationFormat, Comment, Group,
  GroupLink, GroupLinkTemplate, Location,
  Meet, Meetship, Member,
  Participant, Post, QueryForm, User, Tag, Resource, Invitation, MeetTemplate, GroupTemplate }  from '../shared/definitions';
import { filter, map } from 'rxjs/operators';


interface IPoolElement {
  values: any[];
  isFirst: boolean;
}

export class Pool<T extends User| AnimationFormat| Member| Group| Meet| Participant| Meetship| MeetTemplate| GroupLink| QueryForm| Location| Comment| Invitation| Post| Resource| GroupLinkTemplate| GroupTemplate| Tag> {

  private poolElements$: BehaviorSubject<IPoolElement> = new BehaviorSubject<IPoolElement>({ values: [], isFirst: true });

  get elements$(): Observable<T[]> {
    return this.poolElements$
      .asObservable()
      .pipe(
        filter((el: IPoolElement) => !el.isFirst),
        map((el: IPoolElement) => el.values)
      );
  }

  private get elements(): T[] {
    return this.poolElements$.getValue().values;
  }

  add(el: T | T[]) {
    if (el instanceof Array) {
      this.poolElements$.next({ values: [...this.elements, ...el], isFirst: false });
    }
    else {
      this.poolElements$.next({ values: [el, ...this.elements], isFirst: false });
    }
  }

  throwError(err: any) {
    this.poolElements$.error(err);
  }

  modify(el: T) {
    const filtered = this.elements.filter(_ => _.id != el.id);
    const toModify = this.elements
      .filter(_ => _.id == el.id)
      .map(entity => {
        Object.keys(el).forEach(key => {
          if (entity.hasOwnProperty(key))
            entity[key] = el[key];
          if (entity['properties'].hasOwnProperty(key) && entity['properties'][key] != el[key])
            entity['properties'][key] = el[key];
        });
        return entity;
      });
    this.poolElements$.next({ values: [...filtered, ...toModify], isFirst: false });
  }

  remove(el: T) {
    const elmts = this.elements.filter(_ => _.id != el.id);
    this.poolElements$.next({ values: elmts, isFirst: false });
  }
}
