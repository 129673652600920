import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class GtmService {
  constructor(private _location: Location) {}

  init() {
    const consent = localStorage.getItem('cookiesConsent');

    if (consent) {
      this.addGoogleScripts();
    } else {
      this.removeGoogleScripts();
    }
  }

  addGoogleScripts() {
    if (
      window.location.hostname === 'staging.prix-sante-entrepreneurs.harmonie-boost.fr' ||
      window.location.hostname === 'prix-sante-entrepreneurs.harmonie-boost.fr'
    ) {
      // Set GTM for Harmonie Boost
      this.renderGtmScripts('GTM-N3X8HFH');
    } else if (window.location.hostname === 'kawaa.co' || window.location.hostname === 'staging.kawaa.co') {
      // Set GTM for kawaa.co
      this.renderGtmScripts('GTM-K7H9BPB');
      this.renderGtagScripts('G-EKP1NL9MEC');
    } else if (window.location.hostname === 'evenement-paris.kawaa.co') {
      this.renderGtagScripts('G-S9VVFR2MWE');
    } else if (window.location.hostname === 'paris.kawaa.co') {
      // Set GTM for paris.kawaa.co
      this.renderGtmScripts('GTM-TSL883B');
      this.renderGtagScripts('G-JP7WD9R4QV');
    } else if (window.location.hostname === 'lille.kawaa.co') {
      // Set GTM for lille.kawaa.co
      this.renderGtmScripts('GTM-TDVZWBQ');
      this.renderGtagScripts('G-TDHJ94B8ZG');
    } else {
      this.removeGoogleScripts();
    }
  }

  // Function to render GTM script into head and body
  renderGtmScripts(gtmId: string) {
    if (!document.getElementById('headGtmScript')) {
      const headGtmScript = document.createElement('script');
      headGtmScript.setAttribute('id', 'headGtmScript');

      headGtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`;
      document.head.appendChild(headGtmScript);
    }

    if (!document.getElementById('bodyGtmNoScript')) {
      const bodyGtmNoScript = document.createElement('noscript');
      bodyGtmNoScript.setAttribute('id', 'bodyGtmNoScript');
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
      iframe.setAttribute('height', '0');
      iframe.setAttribute('width', '0');
      iframe.setAttribute('style', 'display:none;visibility:hidden');
      bodyGtmNoScript.appendChild(iframe);
      document.body.appendChild(bodyGtmNoScript);
    }
  }

  // Function to render gtag.js script into head
  renderGtagScripts(gtagId: string) {
    if (!document.getElementById('headGtagScript')) {
      const headScript = document.createElement('script');
      headScript.setAttribute('id', 'headGtagScript');
      headScript.setAttribute('async', '');
      headScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagId}`);
      document.head.appendChild(headScript);
    }

    if (!document.getElementById('dataLayerGtagScript')) {
      const dataLayerScript = document.createElement('script');
      dataLayerScript.setAttribute('id', 'dataLayerGtagScript');
      dataLayerScript.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gtagId}');`;
      document.head.appendChild(dataLayerScript);
    }
  }

  removeGoogleScripts() {
    const headGtmScript = document.getElementById('headGtmScript');
    const bodyGtmNoScript = document.getElementById('bodyGtmNoScript');
    const headGtagScript = document.getElementById('headGtagScript');
    const dataLayerScript = document.getElementById('dataLayerGtagScript');
    headGtmScript?.remove();
    bodyGtmNoScript?.remove();
    headGtagScript?.remove();
    dataLayerScript?.remove();
  }
}
