<!--
  Desktop template
-->
<div *ngIf="viewType == 'desktop'" class="row">
  <h3
    class="section-title m-m-b"
    [style.border-bottom-color]="theme?.colors?.primary"
  >
     {{ 'MEET_PAGE.info' | translate }}
  </h3>

  <div class="col col-12 infos-meet-section">

    <!-- >>> Description -->
    <div class="infos-meet-desc bubble-left-in w100">
      <div *ngIf="!isMeetFetching">
        <span class="p-static dynamic-content" [innerHtml]="meetDescription | linky | sanitizeHtml"></span>
      </div>
      <div *ngIf="isMeetFetching">
        <div class="full-size-text is-fetching"></div>
        <div class="full-size-text is-fetching"></div>
        <div class="mid-size-text is-fetching"></div>
        <br>
        <div class="full-size-text is-fetching"></div>
        <div class="full-size-text is-fetching"></div>
        <div class="third-size-text is-fetching"></div>
      </div>
    </div>
    <!-- <<< Description -->

    <!-- >>> Author -->
    <div class="author-meet">
      <div class="author-profil">
        <div class="img-author-profil">
          <img
            [src]="organizatorPictureSlug | imageResizeWidth:90"
            [alt]="organizatorName"
            (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
          />
        </div>
        <div class="desc-author-profil m-s-l">
          <a
            [routerLink]="['/profile', organizatorId, 'organisations']"
            [queryParams]="{'_': theme?.group_id}"
          >
            <h4 class="title-h4">
              <div
                *ngIf="isOrganizatorsFetching"
                class="text-full-width is-fetching"
              ></div>
              <span *ngIf="!isOrganizatorsFetching" class="link-effect">
                {{ organizatorName }}
              </span>
            </h4>
          </a>
          <!-- TODO Later cause it's not in place in back - 04 dec 2017 -->
          <div class="author-legend">{{ 'MEET_PAGE.organizer' | translate }}</div>
        </div>
      </div>

      <a
        *ngIf="nbMeetOrganizedByOrganisator"
        class="stats-author m-m-l"
        [routerLink]="['/profile', organizatorId, 'organisations']"
        [queryParams]="{'_': theme?.group_id}"
      >
        <span class="link-effect">
          <span class="number-stats">{{ nbMeetOrganizedByOrganisator }}</span>
          {{ "MEET_PAGE.organized-meets" | translate | pluralize:nbMeetOrganizedByOrganisator }}
        </span>
      </a>

      <!-- TODO later, not currently in place in back-end, and we cannot give the email of the organizator at ervery1 -->
      <!-- <div class="contact-author m-m-l">
        <button class="small contact">
          <i class="fa fa-envelope-o" aria-hidden="true"></i>
          Contact
        </button>
      </div> -->
    </div>
    <!-- <<< Author -->
  </div>
</div>

<!--
  Mobile template
-->
<div *ngIf="viewType == 'mobile'" class="w100">
  <div class="row mobile-meet-description">
    <!-- >>> Description -->
    <div class="col col-12 description">
      <div *ngIf="!isMeetFetching && sliceDescription">
        <span
          class="p-static dynamic-content"
          [innerHtml]="meetDescription | slice:0:320 | linky | sanitizeHtml"
        ></span>
        <span *ngIf="meetDescription.length > 320">
          ...
          <a
            class="link-effect padding-link-effect"
            (click)="sliceDescription = false"
            [style.color]="theme?.colors?.primary || '#1BA2A8'"
          >
            {{ 'MEET_PAGE.show-more' | translate }}
          </a>
        </span>
      </div>
      <div *ngIf="isMeetFetching">
        <div class="full-size-text is-fetching"></div>
        <div class="full-size-text is-fetching"></div>
        <div class="mid-size-text is-fetching"></div>
        <br>
        <div class="full-size-text is-fetching"></div>
        <div class="full-size-text is-fetching"></div>
        <div class="third-size-text is-fetching"></div>
      </div>
      <span
        *ngIf="!sliceDescription"
        class="p-static dynamic-content"
        [innerHtml]="meetDescription | linky | sanitizeHtml"
      ></span>
    </div>
    <!-- <<< Description -->
    <!-- >>> Author -->
    <div class="col col-12 about-author">
      <div class="profile">
        <div class="picture" [style.border-color]="theme?.colors?.primary">
          <img
            [src]="organizatorPictureSlug | imageResizeWidth:90"
            [alt]="organizatorName"
            (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
          />
        </div>
        <div class="name">
          <a
            [routerLink]="['/profile', organizatorId, 'organisations']"
            [queryParams]="{'_': theme?.group_id}"
          >
            <h4>
              <div
                *ngIf="isOrganizatorsFetching"
                class="text-full-width is-fetching"
              ></div>
              <span
                *ngIf="!isOrganizatorsFetching"
                class="underline"
              >
                {{ organizatorName }}
              </span>
            </h4>
          </a>
          <div class="name-legend">
            {{ 'MEET_PAGE.organizer' | translate }}
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- <<< Author -->
</div>
