import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IViewType, ViewTypeService } from 'src/app/shared/view-type.service';
import { HelpscoutService } from 'src/app/core/helpscout.service';

@Component({
  selector: 'k-offers',
  templateUrl: './offers-showcase.component.html',
  styles: [`
    .img-offer {
      height: 120px;
    }

    .p-baseline {
      height: 110px;
    }
  `]
})

export class OffersShowcaseComponent {

  @Input() showActions: boolean;

  viewType$: Observable<IViewType> = this._viewType.get();

  constructor(
    private _viewType: ViewTypeService,
    private _helpscout: HelpscoutService
  ) { }

  onGetDemo() {
    this._helpscout.prefillAndOpen({
      subject: 'Demande de démo',
      text: "Bonjour, j'aimerais prendre RDV pour une démo de la plateforme Kawaa..."
    });
  }

  onContact() {
    this._helpscout.open();
  }
}
