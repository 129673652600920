import { Component, Input, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { Group, ITranslatedValue } from '../shared';

interface IGroupStatsConfig {
  meet_naming: ITranslatedValue;
  member_naming: ITranslatedValue;
}

@Component({
  selector: 'group-stats',
  templateUrl: './group-stats.component.html'
})
export class GroupStatsComponent implements OnChanges {
  @Input() group:  Group;
  @Input() config: IGroupStatsConfig;

  localConfig = {
   meet_naming: { fr: 'Rencontre', en: 'Meet' },
   member_naming: { fr: 'Membre', en: 'Member' }
  };

  ngOnChanges(change: SimpleChanges) {
    // Prevent config to be overrided by null/undefined if change.config is not set.
    if (change.config && change.config.currentValue) {
      this.localConfig = change.config.currentValue;
    }
  }
}