
import { combineLatest as observableCombineLatest, of as observableOf,  Observable } from 'rxjs';

import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { Component, Inject, ViewChild, OnInit, ElementRef }  from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute }  from '@angular/router';
import { CustomService }  from './custom.service';
import { IColors, IPageSection, AnimationFormat, Group, Meet, GroupLink }  from '../shared/';
import { Collection, AppStore }  from '../store/';
import { MetaService } from '../core';

@Component({
  templateUrl: './custom-page.component.html'
})

export class CustomPageComponent implements OnInit {

  group:                  Group;
  colors:                 IColors = { primary: '' };
  sections:               IPageSection[] = [];

  @ViewChild('board', { static: true }) socialBoard:            ElementRef;
  howtankContentRange:    Range;

  constructor(
    @Inject(DOCUMENT)
    private _document:        any,
    private _router:          Router,
    private _route:           ActivatedRoute,
    private _customService:   CustomService,
    private _appStore:        AppStore,
    private _metaService:     MetaService
  ) {
    this.howtankContentRange = _document.createRange();
  }

  ngOnInit() {
    const groupId: string = this._route.parent.snapshot.params['id'];

    const group$: Observable<Group> = this._route.params.pipe(
      map(_ => _.id),
      distinctUntilChanged(),
      mergeMap((id: string) => {
        if (id)
          return observableOf(id);
        else
          return this._route.parent.params.pipe(map(_ => _.id));
      }),
      mergeMap((id: string) => this._appStore.load('Group', id).entity),);

    const pageName$: Observable<string> = this._route.params.pipe(
      map(params => params.page_name ? params.page_name : 'home'));

    group$.subscribe(
      (group: Group) => {
        this.group = group;

        if (group.name) {
          this._metaService.setTitle(group.name);
        }
        if (group.options && group.options.colors) {
          this.colors = group.options.colors;
        }
      },
      (error) => this._router.navigate(['/page-not-found'])
    );

    pageName$.pipe(
      mergeMap((pageName: string) => this._customService.loadPageSections(groupId, pageName)))
      .subscribe(
        (sections: IPageSection[]) => {
          this.sections = sections;
        },
        (error) => console.log(error)
      );

    observableCombineLatest(group$, pageName$)
      .subscribe(
        ([group, pageName]) => {
          const pageTitle: string = pageName.charAt(0).toUpperCase() + pageName.slice(1);

          if (group.options && group.options.howtank_hostmnemonic) {
            this.setHowtankSocialBoard(pageName, group.options.howtank_hostmnemonic);
          }
        },
        (error) => console.log(error)
      );
  }

  setHowtankSocialBoard(pageName: string, hostmnemonic: string) {
    const el = this.socialBoard.nativeElement;

    if (el && pageName == 'social-board') {
      const content = this.howtankContentRange.createContextualFragment(`
        <div id="ht_socialboard" style="padding:0!important;margin:0!important;" data-target="https://www.howtank.com/social/${hostmnemonic}"></div>
        <script type="text/javascript" src="https://www.howtank.com/scripts/build/module.embed-opentank.js"></script>
      `);
      el.appendChild(content);
    }
    else if (el && pageName != 'social-board') {
      this.howtankContentRange.selectNodeContents(el);
      this.howtankContentRange.deleteContents();
    }
  }

  onSelectMeet(meet: Meet): void {
    const url: string[] = this._router.url.split('/');

    if (url.length == 3)
      this._router.navigate(['rencontre', meet.id], { relativeTo: this._route });
    else if (url.length == 4)
      this._router.navigate(['../rencontre', meet.id], { relativeTo: this._route });
    else if (url.length == 5)
      this._router.navigate(['../../rencontre', meet.id], { relativeTo: this._route });
  }

  onSelectFormat(group: Group, format: AnimationFormat): void {
    const url: string[] = this._router.url.split('/').filter((_, i) => i != 0);

    if (url.length == 2)
      this._router.navigate(['format', format.id], { relativeTo: this._route });
    else if (url.length == 3)
      this._router.navigate(['../format', format.id], { relativeTo: this._route });
    else if (url.length == 4)
      this._router.navigate(['../../format', format.id], { relativeTo: this._route });
  }

  onCreateMeet(group: Group): void {
    const tree = this._router.parseUrl(this._router.url);
    const url: string[] = tree.root.children['primary'].segments.map(_ => _.path);
    let params: any  = { groupId: url[1] };

    if (group.place) {
      params.placeId = group.id;
    }
    else {
      params.groupId = group.id;
    }

    if (group.options && group.options.meet_submission_init_page) {
      this._router.navigateByUrl(group.options.meet_submission_init_page);
    }
    else {
      this._router.navigate(['/admin', 'rencontres', 'creer', params], { queryParams: { '_': url[1] } });
    }
  }

  onSelectGroupLink(targetGroup: Group, originGroup: Group) {
    if (targetGroup) {
      this._appStore.load('GroupLink', `${targetGroup.id}`, { origin_group_id: originGroup.id })
        .entityByProps
        .subscribe(
          (link: GroupLink) => this._router.navigate(['/home', `${targetGroup.id}`, link.flag, originGroup.id]),
          error => this._router.navigate(['/home', originGroup.id])
        );
    }
    else {
      // used in /fr/decouvrir/lieux
      this._router.navigate(['/home', originGroup.id]);
    }
  }
}
