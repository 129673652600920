import { Routes } from '@angular/router';

import { ProviderGuardService } from './provider-guard.service';
import { AuthAccessGuard } from './auth-access.guard';
import { AuthPageComponent } from './auth-page.component';

export const authRoutes: Routes = [
  {
    path: 'k-auth',
    component: AuthPageComponent,
    canActivate: [AuthAccessGuard],
    data: { meta: { title: 'Authentification' } },
  },
  {
    path: 'k-auth/:provider',
    component: AuthPageComponent,
    canActivate: [ProviderGuardService]
  }
];
