import { of as observableOf, Observable } from 'rxjs';

import { map, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppStore } from '../store/';
import { Group, ITheme } from '../shared/definitions';


@Injectable()
export class ThemeResolver implements Resolve<ITheme> {

  theme:  ITheme = {
    group_id:                     20,
    colors:                       { primary: '' },
    auth_schema:                  { facebook_connect: true, kawaa_connect: true, paris_connect: false, howtank_connect: false },
    group_links_naming:           { value: 'group-link', gender: 'masculine' },
    howtank_visas:                null,
    meets_in_linked_groups_only:  false
  };

  constructor(private appStore: AppStore) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITheme> {
    const id = route.queryParams['_'] ? route.queryParams['_'] : route.parent.params['id'];

    if (!id)
      return observableOf(this.theme);

    return this.appStore.load('Group', id).entity.pipe(
      first(),
      map((group: Group) => {
        this.theme.group_id = (group.options && group.options.master_group_id ? group.options.master_group_id : group.id);

        if (group.options && group.options.colors)
          this.theme.colors = group.options.colors;
        if (group.options && group.options.auth_schema)
          this.theme.auth_schema = group.options.auth_schema;
        if (group.options && group.options.howtank_visas)
          this.theme.howtank_visas = group.options.howtank_visas;
        if (group.options && group.options.group_links_naming)
          this.theme.group_links_naming = group.options.group_links_naming;
        if (group.options && group.options.meets_in_linked_groups_only)
          this.theme.meets_in_linked_groups_only = group.options.meets_in_linked_groups_only;
        if (group.options && group.options.meet_snippets)
          this.theme.meet_snippets = group.options.meet_snippets;

        return this.theme;
      }),);
  }
}
