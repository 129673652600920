import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Group, QueryForm, ITranslatedValue, IColors } from '../shared/index';
import { AppStore } from '../store/index';

@Component({
  selector: 'group-query-forms-reports',
  templateUrl: './group-query-forms-reports.component.html',
  styles: [`
    .query-form-number {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 50px;
      height: 50px;
      border: 1px solid #ebebeb;
      border-radius: 3px;
      display: flex;
      align-items:  center;
      justify-content: center;
      font-weight: 800;
      font-size: 18px;
    }
  `]
})

export class GroupQueryFormsReportsComponent implements OnInit {

  @Input() group: Group;
  @Input() config: any;
  @Input() sectionTitle: ITranslatedValue;

  color: string = '';
  queryForms$: Observable<QueryForm[]>;

  constructor(
    private _appStore: AppStore,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    const groupId = this._route.snapshot.params.id;

    this.queryForms$ = this._appStore.load('GroupQueryForms', groupId, { kind: 'report', category: 'quiz' }).entities;

    if (this.group && this.group.options && this.group.options.colors) {
      this.color = this.group.options.colors.primary;
    }
    else if (this.config && this.config.color) {
      this.color = this.config.color;
    }
  }

  typeOf(e: any): 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' | 'array' {
    const type = typeof e;
    if (type == 'object')
      return (e instanceof Array) ? 'array' : type;
    return type;
  }
}
