import { pluck } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '../core';
import { AuthService } from '../auth';

@Component({
  template: `
    <main-header class="container-fluid"></main-header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <main-footer></main-footer>
  `
})
export class MainComponent {

  constructor(
    private _authService: AuthService,
    private _translate: TranslateService,
    private _route: ActivatedRoute,
    private _metaService: MetaService
  ) {
    this._route.data
      .pipe(pluck('lang'))
      .subscribe(lang => {
        if (!lang) // HOME page
          this.determineLang();
        else // other pages, cf. main routing
          this._translate.use(lang);
      });

    this._authService.universalAuth();
    this._metaService.setDefaultFavicon();
  }

  determineLang() {
    this._authService.getUser()
      .subscribe(user => {
        if (user && user.id && user.locale) {
          this._translate.use(user.locale);
        }
        else if (this._translate.getBrowserLang()) {
          this._translate.use(this._translate.getBrowserLang());
        }
      });
  }
}
