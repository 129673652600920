import { Component, OnInit }  from '@angular/core';

@Component({
  template: `
    <div class="fade-in-anim">
      <div class="page-title hidden-if-not-mobile text-post-light-purple p-lr-40">maison partagée</div>
      <div class="main-image flex">
        <img src="assets/images/home/house-sofa.jpeg" alt="a comfortable house sofa">
      </div>
      <div class="post-block">
        <div class="post text-post-light-purple bg-post-light-yellow">
          <div class="text">
            <p class="paragraph">
              Tu cherches un logement ? Plutôt du genre colocation ? Rejoins l'une de nos habitations partagées. On y partage non seulement des espaces communs mais aussi le goût de l'engagement. Tous les genres et les âges s'y croisent. Il n'y a pas encore de maison partagée kawaa là où tu es ? C'est pas grave, on va la créer ensemble !
            </p>
          </div>
          <div class="borders">
            <div class="border-t-post-light-purple"></div>
            <div class="border-r-post-light-purple"></div>
            <div class="border-b-post-light-purple"></div>
            <div class="border-l-post-light-purple"></div>
          </div>
        </div>
      </div>
      <div class="flex image-banner">
        <div class="left-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/book-shelf.jpeg" alt="a book shelf">
        </div>
        <div class="right-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/sofa-cushion.jpeg" alt="a sofa cushion">
        </div>
      </div>
    </div>
  `
})

export class SharedHouseComponent implements OnInit {

  ngOnInit() {}
}
