import { Injectable } from '@angular/core';
import { Location, Area, IArea } from 'src/app/shared/definitions';

declare var env: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {

  private static promise;

  private _service: google.maps.places.Autocomplete;
  private _class: typeof Location | typeof Area;
  private _callback?: any;

  constructor() {}

  /* A function to inject a script tag to the DOM's head, with the correct GooglePlaces Api Key depending on the environment */
  load() {
    if (!GooglePlacesService.promise) {
      GooglePlacesService.promise = new Promise((resolve) => {
          window['onLibraryLoaded'] = () => {
            resolve('loaded');
          }
          const node = document.createElement('script');
          node.src = `https://maps.googleapis.com/maps/api/js?key=${env.googlePlacesApiKey}&libraries=places&language=fr&callback=onLibraryLoaded`;
          node.type = 'text/javascript';
          document.getElementsByTagName('head')[0].appendChild(node);
      });
    }
    return GooglePlacesService.promise;
  }

  init(input: HTMLInputElement, _class: typeof Location | typeof Area, _callback?: any) {
    this.load().then(() => {
      const options = {
        fields: ["address_components", "geometry", "name"],
      }
      this._service = new google.maps.places.Autocomplete(input, options);
      this._class = _class;
      if (_callback) {
        this._callback = _callback;
      }
      this.addGoogleListener();
    })
  }

  private addGoogleListener() {
    google.maps.event.addListener(this._service, 'place_changed', () => {
      const address = this._service.getPlace();

      let result: Location | IArea;
      switch (this._class) {
        case Location:
          result = this.getLocation(address);
          break;
        case Area:
          result = this.getArea(address);
          break;
        default:
      }
      if (this._callback) {
        this._callback(result);
      }
    });
  }

  public getLocation(address: any) {
    const addressComponents = address.address_components;

    return <Location> {
      id: null,
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      zip_code: addressComponents?.find(el => el.types.includes('postal_code'))?.long_name,
      address: address.name,
      city: addressComponents?.find(el => el.types.includes('locality'))?.long_name,
      country: addressComponents?.find(el => el.types.includes('country'))?.short_name,
      name: address.name
    };
  }

  public getArea(address: any) {
    const addressComponents = address.address_components;

    return <IArea> {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      range: 5,
      city: addressComponents?.find(el => el.types.includes('locality'))?.long_name,
    };
  }
}
