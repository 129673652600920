<div class="hide-m flex align-middle">
  <li class="login-user pointer" *ngIf="(isLoggedIn$ | async)">
    <a
      href="javascript:void(0)"
      (click)="this.toggle = !this.toggle"
      href="javascript:void(0)"
      title="Profil"
      [style.border-color]="group ? colors.header_text : '#ececec'"
    >
      <img
        src="/assets//images/avatar_default.jpg"
        [alt]="(user$ | async)?.nickname"
        *ngIf="!(user$ | async)?.avatar?.slug"
      >
      <img
        [src]="(user$ | async)?.avatar?.slug"
        [alt]="(user$ | async)?.nickname"
        *ngIf="(user$ | async)?.avatar?.slug"
      >
    </a>
  </li>

  <li
    *ngIf="(isLoggedIn$ | async)"
    class="dropdown-icon pointer"
    (click)="toggle = !toggle"
  >
    <a href="javascript:void(0)">
      <i
        *ngIf="!toggle"
        class="fal fa-angle-down"
        alt="Dropdown"
        aria-hidden="true"
        [style.color]="colors?.header_text ? colors.header_text : '#000'"
      ></i>
      <i
        *ngIf="toggle"
        class="fal fa-angle-up"
        alt="Dropdown"
        aria-hidden="true"
        [style.color]="colors?.header_text ? colors.header_text : '#000'"
      ></i>
    </a>
  </li>
</div>

<div class="pos-r m-xxs-t hide-m flex">
  <nav class="dropdown-custom"
       [ngClass]="{
         dropdown: toggle,
         hide: !toggle
       }"
      >
    <ul class="ul-custom">
      <li>
        <a
          [routerLink]="['/profile']"
          [queryParams]="{'_': group?.id}"
          (click)="toggle = !toggle"
        >
          <p class="dropdown-custom-info">
            {{ 'HEADER_DROPDOWN.profile' | translate }}
            <span class="dropdown-custom-subinfo">
              {{ 'HEADER_DROPDOWN.profile-details' | translate }}
            </span>
          </p>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/profile', 'activity']"
          [queryParams]="{'_': group?.id}"
          (click)="toggle = !toggle"
        >
          <p class="dropdown-custom-info">
            {{ 'HEADER_DROPDOWN.activity' | translate }}
            <span class="dropdown-custom-subinfo">
              {{ 'HEADER_DROPDOWN.activity-details' | translate }}
            </span>
          </p>
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/admin', 'rencontres']"
          [queryParams]="{'_': group?.id}"
          (click)="toggle = !toggle"
        >
          <p class="dropdown-custom-info">
            {{ 'HEADER_DROPDOWN.admin' | translate }}
            <span class="dropdown-custom-subinfo">
              {{ 'HEADER_DROPDOWN.admin-details' | translate }}
            </span>
          </p>
        </a>
      </li>
      <li *ngFor="let g of (groupsCollection$ | async)?.groups">
        <a
          [routerLink]="['/admin', 'groupes', g.id]"
          [queryParams]="{ '_': g?.options?.master_group_id ? g.options.master_group_id : g.id }"
        >
          <p class="dropdown-custom-info">
            {{ g.name }}
            <span class="dropdown-custom-subinfo">
              {{ 'HEADER_DROPDOWN.dashboard' | translate }}
            </span>
          </p>
        </a>
      </li>
      <li *ngIf="(groupsCollection$ | async)?.meta.total > 1">
        <a
          (click)="toggle = !toggle"
          [routerLink]="['/admin', 'groupes']"
          [queryParams]="{ '_': group?.id }"
        >
          <p class="dropdown-custom-info flex align-middle">
            {{ 'HEADER_DROPDOWN.more-group' | translate }}
            <i class="fal fa-angle-right push-right m-xxs-r"></i>
          </p>
        </a>
      </li>
      <li>
        <a (click)="onLogout()" href="javascript:void(0)">
          <p class="dropdown-custom-info flex align-middle">
            {{ 'HEADER_DROPDOWN.disconnect' | translate }}
            <i class="far fa-power-off push-right"></i>
          </p>
        </a>
      </li>
    </ul>
  </nav>
</div>

<!-- Invisible modal for dropdown click outside -> need to find a better way to execute this properly-->
<div
  class="pos-a"
  [ngClass]="{
    'modalDropdown dropdown': toggle,
    'hide': !toggle
  }"
  (click)="toggle = !toggle"
>
</div>

<!-- Mobile header -->
<div class="show-m section-bottom m-x-b isLogged" *ngIf="(isLoggedIn$ | async)">
  <div class="gradient-mobile"></div>
  <img
    src="/assets/images/avatar_default.jpg"
    [alt]="userName"
    *ngIf="!(user$ | async)?.avatar?.slug"
    class="mobile-profile-nav push-center"
  >
  <img
    [src]="(user$ | async)?.avatar?.slug"
    [alt]="userName"
    *ngIf="(user$ | async)?.avatar?.slug"
    class="mobile-profile-nav push-center"
  >
  <div class="text-center m-xs-t">
    <div class="text-big bold-weight-text m-s-b text-underline">
      {{ userName }}
    </div>
  </div>
  <div class="overflow-y-scroll">
    <nav>
      <ul>
        <li class="bg-dark-black" *ngIf="(isLoggedIn$ | async)">
          <a
            [routerLink]="['/profile', 'activity']"
            [queryParams]="{ '_': group?.id }"
            class="flex space-between text-white"
          >
            <span>{{ 'HEADER.activity-profil' | translate }}</span>
            <i class="fas fa-user" aria-hidden="true"></i>
          </a>
        </li>
        <li class="bg-white" *ngIf="(isLoggedIn$ | async)">
          <a
            [routerLink]="['/admin', 'rencontres']"
            [queryParams]="{ '_': group?.id }"
            class="flex space-between text-dark-grey"
          >
            <span>{{ 'HEADER.admin' | translate }}</span>
            <i class="fal fa-angle-right" aria-hidden="true"></i>
          </a>
        </li>
        <li *ngFor="let g of (groupsCollection$ | async)?.groups">
          <a
            [routerLink]="['/admin', 'groupes', g.id]"
            [queryParams]="{ '_': g?.options?.master_group_id ? g.options.master_group_id : g.id }"
            class="flex space-between text-dark-grey"
          >
            <span>
              {{ g.name }}
            </span>
          </a>
        </li>
        <li class="bg-lighter-grey" *ngIf="(groupsCollection$ | async)?.meta.total > 2">
          <a
            [routerLink]="['/admin', 'groupes']"
            [queryParams]="{ '_': group?.id }"
            class="flex space-between text-dark-grey text-right"
          >
            <span class="m-xs-r light-weight-text">
              {{ 'HEADER_DROPDOWN.more-group' | translate }}
            </span>
            <i class="fal fa-angle-right light-weight-text"></i>
          </a>
        </li>
        <li class="bg-white" *ngIf="(isLoggedIn$ | async)">
          <a
            href="javascript:void(0)"
            (click)="onLogout()"
            class="flex space-between text-error"
          >
            <span>{{ 'HEADER.disconnect' | translate }}</span>
            <i class="far fa-power-off" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
