import { Component, OnInit }  from '@angular/core';
import { Router } from '@angular/router';
import { Collection, AppStore } from 'src/app/store/';
import { Meet, Group } from 'src/app/shared/definitions';

@Component({
  templateUrl: './workshops-meets.component.html'
})

export class WorkshopsMeetsComponent implements OnInit {
  meetsCollection:      Collection<Meet>;
  groupsCollection:     Collection<Group>;
  urlLocation:              string;

  constructor(private _appStore:  AppStore, private router: Router ) {}

  ngOnInit() {
    this.urlLocation = 'https://evenement-paris.kawaa.co'
    this.groupsCollection = this._appStore.load('Group', '3558', {})
    this.meetsCollection = this._appStore.load('GroupMeets', '3558', { time_scope: 'future' })
  }
}
