import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'k-input-mails',
  templateUrl: './input-mails.component.html',
})
export class InputMailsComponent {
  @Input() inputId: string;
  @Input() name: string;

  /**
  * Emit all the mails as a string, eache entries separated by comas.
  */
  @Output() value = new EventEmitter<string>();

  localValue: string = '';
  mails: Array<{ isEditable: boolean, isValid: boolean, value: string }> = [];
  hasError: boolean;

  onChange(): void {
    if (this.localValue.indexOf(' ') === -1 && this.localValue.indexOf(',') === -1) {
      this.hasError = false;
      return;
    }
    this.extractMails();
  }

  extractMails(): void {
    if (this.localValue === '') {
      return;
    }
    const mailReg = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/g;
    const splitReg = /\,\ |\ |\,/;
    const inputSplitted = this.localValue.trim().split(splitReg);

    const newMails = inputSplitted
      .map(mail => {
        if (mail.match(mailReg)) {
          return { value: mail.trim(), isEditable: false, isValid: true };
        }
      })
      .filter(e => e);

    if (newMails.length) {
      this.hasError = false;
      this.mails = this.mails.concat(newMails);
      this.localValue = '';
      this.emitNext();
    } else {
      this.hasError = true;
    }
  }

  removeMail($event: Event, i: number): void {
    $event.stopPropagation();
    this.mails.splice(i, 1);
  }

  editMail($event: MouseEvent, i: number): void {
    this.mails[i].isEditable = true;
  }

  editLastMail() {
    if (this.localValue) {
      return;
    }
    this.localValue = this.mails.pop().value;
  }

  lockMail(i: number): void {
    const oneMailReg = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/g;

    if (this.mails[i].value.match(oneMailReg)) {
      this.mails[i].isValid = true;
      this.mails[i].isEditable = false;
      this.emitNext();
    } else {
      this.mails[i].isValid = false;
    }
  }

  emitNext(): void {
    this.value.next(this.mails.map(mail => mail.value).join());
  }
}
