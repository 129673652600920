// Angular imports
import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Interfaces
import { Meet, Participant } from '../../../shared/';
import { AuthRedirectService } from '../../../auth/auth-redirect.service';
import { AuthService } from '../../../auth';
import { TrackingService } from 'src/app/core/tracking.service';

@Component({
  selector: 'primary-button',
  templateUrl: './primary-button.component.html',
})
export class PrimaryButtonComponent implements OnChanges {
  @Input() userAsParticipant: Participant;
  @Input() meet:              Meet;

  @Input() viewType:          string;

  @Input() meetDateTime:      string;

  showDropdownActions:        boolean = false;

  state:                      string;

  constructor(
    private _router:              Router,
    private _route:               ActivatedRoute,
    private _authRedirectService: AuthRedirectService,
    private _authService:         AuthService,
    private _trackingService:     TrackingService,
  ) { }

  ngOnChanges(): void {
    if (this.meet) {
      this.setButtonState();
    }
  }

  setButtonState(): void {
    if (this._authService.isLoggedIn && this.userAsParticipant && this.userAsParticipant.role == 'organizator') {
      this.state = 'manage-meet';
    }
    else if (this._authService.isLoggedIn && this.userAsParticipant && this.userAsParticipant.role == 'participant') {
      this.setStateWhenParticipant();
    }
    else if (this.meet.moderation == 'on_invitation') {
      this.state = 'meet-on-invitation';
    }
    else if (this.meet.moderation == 'closed') {
      this.state = 'no-participations';
    }
    else if (this.meet.status == 'draft') {
      this.state = 'draft-meet';
    }
    else if (this.meet.status == 'refused') {
      this.state = 'refused-meet';
    }
    else if (this.meet.status == 'erased') {
      this.state = 'erased-meet';
    }
    else if (this.meet.datetime && new Date(this.meet.datetime) < new Date()) {
      this.setStateWhenPassed();
    }
    else {
      this.state = 'default';
    }
  }

  setStateWhenParticipant() {
    if (this.userAsParticipant.status == 'active') {
      this.state = 'already-going';
    }
    else if (this.userAsParticipant.status == 'pending') {
      this.state = 'on-waiting-list';
    }
    else if (this.userAsParticipant.status == 'suspended') {
      this.state = 'suspended';
    }
    else if (this.userAsParticipant.status == 'refused') {
      this.state = 'refused';
    }
    else if (this.userAsParticipant.status == 'invited') {
      this.state = 'invited';
    }
  }

  setStateWhenPassed() {
    const now = new Date();
    const mdt = new Date(this.meet.datetime);
    const distance = (mdt.getTime() - now.getTime()) / 1000;
    // 18 000 == 5 hours
    if (distance < 0 && distance > -18000) {
      this.state = 'in-progress';
    }
    else {
      this.state = 'past-meet';
    }
  }

  /**
   * Actions
   */
  onParticipate(): void {
    this._trackingService.trackEvent('Meet Page', 'going', `${this.meet.id}`);
    this._router
      .navigate(['.', { action: 'going' }], { relativeTo: this._route, fragment: 'confirmation' })
      .then(() => {
        if (!this._authService.isLoggedIn) {
          this._authRedirectService.authenticateThenGoBack(
            { warningMessage: 'need-authentication-to-join-meet', tab: 'register' },
            this._route.snapshot.queryParams['_']
          );
        }
      });
  }

  onLeave(): void {
    this._trackingService.trackEvent('Meet Page', 'not_going', `${this.meet.id}`);
    this._router.navigate(['.', { action: 'not_going' }], { relativeTo: this._route, replaceUrl: true });
  }

  onManage(): void {
    const parentUrl = this._route.parent.snapshot.url;
    let queryParams = {};

    if (parentUrl && parentUrl[0] && parentUrl[0].path == 'home') {
      queryParams = { _: parentUrl[1].path };
    }

    this._router.navigate([ '/admin', 'rencontres', this.meet.id], { queryParams });
  }
}
