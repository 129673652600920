
export function attach(prop, value) {
  if (typeof value == 'boolean') {
    return prop + '=' + value + '&';
  }
  if (typeof value == 'string') {
    return prop + '=' + encodeURIComponent(value) + '&';
  }
  else if (typeof value == 'number') {
    return prop + '=' + value.toString() + '&';
  }
  else if (value instanceof Array) {
    let res = '';
    value.forEach(val => res += attach(prop + '[]', val) + '&' );
    return res;
    //return (res == '' ? prop + '[]=&' : res);
  }
  else if (value instanceof Object) {
    let res = '';
    Object.keys(value).forEach(p => res += attach(prop + '[' + p + ']', value[p]));
    return res;
  }
  return '';
}

export function getFormatedDate(val: Date, format: string = 'DD-MM-YYYY') {
  let dd = val.getDate();
  let mm = val.getMonth() + 1; //January is 0!
  let year = val.getFullYear();

  const month = mm < 10 ? '0' + +mm : +mm;
  const day = dd < 10 ? '0' + +dd : +dd;

  if (format == 'DD-MM-YYYY')
    return (day + '-' + month + '-' + year);
  else
    return (year + '-' + month + '-' + day);
}

export function getFormatedTime(datetime: string) {
  return datetime.substr(11, 5);
}

/**
 * humanizeDuration() and humanizeDurations() are functions
 * used into format-details.component, used to format minutes into more
 * readable sentence. For example :
 * 1440 (minutes) => 1 jour
 */
function pluralUnit(number: number, unit: string) {
  return (number > 1) ? unit + 's' : unit;
}

export function humanizeDuration(minutes: number) {
  if (minutes >= 1440) {
    const res = Math.round(minutes / 1440);
    return res + pluralUnit(res, ' jour');
  }
  else if (minutes >= 120) {
    const res = Math.round(minutes / 60);
    return res + pluralUnit(res, ' heure');
  }
  return minutes + pluralUnit(minutes, ' minute');
}

export function humanizeDurations(m_min: number, m_max: number) {
  if (m_max >= 1440) {
    m_max = Math.round(m_max / 1440);
    m_min = Math.round(m_min / 1440);
    if (m_min == m_max)
      m_min--;
    return 'Entre ' + m_min + ' et ' + m_max + pluralUnit(m_max, ' jour');
  }
  else if (m_max >= 120) {
    m_max = Math.round(m_max / 60);
    m_min = Math.round(m_min / 60);
    if (m_min == m_max)
      m_min--;
    return 'Entre ' + m_min + ' et ' + m_max + pluralUnit(m_max, ' heure');
  }
  return 'Entre ' + m_min + ' et ' + m_max + pluralUnit(m_max, ' minute');
}
