import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../core/api-config';
import { Resource } from '../../../shared/definitions';

interface CreateResourceOptions {
  category: string;
  title?: string;
  group_id?: string;
  meet_id?: string;
}

@Injectable()
export class ResourceService {

  constructor(
    private _http: HttpClient,
    private _api: ApiConfig
  ) { }

  getResource(resourceId: number): Observable<Resource> {
    const token = localStorage.getItem('ngStorage-oauthToken');

    let headers: HttpHeaders = this._api.headers();
    headers = headers.set('X-Auth-Key', JSON.parse(token));

    return this._http.get<Resource>(this._api.getPath(`resources/${resourceId}`), { headers });
  }

  createResource(file: Blob, params: CreateResourceOptions): Observable<Resource> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    Object.keys(params).forEach(key => formData.append(key, params[key]));

    return Observable.create(observer => {
      const req: XMLHttpRequest = new XMLHttpRequest();
      req.open('POST', this._api.getPath('resources'), true);
      req.setRequestHeader('X-Api-Key', this._api.apiKey);
      const token = localStorage.getItem('ngStorage-oauthToken');
      req.setRequestHeader('X-Auth-Key', JSON.parse(token));
      req.send(formData);

      req.onload = () => {
        try {
          const res = JSON.parse(req.response);
          if (res.error)
            observer.error('error-create');
          else
            observer.next(res);
        }
        catch (e) {
          observer.error('error-create');
        }
      };

      req.onerror = (err) => observer.error('error-create');
    });
  }

  updateResource(file: Blob, resourceId: number): Observable<Resource> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return Observable.create(observer => {
      const req: XMLHttpRequest = new XMLHttpRequest();
      req.open('PUT', this._api.getPath(`resources/${resourceId}`), true);
      req.setRequestHeader('X-Api-Key', this._api.apiKey);
      const token = localStorage.getItem('ngStorage-oauthToken');
      req.setRequestHeader('X-Auth-Key', JSON.parse(token));
      req.send(formData);

      req.onload = () => {
        try {
          const res = JSON.parse(req.response);
          if (res.error)
            observer.error('error-update');
          else
            observer.next(res);
        }
        catch (e) {
          observer.error('error-update');
        }
      };

      req.onerror = (err) => observer.error('error-update');
    });
  }

  deleteResource(resourceId: number): Observable<{ success: boolean }> {
    const token = localStorage.getItem('ngStorage-oauthToken');

    let headers: HttpHeaders = this._api.headers();
    headers = headers.set('X-Auth-Key', JSON.parse(token));

    return this._http.delete<{ success: boolean }>(this._api.getPath(`resources/${resourceId}`), { headers });
  }
}

//function createFormData(data, params) {
  //let fileData: FormData = new FormData();

  //if (data.slug.substr(0, 4) == 'http') {
  //// file with slug (url)
    //fileData.append('slug', data.slug);
  //}
  //else {
  //// uploaded file
    //fileData.append('file', dataURItoBlob(data));
  //}

  //Object.keys(params).forEach(key => fileData.append(key, params[key]))

  //return fileData;
//}

//function dataURItoBlob(dataURI) {
  //var dataSplit = dataURI.split(',');
  //var binary = atob(dataSplit[1]);
  //var array = [];
  //for (var i = 0; i < binary.length; i++) {
    //array.push(binary.charCodeAt(i));
  //}
  //return new Blob([new Uint8Array(array)], {
    //type: 'image/png'
  //});
//}
