import { Input, Output, EventEmitter,
  Component, OnChanges, OnDestroy,
  SimpleChanges, ViewChild, Renderer2 }  from '@angular/core';
import { IColors, ITranslatedValue, Location,
  Marker, Meet }  from '../shared/';
import { Collection } from '../store/index';

declare var env: any;
declare var L: any;

const SVG_CLUSTER =
    `<?xml version='1.0' encoding='UTF-8'?>
    <svg width='40px' height='40px' viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <title>map-marker-cluster1</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
    <g id='map-marker-cluster1'>
    <g id='Page-1'>
    <g id='512---Play-Store-Copy'>
    <g id='map-marker-cluster1'>
    <g id='MARK4' transform='translate(0.317353, 0.043565)'>
    <circle id='Oval-2' fill='{{ color }}' cx='19.682647' cy='19.956435' r='19.4782175'></circle>
    <circle id='Oval' stroke='#F0F0F0' stroke-width='2' cx='19.682647' cy='19.956435' r='14'></circle>
    </g>
    </g>
    </g>
    </g>
    </g>
    </g>
    </svg>`;

const SVG_MARKER =
    `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="28px" height="32px" viewBox="0 0 28 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 43.2 (39069) - http://www.bohemiancoding.com/sketch -->
    <title>map-marker-full</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="map-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="map-marker-full" fill="{{ color }}">
    <g id="map-marker" transform="translate(1.000000, 0.000000)">
    <path d="M13.0984176,0.16462439 C8.22163493,0.149504458 3.75894517,2.89913021 1.585998,7.25784557 C-0.586949169,11.6165609 -0.09347869,16.8287933 2.85900588,20.7038634 L2.85900588,20.7667707 L2.95352353,20.8689951 C3.35491855,21.3811652 3.79463588,21.8622124 4.26889412,22.308 L6.41917059,24.6670244 L12.5076824,31.1857951 C12.6561406,31.3395657 12.8608634,31.426436 13.0747882,31.426436 C13.288713,31.426436 13.4934359,31.3395657 13.6418941,31.1857951 L19.7304059,24.6670244 L21.8806824,22.308 C22.3468102,21.8613601 22.7786098,21.3803336 23.1724235,20.8689951 L23.2669412,20.7667707 L23.2669412,20.7038634 C26.2116774,16.8389439 26.7108782,11.6428214 24.555677,7.28956472 C22.4004758,2.93630803 17.9624262,0.176412508 13.0984176,0.16462439 Z" id="Combined-Shape"></path>
    </g>
    </g>
    </g>
    </svg>`;

@Component({
  selector: 'meets-map',
  templateUrl: './meets-map.component.html'
})

export class MeetsMapComponent implements OnChanges, OnDestroy {

  @ViewChild('leafletMap', { static: true }) leafletMap;

  map:                      L.Map;
  markersGroup:             L.FeatureGroup;

  @Input() meetsCollection: Collection<Meet>;
  @Input() locations:       Location[] = [];
  @Input() height:          string;
  @Input() mapboxId:        string;
  @Input() colors:          IColors;
  @Input() meetNaming:      ITranslatedValue;

  @Output() selectMeet:     EventEmitter<Meet> = new EventEmitter<Meet>();
  @Output() selectMarker:   EventEmitter<{ lat: number, lng: number }> = new EventEmitter<{ lat: number, lng: number }>();
  @Output() closeMarker:    EventEmitter<boolean> = new EventEmitter<boolean>();

  savedZoom:                number;
  showCarousel:             boolean = false;
  showedMeetIdx:            number = 0;

  svgCluster: string = SVG_CLUSTER;

  svgMarker: string = SVG_MARKER;

  constructor(
    private _renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.locations && changes.locations.currentValue) {
      this.setMarkers(changes.locations.currentValue);
    }
  }

  ngOnDestroy() {
    this.map.remove();
  }

  setMarkers(locations: Location[]) {
    if (!this.map)
      this.initMap();

    const markers: L.Marker[] = this.createMarkers(locations);

    if (this.markersGroup)
      this.map.removeLayer(this.markersGroup);

    this.markersGroup = L.markerClusterGroup({
      iconCreateFunction: (cluster) => {
        const meets_count = cluster.getAllChildMarkers().reduce((mem, m) => mem + m.meets_count, 0);
        return this.createIcon(meets_count, 'cluster');
      }
    });

    const layer = L.featureGroup(markers);
    this.markersGroup.addLayer(layer);
    this.map.addLayer(this.markersGroup);

    if (this.savedZoom && !this.showCarousel) {
      this.map.setZoom(this.savedZoom);
    }
    else if (layer.getBounds().isValid()) {
      this.map.fitBounds(layer.getBounds());
    }
  }

  initMap() {
    const el = this._renderer.createElement('div');
    // assign a random id of the element containing the map so that
    // we can re-use the map
    const leafletMapId = `leafletMap-${Math.floor(Math.random() * 300)}`;
    this._renderer.setAttribute(el, 'id', leafletMapId);
    this._renderer.setStyle(el, 'height', this.height);
    this._renderer.appendChild(this.leafletMap.nativeElement, el);

    this.map = L.map(leafletMapId, {
      scrollWheelZoom: false,
      maxZoom: 15,
      dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile
    });

    L.tileLayer('https://api.mapbox.com/styles/v1/kawaa/{id}/tiles/256/{z}/{x}/{y}?access_token={token}', {
      id: (this.mapboxId ? this.mapboxId : env.provider.mapbox.id),
      token: env.provider.mapbox.secret
    }).addTo(this.map);

    L.tileLayer('', {
      attribution: 'Utilisez deux doigts pour déplacer la carte'
    }).addTo(this.map);

    this.map.setView([48.883, 2.33], 6);
  }

  closeCarousel() {
    this.showCarousel = false;
    this.showedMeetIdx = 0;
    this.closeMarker.emit(true);
  }

  createIcon(count: number, kind: 'marker'|'cluster') {
    const iconUrl: string = kind == 'marker' ? this.svgMarker : this.svgCluster;
    const iconSize: number[] = kind == 'marker' ? [35,45] : (count < 100 ? [45,55] : [65,75]);
    const numClass: string = kind == 'marker' ? 'marker-number' : 'cluster-number';
    const color = this.colors ? this.colors.primary : '#00A0A9';

    const Icon = L.Icon
    .extend({
      options: {
        iconUrl: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconUrl.replace('{{ color }}', color)),
        iconSize: iconSize,

        shadowUrl: '/assets/images/map/map-shadow-full.png',

        shadowSize: [35,35],
        shadowAnchor: [2, 16]
      },
      createIcon: function() {
        const div = document.createElement('div');
        const img = this._createImg(this.options['iconUrl']);
        img.setAttribute ('class', 'marker-img');
        const numDiv = document.createElement('div');
        numDiv.innerHTML = count.toString();
        numDiv.setAttribute ('class', numClass);
        div.appendChild (img);
        div.appendChild (numDiv);
        this._setIconStyles(div, 'icon');
        return div;
      }
    });
    return new Icon;
  }

  createMarkers(locations: Location[]): L.Marker[] {
    const meetNaming: string = this.meetNaming ? this.meetNaming.fr : 'événement';

    return locations
      .map(loc => {
        const marker: any = L.marker([loc.lat, loc.lng], { icon: this.createIcon(loc.meets_count, 'marker') });
        // assign new prop that we'll use to create clusters
        marker.meets_count = loc.meets_count;
        const popoup = L.DomUtil.create('div');

        let innerHTML = `
          <h5>${loc.name}</h5>
          <b>${loc.meets_count}</b> ${meetNaming}${loc.meets_count == 1 ? '' : 's'} </br>
        `;
        if (loc.participants_count > 2)
          innerHTML += `<b>${loc.participants_count}</b> participants </br>`;
        popoup.innerHTML = innerHTML;
        marker.bindPopup(popoup, { closeButton: false });

        marker.addEventListener('click', () => {
          this.savedZoom = this.map.getZoom();
          this.showCarousel = true;
          this.selectMarker.next({ lat: loc.lat, lng: loc.lng });
        });
        marker.addEventListener('mouseover', () => {
          marker.togglePopup();
        });
        marker.addEventListener('mouseout', () => {
          marker.togglePopup();
        });
        return marker;
      });
  }

  emitSelectedMeet(meet: Meet) {
    this.selectMeet.emit(meet);
  }

  swipe(currentIndex: number, isFirst: boolean, isLast: boolean, action = 'right') {
    // out of range
    if (currentIndex < 0) return;

    // swipe right
    if (action === 'right') {
      this.showedMeetIdx = isLast ? 0 : currentIndex + 1;
    }

    // swipe left
    if (action === 'left') {
      this.showedMeetIdx = isFirst ? 0 : currentIndex - 1;
    }
  }

  hideMeets(idx: number) : boolean {
    if(idx != this.showedMeetIdx && idx != this.showedMeetIdx - 1 && idx != this.showedMeetIdx + 1) return true;
    return false;
  }
}
