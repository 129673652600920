<div *ngFor="let query of queries; let i=index" class="m-m-b">

  <div *ngIf="accordion[i]" class="align-center w50 w100-m p-tb-30 p-lr-30 borders-all border-radius">
    <div class="m-s-b">
      <label>{{ 'ADMIN_SHARED.FORM_QUESTIONS.question-type' | translate }}</label>
      <select [(ngModel)]="query.kind">
        <option [selected]="query.kind == 'input'" [value]="'input'">
          {{ 'ADMIN_SHARED.FORM_QUESTIONS.open-question' | translate }}
        </option>
        <option [selected]="query.kind == 'select'" [value]="'select'">
          {{ 'ADMIN_SHARED.FORM_QUESTIONS.list-question' | translate }}
        </option>
        <option [selected]="query.kind == 'multi_choice'" [value]="'multi_choice'">
          {{ 'ADMIN_SHARED.FORM_QUESTIONS.multiple-question' | translate }}
        </option>
      </select>
    </div>

    <div class="m-s-b">
      <label>{{ 'ADMIN_SHARED.FORM_QUESTIONS.question-text' | translate }}</label>
      <input [(ngModel)]="query.question">
    </div>

    <div class="m-s-b" *ngIf="query.kind == 'select' || query.kind == 'multi_choice'">
      <label>{{ 'ADMIN_SHARED.FORM_QUESTIONS.options-list' | translate }}</label>
      <input
        *ngFor="let option of query.option_values; let optIndex = index; trackBy:trackByIdx"
        class="input-questions-tab"
        [id]="'opt' + optIndex"
        type="text"
        [(ngModel)]="query.option_values[optIndex]"
      >
      <div class="m-xs-t pointer" (click)="query.option_values.push('')">
        + {{ 'ADMIN_SHARED.FORM_QUESTIONS.add-answer' | translate }}
      </div>
    </div>

    <div class="m-s-b">
      <input
        #required
        name="required"
        type="checkbox"
        class="m-s-l"
        [id]="'required-q' + i"
        [checked]="query.required"
        (change)="query.required = required.checked"
      >
      <label [for]="'required-q' + i" id="label-required" name="required">
        {{ "ADMIN_SHARED.FORM_QUESTIONS.required-question" | translate }}
      </label>
    </div>

    <div class="text-right">
      <a class="btn-validate button m-s-r" (click)="onSaveQuestion(i)">
        {{ 'ADMIN_SHARED.FORM_QUESTIONS.option-save' | translate }}
      </a>
      <a class="btn-danger outline button" (click)="accordion[i] = !accordion[i]">
        {{ 'ADMIN_SHARED.FORM_QUESTIONS.option-cancel' | translate }}
      </a>
    </div>
  </div>

  <div *ngIf="!accordion[i]" class="row align-center w50 w100-m p-tb-30 p-lr-30 borders-all border-radius">
    <div class="col col-11">
      <p class="m-xs-b">
        <span class="bold-weight-text">
          {{ 'ADMIN_SHARED.FORM_QUESTIONS.question' | translate }} {{ i+1 }} :
        </span>
        {{ query.question }}
      </p>
      <ul class="italic-text" *ngIf="query.kind == 'select' || query.kind == 'multi_choice'">
        {{ 'ADMIN_SHARED.FORM_QUESTIONS.possible-answer' | translate }} :
        <li *ngFor="let answer of query.option_values; let i = index">
          - {{ answer }}
        </li>
      </ul>
      <i *ngIf="query.kind == 'input'">{{ 'ADMIN_SHARED.FORM_QUESTIONS.free-text' | translate }}</i>
    </div>
    <div class="col col-1 text-right">
      <a class="m-xs-r pointer" (click)="accordion[i] = !accordion[i]">
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </a>
      <a class="pointer" (click)="onDeleteQuestion(query)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>

</div>

<div class="text-right">
  <button
    fillColors="background-color"
    class="button"
    (click)="onAddNewQuestion()"
  >
    <i class="fa fa-plus" aria-hidden="true"></i>
    {{ 'ADMIN_SHARED.FORM_QUESTIONS.add-question' | translate }}
  </button>
</div>
