import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  HomeComponent,
  HomepageComponent,
  CoffeeKitchenComponent,
  WorkshopsMeetsComponent,
  SharedOfficeComponent,
  SharedHouseComponent,
  AboutComponent
} from 'src/app/main/home';

const homeRoutes: Routes = [
  {
    component: HomeComponent,
    path: '',
    children: [
      {
        path: '',
        component: HomepageComponent,
        data: { meta: { title: 'Kawaa - On a tant à partager' } }
      },
      {
        path: 'cafe-cuisine',
        component: CoffeeKitchenComponent,
        data: { meta: { title: 'Café & cuisine' } }
      },
      {
        path: 'ateliers-rencontres',
        component: WorkshopsMeetsComponent,
        data: { meta: { title: 'Ateliers & Rencontres' } }
      },
      {
        path: 'bureau-partage',
        component: SharedOfficeComponent,
        data: { meta: { title: 'Bureau partagé' } }
      },
      {
        path: 'maison-partagee',
        component: SharedHouseComponent,
        data: { meta: { title: 'Maison partagée' } }
      },
      {
        path: 'a-propos',
        component: AboutComponent,
        data: { meta: { title: 'À propos' } }
      }
    ]  
  },
];

export const homeRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(homeRoutes);
