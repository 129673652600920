import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Ttab } from './auth-box.component';

/**
 * Please note that error messages, infoMessage and warningMessage MUST be
 * keys stored in i18n JSON file (in `AUTH.MESSAGES`).
 * Feel free to add whatever you need.
 */
export interface IAuthenticateOptions {
  redirectUrl?:      string;
  tab?:              Ttab;
  errorMessage?:    string;
  warningMessage?:  string;
  infoMessage?:     string;
}

@Injectable()
export class AuthRedirectService {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) {}

  /**
   * Do not use this method in a Guard, because it occurs BEFORE the route
   * changed, so the redirection won't work.
   */
  authenticateThenGoBack(opt: IAuthenticateOptions, masterGroupId: number): Promise<boolean> {
    return this.authenticateThenGoTo(
      { ...opt, redirectUrl: this._router.url },
      masterGroupId
    );
  }

  authenticateThenGoTo(opt: IAuthenticateOptions, masterGroupId: number): Promise<boolean> {
    if (!opt.redirectUrl) {
      console.error('Error: opt.redirectUrl is undefined.');
      return;
    }
    return this._router.navigate(
      this.generateAuthUrl(this._router.url, masterGroupId), // _router.url is bad in meet access guard ("/" instead of "/admin/rencontres/{{id}}?_={{id}}")
      { queryParams: opt, replaceUrl: true }
    );
  }

  generateAuthUrl(fromUrl: string, masterGroupId: number): Array<any> {
    const splitedUrl = fromUrl
      ? fromUrl.split('/').filter(e => e)
      : this._router.url.split('/').filter(e => e);

    // For admin group
    if (masterGroupId) {
      return ['home', masterGroupId, 'k-auth'];
    }
    // Group
    else if (splitedUrl[0] == 'home') {
      // second element could be '{{id}}?pk_campaign=platform&pk_kwd=create',
      // by using parseInt() we get only {{id}}
      return ['home', parseInt(splitedUrl[1]), 'k-auth'];
    }
    // Kawaa
    else {
      return ['/fr/k-auth'];
    }
  }

  loginAndParticipateToMeet(): void {
    /**
     * Guillaume's PO Idea.
     * Automatic confirm participation if no form is set.
     * Not enough time for now but hope i've have it in the near future.
     */
  }
}
