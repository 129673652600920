import { Pipe } from '@angular/core';

@Pipe( { name: 'date_std' } ) export class DateStdPipe {
  transform( val ) {
    var res = '';
    var mois = { '01' : 'Jan', '02' : 'Fev', '03' : 'Mars', '04' : 'Avril', '05' : 'Mai', '06' : 'Juin', '07' : 'Juillet',
                 '08' : 'Août', '09' : 'Sept', '10' : 'Oct', '11' : 'Nov', 12 : 'Dec' };
    if( val && val.length > 21 )
      res = val.substring( 8, 10 ) + ' ' + mois[ val.substring( 5, 7 ) ] + ' ' + val.substring( 0, 4 ) + ' - ' + val.substring( 11, 16 );
    return res;
  }
}

@Pipe({ name: 'format_date' }) export class FormatDatePipe {
  transform(val: Date, format: string = 'DD-MM-YYYY') {
    let dd = val.getDate();
    let mm = val.getMonth() + 1; //January is 0!
    let year = val.getFullYear();

    const month = mm < 10 ? '0' + +mm : +mm;
    const day = dd < 10 ? '0' + +dd : +dd;

    if (format == 'DD-MM-YYYY')
      return (day + '-' + month + '-' + year);
    else
      return (year + '-' + month + '-' + day);
  }
}

@Pipe( {name: 'format_time'} ) export class FormatTimePipe {
  transform(val) {
    let hhmm = '';
    let hh = val.getHours();
    let mm = val.getMinutes();
    if (hh < 10)
      hh = '0' + hh;
    if (mm < 10)
      mm = '0' + mm;
    hhmm = hh + ':' + mm;
    return hhmm;
  }
}

@Pipe({ name: 'values' }) export class ValuesPipe {
  transform(value: any): Array<Array<string>> {
    return Object.keys(value).map(key => [key, value[key]]);
  }
}

@Pipe({ name: 'singularize' }) export class SingularizePipe {
  transform(value: string, type: number) {
    switch (type) {
      case 1:
        let words = value.split(' ');
        let result;
        result = words.map(w => w[w.length - 1] == 's' ? w = w.slice(0, w.length -1): w).join(' ');
        return result;
      default:
        // code...
        break;
    }
  }
}

@Pipe({ name: 'pluralize' }) export class PluralizePipe {
  transform(value: string, num: number) {
    let words = value.split(' ');
    if (num == 1) {
      return words.map(w => w[w.length - 1] == 's' ? w = w.slice(0, w.length -1): w).join(' ');
    }
    else {
      return words.map(word => word[word.length - 1] == 's' ? word : this.putEnding(word)).join(' ');
    }
  }

  putEnding(word: string): string {
    if (word.slice(-3) == 'eau')
      return word + 'x';
    else
      return word + 's';
  }
}

@Pipe({ name: 'aToz' }) export class AlphabeticalOrderPipe {
  transform(values: any[], param?: string): any[] {
    if (!values)
      return [];

    let capitalize = ( val => val.charAt(0).toUpperCase() + val.slice(1) );

    return values.sort( (p1, p2) => {
      if (param) {
        p1 = p1[param];
        p2 = p2[param];
      }
      return capitalize(p1)<capitalize(p2)?-1:(capitalize(p1)>capitalize(p2)?1:0);
    });

  }
}
