
import { pluck } from 'rxjs/operators';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ITheme } from '../../shared/index';
import { ViewTypeService, IViewType } from '../../shared/view-type.service';
import { Observable } from 'rxjs';

declare var env: any;

// This component is mostly the same as the component we used in meet-page.
// It depends of it's css. If you need to modify this css, please note that it'll have
// some side effects on the meet-page.
@Component({
  selector: 'group-details-share',
  templateUrl: './group-details-share.component.html',
  host: {
    style: 'position: relative; display: inline-block'
  }
})
export class GroupDetailsShareComponent {
  @Input() viewType: string = 'desktop';

  showIcons: boolean = false; // Used and muted in template, in mobile view.
  theme:     ITheme;
  viewType$: Observable<IViewType>;

  constructor(
    @Inject(DOCUMENT) private _document:  any,
    private _route:     ActivatedRoute,
    private _viewType:  ViewTypeService,
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnInit() {
    this.viewType$ = this._viewType.get();
  }

  shareOnFacebook(): void {
    const actualUrl: string = this._document.location.href;
    const facebookUrl: string = 'https://www.facebook.com/dialog/share';

    const sharingUrl: string = facebookUrl
      + `?app_id=${env.provider.fb.id}`
      + '&display=popup'
      + '&href=' + encodeURI(actualUrl);

    // TODO : add meet hashtags
    window.open(sharingUrl, '', 'height=420,width=670');
  }

  shareOnTwitter(): void {
    const actualUrl = this._document.location.href;
    const twitterUrl = 'https://twitter.com/intent/tweet';
    const ourTwitter = 'kawaa_co';

    const sharingUrl = twitterUrl
      + '?url=' + encodeURI(actualUrl)
      + '&via=' + ourTwitter;

    // TODO : add meet hashtags
    window.open(sharingUrl, '', 'height=420,width=670');
  }

  shareByMail(): void {
    const actualUrl = this._document.location.href;
    const subject = this._document.title;

    const mailUrl = 'mailto:?subject=' + subject + '&body=' + actualUrl;
    window.open(mailUrl);
  }

  copyLinkToClipboard(invisibleInput: HTMLInputElement): void {
    const actualUrl = this._document.location.href;

    invisibleInput.value = actualUrl;
    invisibleInput.select();
    this._document.execCommand('copy');
  }
}
