<div class="input-file" [ngClass]="{ 'error': status.startsWith('error'), 'success': status === 'success' }">
  <div class="input-file-icon m-s-b">
    <div class="circle-icon">

      <i *ngIf="status === 'neutral'" class="zoom-in fa fa-upload" aria-hidden="true"></i>
      <i *ngIf="status.startsWith('error')" class="zoom-in fa fa-exclamation" aria-hidden="true"></i>
      <i *ngIf="status === 'success'" class="zoom-in fa fa-check" aria-hidden="true"></i>

    </div>
  </div>
  <div class="input-file-msg m-xs-b">{{ fileName || 'INPUT-FILE.choose-file' | translate }}</div>

  <div class="input-file-alert italic-text">
    <ng-container [ngSwitch]="status">
      <ng-template ngSwitchCase="neutral">
        {{ 'INPUT-FILE.no-file' | translate }}
      </ng-template>
      <ng-template ngSwitchCase="error-ext">
        {{ 'INPUT-FILE.accept-message' | translate }} <b>{{ accept }}</b>
      </ng-template>
      <ng-template ngSwitchCase="error-size">
        {{ 'INPUT-FILE.file-too-large' | translate }}
      </ng-template>
    </ng-container>
  </div>

  <input [id]="inputId" type="file" (change)="onChange($event)" [accept]="accept">
</div>
