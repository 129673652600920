<div class="footer hide-m">
  <div class="w100 footer-copyright">
    <div class="row align-middle content-width">
      <div class="col col-2 push-left">
        <div class="footer-logo">
          <a href="https://www.kawaa.co" target="_blank">
            <img src="/assets/images/logo/logo-new-kawaa-brown.png" alt="Kawaa - Entreprise agréée sociale et solidaire">
          </a>
        </div>
      </div>
      <div class="col col-2 push-right text-right">
        <a
          *ngIf="!customCguLink"
          class="link-base text-dark-grey"
          [href]="'https://www.kawaa.co/st/' + ('FOOTER.cgu-link' | translate)"
          target="_blank" rel="noopener"
        >
          {{ 'FOOTER.terms-and-conditions' | translate }}
        </a>

        <a
          *ngIf="customCguLink"
          class="link-base text-dark-grey"
          [href]="customCguLink"
        >
          {{ 'FOOTER.terms-and-conditions' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
