import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ProviderGuardService } from './provider-guard.service';
import { IsUserLoggedGuard } from './is-user-logged.guard';
import { AuthAccessGuard } from './auth-access.guard';
import { AuthBoxComponent } from './auth-box.component';
import { AuthBoxTabsComponent } from './auth-box-tabs.component';
import { AuthRedirectService } from './auth-redirect.service';
import { AuthPageComponent } from './auth-page.component';
import { AuthService } from './auth.service';

@NgModule({
  imports:      [
    SharedModule
  ],
  declarations: [
    AuthPageComponent,
    AuthBoxComponent,
    AuthBoxTabsComponent
  ],
  providers:    [
    ProviderGuardService,
    IsUserLoggedGuard,
    AuthAccessGuard,
    AuthRedirectService,
    AuthService
  ],
  exports: [
    AuthPageComponent,
    AuthBoxComponent,
    AuthBoxTabsComponent
  ]
})
export class AuthModule { }
