<div class="hide-m margin-auto align-middle content-width col-7">
  <div class="carousel">
      <div class="carousel-leftview col col-5 h100">
        <h1 class="carousel-leftview-date" [innerHTML]="config.progContents[index].date"></h1>
        <div class="carousel-leftview-slider flex">
          <a href="javascript:void(0)" class="carousel-leftview-slider-previous" (click)="previousSlide()">
            <i class="arrow fa fa-arrow-left" aria-hidden="true"></i>
          </a>
          <div class="carousel-leftview-slider-status flex m-xs-lr p-t-5">
            <div class="circle" [class.filled]='index === 0'></div>
            <div class="circle" [class.filled]='index === 1'></div>
            <div class="circle" [class.filled]='index === 2'></div>
            <div class="circle" [class.filled]='index === 3'></div>
            <div class="circle" [class.filled]='index === 4'></div>
            <div class="circle" [class.filled]='index === 5'></div>
          </div>
          <a href="javascript:void(0)" class="carousel-leftview-slider-next" (click)="nextSlide()">
            <i class="arrow fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="block-appear-l-r">
        <div [class.no-margin]='index === 5' class="carousel-content p-l-20 p-t-20 col col-7 push-right">
          <h3 [class.centered-prog-title]='index === 0' class="text-white p-b-15 text-larger">
            {{ config.progContents[index].progTitle }}
          </h3>
          <a *ngFor="let meet of config.progContents[index].meets" [routerLink]="meet.link">
            <p class="m-xs-b text-white m-s-lr text-base link-opacity">
              {{ meet.meetTitle }}
            </p>
          </a>
        </div>
      </div>
  </div>
</div>


<div class="containerM show-m row col col-10">
  <div>
    <div class="dayContainer flex">
      <div class="dayContainer-content p-tb-15 flex">
        <i (click)="previousSlide()" class="arrow fa fa-arrow-left" aria-hidden="true"></i>
        <p class="date text-base p-lr-15" [innerHTML]="config.progContents[index].date"></p>
        <i (click)="nextSlide()" class="arrow fa fa-arrow-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="eventContainer text-white p-t-10 p-b-15">
    <div>
      <h3 [class.centered-prog-title-mobile]='index === 0' class="p-b-15 p-t-60 m-s-t p-lr-20 text-big text-center">
        {{ config.progContents[index].progTitle }}
      </h3>
      <div class="meets-container p-b-10">
        <a *ngFor="let meet of config.progContents[index].meets" [routerLink]="meet.link">
          <p class="meet text-white p-b-5 p-lr-10">
            {{ meet.meetTitle }}
          </p>
        </a>
      </div>
    </div>
  </div>
</div>
