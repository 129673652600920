import {
  combineLatest as observableCombineLatest,
  of as observableOf,
  Observable,
  BehaviorSubject
} from 'rxjs';

import { distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';
import { Optional, Injectable } from '@angular/core';
import { AppStore, Collection }  from '../store/';
import { Meet, Group, Location, User } from '../shared/';

export interface IMeetsScope {
  lat?:         number;
  lng?:         number;
  range?:       number;
  city?:        string;
  zipCode?:     string;
  timeScope?:   string;
  tags?:        string;
  flags?:       string;
}

export type MeetsScopeEntityTypes = 'GroupMeets'|'Meets'|'AccountMeetsParticipations'|'AccountMeetsOrganizations'|'UserMeetsOrganizations';

@Injectable()
export class MeetsScopeStore {

  private _queryScope$: BehaviorSubject<IMeetsScope> = new BehaviorSubject<IMeetsScope>({});

  constructor(
    @Optional() private _appStore: AppStore
  ) {}

  get scopeParams$(): Observable<IMeetsScope> {
    return this._queryScope$.asObservable();
  }

  get scopeParamsSnapshot(): IMeetsScope {
    return this._queryScope$.getValue();
  }

  setScopeParams(params: IMeetsScope): void {
    this._queryScope$.next(params);
  }

  getMeetsCollection(entityType: MeetsScopeEntityTypes, group?: Group, user?: User): Observable<Collection<Meet>> {
    return this._queryScope$.pipe(
      map((scope: IMeetsScope) => {
        let queries: any = {  };

        if ('lat' in scope && 'lng' in scope && 'range' in scope) {
          queries.lat = scope.lat;
          queries.lng = scope.lng;
          queries.range = scope.range;
        }

        if ('tags' in scope) {
          queries.q = scope.tags;
        }

        if ('zipCode' in scope) {
          queries.zip_code = scope.zipCode;
        }

        if ('flags' in scope) {
          queries.flags = scope.flags;
        }

        queries.time_scope = 'timeScope' in scope ? scope.timeScope : 'future';

        if (entityType == 'GroupMeets' && group) {
          return this._appStore.load('GroupMeets', `${group.id}`, queries);
        }
        else if (entityType == 'UserMeetsOrganizations' && user) {
          return this._appStore.load('UserMeets', `${user.id}`, { ...queries, role: 'organizator', status: 'published' });
        }
        else if (entityType == 'AccountMeetsParticipations') {
          return this._appStore.load('AccountMeets', null, { ...queries, role: 'participant' });
        }
        else if (entityType == 'AccountMeetsOrganizations') {
          return this._appStore.load('AccountMeets', null, { ...queries, role: 'organizator' });
        }
        else {
          return this._appStore.load('Meets', null, queries);
        }
      }));
  }

  getMeetsLocations(entityType: MeetsScopeEntityTypes, group?: Group, user?: User): Observable<Location[]> {
    return this._queryScope$.pipe(
      mergeMap((scope: IMeetsScope) => {
        let queries: any = {  };

        queries.time_scope = 'timeScope' in scope ? scope.timeScope : 'future';

        if ('lat' in scope && 'lng' in scope && 'range' in scope) {
          queries.lat = scope.lat;
          queries.lng = scope.lng;
          queries.range = scope.range;
        }

        if ('tags' in scope) {
          queries.q = scope.tags;
        }

        if ('zipCode' in scope) {
          queries.zip_code = scope.zipCode;
        }

        if ('flags' in scope) {
          queries.flags = scope.flags;
        }

        if (entityType == 'GroupMeets' && group) {
          return this._appStore.load('GroupMeetsLocations', `${group.id}`, queries).entities;
        }
        else if (entityType == 'UserMeetsOrganizations' && user) {
          return this._appStore.load('UserMeetsLocations', `${user.id}`, { ...queries, role: 'organizator', status: 'published' }).entities;
        }
        else if (entityType == 'AccountMeetsParticipations') {
          return this._appStore.load('AccountMeetsLocations', null, { ...queries, role: 'participant' }).entities;
        }
        else if (entityType == 'AccountMeetsOrganizations') {
          return this._appStore.load('AccountMeetsLocations', null, { ...queries, role: 'organizator' }).entities;
        }
        else {
          return this._appStore.load('MeetsLocations', null, queries).entities;
        }
      }),
    distinctUntilChanged((first: Location[], next: Location[]) => (
      first.length == next.length && next.every((el, i) => first[i] && first[i].id == el.id)
    )),);
  }

  getMeetsCount(entityType: MeetsScopeEntityTypes, group?: Group, user?: User): Observable<{ future: number, past: number }> {
    return this._queryScope$.pipe(
      switchMap((scope: IMeetsScope) => {
        let queries: any = {  };

        if ('lat' in scope && 'lng' in scope && 'range' in scope) {
          queries.lat = scope.lat;
          queries.lng = scope.lng;
          queries.range = scope.range;
        }

        if ('tags' in scope) {
          queries.q = scope.tags;
        }

        if ('zipCode' in scope) {
          queries.zip_code = scope.zipCode;
        }

        if ('flags' in scope) {
          queries.flags = scope.flags;
        }

        let future$: Observable<number> = observableOf(null);
        let past$: Observable<number> = observableOf(null);

        if (entityType == 'GroupMeets' && group) {
          future$ = this._appStore.load('GroupMeets', `${group.id}`, { ...queries, time_scope: 'future' }).meta.pipe(map(_ => _.total));
          past$ = this._appStore.load('GroupMeets', `${group.id}`, { ...queries, time_scope: 'past' }).meta.pipe(map(_ => _.total));
        }
        else if (entityType == 'UserMeetsOrganizations') {
          future$ = this._appStore.load('UserMeets', `${user.id}`, { ...queries, time_scope: 'future', role: 'organizator', status: 'published' }).meta.pipe(map(_ => _.total));
          past$ = this._appStore.load('UserMeets', `${user.id}`, { ...queries, time_scope: 'past', role: 'organizator', status: 'published' }).meta.pipe(map(_ => _.total));
        }
        else if (entityType == 'AccountMeetsParticipations') {
          future$ = this._appStore.load('AccountMeets', null, { ...queries, time_scope: 'future', role: 'participant' }).meta.pipe(map(_ => _.total));
          past$ = this._appStore.load('AccountMeets', null, { ...queries, time_scope: 'past', role: 'participant' }).meta.pipe(map(_ => _.total));
        }
        else if (entityType == 'AccountMeetsOrganizations') {
          future$ = this._appStore.load('AccountMeets', null, { ...queries, time_scope: 'future', role: 'organizator' }).meta.pipe(map(_ => _.total));
          past$ = this._appStore.load('AccountMeets', null, { ...queries, time_scope: 'past', role: 'organizator' }).meta.pipe(map(_ => _.total));
        }
        else if (entityType == 'Meets'){
          future$ = this._appStore.load('Meets', null, { ...queries, time_scope: 'future' }).meta.pipe(map(_ => _.total));
          past$ = this._appStore.load('Meets', null, { ...queries, time_scope: 'past' }).meta.pipe(map(_ => _.total));
        }

        return observableCombineLatest(future$, past$).pipe(
          map(([future, past]) => ({ future, past })));
      }));
  }

  onSearchTags(event: string[]) {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot);

    if (event.length > 0) {
      newScope.tags = event.join('^');
    }
    else {
      delete newScope.tags;
    }
    this.setScopeParams(newScope);
  }

  onSearchCity(event: { lat?: number, lng?: number, range?: number, city?: string }) {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot, event);
    if (Object.keys(event).length == 0) {
      delete newScope.city;
      delete newScope.lat;
      delete newScope.lng;
      delete newScope.range;
    }

    this.setScopeParams(newScope);
  }

  onSelectMarker({ lat, lng }) {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot, { lat, lng, range: 0.001 });
    this.setScopeParams(newScope);
  }

  onCloseMarker() {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot);
    delete newScope.lat;
    delete newScope.lng;
    delete newScope.range;
    this.setScopeParams(newScope);
  }

  onChangeTimeScope(timeScope: string) {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot, { timeScope });
    this.setScopeParams(newScope);
  }

  onChangeFlags(flags: string[]) {
    const newScope: IMeetsScope = Object.assign({}, this.scopeParamsSnapshot);

    if (flags.length > 0) {
      newScope.flags = flags.join('^');
    }
    else {
      delete newScope.flags;
    }
    this.setScopeParams(newScope);
  }
}
