
import { pluck } from 'rxjs/operators';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Definitions
import { AnimationFormat, ITheme } from '../../../shared/definitions';

@Component({
  selector: 'snippet-format',
  templateUrl: './snippet-format.component.html'
})
export class SnippetFormatComponent implements OnChanges {
  @Input() format: AnimationFormat;

  /**
   * Duration values, in minutes
   */
  min:   string;
  max:   string;
  fix:   string;
  theme: ITheme;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.format.currentValue
      && change.format.currentValue.modalities
      && change.format.currentValue.modalities.duration
    ) {
      const { min, max, fix } = change.format.currentValue.modalities.duration;
      this.min = this.humanizeMinute(min);
      this.max = this.humanizeMinute(max);
      this.fix = this.humanizeMinute(fix);
    }
  }

  navigateToFormat(format: AnimationFormat) {
    this._router.navigate(['../../format', format.id], { relativeTo: this._route });
  }

  /**
   * Convert minutes into more readable string
   * Ex :
   * - 12 => '12 min'
   * - 59 => '59 min'
   * - 60 => '1h'
   * - 61 => '1h01'
   * - 75 => '1h15'
   * - 90 => '1h45'
   * Etc...
   */
  humanizeMinute(minutes: number): string {
    if (!minutes)
      return undefined;
    else if (minutes < 60)
      return minutes + ' min';
    else {
      if (minutes % 60 == 0)
        return parseInt('' + minutes / 60) + 'h';
      if (minutes % 60 < 10)
        return parseInt('' + minutes / 60) + 'h' + '0' + minutes % 60;
      return parseInt('' + minutes / 60) + 'h' + minutes % 60;
    }
  }
}
