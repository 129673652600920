<div class="row align-middle content-width">
	<div class="w100 custom-content group-content m-x-b m-m-t block-talk">
		<div class="custom-title">
      <h3 class="section-title m-m-b" [style.border-bottom-color]="colors?.primary">
        {{ sectionTitle | customTranslate }}
      </h3>
    </div>
    
    <div class="container m-m-b">
      <div class="main-content">
        <div *ngIf="(collection?.meta | async)?.next" class="text-center m-xs-b">
          <button (click)="loadNext()" class="button outline btn-ghost">
            {{ 'show-more' | translate }} <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </button>
        </div>
        <div class="talk-display flipIn" *ngFor="let comment of comments$ | async; let i = index">
          <div class="talk-sheet" [ngClass]="{ 'bg-lighter-grey': i % 2 == 0 }">
            <div class="profile-pic">
              <img [src]="getProfilePic(comment.member)" [alt]="comment.member?.nickname">
            </div>
            <div class="talk-desc">
              <b>{{ comment.member?.nickname }}</b>
              <span class="role" *ngIf="comment.member && comment.member.role !== 'member'">(Adm.)</span>
              <span class="date-msg">
                - <i>{{ comment.created_at | date:'dd-MM-y' }} à {{ comment.created_at | date:'HH:mm' }}</i>
                </span>
              <p class="p-static" [innerHtml]="comment.content | linky | sanitizeHtml"></p>
            </div>
          </div>
        </div>

        <div class="talk-meet" *ngIf="(user$ | async)?.id" [style.border-color]="colors?.primary">
          <div class="profil-img">
            <img [src]="getProfilePic(user$ | async)" [alt]="(user$ | async)?.nickname">
          </div>
          <textarea autosize #commentInput [placeholder]="'GROUP_PAGE.your-message' | translate"></textarea>
          <button
            [ngStyle]="{
              'color': 'white',
              'background-color': colors?.primary || '##1BA2A8'
            }"
            (click)="postComment(commentInput.value); commentInput.value = ''"
            class="button small"
          >
            {{ 'GROUP_PAGE.send-comment' | translate }}
          </button>
        </div>

        <div class="no-content" *ngIf="!(user$ | async)?.id">
          <a (click)="onAuth()" class="link-base pointer text-success">
            {{ 'GROUP_PAGE.login' | translate }}
          </a>
          {{ 'GROUP_PAGE.login-to-comment' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
