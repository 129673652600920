import { Component, Input, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { TranslateService }  from '@ngx-translate/core';
import { IColors, Meet, Participant, Tag }  from '../shared/definitions';
import { AppStore } from '../store/';
import { ViewTypeService, IViewType } from '../shared/view-type.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'meet-details-box',
  templateUrl: './meet-details-box.component.html',
  host: {
    class: 'widget-wrapper',
  },
})

export class MeetDetailsBoxComponent implements OnInit {

  //@ViewChild('contextMenu', { static: false }) contextMenu;

  @Input() meet:       Meet;
  @Input() colors:     IColors = { primary: '' };

  @Output('selectMeet') selected: EventEmitter<{ meet: Meet, offset: number }> = new EventEmitter<{ meet: Meet, offset: number }>();

  participants:        Participant[];
  organizator:         Participant;
  visibleParticipants: Participant[];

  locality:      string;

  pastMeet:      boolean;

  viewType$: Observable<IViewType> = this._viewType.get();

  constructor(
    private _elementRef:  ElementRef,
    private _appStore:    AppStore,
    private _translate:   TranslateService,
    private _viewType:    ViewTypeService
  ) {}

  ngOnInit() {
    this._appStore
      .load('Participants', this.meet.id.toString(), { by_role: 'DESC'})
      .entities
      .subscribe(
        (res) => {
          this.participants = res.filter(p => p.role !== 'organizator' && p.status !== 'suspended');
          this.organizator = res.find(p => p.role === 'organizator');
          this.visibleParticipants = this.participants.slice(0, 3);
        },
        (error) => console.log(error),
      );

    if (this.meet && this.meet.datetime && new Date(this.meet.datetime) < new Date()) {
      this.pastMeet = true;
    }
    else {
      this.pastMeet = false;
    }

    this.setLocality(this.meet);
  }

  selectMeet(meet) {
    this.selected.emit({ meet: meet, offset: this._elementRef.nativeElement.offsetTop - 80 });
  }

  onKeydown(event: KeyboardEvent, meet: Meet) {
    if (event.code === 'Enter' || event.which === 13) {
      this.selected.emit({ meet: meet, offset: this._elementRef.nativeElement.offsetTop - 80 });
    }
  }

  setLocality(meet: Meet) {
    let locality = '';

    let name = '';
    if (meet.place && meet.place.name) {
      name = meet.place.name;
    }
    else if (meet.location && meet.location.name) {
      name = meet.location.name;
    }

    if (name.length >= 20) {
      name = `${name.substr(0, 20)}...`;
    }

    let city = '';
    if (meet.place && meet.place.location && meet.place.location.city) {
      city = meet.place.location.city;
    }
    else if (meet.location) {
      city = meet.location.city ? meet.location.city : meet.location.address;
    }

    if (city.length > 0) {
      locality = `${name} - ${city}`;
    }
    else {
      locality = name;
    }

    if (locality.length >= 37) {
      locality = `${locality.substr(0, 37)}...`;
    }

    this.locality = locality;
  }

  getParticipantShortName(p: Participant) {
    if (p === undefined) {
      return '';
    }
    const { first_name, last_name } = p.member;
    if (!first_name && !last_name) {
      if (p.role === 'organizator')
        return 'Organisateur';
      return 'Anonyme';
    }

    if (p.role === 'organizator') {
      const status = (this._translate.currentLang == 'en') ? 'organizer' : 'organisateur';
      if (this.meet.options.organizator)
        return this.meet.options.organizator + ' (' + status + ')';
      return `${first_name} ${last_name ? last_name[0] : ''} (${status})`;
    }
    return `${first_name} ${last_name ? last_name[0] : ''}`;
  }
}
