<div>
  <div *ngIf="config?.search_bar" class="col col-12 m-m-tb">
    <searchbar
      [colors]="colors"
      (onNewCity)="onSearchCity($event)"
      (onNewTags)="onSearchTags($event)"
      (onNewPeriodMeets)="onChangeTimePeriod($event)"
      [predefinedTags]="predefinedTags"
      [predefinedTagsWithImage]="predefinedTagsWithImage"
      [config]="config.search_bar"
    ></searchbar>
  </div>

  <div class="w100 z-over-content p-tb-20 flex">
    <div *ngIf="showTimeScope" class="flex align-bottom">
      <a
        href="javascript:void(0)"
        class="italic-text m-xxs-r"
        [style.color]="colors.primary"
        (click)="onChangeTimeScope('future')"
      >
        <span [style.text-decoration]="timeScope == 'future' ? 'underline' : 'none'">
          {{ 'MEETS_BOARD.future' | translate }}
        </span>
        <span class="hide-m">
          ({{ meetsCount?.future ? meetsCount.future : '...' }})
        </span>
      </a>
      <span
        class="m-xxs-lr"
        [style.color]="colors.primary"
      >|</span>
      <a
        href="javascript:void(0)"
        class="italic-text m-xxs-r"
        [style.color]="colors.primary"
        (click)="onChangeTimeScope('past')"
      >
        <span [style.text-decoration]="timeScope == 'past' ? 'underline' : 'none'">
          {{ 'MEETS_BOARD.past' | translate }}
        </span>
         <span class="hide-m">({{ meetsCount?.past ? meetsCount.past : '...' }})</span>
      </a>
    </div>

    <div class="push-right">
     <button
      [ngStyle]="{
        'border-color': viewType == 'list' ? colors.primary : '#8E8E8E',
        'border-radius': '3px 0 0 3px',
        'color': viewType == 'list' ? '#FFF' : '#8E8E8E',
        'background-color': viewType == 'list' ? colors.primary : '#FFF',
        'padding': '8px 10px'
      }"
      (click)="viewType = 'list'"
     >
       <i class="fa fa-list" aria-hidden="true"></i>
       <span class="hide-t">
         {{ 'MEETS_BOARD.list' | translate }}
       </span>
     </button>
     <button
      [ngStyle]="{
        'border-color': viewType == 'map' ? colors.primary : '#8E8E8E',
        'border-radius': '0 3px 3px 0',
        'color': viewType == 'map' ? '#FFF' : '#8E8E8E',
        'background-color': viewType == 'map' ? colors.primary : '#FFF',
        'padding': '8px 10px'
      }"
      (click)="viewType = 'map'"
     >
       <i class="fa fa-location-arrow" aria-hidden="true"></i>
       <span class="hide-t">
         {{ 'MEETS_BOARD.map' | translate }}
       </span>
     </button>
    </div>

  </div>

  <div *ngIf="viewType == 'map'" class="w100 map-view m-m-b">
    <meets-map
      [meetsCollection]="collection"
      [locations]="locations$ | async"
      [colors]="colors"
      [height]="'650px'"
      [mapboxId]="mapboxId"
      [meetNaming]="meetNaming"
      (selectMeet)="onSelectMeet($event)"
      (selectMarker)="onSelectMarker($event)"
      (closeMarker)="onCloseMarker()"
    ></meets-map>
  </div>

  <div *ngIf="viewType == 'list'" class="w100 m-m-b">
    <div class="list-content p-b-30">
      <meets-list
       class="m-m-b"
       [meetsCollection]="collection"
       [colors]="colors"
       [creationBox]="creationBox"
       (selectMeet)="onSelectMeet($event.meet)"
       (create)="onCreateMeet()"
      ></meets-list>
      <div *ngIf="(collection?.meta | async)?.total == 0" class="no-content m-s-b">
        {{ 'MEETS_BOARD.no-matching-result' | translate }}
      </div>
      <a
        *ngIf="(collection?.meta | async)?.next"
        [ngStyle]="{
          'background-color': colors.primary,
          'border-color': colors.primary,
          'color': '#FFF'
        }"
        (click)="onLoadNextMeets()"
        class="see-more-link"
        href="javascript:void(0)"
      >
        {{ 'MEETS_BOARD.show-more' | translate }}
      </a>
    </div>
  </div>
</div>
