import { Component }  from '@angular/core';
import { Router }  from '@angular/router';
import { Meet }  from '../../shared/';

@Component({
  templateUrl: './discover-meets.component.html'
})

export class DiscoverMeetsComponent {

  constructor(
    private _router:          Router
  ) { }

  onSelectMeet(meet: Meet) {
    this._router.navigate(['fr', 'rencontre', meet.id]);
  }

  onCreateMeet() {
    this._router.navigate(['admin', 'rencontres', 'creer']);
  }
}
