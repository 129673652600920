import { ElementRef, Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Area, IArea, IColors } from '../../definitions';
import { GooglePlacesService } from 'src/app/shared/services/google-places.service';

declare var env: any;

@Component({
  selector: 'search-by-city',
  template: `
    <p
      class="text-big pointer p-t-5 bold"
      [style.color]="colors.primary"
      *ngIf="findedCity"
      (click)="onClickCity()"
    >
      {{ cityValue }} <i class="far fa-times"></i>
    </p>
    <input
      [ngStyle]="{
        'display': !findedCity ? 'inline-block' : 'none'
      }"
      type="search"
      id="second-search"
      class="search-input unselected-input border-radius form-control"
      placeholder="Ville"
      [formControl]="city"
      [style.color]="colors.primary"
      [style.border-color]="colors.primary"
     />
  `
})

export class SearchByCityComponent implements OnInit {
  city:                   FormControl = new FormControl();
  area:                   IArea;
  searchPlaces:           any;
  cityValue:              string;
  findedCity:             boolean = false;

  @Input()  colors:        IColors = { primary: '' };
  @Output() searchedCity: EventEmitter<IArea> = new EventEmitter<IArea>();

  constructor(
    private _googlePlacesService: GooglePlacesService,
    private myElement: ElementRef
  ) {}

  ngOnInit() {
    this.autocompleteInit();

    this.city.valueChanges
      .subscribe(data => {
        if (data == '') {
          this.findedCity = false;
          this.searchedCity.emit(this.area = { });
        } else if (this.area && this.area.city != undefined) {
          this.cityValue = this.area.city;
          this.findedCity = true;
        }
      });
  }

  autocompleteInit() {
    this._googlePlacesService.init(this.myElement.nativeElement.querySelector('#second-search'), Area, this._setResult.bind(this));
  }

  private _setResult(area: IArea) {
    this.area = area;
    if (this.area.city !== undefined) {
      this.city.setValue(this.area.city);
    }
    else {
      this.area = { };
    }
    this.searchedCity.emit(this.area);
  }

  onClickCity() {
    this.findedCity = false;
    this.city.setValue('');
  }
}
