<div class="footer ka-footer-agency hide-m">

  <div class="w100 footer-component">
    <div class="row align-top content-width p-t-30">
      <div class="col col-12">
        <div class="row">
          <div class="col col-5 push-left">
            <div class="row align-middle footer-nav-part">
              <div class="col col-6">
                <h3 class="footer-title">{{ 'FOOTER.about-us' | translate }}</h3>
                <nav class="footer-nav">
                  <ul>
                    <li>
                      <a class="link-underline-rollover" [routerLink]="'/st/ils-nous-font-confiance'">{{ 'FOOTER.partners' | translate }}</a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" [routerLink]="'/st/presse'">{{ 'FOOTER.press' | translate }}</a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" href="https://blog.kawaa.co" target="_blank" rel="noopener">
                        {{ 'FOOTER.kawaa-stories' | translate }}
                      </a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" href="javascript:void()" (click)="onClickContact()">
                        {{ 'FOOTER.contact-us' | translate }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="col col-6">
                <h3 class="footer-title">Plateforme</h3>
                <nav class="footer-nav">
                  <ul>
                    <li>
                      <a class="link-underline-rollover" [routerLink]="'/admin/rencontres'">
                        {{ 'FOOTER.your-space' | translate }}
                      </a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" [routerLink]="'/fr/decouvrir/lieux'">
                        {{ 'FOOTER.map-of-places' | translate }}
                      </a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" [routerLink]="'/fr/decouvrir/groupes'">
                        {{ 'FOOTER.find-groups' | translate }}
                      </a>
                    </li>
                    <li>
                      <a class="link-underline-rollover" href="https://aide.kawaa.co" target="_blank" rel="noopener">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div class="col col-6 push-right">
            <div class="m-m-b">
              <ng-container *ngIf="!newsletterRes">
                <h3 class="footer-title text-primary">
                  {{ 'FOOTER.newsletter-subscribe-title' | translate }}
                </h3>
                <form
                  #subscribeForm="ngForm"
                  (ngSubmit)="onSubscribeNewsletter(email.value)"
                  class="k-get-demo-input flex"
                >
                  <input
                    ngModel
                    email
                    #email
                    class="input-small"
                    type="email"
                    name="email"
                    placeholder="Votre email"
                    required
                  />
                  <button
                    type="submit"
                    class="text-big"
                    [class.disabled]="!subscribeForm.form.valid"
                    [disabled]="!subscribeForm.form.valid"
                  >
                    {{ 'FOOTER.newsletter-subscribe-btn' | translate }}
                  </button>
                </form>
              </ng-container>
              <div *ngIf="newsletterRes" class="flex align-center block-appear-t-b p-tb-20">
                <p *ngIf="newsletterRes.result == 'success'" class="p-static">
                  {{ 'FOOTER.newsletter-subscribe-success' | translate }}
                </p>
                <p *ngIf="newsletterRes.result == 'error' && newsletterRes.manageLink" class="p-static">
                  {{ 'FOOTER.newsletter-subscribe-err-existing' | translate }}
                  <a [href]="newsletterRes.manageLink" target="_blank" rel="noopener" class="link-underline">
                    {{ 'FOOTER.newsletter-subscribe-err-existing-link' | translate }}
                  </a>
                </p>
                <p *ngIf="newsletterRes.result == 'error' && !newsletterRes.manageLink" class="p-static">
                  {{ 'FOOTER.newsletter-subscribe-err' | translate }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col col-9">
                <div class="footer-social text-right">
                  <h3 class="footer-title">{{ 'FOOTER.find-us' | translate }}</h3>
                  <nav>
                    <ul>
                      <li>
                        <a href="http://facebook.com/kawaa.co?fref=ts" target="_blank" rel="noopener" title="Retrouvez-nous sur Facebook !" class="facebook-i">
                          <i class="fab fa-facebook-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="http://twitter.com/kawaa_co" target="_blank" rel="noopener" title="Retrouvez-nous sur Twitter !" class="twitter-i">
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li>
                        <a href="http://instagram.com/kawaa_co" target="_blank" rel="noopener" title="Retrouvez-nous sur Instagram !" class="instagram-i">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div class="col col-3">
                <div class="footer-lang text-right">
                  <h3 class="footer-title">{{ 'FOOTER.languages' | translate }}</h3>
                  <nav>
                    <ul>
                      <li>
                        <a
                          href="javascript:void(0)"
                          [ngClass]="currentLang === 'fr' ? 'bold-weight-text' : 'link-underline-rollover'"
                          (click)="changeLang('fr')"
                        >
                          FR
                        </a>
                      </li>
                      <li class="no-padding">-</li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          [ngClass]="currentLang === 'en' ? 'bold-weight-text' : 'link-underline-rollover'"
                          (click)="changeLang('en')"
                        >
                          EN
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w100 footer-copyright">
    <div class="row auto align-bottom content-width">
      <div class="col push-left">
        <div class="footer-logo">
          <a routerLink="/">
            <img src="/assets/images/logo/text-alone.svg" alt="Kawaa - Entreprise agréée sociale et solidaire">
          </a>
          <span class="footer-trademark">{{ 'FOOTER.trademark' | translate }}</span>
        </div>
      </div>

      <div class="col">
        <span class="legal-footer">
          <a class="link-underline-rollover" [routerLink]="'/st/conditions-utilisation'">{{ 'FOOTER.terms-and-conditions' | translate }}</a>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="show-m bg-lighter-grey">
  <div
    style="border-top: 1px solid #ebebeb"
    class="flex align-middle p-tb-10 p-l-20"
  >
    <img class="img-auto" src="/assets/images/logo/text-alone.svg" alt="Kawaa - Entreprise agréée sociale et solidaire">
  </div>
  <ul class="section-bottom">
    <nav class="accordeon-footer show-footer ">
      <ul>
        <li>
          <a routerLink="/st/ils-nous-font-confiance" class="text-dark-grey">
            {{ 'FOOTER.partners' | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/st/presse" class="text-dark-grey">
            {{ 'FOOTER.press' | translate }}
          </a>
        </li>
        <li>
          <a
            href="https://blog.kawaa.co"
            target="_blank"
            rel="noopener"
            class="text-dark-grey"
          >
            {{ 'FOOTER.kawaa-stories' | translate }}
          </a>
        </li>
        <li>
          <a href="https://aide.kawaa.co" target="_blank" rel="noopener" class="text-dark-grey">
            FAQ
          </a>
        </li>
        <li>
          <a routerLink="/st/conditions-utilisation" class="text-dark-grey">
            {{ 'FOOTER.terms-and-conditions' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="onClickContact()" class="flex space-between text-dark-grey">
            <span>{{ 'FOOTER.contact-us' | translate }}</span>
            <i class="fas fa-envelope"></i>
          </a>
        </li>
        <li class="flex">
          <a
            class="w50 text-dark-grey"
            [class.selected]="currentLang == 'fr'"
            (click)="changeLang('fr')"
            href="javascript:void(0)"
          >
            Français
          </a>

          <a
            class="w50 border-l text-dark-grey"
            [class.selected]="currentLang == 'en'"
            (click)="changeLang('en')"
            href="javascript:void(0)"
          >
            English
          </a>
        </li>

        <li class="flex text-center social-mobile">
          <a
            href="http://facebook.com/kawaa.co?fref=ts"
            target="_blank"
            rel="noopener"
            title="Retrouvez-nous sur Facebook !"
            class="w25 text-facebook text-center"
          >
            <i class="fab fa-facebook-square text-large"></i>
          </a>

          <a
            href="http://twitter.com/kawaa_co"
            target="_blank"
            rel="noopener"
            title="Retrouvez-nous sur Twitter !"
            class="w25 text-twitter border-l text-center"
          >
            <i class="fab fa-twitter text-large"></i>
          </a>

          <a
            href="http://instagram.com/kawaa_co"
            target="_blank"
            rel="noopener"
            title="Retrouvez-nous sur Instagram !"
            class="w25 text-instagram border-l text-center"
          >
            <i class="fab fa-instagram text-large"></i>
          </a>
        </li>
        <li class="text-center text-smaller p-tb-20 p-lr-20 text-dark-grey">
          {{ 'FOOTER.trademark' | translate }}
        </li>
      </ul>
    </nav>
  </ul>
</div>
