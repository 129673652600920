
import { throwError as observableThrowError,  Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { ApiConfig } from '../core/';
import { IPageSection } from '../shared/';
import { AppStore } from '../store/app.store';

@Injectable()
export class CustomService {

  private _headers:                 HttpHeaders;
  private _defaultHomeSections:     IPageSection[];

  constructor(
    private _http:      HttpClient,
    private _api:       ApiConfig
  ) {
    this._headers = this._api.headers();
    this._defaultHomeSections = [
      {
        flags: 'basic',
        category: 'group_details'
      },
      {
        flags: 'basic',
        config: { view_type: 'list' },
        category: 'group_meets',
        section_id: 'group-meets'
      },
      {
        flags: 'basic',
        title: { fr: 'Membres', en: 'Members'},
        category: 'group_members'
      },
      {
        flags: 'basic',
        title: { fr: 'Commentaires', en: 'Comments'},
        category: 'group_comments',
        section_id: 'comments'
      },
    ];
  }

  loadPageSections(groupId: string, pageName: string): Observable<IPageSection[]> {
    return this._http.get(
      this._api.getPath(`groups/${groupId}/pages/sections?name=${pageName}`),
      { headers: this._headers }
    ).pipe(
      catchError(res => {
        if (res.status == 500) {
          return observableThrowError(res.statusText);
        }
        else {
          return observableThrowError(res.error.error);
        }
      }),
      map((res: { sections: IPageSection[] }) => {
        const sections: IPageSection[] = res.sections;

        if (sections.length == 0) {
          return this._defaultHomeSections;
        }
        else {
          return sections;
        }
      }),);
  }
}
