import { Component, OnInit } from '@angular/core';
import { TrackingService } from '../tracking.service';

interface ICookiesConsent {
  functional: boolean;
  analytics: boolean;
  timestamp?: number; 
}

@Component({
  selector: 'k-cookies-banner',
  templateUrl: './cookies-banner.component.html'
})

export class CookiesBannerComponent implements OnInit {

  bannerState: 'reduced'|'expanded'|'accepted';

  cookiesConsent: ICookiesConsent = { functional: true, analytics: true }; 

  constructor(
    private _trackingService: TrackingService
  ) {}

  ngOnInit() {
    try {
      const consent = localStorage.getItem('cookiesConsent');
      if (consent) {
        this.cookiesConsent = JSON.parse(consent);
        this.bannerState = 'accepted';
      }
      else {
        this._trackingService.initGiveConsent();
        this.bannerState = 'reduced';
      }
    }
    catch(e) {
      this.bannerState = 'accepted';
    }
  }

  onConsent(consent: ICookiesConsent) {
    const now = new Date();
    this.cookiesConsent = { ...consent, timestamp: now.getTime() };

    this._trackingService.trackConsent(consent.analytics);

    try {
      localStorage.setItem('cookiesConsent', JSON.stringify(this.cookiesConsent));
      this.bannerState = 'accepted';
    }
    catch(e) {
      this.bannerState = 'accepted';
    }
  }
}
