<div *ngIf="groupLinks?.length > 0" class="row align-middle content-width">
	<div class="w100 m-x-b">
		<ng-container *ngIf="sectionTitle">
      <h3 
        class="section-title m-m-tb"
        [ngStyle]="{'border-color': 'rgba(config.color)'}"
      >
        {{ sectionTitle | customTranslate }}
      </h3>
    </ng-container>

    <div *ngIf="!filterByCategories?.hide && groupLinkCategories?.length > 1" class="w100 m-m-tb">
      <ng-container *ngFor="let category of groupLinkCategories">
        <button
          *ngIf="category.symbol && category.title"
          class="button outline m-xs-r m-xs-b"
          style="border-width: 2px"
          [style.border-color]="category.color"
          [style.color]="category.selected ? '#FFFFFF' : category.color"
          [style.background-color]="category.selected ? category.color : '#FFFFFF'"
          (click)="onSelectCategory(category)"
        >
          <span>
            <i [ngClass]="['fa', category.symbol]" aria-hidden="true"></i>
            {{ category.title }}
          </span>
        </button>
      </ng-container>
    </div>
    
    <div class="m-m-b">
      <div class="group-box-container">
        <ng-container *ngFor="let groupLink of groupLinks">
          <div
            class="group-box-card"
            *ngIf="groupLink.show"
            (click)="onSelectGroupLink(groupLink)"
          >
            <div
              class="group-box-banner"
              [lazyLoad]="groupLink?.origin_group?.photos && groupLink.origin_group.photos[0] ? groupLink.origin_group.photos[0].slug : ''"
            >
              <div
                class="group-box-logo"
                [ngStyle]="{
                  'border-color': groupLink?.origin_group?.options.colors?.primary,
                  'background-color': groupLink?.origin_group?.options.colors?.header_background
                }">
                  <img [lazyLoad]="groupLink?.origin_group?.avatar?.slug"/>
              </div>
            </div>

            <div class="group-box-title">
              <span>{{ groupLink?.origin_group?.name }}</span>
            </div>
            <div class="row align-right p-tb-10 p-lr-10" *ngIf="!groupLinkTitles?.hide">
              <div
                class="border-radius p-tb-5 p-lr-5"
                *ngIf="groupLink.title"
                [ngStyle]="{
                  'background-color': (groupLink.color ? groupLink.color : colors.primary),
                  'color': '#FFF'
                }"
              >
                {{ groupLink.title }}
              </div>
            </div>

            <div class="group-box-stats row auto" *ngIf="groupLink?.origin_group?.meets_count > 0 || groupLink?.origin_group?.members_count > 0">
              <div class="col meet-stats" *ngIf="groupLink?.origin_group?.meets_count > 0 && !meetStats?.hide">
                <span class="stat-number">{{ groupLink?.origin_group?.meets_count }}</span>
                <span class="stat-legend">{{ meetStats.naming | customTranslate | pluralize:groupLink?.origin_group?.meets_count }}</span>
              </div>

              <div class="col member-stats" *ngIf="groupLink?.origin_group?.members_count > 1 && !memberStats?.hide">
                <span class="stat-number">{{ groupLink?.origin_group?.members_count }}</span>
                <span class="stat-legend">{{ memberStats.naming | customTranslate }}</span>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
