
import { first } from 'rxjs/operators';
import { Input, Output, Component,
  EventEmitter, OnChanges, SimpleChanges,
  ViewChild } from '@angular/core';
import { IColors, ITranslatedValue, Group,
  Location, Marker, Meet } from '../shared/';
import { GroupsService } from './groups.service';
import { AppStore, Collection } from '../store/';
import { marker } from 'leaflet';
import { Router } from '@angular/router';

declare var env: any;

interface IGroupLinksMapConfig {
  mapbox_id?:         string;
  search_bar?:       {
    by_tag?:            boolean,
    by_city?:           boolean
  };
  meet_creation_box?: {
    text:   ITranslatedValue,
    hide:   boolean;
  };
}

interface IScope {
  lat?:   number;
  lng?:   number;
  range?: number;
  city?:  string;
  q?:     string;
}

interface ILeafletMarker extends L.Marker {
  title: string;
  symbol: string;
  color: string;
}

interface IMarkerLayer {
  title: string;
  symbol: string;
  color: string;
  show: boolean;
  data: L.Marker[];
}

interface IMarkerLayersCategory {
  title: string;
  symbol: string;
  color: string;
  selected: boolean;
}

declare var L: any;

@Component({
  selector: 'group-links-map',
  templateUrl: './group-links-map.component.html'
})

export class GroupLinksMapComponent implements OnChanges {

  @ViewChild('leafletMap', { static: true }) leafletMap;

  map:                      L.Map;
  markersGroup:             L.FeatureGroup;

  markerLayers:             IMarkerLayer[] = []; // { : boolean, data: L.Marker[] >
  markerLayersCategories:   IMarkerLayersCategory[] = [];

  @Input() group:           Group;
  @Input() config:          IGroupLinksMapConfig;
  @Input() flags:           string;
  @Input() colors:          IColors = { primary: '#00A0A9' };
  @Input() fitBounds:       boolean = true;

  @Output() selectMeet:     EventEmitter<Meet> = new EventEmitter<Meet>();
  @Output() createMeet:     EventEmitter<Group> = new EventEmitter<Group>();
  @Output() selectGroupLink:  EventEmitter<Group> = new EventEmitter<Group>();

  originGroup:             Group;
  meetsCollection:          Collection<Meet>;
  showMeets:                boolean;

  creationBox:               { text: ITranslatedValue, hide: boolean } = { text: { fr: 'Proposer un événement' }, hide: false };

  scope:                    IScope = {};

  constructor(
    private _router:      Router,
    private _service:     GroupsService,
    private _appStore:    AppStore
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.map) {
      this.initMap(this.config, this.group);
    }

    if (this.group && this.group.options && this.group.options.colors) {
      this.colors = this.group.options.colors;
    }

    if (this.map) {
      this.setLocations({ flags: this.flags });
    }

    if (this.group && this.group.options && this.group.options.meet_submission == 'closed') {
      this.creationBox.hide = true;
    }
    else if (this.config && this.config.meet_creation_box) {
      this.creationBox = this.config.meet_creation_box;
    }
  }

  onSelectMarker(loc: Location) {
    // move map's focus slightly to the right
    //this.map.setView([loc.lat, loc.lng - 0.035], 14);

    this._appStore.load('Group', `${loc.place_id}`)
      .entity.pipe(
      first())
      .subscribe((place: Group) => {
        this.originGroup = place;
      });
  }

  onClosePlace() {
    this.originGroup = null;
    this.showMeets = false;
    this.meetsCollection = null;
  }

  onOpenSelectedLink() {
    this.selectGroupLink.emit(this.originGroup);
  }

  onShowMeets(place: Group) {
    this.showMeets = true;
    this.meetsCollection = this._appStore.load('GroupMeets', `${place.id}`);
  }

  onCloseMeets() {
    this.showMeets = false;
    this.meetsCollection = null;
  }

  onCreateMeet(place: Group) {
    this.createMeet.next(place);
  }

  setLocations(queries?: any) {
    if (this.group) {
      this._service.getLinksLocations(this.group.id.toString(), { status: 'active', ...queries })
        .subscribe((locations: Location[]) => this.setMarkers(locations));
    }
    else {
      this._service.getGroupsLocations(Object.assign({ place: 1 }, queries))
        .subscribe((locations: Location[]) => this.setMarkers(locations));
    }
  }

  setMarkers(locations: Location[]): void {
    const markers: ILeafletMarker[] = this.createMarkers(locations);
    this.markerLayers = [];
    this.markerLayersCategories = [];

    markers.forEach(marker => {
      const i = this.markerLayers.findIndex(e => e.symbol == marker.symbol);

      // if layer exists already
      if (i != -1) {
        this.markerLayers[i].data.push(marker);
      }
      // create a new layer
      else {
        this.markerLayersCategories.push({ symbol: marker.symbol, title: marker.title, color: marker.color, selected: false });
        this.markerLayers.push({ symbol: marker.symbol, title: marker.title, color: marker.color, show: true, data: [marker] });
      }
    });

    if (this.markersGroup)
      this.map.removeLayer(this.markersGroup);

    this.markersGroup = L.markerClusterGroup({
      iconCreateFunction: (cluster) => {
        const count = cluster.getAllChildMarkers().length;
        return this.createCluster(count);
      }
    });

    this.updateMarkers();
  }

  updateMarkers(): void {
    // Used to see only the markers in layers with propery show = true
    const sortedMarkers = this.markerLayers.reduce((cumul, markerLayer) => {
      if (markerLayer.show)
        cumul.push(...markerLayer.data);
      return cumul;
    }, []);

    this.markersGroup.eachLayer(layer => this.markersGroup.removeLayer(layer));
    this.map.removeLayer(this.markersGroup);
    const layer = L.featureGroup(sortedMarkers);
    this.markersGroup.addLayer(layer);
    this.map.addLayer(this.markersGroup);

    if (this.fitBounds && layer.getBounds().isValid()) {
      this.map.fitBounds(layer.getBounds());
    }
  }

  onSelectCategory(category: IMarkerLayersCategory) {
    category.selected = !category.selected;

    const showAll: boolean = this.markerLayersCategories.every(_ => !_.selected);

    if (showAll) {
      this.markerLayers.forEach((gl: IMarkerLayer) => gl.show = true);
    }
    else {
      this.markerLayers.forEach((gl: IMarkerLayer) => {
        const i: number = this.markerLayersCategories.map(_ => _.symbol).indexOf(gl.symbol);
        gl.show = this.markerLayersCategories[i].selected;
      });
    }

    this.updateMarkers();
  }

  initMap(config: IGroupLinksMapConfig, group?: Group) {
    this.map = L.map('leafletMap', {
      scrollWheelZoom: false,
      maxZoom: 15,
      dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile
    });

    const mapboxId = (config && config.mapbox_id) ? config.mapbox_id : env.provider.mapbox.id;

    L.tileLayer('https://api.mapbox.com/styles/v1/kawaa/{id}/tiles/256/{z}/{x}/{y}?access_token={token}', {
      id: mapboxId,
      token: env.provider.mapbox.secret,
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, Imagery © <a href="http://mapbox.com">Mapbox</a>'
    }).addTo(this.map);

    if (group && group.location) {
      const { lat, lng } = group.location;
      this.map.setView([lat, lng], 6);
    }
    else {
      // center France
      this.map.setView([47.4, 2.33], 6);
    }
  }

  getSearchedTags(event: string[]) {
    if (event && event.length > 0) {
      this.scope.q = event.join('^');
    }
    else if (event.length == 0) {
      delete this.scope.q;
    }

    this.setLocations({ ...this.scope, flags: this.flags });
  }

  getSearchedCity(event) {
    if (event.lat && event.lng && event.city) {
      this.scope.lat = event.lat.toFixed(3);
      this.scope.lng = event.lng.toFixed(3);
      this.scope.range = 5;
      this.scope.city = event.city;
    }
    else {
      delete this.scope.lat;
      delete this.scope.lng;
      delete this.scope.range;
      delete this.scope.city;
    }

    this.setLocations({ ...this.scope, flags: this.flags });
  }

  createMarkers(locations: Location[]): ILeafletMarker[] {

    return locations
      .map(loc => {
        const marker: any = L.marker([loc.lat, loc.lng], { icon: this.createIcon(loc) });
        // assign new prop that we'll use to create clusters
        marker.meets_count = loc.meets_count;
        marker.color = loc.color;
        marker.title = loc.title;

        // used for sorting all markers in different layers, depending of their icons
        marker.symbol = loc.symbol ? loc.symbol : 'fa fa-star';
        const popoup = L.DomUtil.create('div');

        let innerHTML = `<h5>${loc.name}</h5>`;
        popoup.innerHTML = innerHTML;
        marker.bindPopup(popoup, { closeButton: false });

        marker.addEventListener('click', () => {
          this.onSelectMarker(loc);
        });
        marker.addEventListener('mouseover', () => {
          marker.togglePopup();
        });
        marker.addEventListener('mouseout', () => {
          marker.togglePopup();
        });
        return marker;
      });
  }

  createCluster(count: number) {
    const iconUrl: string = `<?xml version='1.0' encoding='UTF-8'?>
      <svg width='40px' height='40px' viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
      <title>map-marker-cluster1</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
      <g id='map-marker-cluster1'>
      <g id='Page-1'>
      <g id='512---Play-Store-Copy'>
      <g id='map-marker-cluster1'>
      <g id='MARK4' transform='translate(0.317353, 0.043565)'>
      <circle id='Oval-2' fill='{{ color }}' cx='19.682647' cy='19.956435' r='19.4782175'></circle>
      <circle id='Oval' stroke='#F0F0F0' stroke-width='2' cx='19.682647' cy='19.956435' r='14'></circle>
      </g>
      </g>
      </g>
      </g>
      </g>
      </g>
      </svg>`;
    const iconSize: number[] = [45,55];
    const numClass: string = 'cluster-number';
    const color: string = this.colors.primary;

    const Icon = L.Icon
    .extend({
      options: {
        iconUrl: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconUrl.replace('{{ color }}', color)),
        iconSize: iconSize,

        shadowUrl: '/assets/images/map/map-shadow-full.png',

        shadowSize: [35,35],
        shadowAnchor: [2, 16]
      },
      createIcon: function() {
        const div = document.createElement('div');
        const img = this._createImg(this.options['iconUrl']);
        img.setAttribute ('class', 'marker-img');
        const numDiv = document.createElement('div');
        numDiv.innerHTML = count.toString();
        numDiv.setAttribute ('class', numClass);
        div.appendChild (img);
        div.appendChild (numDiv);
        this._setIconStyles(div, 'icon');
        return div;
      }
    });
    return new Icon;
  }

  createIcon(location: Location) {
    const iconUrl: string = `<?xml version="1.0" encoding="UTF-8"?>
      <svg width="28px" height="32px" viewBox="0 0 28 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 43.2 (39069) - http://www.bohemiancoding.com/sketch -->
      <title>map-marker-full</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="map-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="map-marker-full" fill="{{ color }}">
      <g id="map-marker" transform="translate(1.000000, 0.000000)">
      <path d="M13.0984176,0.16462439 C8.22163493,0.149504458 3.75894517,2.89913021 1.585998,7.25784557 C-0.586949169,11.6165609 -0.09347869,16.8287933 2.85900588,20.7038634 L2.85900588,20.7667707 L2.95352353,20.8689951 C3.35491855,21.3811652 3.79463588,21.8622124 4.26889412,22.308 L6.41917059,24.6670244 L12.5076824,31.1857951 C12.6561406,31.3395657 12.8608634,31.426436 13.0747882,31.426436 C13.288713,31.426436 13.4934359,31.3395657 13.6418941,31.1857951 L19.7304059,24.6670244 L21.8806824,22.308 C22.3468102,21.8613601 22.7786098,21.3803336 23.1724235,20.8689951 L23.2669412,20.7667707 L23.2669412,20.7038634 C26.2116774,16.8389439 26.7108782,11.6428214 24.555677,7.28956472 C22.4004758,2.93630803 17.9624262,0.176412508 13.0984176,0.16462439 Z" id="Combined-Shape"></path>
      </g>
      </g>
      </g>
      </svg>`;
    const iconSize: number[] = [35,45];
    const numClass: string = 'marker-number';
    const color: string = location.color ? location.color : this.colors.primary;
    const symbol: string = location.symbol ? location.symbol : 'fa fa-star';

    const Icon = L.Icon
      .extend({
        options: {
          iconUrl: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconUrl.replace('{{ color }}', color)),
          iconSize: iconSize,

          shadowUrl: '/assets/images/map/map-shadow-full.png',

          shadowSize: [35,35],
          shadowAnchor: [2, 16]
        },
        createIcon: function() {
          const div = document.createElement('div');
          const img = this._createImg(this.options['iconUrl']);
          img.setAttribute ('class', 'marker-img');
          const numDiv = document.createElement('div');
          numDiv.innerHTML = `<i class="${symbol}" aria-hidden="true"></i>`;
          numDiv.setAttribute ('class', numClass);
          div.appendChild (img);
          div.appendChild (numDiv);
          this._setIconStyles(div, 'icon');
          return div;
        }
      });
    return new Icon;
  }
}
