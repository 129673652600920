<div class="w100 format-list">
  <div class="row align-middle content-width">
    <div class="w100 custom-content">
      <h3
        class="section-title m-m-b"
        [ngStyle]="{'border-color': colors?.primary}"
      >
        {{ 'GROUP_PAGE.animation-formats' | translate }}
      </h3>
    </div>
    <div *ngIf="collection" class="w100 format-list-content">
      <div class="format-list-grid">
        <format-details-box
          *ngFor="let format of collection.entities | async"
          (click)="onSelectFormat(format)"
          [format]="format"
        ></format-details-box>
      </div>
      <div *ngIf="(collection.meta | async)?.next" class="spec-link group-title bottom">
        <a [style.color]="colors.primary" (click)="loadNextFormats()" href="javascript:(void)">Découvrir plus de formats</a>
      </div>
    </div>
  </div>
</div>
