<h3 class="section-title m-m-b" >
  {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.title' | translate }}
</h3>

<form *ngIf="showNewUserForm" class="form m-m-b">
  <div class="row gutters">
    <div class="col col-4">
      <div class="form-item">
        <label for="firstName">
          {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.first-name' | translate }} *
        </label>
        <input
          name="firstName"
          type="text"
          [(ngModel)]="user.first_name"
          required minlength="2"
          #firstName="ngModel"
          [class.error]="firstName.invalid && firstName.dirty && firstName.touched"
          [class.success]="firstName.valid"
        >
        <div
          *ngIf="firstName.invalid && firstName.dirty && firstName.touched"
          class="desc error italic-text"
        >
          {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.err-user-field' | translate }}
        </div>
      </div>
    </div>

    <div class="col col-4">
      <div class="form-item">
        <label for="lastName">
          {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.last-name' | translate }} *
        </label>
        <input
          name="lastName"
          type="text"
          [(ngModel)]="user.last_name"
          required minlength="2"
          #lastName="ngModel"
          [class.error]="lastName.invalid && lastName.dirty && lastName.touched"
          [class.success]="lastName.valid"
        >
        <div
          *ngIf="lastName.invalid && lastName.dirty && lastName.touched"
          class="desc error italic-text"
        >
          {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.err-user-field' | translate }}
        </div>
      </div>
    </div>

    <div class="col col-4">
      <div class="form-item">
        <label for="password">
          {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.password' | translate }} *
        </label>
        <input
          name="password"
          type="password"
          [(ngModel)]="user.password"
          #password="ngModel"
          required minlength="8"
          [class.error]="password.invalid && password.dirty && password.touched"
          [class.success]="password.valid"
        >
        <div class="desc italic-text">
          <span class="text-smaller">{{ 'MEET_PAGE.CONFIRM_PARTICIPATION.password-lenght' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="message" class="message">
  {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.' + message | translate }}
</div>

<form
  novalidate
  (ngSubmit)="onSubmit()"
  #ngForm="ngForm"
  [formGroup]="form"
>

  <ng-container *ngIf="queryForm">
    <blockquote *ngIf="queryForm.message" [innerHTML]="queryForm.message | linky" class="p-tb-10">
    </blockquote>

    <p class="p-static m-s-tb">
      {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.answer-to-questions' | translate }}
    </p>

    <div class="form-item w100">
      <ask-questions-form
        [questions]="queryForm.queries"
        [parentForm]="form"
        [parentFormIsSubmitted]="ngForm.submitted"
      >
      </ask-questions-form>
    </div>
  </ng-container>

  <fieldset *ngIf="meet?.mode === 'hybrid'" class="input-admin">
    <legend>Comment souhaitez-vous participer ? <span class="req">*</span></legend>
    <div class="form-item">
      <!-- We subsctract 1 to max_participants which is the organizer -->
      <input
        type="radio"
        name="location"
        id="mode-onsite"
        formControlName="location"
        value="onsite"
        [disabled]="meet.participants_onsite_count >= meet.options.max_participants - 1"
      />
      <label
        class="radio"
        for="mode-onsite" 
        [class.disabled]="meet.participants_onsite_count >= meet.options.max_participants - 1"
      >
        <span class="text-big text-black m-xxs-l">
          En présentiel
          <ng-container *ngIf="meet.participants_onsite_count >= meet.options.max_participants - 1">
            (complet)
          </ng-container>
        </span>
      </label>
    </div>
    <div class="form-item">
      <input
        type="radio"
        name="location"
        formControlName="location"
        id="mode-online"
        value="online"
        [disabled]="meet.participants_online_count >= meet.options.max_online_participants"
        />
      <label
        class="radio"
        for="mode-online"
        [class.disabled]="meet.participants_online_count >= meet.options.max_online_participants"
      >
        <span class="text-big text-black m-xxs-l">
          En ligne
          <ng-container *ngIf="meet.participants_online_count >= meet.options.max_online_participants">
            (complet)
          </ng-container>
        </span>
      </label>
    </div>
  </fieldset>

  <div *ngIf="errorMsg != ''" class="message error m-xs-tb" (click)="errorMsg = ''">
    {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.' + errorMsg | translate }}
  </div>


  <ng-container *ngIf="meet?.moderation !== 'on_invitation' || action == 'invitation'; else onInvitation">
    <p class="m-x-t p-static text-right">
      {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.confirm-question' | translate }}
    </p>
    <div class="modal-border-top w100 w100-sm text-right">
      <button
        class="btn-white-black small outline m-s-t m-x-r"
        (click)="onClickBack()"
      >
        <i class="fa fa-angle-left m-xs-r" aria-hidden="true"></i>
        <span class="hide-sm">{{ 'MEET_PAGE.back' | translate }}</span>
      </button>
      <button
        type="submit"
        class="button btn-validate cta button-large pulse m-s-t m-s-l"
        [disabled]="isButtonDisabled"
      >
        <span class="hide-sm">{{ 'MEET_PAGE.confirm' | translate }}
          <span *ngIf="isButtonDisabled">...</span>
        </span>
      </button>
    </div>
  </ng-container>

  <ng-template #onInvitation>
    <div *ngIf="fetchingUserAsParticipant" class="message">
        {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.checking-invitation' | translate }}
    </div>
    <div *ngIf="!fetchingUserAsParticipant && !userAsParticipant" class="message warning">
      {{ 'MEET_PAGE.CONFIRM_PARTICIPATION.no-invitation-found' | translate }}
    </div>
    <div *ngIf="!fetchingUserAsParticipant && userAsParticipant">
      <p class="m-x-t p-static text-right">{{ 'MEET_PAGE.CONFIRM_PARTICIPATION.confirm-question' | translate }}</p>
      <div class="modal-border-top w100 w100-sm text-right">
        <button
          class="btn-white-black small outline m-s-t"
          (click)="onClickBack()"
        >
          <span class="hide-sm">{{ 'MEET_PAGE.back' | translate }}</span>
        </button>
        <button
          type="submit"
          class="btn-validate big m-s-t m-s-l"
        >
          <span class="hide-sm">{{ 'MEET_PAGE.confirm' | translate }}</span>
        </button>
      </div>
    </div>
  </ng-template>

</form>
