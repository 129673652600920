 import { Component, OnInit } from '@angular/core';
 import { INotification, NotificationService } from './notification.service';

 @Component({
  templateUrl: './notification.component.html',
  selector: 'k-notification',
})
export class NotificationComponent implements OnInit {
  notification: INotification;

  constructor(
    private _notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this._notificationService.notification$.subscribe(n => this.notification = n);
  }
}
