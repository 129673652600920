<div
  *ngIf="notification"
  class="appear-fast z-over-content"
  style="max-width: 600px; margin: auto; position: fixed; bottom: 0px; left: 50%; transform: translateX(-50%)"
>
  <div
    class="message"
    style="box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.2)"
    [ngClass]="{
      'error': notification.type === 'error',
      'success': notification.type === 'success',
      'warning': notification.type === 'warning'
    }"
    [innerHtml]=" notification.content | translate "
  >
  </div>
</div>
