import { NgModule }                       from '@angular/core';

import { SharedModule }                   from '../shared/shared.module';
import { MeetsModule }                    from '../meets/';
import { FormatsModule }                  from '../formats/';

import { GroupsService }                  from './groups.service';

import { GroupLinksComponent }            from './group-links.component';
import { GroupLinksMapComponent }         from './group-links-map.component';
import { GroupDetailsComponent }          from './group-details/group-details.component';
import { GroupDetailsBlockInfo }          from './group-details/group-details-block-info.component';
import { GroupDetailsShareComponent }     from './group-details/group-details-share.component';
import { GroupFormatsComponent }          from './group-formats.component';
import { GroupBlockPostsComponent }       from './group-block-posts.component';
import { GroupMeetsComponent }            from './group-meets.component';
import { GroupCommentsComponent }         from './group-comments.component';
import { GroupMembersComponent }          from './group-members.component';
import { GroupQueryFormsComponent }       from './group-query-forms.component';
import { GroupQueryFormsReportsComponent } from './group-query-forms-reports.component';
import { GroupPhotosComponent }           from './group-photos.component';
import { GroupMeetsCarouselComponent }    from './group-meets-carousel.component';
import { GroupStatsComponent }            from './group-stats.component';
import { GroupsListComponent }            from './groups-list.component';

@NgModule({
  imports:      [
    SharedModule,
    MeetsModule,
    FormatsModule
  ],
  declarations: [
    GroupDetailsComponent,
    GroupsListComponent,
    GroupDetailsBlockInfo,
    GroupDetailsShareComponent,
    GroupLinksComponent,
    GroupLinksMapComponent,
    GroupFormatsComponent,
    GroupBlockPostsComponent,
    GroupMeetsComponent,
    GroupCommentsComponent,
    GroupMembersComponent,
    GroupQueryFormsComponent,
    GroupQueryFormsReportsComponent,
    GroupPhotosComponent,
    GroupMeetsCarouselComponent,
    GroupStatsComponent
  ],
  exports:      [
    GroupDetailsComponent,
    GroupsListComponent,
    GroupDetailsBlockInfo,
    GroupDetailsShareComponent,
    GroupLinksComponent,
    GroupLinksMapComponent,
    GroupFormatsComponent,
    GroupBlockPostsComponent,
    GroupMeetsComponent,
    GroupCommentsComponent,
    GroupMembersComponent,
    GroupQueryFormsComponent,
    GroupQueryFormsReportsComponent,
    GroupPhotosComponent,
    GroupMeetsCarouselComponent,
    GroupStatsComponent
  ],
  providers:    [
    GroupsService
  ]
})
export class GroupsModule { }
