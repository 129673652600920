import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'fr' },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  //{ path: 'st', loadChildren: () => import('./main/static/static.module').then(m => m.StaticModule) },
  { path: 'widget', loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: '**', component: PageNotFoundComponent }
];

