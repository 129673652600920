<!-- >>> Login box -->
<div
  class="login-box borders-all border-radius border-primary p-tb-20 p-lr-20"
  [style.border-color]="colors.primary"
>
  <!-- >>> Tabs -->
  <auth-box-tabs
    *ngIf="activeTab === 'login' || activeTab === 'register'"
    [activeTab]="activeTab"
    [colors]="colors"
    (activeTabChange)="activeTab = $event; error = ''"
  ></auth-box-tabs>
  <!-- <<< Tabs -->

  <!-- >>> Messages -->
  <div *ngIf="warningMessage && (activeTab === 'login' || activeTab === 'register')" class="message warning">{{ 'AUTH.MESSAGES.' + warningMessage | translate }}</div>
  <div *ngIf="errorMessage" class="message error">{{ 'AUTH.MESSAGES.' + errorMessage | translate }}</div>
  <div *ngIf="infoMessage" class="message">{{ 'AUTH.MESSAGES.' + infoMessage | translate }}</div>
  <!-- <<< Messages -->

  <!-- >>> Login form -->
  <ng-container *ngIf="activeTab === 'login'">
    <form
      #loginForm="ngForm"
      (ngSubmit)="login()"
      class="form"
    >
      <div class="form-item">
        <label>{{ 'AUTH.FORMS.mail' | translate }}</label>
        <input
          name="email"
          type="email"
          [(ngModel)]="user.email"
          (ngModelChange)="loginError = ''"
          #email="ngModel"
          required email
          [class.error]="loginError || (email.invalid && email.dirty && email.touched)"
        />
      </div>

      <div class="form-item">
        <label class="flex align-middle space-between">
            Mot de passe
          <a
            href="javascript:void(0)"
            class="text-right text-dark-grey italic-text text-smaller"
            (click)="showPassword = !showPassword"
          >
            <i class="fa fa-eye" aria-hidden="true"></i>
            <span *ngIf="showPassword">{{ 'AUTH.FORMS.hide-password' | translate }}</span>
            <span *ngIf="!showPassword">{{ 'AUTH.FORMS.show-password' | translate }}</span>
          </a>
        </label>
        <!-- Minlenght is 4 to handle old and unsecure password -->
        <input
          name="password"
          [type]="showPassword ? 'text' : 'password'"
          [(ngModel)]="user.password"
          (ngModelChange)="loginError = ''"
          #password="ngModel"
          required minlength="4"
          [class.error]="loginError || (password.invalid && password.dirty && password.touched)"
        />
      </div>

      <div
        *ngIf="loginError"
        class="message error pulse"
        data-component="message"
      >
        {{ 'AUTH.FORMS.error-login' | translate }}
      </div>

      <div class="m-m-t flex justify-space-between">
        <a
        href="javascript:void(0)"
        class="no-flex text-small underline-text italic-text text-primary m-xs-t"
        (click)="activeTab = 'resetPassword'"
      >
        {{ 'AUTH.FORMS.forgot-password' | translate }}
      </a>
        <button
          type="submit"
          class="button uppercase big w100-m"
          [class.disabled]="!loginForm.form.valid"
          [disabled]="!loginForm.form.valid"
          [style.background-color]="colors.primary"
        >
          {{ 'AUTH.FORMS.btn-login' | translate }}
        </button>
      </div>
    </form>

    <!-- >>> Providers buttons -->
    <button
      *ngIf="!authSchema.facebook_connect === false"
      (click)="loginWithFacebook()"
      class="w100 btn-facebook m-xs-b"
    >
      {{ 'AUTH.FORMS.login-with-facebook' | translate }}
    </button>
    <button
      *ngIf="!authSchema.paris_connect === false"
      (click)="loginWithParis()"
      class="w100 btn-paris m-xs-b"
    >
      {{ 'AUTH.FORMS.login-with-paris' | translate }}
    </button>

    <!-- Harmony SSO is non-available until further notice.
      See 'Probleme de connexion HM/Interconnexion k-space/SSO' 2020-23-11

      <div *ngIf="!authSchema.harmonie_connect === false" class="border-radius borders-all bg-lighter-grey p-tb-15 p-lr-10">
        <p class="text-base">Connexion avec Harmonie Connect :</p>
        <p class="text-small text-dark-grey m-xs-b">Vous êtes adhérent Harmonie Mutuelle ? <br /> Connectez-vous directement avec votre compte mutuelle ou <a href="https://www.harmonie-mutuelle.fr/harmonie-connect" class="underline-text">créez-en un avant</a>.</p>
        <a href="https://www.harmonie-mutuelle.fr/harmonie-connect" style="margin: 0 auto; background: #F59330; border-radius: 30px; overflow: hidden; display: flex; align-items: center; max-width: fit-content;">
          <span style="height: 35px; width: 35px; background: #FFFFFF; margin: 3px; border-top-left-radius: 50%; border-bottom-left-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/194136/HMC.png" alt="logo harmonie mutuelle" style="height: 20px; width: auto;"/>
          </span>
          <span class="text-white text-base p-r-15 p-l-5"><b>Se connecter</b></span>
        </a>
      </div>
    -->

    <!-- >>> Providers buttons -->
    <div
      *ngIf="facebookError"
      class="message error pulse m-s-t"
      data-component="message"
    >
      {{ 'AUTH.FORMS.error-login-with-facebook' | translate }}
    </div>
  </ng-container>
  <!-- <<< Login form -->

  <!-- >>> Register form -->
  <ng-container *ngIf="activeTab === 'register'">
    <form
      #registerForm="ngForm"
      (ngSubmit)="register()"
      class="form"
    >

      <div class="form-item">
        <label>{{ 'AUTH.FORMS.first-name' | translate }}</label>
        <input
          name="firstName"
          type="text"
          [(ngModel)]="user.first_name"
          required minlength="2"
          #firstName="ngModel"
          [class.error]="firstName.invalid && firstName.dirty && firstName.touched"
          [class.success]="firstName.valid"
        >
        <div
          *ngIf="firstName.invalid && firstName.dirty && firstName.touched"
          class="desc error italic-text"
        >
          {{ 'AUTH.FORMS.name-error' | translate }}
        </div>
      </div>

      <div class="form-item">
        <label>{{ 'AUTH.FORMS.last-name' | translate }}</label>
        <input
          name="lastName"
          type="text"
          [(ngModel)]="user.last_name"
          required minlength="2"
          #lastName="ngModel"
          [class.error]="lastName.invalid && lastName.dirty && lastName.touched"
          [class.success]="lastName.valid"
        >
        <div
          *ngIf="lastName.invalid && lastName.dirty && lastName.touched"
          class="desc error italic-text"
        >
          {{ 'AUTH.FORMS.name-error' | translate }}
        </div>
      </div>

      <div class="form-item">
        <label>{{ 'AUTH.FORMS.mail' | translate }}</label>
        <input
          name="email"
          type="email"
          [(ngModel)]="user.email"
          (ngModelChange)="registerError = ''"
          required email
          #email="ngModel"
          [class.error]="registerError || (email.invalid && email.dirty && email.touched)"
          [class.success]="!registerError && email.valid"
        >
        <div
          *ngIf=" registerError || (email.invalid && email.dirty && email.touched)"
          class="desc error italic-text"
        >
          <span *ngIf="(email.invalid && email.dirty && email.touched)">{{ 'AUTH.FORMS.error-mail-invalid' | translate }}</span>
          <span *ngIf="registerError">{{ 'AUTH.FORMS.error-mail-already-used' | translate }}</span>
        </div>
      </div>

      <div class="form-item">
        <label class="flex align-middle space-between">
          {{ 'AUTH.FORMS.password' | translate }}
          <a
            href="javascript:void(0)"
            class="text-right text-dark-grey italic-text text-smaller"
            (click)="showPassword = !showPassword"
          >
            <i class="fa fa-eye" aria-hidden="true"></i>
            <span *ngIf="showPassword">{{ 'AUTH.FORMS.hide-password' | translate }}</span>
            <span *ngIf="!showPassword">{{ 'AUTH.FORMS.show-password' | translate }}</span>
          </a>

        </label>
        <input
          name="password"
          [type]="showPassword ? 'text' : 'password'"
          [(ngModel)]="user.password"
          #password="ngModel"
          required minlength="8"
          [class.error]="registerError || (password.invalid && password.dirty && password.touched)"
          [class.success]="password.valid"
        >
        <div class="desc italic-text">
          <span class="text-smaller">{{ 'AUTH.FORMS.password-help' | translate }}</span>
        </div>
      </div>

      <p
        *ngIf="!rgpdOptIn"
        class="dynamic-content text-small text-center"
        [innerHtml]="'AUTH.FORMS.cgu' | translate"
      ></p>

      <div *ngIf="rgpdOptIn" class="form-item flex m-m-t">
        <span class="p-t-30">
          <input [(ngModel)]="rgpdOptedIn" name="rgpd" type="checkbox" id="rgpd">
          <label for="rgpd" style="padding-top: 0px">
            <p
              class="dynamic-content text-small m-xs-l"
              style="position: relative; top: -25px"
              [innerHtml]="rgpdOptIn | customTranslate"
            ></p>
          </label>
        </span>
      </div>

      <div *ngIf="newsletterOptIn" class="form-item flex m-m-t">
        <span class="p-t-30">
          <input [(ngModel)]="newsletterOptedIn" name="newsletter" type="checkbox" id="newsletter">
          <label for="newsletter" style="padding-top: 5px">
            <p
              class="dynamic-content text-small m-xs-l"
              style="position: relative"
              [innerHtml]="newsletterOptIn | customTranslate"
            >
          </p>
          </label>
        </span>
      </div>

      <div class="text-right m-m-t">
        <button
          class="button uppercase big w100-m"
          [style.background-color]="colors.primary"
          [disabled]="!registerForm.form.valid || !rgpdOptedIn"
        >
          {{ 'AUTH.FORMS.btn-register' | translate }}
        </button>
      </div>
    </form>
    <!-- >>> Providers buttons -->
    <button
      *ngIf="!authSchema.facebook_connect === false"
      (click)="loginWithFacebook()"
      class="w100 btn-facebook m-xs-b"
    >
      {{ 'AUTH.FORMS.register-with-facebook' | translate }}
    </button>
    <button
      *ngIf="!authSchema.paris_connect === false"
      (click)="loginWithParis()"
      class="w100 btn-paris m-xs-b"
    >
      {{ 'AUTH.FORMS.register-with-paris' | translate }}
    </button>
    <!-- Harmony SSO is non-available until further notice.
      See 'Probleme de connexion HM/Interconnexion k-space/SSO' 2020-23-11

      <div *ngIf="!authSchema.harmonie_connect === false" class="border-radius borders-all bg-lighter-grey p-tb-15 p-lr-10">
        <p class="text-base">Inscription avec Harmonie Connect :</p>
        <p class="text-small text-dark-grey m-xs-b">Vous êtes adhérent Harmonie Mutuelle ? <br /> Inscrivez-vous directement avec votre compte mutuelle ou <a href="#" class="underline-text">créez-en un avant</a>.</p>
        <a href="#" style="margin: 0 auto; background: #F59330; border-radius: 30px; overflow: hidden; display: flex; align-items: center; max-width: fit-content;">
          <span style="height: 35px; width: 35px; background: #FFFFFF; margin: 3px; border-top-left-radius: 50%; border-bottom-left-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/194136/HMC.png" alt="logo harmonie mutuelle" style="height: 20px; width: auto;"/>
          </span>
          <span class="text-white text-base p-r-15 p-l-5"><b>S'inscrire</b></span>
        </a>
      </div>
    -->
    <!-- <<< Providers buttons -->
  </ng-container>
  <!-- <<< Register form -->

  <!-- >>> sendMailWithToken form -->
  <ng-container *ngIf="activeTab === 'resetPassword'">
    <div class="m-s-b m-s-t">
      <h1 class="text-center text-larger m-s-b">
        {{ 'AUTH.FORMS.forgot-password' | translate }}
      </h1>
      <p class="text-center italic-text text-base m-s-b">
        {{ 'AUTH.FORMS.forgot-password-instructions' | translate }}
      </p>
      <form
        #sendResetForm="ngForm"
        (ngSubmit)="sendMailWithToken()"
        class="form"
      >
        <div class="form-item">
          <label>{{ 'AUTH.FORMS.mail' | translate }} </label>
          <input
            name="email"
            type="email"
            [(ngModel)]="user.email"
            (ngModelChange)="resetPasswordError = ''"
            #email="ngModel"
            required email
            [class.error]="resetPasswordError || (email.invalid && email.dirty && email.touched)"
          />
        </div>
        <div
          *ngIf="resetPasswordError"
          class="message error pulse"
          data-component="message"
        >
          {{ 'AUTH.FORMS.forgot-password-error' | translate }}
        </div>

        <div class="row auto m-m-t">
          <div class="col">
            <a
              href="javascript:void(0)"
              class="text-small underline-text italic-text text-grey"
              (click)="activeTab = 'login'"
            >
              {{ 'AUTH.FORMS.cancel' | translate }}
            </a>
          </div>
          <div class="col text-right">
            <button
              type="submit"
              class="button uppercase big w100-m"
              [style.background-color]="colors.primary"
              [disabled]="!sendResetForm.form.valid"
            >
              {{ 'AUTH.FORMS.reset-password' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <!-- <<< sendMailWithToken form -->

  <!-- >>> resetPasswordMailSent -->
  <div
    *ngIf="activeTab === 'resetPasswordMailSent'"
    class="zoom-in text-center"
  >
    <div class="m-s-b m-s-t">
      <h1 class="text-larger m-s-b">
        {{ 'AUTH.FORMS.reset-password-mail-sent-title' | translate }}
      </h1>
      <p class="text-base m-s-b">
        {{ 'AUTH.FORMS.reset-password-mail-sent-p1' | translate }}
      </p>
      <p class="text-dark-grey m-s-b">
        <span class="italic-text text-base">
          {{ 'AUTH.FORMS.reset-password-mail-sent-p2' | translate }}
        </span>
        <br>
        <a
          href="javascript:void(0)"
          class="underline-text text-primary"
          (click)="sendMailWithToken()"
        >
          {{ 'AUTH.FORMS.reset-password-mail-sent-send-new-mail' | translate }}
        </a>
        <br>
        {{ 'AUTH.FORMS.at' | translate }}
        <span class="bold-weight-text">{{ user.email }}</span>
      </p>

      <p class="text-dark-grey">
        {{ 'AUTH.FORMS.reset-password-mail-sent-wrong-mail' | translate }}
        <a
          href="javascript:void(0)"
          class="underline-text text-dark-grey"
          (click)="activeTab = 'resetPassword'"
        >
        {{ 'AUTH.FORMS.reset-password-mail-sent-change-mail' | translate }}
        </a>
      </p>
    </div>
  </div>
  <!-- <<< resetPasswordMailSent -->

  <!-- >>> resetPasswordWithToken form -->
  <ng-container *ngIf="activeTab === 'resetPasswordWithToken'">
    <h1 class="text-center text-larger m-s-b">
      {{ 'AUTH.FORMS.change-password' | translate }}
    </h1>
      <form
        #resetForm="ngForm"
        (ngSubmit)="resetPasswordWithToken()"
        class="form"
      >
        <div class="form-item">
          <label class="flex align-middle space-between">
            {{ 'AUTH.FORMS.password' | translate }}
            <a
              href="javascript:void(0)"
              class="text-right text-grey italic-text text-smaller"
              (click)="showPassword = !showPassword"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
              <span *ngIf="showPassword">
                {{ 'AUTH.FORMS.hide-password' | translate }}
              </span>
              <span *ngIf="!showPassword">
                  {{ 'AUTH.FORMS.show-password' | translate }}
              </span>
            </a>
          </label>
          <!-- Minlenght is 4 to handle old and unsecure password -->
          <input
            name="password"
            [type]="showPassword ? 'text' : 'password'"
            [(ngModel)]="user.password"
            (ngModelChange)="resetPasswordError = ''"
            #password="ngModel"
            required minlength="8"
            [class.error]="password.invalid && password.dirty && password.touched"
          />
          <div class="desc italic-text">
            <span class="text-smaller">{{ 'AUTH.FORMS.password-help' | translate }}</span>
          </div>
        </div>
        <div
          *ngIf="password.invalid && password.dirty && password.touched"
          class="message error pulse"
          data-component="message"
        >
          {{ 'AUTH.FORMS.password-error' | translate }}
        </div>
        <div
          *ngIf="resetPasswordError"
          class="message error pulse"
        >
          {{ 'AUTH.FORMS.reset-password-error' | translate }}
          <a
            href="javascript:void(0)"
            (click)="activeTab = 'resetPassword'; resetPasswordError = ''"
            class="underline-text text-primary"
          >
            {{ 'AUTH.FORMS.reset-password-error-link' | translate }}
          </a>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="button uppercase big w100-m"
            [style.background-color]="colors.primary"
            [disabled]="!resetForm.form.valid"
          >
            {{ 'AUTH.FORMS.reset-and-connect' | translate }}
          </button>
        </div>
      </form>
  </ng-container>
  <!-- <<< resetPasswordWithToken form -->
</div>
<!-- <<< Login box -->
