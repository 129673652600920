<!-- Loader -->
<snippet-loader *ngIf="!paiementType && !paiementValue"></snippet-loader>

<!-- Widget -->
<div
  *ngIf="paiementType && paiementValue"
  class="row"
>
  <div class="col col-12 aside-meet-article format-meet">
      <h3 class="section-title" [style.border-bottom-color]="theme?.colors?.primary">
        Tarif
      </h3>
      <div class="aside-meet-content">
        <!-- Conditionnal messages -->
        <p *ngIf="paiementType == 'on-site-payment'" >
          Un <span class="bold">paiement sur place</span> vous sera demandé pour assister à l'événement.
        </p>
        <p *ngIf="paiementType == 'on-site-conso'" >
            Un <span class="bold">paiement sur place</span> vous sera demandé pour assister à l'événement.
          </p>
        <p *ngIf="paiementType == 'paid'" >
          Un <span class="bold">paiement lors de l'inscription</span> vous sera demandé pour assister à l'événement.
        </p>
        <p *ngIf="paiementType == 'open-price'" >
          Une participation vous sera demandée au moment de l'inscription à l'événement.
        </p>
        <!-- TODO : consommation sur place -->

        <div *ngIf="paiementValue" class="stats-module">
          <div class="stats">
            <!-- Conditionnal price -->
            <div *ngIf="paiementValue == 'open-price'" class="stats-number">
              Prix libre
            </div>
            <div *ngIf="paiementValue != 'open-price'" class="stats-number">
              {{ paiementValue }}
            </div>
            <div *ngIf="paiementValue != 'open-price'" class="stats-legend">
              Euros
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
