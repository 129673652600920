import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormatDetailsComponent } from './format-details.component';
import { FormatDetailsBoxComponent } from './format-details-box.component';
import { FormatsListComponent } from './formats-list.component';
import { MeetsModule } from '../meets/';

@NgModule({
  imports:      [
    SharedModule,
    MeetsModule
  ],
  declarations: [
    FormatDetailsComponent,
    FormatDetailsBoxComponent,
    FormatsListComponent
  ],
  exports: [
    FormatDetailsBoxComponent,
    FormatsListComponent
  ]
})
export class FormatsModule { }
