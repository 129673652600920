<div
  class="widget-card"
  tabindex="0"
  (click)="selectMeet(meet)"
  (keydown)="onKeydown($event, meet)"
  [ngStyle]="{
    'filter': pastMeet ? 'grayscale(60%)' : 'grayscale(0%)'
  }"
>

  <!-- >>> Banner, title, date, and participants -->
  <div
    *ngIf="(viewType$ | async) == 'desktop'"
    class="widget-banner"
    [ngStyle]="{
      'background-image': 'url(' + (meet.photos?.length > 0 ? meet.photos[0].slug : '') + ')',
      'background-color': colors.primary,
      'border-bottom-color': colors.primary
    }"
  >
    <div
      class="overlay-white"
      [ngStyle]="{ 'background-color': colors?.overlay_meet_box }"
      *ngIf="colors?.overlay_meet_box && !pastMeet"
    ></div>
    <div class="widget-date">
      <div class="container-date">
        <span class="date-day">
          {{ meet.datetime?.substr(0,10) | date:'d' }}
        </span>
        <span class="date-month">
          {{ meet.datetime?.substr(0,10) | date:'MMM' }}
        </span>
        <span class="date-hour">
          {{ meet.datetime | meetTime }}
        </span>
      </div>
    </div>

    <div
      class="widget-faces pos-a flex push-right"
      *ngIf="(viewType$ | async) === 'desktop'"
    >
      <ng-container *ngTemplateOutlet="participantsFaces"></ng-container>
    </div>
  </div>
  <!-- <<< End Banner, title, date, and participants  -->

  <!-- >>> Title and locality -->
  <div
    [ngClass]="{
      'widget-desc': (viewType$ | async) === 'desktop',
      'widget-desc-mobile w95 push-center p-tb-10': (viewType$ | async) === 'mobile'
    }"
  >
    <div
      [ngClass]="{
        'hide': (viewType$ | async) === 'desktop',
        'text-primary align-middle flex p-t-10 p-b-5 p-l-10': (viewType$ | async) === 'mobile'
      }"
    >
      <i class="fal fa-clock m-xxs-r"></i>
      <span class="date-day m-xxs-r">
        {{ meet.datetime?.substr(0,10) | date:'d' }}
      </span>
      <span class="date-month">
        {{ meet.datetime?.substr(0,10) | date:'MMM' }} à
      </span>
      <span class="m-xxs-l date-hour">
        {{ meet.datetime | meetTime }}
      </span>
    </div>
    <div
      [ngClass]="{
        'widget-desc-title text-left m-xs-lr': (viewType$ | async) === 'desktop',
        'm-xs-lr bold text-big w80': (viewType$ | async) === 'mobile'
      }"
    >
      {{ meet.name }}
    </div>
    <div *ngIf="meet?.label" class="flex m-xs-lr m-xs-tb">
      <div
        [ngStyle]="{ 'backgroundColor': meet.label.color }"
        class="flex border-radius bold p-lr-10 p-tb-5 text-small align-middle text-white"
      >
        <i [class]="meet.label.symbol"></i>
        <p class="m-xxs-l">
          {{ meet?.label?.title }}
        </p>
      </div>
    </div>
    <div
      [ngClass]="{
        'widget-desc-place': (viewType$ | async) === 'desktop',
        'm-xs-lr text-small text-dark-grey p-t-10': (viewType$ | async) === 'mobile'
      }"
    >
      <ng-container *ngIf="meet?.mode === 'onsite'">
        <i class="fas fa-map-marker-alt m-xxs-r"></i>
        {{ locality }}
      </ng-container>
      <ng-container *ngIf="meet?.mode === 'online'">
        <i class="far fa-video m-xxs-r"></i>
        {{ 'MEETS_BOARD.MEET_BOX.online' | translate }}
      </ng-container>
      <ng-container *ngIf="meet?.mode === 'hybrid'">
        <i class="fa fa-calendar m-xxs-r"></i>
        En ligne et en présentiel
      </ng-container>
    </div>
  </div>
  <!-- <<< End Title and locality -->

  <!-- Mobile face paticipant -->
  <div
    class="widget-faces widget-faces-m  pos-a flex"
    *ngIf="(viewType$ | async) === 'mobile'"
  >
    <ng-container *ngTemplateOutlet="participantsFaces"></ng-container>
  </div>

</div>
<ng-template #participantsFaces>
  <!-- Organisator -->
  <div
    *ngIf="organizator"
    class="face face-organizator"
    [style.border-color]="colors.primary"
  >
    <div *ngIf="(viewType$ | async) === 'desktop'">
      <abbr
        [attr.info-tip]="getParticipantShortName(organizator)"
        class="effect"
      ></abbr>
    </div>
    <div class="face-img" [style.border-color]="colors.primary">
      <img
        [alt]="organizator.member?.first_name"
        [lazyLoad]="organizator.member?.avatar?.slug"
        [defaultImage]="'/assets/images/avatar_default.jpg'"
        (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
      />
    </div>
  </div>

  <!-- Participants faces -->
  <div
    *ngFor="let participant of visibleParticipants"
    class="face"
  >
    <div *ngIf="(viewType$ | async) === 'desktop'">
      <abbr
        [attr.info-tip]="getParticipantShortName(participant)"
        class="effect"
      ></abbr>
    </div>
    <div
      class="face-img"
      [style.border-color]="colors.primary"
    >
      <img
        [alt]="participant.member?.first_name"
        [lazyLoad]="participant.member?.avatar?.slug"
        [defaultImage]="'/assets/images/avatar_default.jpg'"
        (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
      />
    </div>
  </div>

  <!-- More participants -->
  <div
    *ngIf="meet.participants_count > 4"
    [style.border-color]="colors.primary"
    class="face face-all"
  >
    <span class="more-faces">
      {{ meet.participants_count }}
      <i class="fa fa-user" aria-hidden="true"></i>
    </span>
  </div>
</ng-template>
