import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { MeetsModule } from '../meets/';
import { GroupsModule } from '../groups/';

import { MainComponent } from './main.component';
import { HomeComponent } from './home/home.component';
import {
  DiscoverComponent,
  DiscoverMeetsComponent,
  DiscoverGroupsComponent,
  DiscoverPlacesComponent
} from './discover/';

import { MainService } from './main.service';
import { mainRouting } from './main.routing';
import { FormatsModule } from '../formats/formats.module';

import { AuthModule } from '../auth';
import { HeadersFootersModule } from '../headers-footers/headers-footers.module';

@NgModule({
  imports:      [
    SharedModule,
    MeetsModule,
    AuthModule,
    GroupsModule,
    FormatsModule,
    HeadersFootersModule,
    mainRouting,
  ],
  declarations: [
    MainComponent,
    HomeComponent,
    DiscoverComponent,
    DiscoverMeetsComponent,
    DiscoverGroupsComponent,
    DiscoverPlacesComponent,
  ],
  providers:    [
    MainService,
  ],
})
export class MainModule { }
