import { Component, Input } from '@angular/core';
import { IAnswerField } from 'src/app/shared/definitions/others';

@Component({
  selector: 'display-answers',
  templateUrl: './display-answers.component.html'
})

export class DisplayAnswersComponent {

  @Input() answers: IAnswerField[];

  typeOf(e: any): 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' | 'array'  | 'file' {
    if (typeof e == 'object' && e instanceof Array) {
      return 'array';
    }

    if (typeof e == 'string' && e.startsWith('file#')) {
      return 'file';
    }

    return typeof e;
  }
}
