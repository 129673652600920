
import { filter, pluck } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppStore } from '../../../../store/index';
import { Comment, Member, Meet, Participant, ITheme } from '../../../../shared/index';

@Component({
  selector: 'meet-comments',
  templateUrl: './meet-comments.component.html',
})
export class MeetCommentsComponent implements OnInit {
  @Input() meet:              Meet;
  @Input() userAsParticipant: Participant;

  comments:       Comment[] = [];
  theme:          ITheme;

  constructor(
    private _appStore: AppStore,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    const meetId = this._route.snapshot.params['id'];

    this._appStore.load('MeetComments', meetId, { meet_id: meetId }, true)
      .entities
      .subscribe(comments => {
        this.comments = comments.sort( (a, b) => new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1 );
      });

    this._route.data.pipe(pluck('theme'),
      filter(_ => _ ? true : false),)
      .subscribe((theme: ITheme) => {
          this.theme = theme;
      });
  }

  getProfilePic(member: Member): string {
    if (member && member.avatar && member.avatar.slug)
      return member.avatar.slug;
    else if (member && member.avatar && member.avatar.file && member.avatar.file.url)
      return member.avatar.file.url;
    else
      return '/assets/images/avatar_default.jpg';
  }

  postComment(content: string) {
    const meetId = this._route.snapshot.params['id'];

    if (content && content.trim() != '')
      this._appStore.create('MeetComments', meetId, { content: content }, { meet_id: meetId });
  }
}
