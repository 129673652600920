
import { throwError as observableThrowError,  Observable } from 'rxjs';

import { share, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Injectable }  from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, }  from '@angular/common/http';
import { ApiConfig }  from '../core/api-config';
import { attach }  from '../shared/helpers/common.funcs';
import { Pool }  from './app.store-pool';
import { Collection }  from './app.store-collection';
import {
  AnimationFormat, Comment, Group,
  GroupLink, GroupLinkTemplate, Location,
  Meta, Meet, Meetship,
  Member, Invitation, Participant,
  Post, Resource, QueryForm,
  User, Tag, MeetTemplate, GroupTemplate
}  from '../shared/definitions';

export type entityTypes = 'AnimationFormat'|
                    'AnimationFormatMeets'|
                    'AnimationFormats'|
                    'Meets'|
                    'Meet'|
                    'Groups'|
                    'Group'|
                    'Members'|
                    'Meetships'|
                    'AccountMeets'|
                    'User'|
                    'UserMeets'|
                    'UserGroups'|
                    'UserTags' |
                    'GroupMeets'|
                    'Participants'|
                    'GroupLink'|
                    'GroupLinks'|
                    'AccountGroups'|
                    'MeetQueryForms'|
                    'QueryForm'|
                    'GroupQueryForms'|
                    'GroupAnimationFormats'|
                    'GroupPosts'|
                    'GroupComments'|
                    'GroupResources'|
                    'MeetsLocations'|
                    'AccountMeetsLocations'|
                    'UserMeetsLocations'|
                    'GroupsLocations'|
                    'GroupMeetsLocations'|
                    'GroupMeetsTemplates'|
                    'GroupMeetsQueryForms'|
                    'GroupLinksLocations'|
                    'GroupLinksComments'|
                    'GroupLinksQueryForms'|
                    'GroupLinkTemplates'|
                    'GroupTemplate'|
                    'MeetComments'|
                    'MeetInvitaions';

@Injectable()
export class AppStore {

  private _collections: Collection<any>[] = [];

  private _poolUsers:             Pool<User> = new Pool<User>();
  private _poolAnimationFormats:  Pool<AnimationFormat> = new Pool<AnimationFormat>();
  private _poolMembers:           Pool<Member> = new Pool<Member>();
  private _poolGroups:            Pool<Group> = new Pool<Group>();
  private _poolMeets:             Pool<Meet> = new Pool<Meet>();
  private _poolParticipants:      Pool<Participant> = new Pool<Participant>();
  private _poolMeetships:         Pool<Meetship> = new Pool<Meetship>();
  private _poolMeetTemplates:     Pool<MeetTemplate> = new Pool<MeetTemplate>();
  private _poolGroupLinks:        Pool<GroupLink> = new Pool<GroupLink>();
  private _poolQueryForms:        Pool<QueryForm> = new Pool<QueryForm>();
  private _poolLocations:         Pool<Location> = new Pool<Location>();
  private _poolComments:          Pool<Comment> = new Pool<Comment>();
  private _poolInvitations:       Pool<Invitation> = new Pool<Invitation>();
  private _poolPosts:             Pool<Post> = new Pool<Post>();
  private _poolResources:         Pool<Resource> = new Pool<Resource>();
  private _poolGroupLinkTemplates:Pool<GroupLinkTemplate> = new Pool<GroupLinkTemplate>();
  private _poolGroupTemplates:    Pool<GroupTemplate> = new Pool<GroupTemplate>();
  private _poolTags:              Pool<Tag> = new Pool<Tag>();

  private _mappings: any = {
    'AnimationFormat':  {
      pool: this._poolAnimationFormats,
      get: (id: string) => `animation_formats/${id}`,
      extra: () => {
        return { isSingle: true };
      },
      isSingle: true
    },
    'AnimationFormatMeets':  {
      pool: this._poolMeets,
      get: (id: string) => `animation_formats/${id}/meets`,
      extra: (id: string) => {
        return { format_id: id };
      }
    },
    'AnimationFormats':  {
      pool: this._poolAnimationFormats,
      get: (id: string) => 'animation_formats'
    },
    'Members':          {
      pool: this._poolMembers,
      get:   (id: string) => `groups/${id}/members`,
      put:   (id: string) => `groups/${id}/member`,
      post:  (id: string) => `groups/${id}/member`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'Groups': {
      pool: this._poolGroups,
      get: (id: string) => 'groups',
      put: (id: string) => `groups/${id}`,
      post: () => 'groups'
    },
    'Group':          {
      pool: this._poolGroups,
      get: (id: string) => `groups/${id}`,
      put: (id: string) => `groups/${id}`,
      post: () => 'groups',
      isSingle: true
    },
    'GroupQueryForms':  {
      pool: this._poolQueryForms,
      get: (id: string) => `groups/${id}/query_forms`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'Meetships':        {
      pool: this._poolMeetships,
      get:   (id: string) =>  `groups/${id}/meetships`,
      put:   (id: string) =>  `groups/${id}/meetships`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'AccountMeets':   {
      pool: this._poolMeets,
      get:   () => 'account/meets',
      put:   (id: string) => `meets/${id}`,
      post:  () => 'meets',
      extra: () => {
        return { onAccount: true };
      }
    },
    'User':   {
      pool: this._poolUsers,
      get: (id: string) => `users/${id}`,
      isSingle: true
    },
    'UserMeets':   {
      pool: this._poolMeets,
      get:   (id: string) => `users/${id}/meets`,
      put:   (id: string) => `meets/${id}`,
      post:  () => 'meets',
      extra: (id: string) => {
        return { user_id: id };
      }
    },
    'UserGroups': {
      pool: this._poolGroups,
      get: (id: string) => `users/${id}/groups`,
      put: (id: string) => `groups/${id}`,
      post: () => 'groups',
      extra: (id: string) => {
        return { user_id: id };
      }
    },
    'UserTags': {
      pool: this._poolTags,
      get: (id: string) => `users/${id}/tags`,
      extra: (id: string) => {
        return { user_id: id };
      }
    },
    'GroupMeets':   {
      pool: this._poolMeets,
      get:   (id: string) => `groups/${id}/meets/around`,
      put:   (id: string) => `meets/${id}`,
      post:  () => 'meets',
      extra: (id: string) => {
        return { invoking_group_id: id };
      }
    },
    'Meet': {
      pool: this._poolMeets,
      get:   (id: string) => `meets/${id}`,
      put:   (id: string) => `meets/${id}`,
      post:  () => 'meets',
      extra: () => {
        return { isSingle: true };
      },
      isSingle: true
    },
    'Meets': {
      pool: this._poolMeets,
      get:  () => 'meets',
      put:  (id: string) => `meets/${id}`,
      post: () => 'meets'
    },
    'Participants':     {
      pool: this._poolParticipants,
      get:    (id: string) => `meets/${id}/participants`,
      put:    (id: string) => `meets/${id}/participant`,
      post:   (id: string) => `meets/${id}/participant`,
      delete: (id: string) => `meets/${id}/participant`,
      extra:  (id: string) => {
        return { meet_id: id};
      }
    },
    'GroupLink': {
      pool: this._poolGroupLinks,
      get:   (id: string) => `groups/${id}/links/with`,
      isSingle: true
    },
    'GroupLinks': {
      pool: this._poolGroupLinks,
      get:   (id: string) => `groups/${id}/links`,
      put:   (id: string) => `groups/${id}/links`,
      post:  (id: string) => `groups/${id}/links`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'GroupAnimationFormats':  {
      pool: this._poolAnimationFormats,
      get:   (id: string) => `groups/${id}/animation_formats`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'GroupPosts':  {
      pool: this._poolPosts,
      get:   (id: string) => `groups/${id}/posts`,
      put:   (id: string) => `groups/${id}/posts`,
      post:  (id: string) => `groups/${id}/posts`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'GroupResources':  {
      pool: this._poolResources,
      get:   (id: string) => `groups/${id}/resources`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'GroupComments':  {
      pool: this._poolComments,
      get:   (id: string) => `groups/${id}/comments`,
      post:  (id: string) => `groups/${id}/comments`,
      delete:  (id: string) => `groups/${id}/comments`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'AccountGroups': {
      pool: this._poolGroups,
      get:  (id: string) => 'account/groups',
      put:  (id: string) => `groups/${id}`,
      post: (id: string) => `groups/${id}`
    },
    'MeetQueryForms':   {
      pool: this._poolQueryForms,
      get:   (id: string) => `meets/${id}/query_forms`,
      put:   (id: string) => `meets/${id}/query_forms`,
      post:  (id: string) => `meets/${id}/query_forms`,
      delete:  (id: string) => `meets/${id}/query_forms`,
      extra: (id: string) => {
        return { meet_id: id};
      }
    },
    'QueryForm':   {
      pool: this._poolQueryForms,
      get:  (id: string) => `query_forms/${id}`,
      put:  (id: string) => `query_forms/${id}`,
      post: () => 'query_forms',
      isSingle: true
    },
    'MeetsLocations': {
      pool: this._poolLocations,
      get: () => 'meets/locations'
    },
    'AccountMeetsLocations': {
      pool: this._poolLocations,
      get: () => 'account/meets/locations'
    },
    'UserMeetsLocations': {
      pool: this._poolLocations,
      get: (id: string) => `users/${id}/meets/locations`
    },
    'GroupsLocations': {
      pool: this._poolLocations,
      get: () => 'groups/locations'
    },
    'GroupMeetsLocations': {
      pool: this._poolLocations,
      get:   (id: string) => `groups/${id}/meets/locations`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'GroupMeetsTemplates': {
      pool: this._poolMeetTemplates,
      get:   (id: string) => `groups/${id}/meet_templates`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'GroupMeetsQueryForms':  {
      pool: this._poolQueryForms,
      get: (id: string) => `groups/${id}/meets/query_forms`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'GroupLinksLocations': {
      pool: this._poolLocations,
      get:   (id: string) => `groups/${id}/links/locations`,
      extra: (id: string) => {
        return { group_id: id};
      },
    },
    'GroupLinksComments': {
      pool: this._poolComments,
      get:   (id: string) => `groups/${id}/links/comments`,
      extra: (id: string) => {
        return { invoking_group_id: id};
      },
    },
    'GroupLinksQueryForms': {
      pool: this._poolQueryForms,
      get:   (id: string) => `groups/${id}/links/query_forms`,
      extra: (id: string) => {
        return { invoking_group_id: id};
      },
    },
    'GroupLinkTemplates': {
      pool: this._poolGroupLinkTemplates,
      get:   (id: string) => `groups/${id}/links/templates`,
      extra: (id: string) => {
        return { group_id: id };
      }
    },
    'GroupTemplate': {
      pool: this._poolGroupTemplates,
      get:   (id: string) => `groups/${id}/template`,
      extra: (id: string) => {
        return { group_id: id };
      },
      isSingle: true
    },
    'MeetComments': {
      pool: this._poolComments,
      get:  (id: string) => `meets/${id}/comments`,
      post: (id: string) => `meets/${id}/comments`,
    },
    'MeetInvitaions': {
      pool: this._poolInvitations,
      get:  (id: string) => `meets/${id}/invitations`,
      post: (id: string) => `meets/${id}/invitations`,
      extra: (id: string) => {
        return { meet_id: id };
      }
    }
  };

  constructor(
    private http:  HttpClient,
    private api:   ApiConfig
  ) { }

  load(kind: entityTypes, id: string|null, properties: Object = {}, collectAll: boolean = false): Collection<any> {
    const path = this._mappings[kind].get(id) +
      Object.keys(properties).reduce((res, key) => res += attach(key, properties[key]), '?');

    if (this._mappings[kind].extra)
      properties = Object.assign({}, properties, this._mappings[kind].extra(id));

    const collection = this._collections.find(_ => _.id == id && this.compareProperties(_.properties, properties) && kind == _.kind);

    if (!collection) {
      const apiRequest = this.createObs(path);
      const newCollection = new Collection<any>(id, kind, properties, this._mappings[kind].pool, apiRequest);

      if (this._mappings[kind].isSingle) {
        newCollection.collectSingle();
      }
      else if (collectAll) {
        newCollection.collectAll();
      }
      else {
        newCollection.collect(19, 0);
      }

      this._collections.push(newCollection);
      return newCollection;
    }

    return collection;
  }

  reload(kind: entityTypes, id: string|null) {
    this._collections
      .filter(_ => _.id == id && kind == _.kind)
      .forEach(collection => {
        if (this._mappings[kind].isSingle) {
          collection.collectSingle();
        }
        else {
          collection.recollect();
        }
      });
  }

  reloadMeta(kind: entityTypes, id: string|null) {
    this._collections
      .filter(_ => _.id == id && kind == _.kind)
      .forEach(collection => {
        collection.recollectMeta();
      });
  }

  create(kind: entityTypes, id: string, toCreate: Object, properties: any = {}): Observable<any> {
    const path = this._mappings[kind].post(id);

    const obs = this.http.post<any>(this.api.getPath(path), toCreate, { headers: this.headers }).pipe(
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error)),
      publishReplay(1),
      refCount(),);

    obs.subscribe(
      element => {
        let toAdd: any = Object.assign({}, element);
        toAdd['properties'] = properties;
        if (this._mappings[kind].extra) {
          Object.assign(toAdd.properties, this._mappings[kind].extra(id));
        }
        this._mappings[kind].pool.add(toAdd);
      },
      error => console.log(error)
    );

    return obs;
  }

  update(kind: entityTypes, id: string, toUpdate: Object): Observable<any> {
    const path = this._mappings[kind].put(id);

    const obs = this.http.put<any>(this.api.getPath(path), toUpdate, { headers: this.headers }).pipe(
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error)),
      publishReplay(1),
      refCount(),);

    obs.subscribe(
      element => this._mappings[kind].pool.modify(element),
      error => console.log(error)
    );

    return obs;
  }

  delete(kind: entityTypes, id: string, toUpdate: Object = {}) {
    const path = this._mappings[kind]['delete'](id) + '?';

    const body = Object.keys(toUpdate).reduce((res, key) => res += attach(key, toUpdate[key]), '');

    const obs = this.http.delete(this.api.getPath(path + body), { headers: this.headers }).pipe(
      catchError((err: HttpErrorResponse) => observableThrowError(err.error.error)),
      publishReplay(1),
      refCount(),);

    obs.subscribe(
      res => {
        const [success, entity] = Object.keys(res);
        if (success)
          this._mappings[kind].pool.remove(res[entity]);
      },
      error => console.log(error)
    );

    return obs;
  }

  private compareProperties(extra1, extra2): boolean {
    const keys1 = Object.keys(extra1);
    const keys2 = Object.keys(extra2);

    if (keys1.length != keys2.length)
      return false;

    return keys1.every(key1 => keys2.find(key2 => key1 == key2) ? true : false) &&
      keys1.every(key1 => extra1[key1] == extra2[key1]);
  }

  get headers() {
    const headers: HttpHeaders = this.api.headers();

    try {
      const token = localStorage.getItem('ngStorage-oauthToken');

      if (token)
        return headers.append('X-Auth-Key', JSON.parse(token));
      else
        return headers;
    }
    catch(e) {
      return headers;
    }
  }

  private createObs(path: string) {
    return (meta) => this.http.get(this.api.getPath(path + meta), { headers: this.headers }).pipe(share());
  }
}
