import { ElementRef, Directive, HostListener, Input, Renderer2 } from '@angular/core';
//import {
  //DOCUMENT
//}  from '@angular/platform-browser';


@Directive({
  selector: '[animation-scroll]'
})

export class AnimationScrollDirective {

  @Input('animation-scroll') classToAdd:  string;

  constructor(
    //@Inject(DOCUMENT) private _document:  any,
    private _renderer:            Renderer2,
    private _el:                  ElementRef
  ) { }

  ngOnInit() {
    this.addClass();
  }

  @HostListener('window:scroll', ['$event']) onScroll(e) {
    this.addClass();
  }

  addClass() {
    const topPosition: number = this._el.nativeElement.getBoundingClientRect().top;
    const windowHeight: number = window.innerHeight;
    const classList: DOMTokenList = this._el.nativeElement.classList;

    if (windowHeight > topPosition && !classList.contains(this.classToAdd)) {
      this._renderer.addClass(this._el.nativeElement, this.classToAdd);
    }
  }
}
