<div class="content-width">
  <meets-board
    [config]="{
      view_type: 'list',
      search_bar: { by_tag: true, by_city: true, by_date: true }
    }"
    [entityType]="'Meets'"
    (selectMeet)="onSelectMeet($event)"
    (createMeet)="onCreateMeet()"
  >
  </meets-board>
</div>
