<nav class="home-header">
  <div class="burger-menu text-light-yellow flex-column">
    <div class="burger-block" [class.active]="active">
      <div class="burger" (click)="onBurgerClicked()" [class.active]="active">
        <div class="burger-box" [class.active]="active">
          <div class="burger-inner" [class.active]="active"></div>
        </div>
      </div>
    </div>
    <div [class.active]="active" class="burger-list">
      <div class="navigation-menu" [class.active]="active" >
        <div class="main-title">
          <a class="text-light-yellow" routerLink="/" (click)="navigateOutsideIn('/')">
            <img class="text-light-yellow" src="assets/images/logo/kawaa-navigation.svg#kawaa-navigation" />
          </a>
        </div>
        <ul class="navigation-links" [class.active]="active">
          <li class="navigation-link navlink-dropdown text-light-yellow">
            <div>
              <div class="navlink-title">
                lieux
              </div>
              <ul class="navlink-dropdown-menu" [class.active]="active">
                <li class="bg-light-yellow">
                  <a class="p-15 text-brown display-block" href="https://paris.kawaa.co">Paris Daumesnil</a>
                </li>
                <li class="bg-light-yellow">
                  <a class="p-15 text-brown display-block" href="https://lumiere.kawaa.co/">Paris Lumière</a>
                </li>
                <li class="bg-light-yellow">
                  <a class="p-15 text-brown display-block" href="https://lille.kawaa.co">Lille Gambetta</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="text-yellow">
            <a
              routerLink="/cafe-cuisine"
              class="navigation-link"
              routerLinkActive="link-underline-noanim"
              #coffeeKitchen="routerLinkActive"
              [class.link-underline-noanim-inactive]="!coffeeKitchen.isActive"
              (click)="navigateOutsideIn('/cafe-cuisine')"
            >
              café
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              cuisine
            </a>
          </li>
          <li class="text-nav-green">
            <a
              routerLink="/ateliers-rencontres"
              class="navigation-link"
              routerLinkActive="link-underline-noanim"
              #workshopsMeets="routerLinkActive"
              [class.link-underline-noanim-inactive]="!workshopsMeets.isActive"
              (click)="navigateOutsideIn('/ateliers-rencontres')"
            >
              ateliers
              <svg class="icon" fill="currentColor">
                <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
              </svg>
              rencontres
            </a>
          </li>
          <li class="text-orange">
            <a
              routerLink="/bureau-partage"
              class="navigation-link"
              routerLinkActive="link-underline-noanim"
              #sharedOffice="routerLinkActive"
              [class.link-underline-noanim-inactive]="!sharedOffice.isActive"
              (click)="navigateOutsideIn('/bureau-partage')"
            >
              bureau partagé
            </a>
          </li>
          <li class="text-light-purple">
            <a
              routerLink="/maison-partagee"
              class="navigation-link"
              routerLinkActive="link-underline-noanim"
              #sharedHouse="routerLinkActive"
              [class.link-underline-noanim-inactive]="!sharedHouse.isActive"
              (click)="navigateOutsideIn('/maison-partagee')"
            >
              maison partagée
            </a>
          </li>
          <li class="text-light-yellow">
            <a
              routerLink="/a-propos"
              class="navigation-link"
              routerLinkActive="link-underline-noanim"
              #about="routerLinkActive"
              [class.link-underline-noanim-inactive]="!about.isActive"
              (click)="navigateOutsideIn('/a-propos')"
            >
              à propos
            </a>
          </li>
        </ul>
        <div
          class="login-link m-m-l authentication-button bg-light-yellow text-brown border-radius"
          *ngIf="!(isLoggedIn$ | async)"
          (click)="onAuth('login')"
        >
          {{ 'HEADER.login' | translate }}
        </div>
        <header-menu
            *ngIf="(isLoggedIn$ | async)"
            [group]="group"
            [home]="true"
            class="m-m-l home-user-avatar"
          >
        </header-menu>
      </div>
    </div>
  </div>
</nav>
