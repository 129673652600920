<!-- TODO: On hover, border has primary color -->
<div class="row auto gutters m-s-b text-center">
  <a
    (click)="changeActiveTab('login')"
    [ngClass]="{ 'bg-primary border-primary': activeTab === 'login' }"
    [style.border-color]="activeTab === 'login' ? colors.primary: ''"
    [style.background-color]="activeTab === 'login' ? colors.primary: ''"
    class="col borders-all border-radius p-tb-20 login-tab"
    href="javascript:void(0)"
  >
    <div
      *ngIf="(viewType$ | async) === 'desktop'"
      class="svgrhubarbe"
    >
      <svg width="75px" height="75px" viewBox="0 0 75 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Formv1" transform="translate(-75.000000, -44.000000)" [attr.fill]="activeTab === 'login' ? 'white' : '#c5c5c5'" fill-rule="nonzero">
            <g id="Group-9">
              <g id="Group-7" transform="translate(21.000000, 24.000000)">
                <g id="Face-icon" transform="translate(54.000000, 20.000000)">
                  <path d="M37.5,0 C16.7682927,0 0,16.7682927 0,37.5 C0,58.2317073 16.7682927,75 37.5,75 C58.2317073,75 75,58.2317073 75,37.5 C75,16.7682927 58.2317073,0 37.5,0 Z M13.1859756,63.4908537 C13.4146341,55.3353659 19.9695122,48.1707317 29.7256098,45.5792683 C32.0121951,46.875 34.679878,47.5609756 37.5,47.5609756 C40.320122,47.5609756 42.9878049,46.7987805 45.2743902,45.5792683 C55.0304878,48.1707317 61.5853659,55.3353659 61.8140244,63.4908537 C55.4878049,69.4359756 46.875,73.1707317 37.5,73.1707317 C28.125,73.1707317 19.5121951,69.4359756 13.1859756,63.4908537 Z M37.5,45.7317073 C29.6493902,45.7317073 23.1707317,39.3292683 23.1707317,31.402439 C23.1707317,23.4756098 29.6493902,17.1493902 37.5,17.1493902 C45.3506098,17.1493902 51.8292683,23.5518293 51.8292683,31.402439 C51.8292683,39.2530488 45.3506098,45.7317073 37.5,45.7317073 Z M63.6432927,61.7378049 C62.652439,53.8871951 56.4786585,47.179878 47.4085366,44.2073171 C51.2195122,41.2347561 53.7347561,36.5853659 53.7347561,31.402439 C53.7347561,22.4847561 46.4939024,15.2439024 37.5762195,15.2439024 C28.6585366,15.2439024 21.3414634,22.4847561 21.3414634,31.402439 C21.3414634,36.5853659 23.8567073,41.2347561 27.6676829,44.2073171 C18.6737805,47.179878 12.4237805,53.8871951 11.4329268,61.7378049 C5.48780488,55.3353659 1.82926829,46.875 1.82926829,37.5 C1.82926829,17.8353659 17.8353659,1.82926829 37.5,1.82926829 C57.1646341,1.82926829 73.1707317,17.8353659 73.1707317,37.5 C73.1707317,46.875 69.5121951,55.3353659 63.6432927,61.7378049 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <p
      class="uppercase bold-text"
      [class.text-white]="activeTab === 'login'"
      [class.text-grey]="!(activeTab === 'login')"
    >
      {{ 'AUTH.login' | translate }}
    </p>
  </a>

  <!-- If active bg primary -->
  <a
    (click)="changeActiveTab('register')"
    [ngClass]="{ 'bg-primary border-primary': activeTab === 'register' }"
    [style.border-color]="activeTab === 'register' ? colors.primary: ''"
    [style.background-color]="activeTab === 'register' ? colors.primary: ''"
    class="col borders-all border-radius p-tb-20 login-tab"
    href="javascript:void(0)"
  >
    <div
      *ngIf="(viewType$ | async) === 'desktop'"
      class="svgrhubarbe"
    >
      <svg width="75px" height="75px" viewBox="0 0 75 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Formv1" transform="translate(-279.000000, -54.000000)" [attr.fill]="activeTab === 'register' ? 'white' : '#c5c5c5'" fill-rule="nonzero">
            <g id="Group-9">
              <g id="Group-8" transform="translate(225.000000, 24.000000)">
                <g id="icon-pencil" transform="translate(54.000000, 30.000000)">
                  <g id="Group">
                    <path d="M56.2933969,36.3979578 C55.7023695,36.3979578 55.2246313,36.8752941 55.2246313,37.4634406 L55.2246313,53.8292564 L2.24592378,53.8292564 L2.24592378,5.70352941 L48.6196603,5.70352941 C49.2106877,5.70352941 49.6884258,5.22619312 49.6884258,4.63804661 C49.6884258,4.04990011 49.2106877,3.57256382 48.6196603,3.57256382 L1.17715824,3.57256382 C0.586130903,3.57256382 0.108392709,4.04990011 0.108392709,4.63804661 L0.108392709,54.8958047 C0.108392709,55.4850166 0.586130903,55.9612875 1.17715824,55.9612875 L56.2933969,55.9612875 C56.8844242,55.9612875 57.3621624,55.4850166 57.3621624,54.8958047 L57.3621624,37.4645061 C57.3621624,36.8752941 56.8844242,36.3979578 56.2933969,36.3979578 Z" id="Shape"></path>
                    <path d="M32.1125766,12.6003996 C32.1125766,12.0111876 31.6348384,11.5349168 31.0438111,11.5349168 L8.89471417,11.5349168 C8.30368683,11.5349168 7.82594863,12.0111876 7.82594863,12.6003996 C7.82594863,13.1896115 8.30368683,13.6658824 8.89471417,13.6658824 L31.0438111,13.6658824 C31.6348384,13.6658824 32.1125766,13.1896115 32.1125766,12.6003996 Z" id="Shape"></path>
                    <path d="M8.89471417,22.3442397 L19.5235874,22.3442397 C20.1146147,22.3442397 20.5923529,21.8679689 20.5923529,21.2787569 C20.5923529,20.689545 20.1146147,20.2132741 19.5235874,20.2132741 L8.89471417,20.2132741 C8.30368683,20.2132741 7.82594863,20.689545 7.82594863,21.2787569 C7.82594863,21.8679689 8.30475559,22.3442397 8.89471417,22.3442397 Z" id="Shape"></path>
                    <path d="M29.1553024,29.9560488 C29.1553024,29.3668368 28.6775642,28.890566 28.0865369,28.890566 L8.89471417,28.890566 C8.30368683,28.890566 7.82594863,29.3668368 7.82594863,29.9560488 C7.82594863,30.5452608 8.30368683,31.0215316 8.89471417,31.0215316 L28.0865369,31.0215316 C28.6775642,31.0215316 29.1553024,30.5452608 29.1553024,29.9560488 Z" id="Shape"></path>
                    <path d="M8.89471417,39.699889 L16.7618973,39.699889 C17.3529246,39.699889 17.8306628,39.2236182 17.8306628,38.6344062 C17.8306628,38.0451942 17.3529246,37.5689234 16.7618973,37.5689234 L8.89471417,37.5689234 C8.30368683,37.5689234 7.82594863,38.0451942 7.82594863,38.6344062 C7.82594863,39.2236182 8.30475559,39.699889 8.89471417,39.699889 Z" id="Shape"></path>
                    <path d="M8.89471417,48.3771809 L20.9076388,48.3771809 C21.4986661,48.3771809 21.9764043,47.9009101 21.9764043,47.3116981 C21.9764043,46.7224861 21.4986661,46.2462153 20.9076388,46.2462153 L8.89471417,46.2462153 C8.30368683,46.2462153 7.82594863,46.7224861 7.82594863,47.3116981 C7.82594863,47.9009101 8.30475559,48.3771809 8.89471417,48.3771809 Z" id="Shape"></path>
                    <path d="M72.4595443,2.41651498 C71.3223778,1.28284129 69.8880944,0.682974473 68.3116653,0.682974473 C66.3023861,0.682974473 64.2642502,1.65682575 62.4152858,3.50117647 L30.3779702,35.4411543 C30.3704888,35.4486127 30.3683513,35.4592675 30.3608699,35.4645949 C30.3031566,35.5274584 30.2636123,35.6009767 30.2208616,35.6755605 C30.1909362,35.7277691 30.1545982,35.7757159 30.1353604,35.831121 C30.1300166,35.8460377 30.1171914,35.8556271 30.1129163,35.8705438 L26.5956089,47.0250832 C26.4748384,47.4075916 26.5806462,47.8263263 26.8692129,48.1076138 C27.0712096,48.3057936 27.3405385,48.4102109 27.6152113,48.4102109 C27.7306379,48.4102109 27.8471334,48.3910322 27.9604225,48.3526748 L38.9259569,44.623485 C38.9366446,44.6202886 38.9441259,44.6106992 38.9548136,44.6064373 C39.0039768,44.5872586 39.0445899,44.5531632 39.0916156,44.5275916 C39.171773,44.4817758 39.2519304,44.440222 39.3171251,44.3773585 C39.3214002,44.3730966 39.3278128,44.3720311 39.3320878,44.3677691 L71.371541,12.4267259 C75.2148219,8.59738069 74.6879205,4.6391121 72.4595443,2.41651498 Z M63.1719718,5.76 L66.138865,8.71671476 L69.1046893,11.6734295 L38.5775394,42.1089456 L32.6448219,36.1944506 L63.1719718,5.76 Z M31.623082,38.1890344 L36.609942,43.1605771 L29.2685915,45.6570033 L31.623082,38.1890344 Z M70.5774482,10.129545 L64.7206131,4.29176471 C66.9350953,2.49322974 69.3654681,2.34832408 70.9483099,3.92310766 C72.5578708,5.52879023 72.3890058,7.89309656 70.5774482,10.129545 Z" id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <!-- If active text white -->
    <p
      [class.text-white]="activeTab === 'register'"
      [class.text-grey]="!(activeTab === 'register')"
      class="uppercase bold-text"
    >
    {{ 'AUTH.register' | translate }}
    </p>
  </a>
</div>
