<div class="row block-talk m-m-t" id="chat">
  <h3
    class="section-title m-x-t m-m-b"
    [style.border-bottom-color]="theme?.colors?.primary"
  >
     {{ 'MEET_PAGE.chat' | translate }}
  </h3>
  <div class="w100 main-content-static">
    <div class="container">
      <div class="main-content">
        <!-- <h3 class="text-center">{{ 'MEET_PAGE.discussion' | translate }}</h3> -->
        <div class="talk-display">
          <div class="talk-sheet" *ngFor="let comment of comments">
            <div class="profile-pic">
              <img
                [lazyLoad]="getProfilePic(comment.member)"
                [defaultImage]="'/assets/images/avatar_default.jpg'"
                [alt]="comment.member?.nickname"
              >
            </div>
            <div class="talk-desc">
              <b>{{ comment.member?.nickname }}</b>
              <span class="role" *ngIf="comment.member && comment.member.role !== 'member'">(Org.)</span>
              <span class="date-msg">
                <!-- TODO : translation -->
                - <i>{{ comment.created_at | date:'dd-MM-y' }} à {{ comment.created_at | date:'HH:mm' }}</i>
                </span>
              <p class="p-static" [innerHtml]="comment.content | linky | sanitizeHtml"></p>
            </div>
          </div>
        </div>

        <div class="talk-meet" *ngIf="userAsParticipant" [style.border-color]="theme?.colors?.primary">
          <div class="profil-img">
            <img [src]="getProfilePic(userAsParticipant?.member)" alt="{{userAsParticipant?.member?.nickname}}">
          </div>
          <textarea autosize #commentInput placeholder="{{ 'MEET_PAGE.your-message' | translate }}"></textarea>
          <button
            [ngStyle]="{
              'color': 'white',
              'background-color': theme?.colors?.primary || '##1BA2A8'
            }"
            (click)="postComment(commentInput.value); commentInput.value = ''"
            class="button small"
          >
            {{ 'MEET_PAGE.send-comment' | translate }}
          </button>
        </div>

        <div 
          class="no-content" 
          *ngIf="!userAsParticipant"
          style="border: none; min-height: 80px;"
        >
          <div class="talk-contribution">
            <span>{{ 'MEET_PAGE.participate-to-comment' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
