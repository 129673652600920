import { Component, Input, OnChanges } from '@angular/core';

import { IColors, Member, Group, ITranslatedValue } from '../shared';
import { AppStore, Collection } from '../store';
import { Router } from '@angular/router';

@Component({
  selector: 'group-members',
  templateUrl: './group-members.component.html'
})

export class GroupMembersComponent implements OnChanges {
  @Input() group:        Group;
  @Input() colors:       IColors = { primary: '' };
  @Input() sectionTitle: ITranslatedValue;

  membersCollection:  Collection<Member>;

  constructor(
    private _router:    Router,
    private _appStore:  AppStore
  ) {}

  ngOnChanges() {
    if (this.group) {
      this.membersCollection = this._appStore.load('Members', `${this.group.id}`, { role: 'member', status: 'active' });
    }
  }

  loadNext() {
    this.membersCollection.collectNext();
  }

  onClickMember(member: Member) {
    if (this.group && this.group.options && this.group.options.master_group_id) {
      this._router.navigate(['/profile', member.user_id, 'organisations'], { queryParams: { '_': this.group.options.master_group_id } });
    }
    else {
      this._router.navigate(['/profile', member.user_id, 'organisations'], { queryParams: { '_': this.group.id } });
    }
  }

  /**
   * Take a member object and returns his short name.
   * A short name is his first name, and the first letter of
   * his last name.
   * Not optimized at all. Need to create a custom pipe. DRY
   * Idea : immutability with onPush change detection strategy
   */
  getMemberShortName(m: Member): string {
    if (m === undefined || (!m.first_name && !m.last_name)) {
      return '';
    }
    const { first_name, last_name } = m;
    return `${first_name} ${last_name ? last_name[0] : ''}`;
  }

  trackByFn(index, member) {
    return index;
  }
}
