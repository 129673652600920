<ng-container *ngIf="group?.meets_count > 0 || !group">
  <div class="content-width">
    <meets-board
      [group]="group"
      [entityType]="'GroupMeets'"
      [config]="config"
      (selectMeet)="onSelectMeet($event)"
      (createMeet)="onCreateMeet()"
    ></meets-board>
  </div>
</ng-container>

<ng-container
  *ngIf="group?.meets_count == 0 && group?.options?.meet_submission != 'closed'"
>
  <div class="row align-middle content-width">
    <div class="w100 bg-lighter-grey text-center p-tb-60 m-s-tb">
      <button
        class="button cta button-big"
        (click)="onCreateMeet()"
        [animation-scroll]="'block-appear-b-t'"
        [style.background-color]="color"
      >
        {{ creationBox.text | customTranslate }}
      </button>
    </div>
  </div>
</ng-container>
