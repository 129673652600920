import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { IColors, ITranslatedValue, Meet } from '../shared/definitions';
import { Collection } from '../store/index';
import { Observable } from 'rxjs';

@Component({
  selector: 'meets-list',
  templateUrl: './meets-list.component.html',
  host: {'class': 'w100'}
})

export class MeetsListComponent implements OnChanges {

  @Input() meetsCollection: Collection<Meet>;
  @Input() meets$:          Observable<Meet[]>;

  @Input() colors:          IColors = { primary: '' };
  @Input() creationBox:     { text: ITranslatedValue, hide: boolean };

  @Output() selectMeet:     EventEmitter<{ meet: Meet, offset: number }> = new EventEmitter<{ meet: Meet, offset: number }>();
  @Output() create:         EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges() {
    if (!this.creationBox) {
      this.creationBox = { text: { fr: 'Proposer un événement' }, hide: false };
    }

    if (!this.colors) {
      this.colors = { primary: '' };
    }

    if (this.meetsCollection) {
      this.meets$ = this.meetsCollection.entities;
    }
  }

  onSelectMeet(event) {
    this.selectMeet.next(event);
  }

  onMeetCreate() {
    this.create.next(true);
  }
}
