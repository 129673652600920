<div class="sml">
  <div class="meet-list-component align-middle flex row-wrap">
    <div
      *ngIf="!creationBox.hide"
      (click)="onMeetCreate()"
      class="widget-wrapper"
    >
      <div class="widget-card purpose-meet">
        <div
          class="widget-banner picture hide-m"
          [ngStyle]="{
            'background-color': colors.primary,
            'border-bottom-color': colors.primary
          }"
        >
        </div>

        <div class="widget-desc">
          <p class="p-l-10 p-r-10 p-static"
            [ngStyle]="{
              'color': colors.primary,
              'margin': '65px 0',
              'text-align': 'center'
            }"
          >
            {{ creationBox.text | customTranslate }}
          </p>
        </div>
      </div>
    </div>

    <meet-details-box
      *ngFor="let meet of meets$ | async"
      [meet]="meet"
      [colors]="colors"
      (selectMeet)="onSelectMeet($event)"
    ></meet-details-box>
  </div>
</div>
