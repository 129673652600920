
import { of as observableOf,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meet, Participant, User } from '../shared/';
import { ApiConfig } from '../core/';

@Injectable()
export class MeetService {

  constructor(
    private _http: HttpClient,
    private _api: ApiConfig
  ) { }

  get headers() {
    const headers: HttpHeaders = this._api.headers();
    const token = localStorage.getItem('ngStorage-oauthToken');
    if (token)
      return headers.append('X-Auth-Key', JSON.parse(token));
    else
      return headers;
  }

  // --------------> PARTICIPANT <-------------------

  loadMyParticipant(id: string): Observable<Participant> {
    if (this.headers.get('X-Auth-Key')) {
      return this._http.get<{ success: boolean, participant: Participant }>(
        this._api.getPath('meets/' + id + '/participant'),
        { headers: this.headers }
      ).pipe(
        map(data => {
          if(data.success)
            return data.participant;
          else
            return null;
        })
      );
    }
    return observableOf(null);
  }

  manageInvitations(
    invitation: string,
    token: string,
    data?: {first_name: string, last_name: string, telephone: string, password: string}
  ): Observable<{ auth_key: string, user: User, participant: Participant }> {

    return this._http.put<{ auth_key: string, user: User, participant: Participant }>(
      this._api.getPath('invitations/' + token),
      {
        validation: invitation == 'accept',
        ...data
      },
      { headers: this.headers }
    );
  }

  // --------------> HOWTANK API Calls <-------------------

  apiHowtank(meet: Meet, group: any, action: string) {
    if (group && group.options && group.options.auth_schema && group.options.auth_schema.howtank_connect) {
    const meetPath: string = `/home/${group.id}/rencontre/${meet.id}`;
    const path: string = `/api/howtank?meet_name=${encodeURIComponent(meet.name)}&meet_path=${encodeURIComponent(meetPath)}&action=${action}&visas=${group.options.howtank_visas}`;
    this._http.get(path, { withCredentials: true })
      .subscribe(_ => console.log(_));
    }
  }
}
