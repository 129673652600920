import { Component, ViewChild }  from '@angular/core';
import { Input, Output, EventEmitter, ElementRef }  from '@angular/core';
import { ResourceService }  from './resource.service';
import { Resource }  from '../../definitions';
import { TranslateService } from '@ngx-translate/core';

const SlimLib = require('./slim.commonjs.js');

@Component({
  selector: 'k-image-cropper',
  templateUrl: './cropper.component.html'
})

export class CropperComponent {

  i18n:                             any;
  resource:                         Resource;
  resourceToDelete:                 Resource;
  
  @ViewChild('cropper', { static: true }) cropper:    ElementRef;

  @Output()   newPhoto:             EventEmitter<Resource> = new EventEmitter<Resource>();
  @Output()   uploading:            EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()    initialPhotos:        Resource[] = [];
  @Input()    options:              { category: string, title?: string, group_id?: string, meet_id?: string };
  @Input()    settings:             { ratio?: string, size?: string } = {};

  constructor(
    private _resourceService:   ResourceService,
    private _translateService:  TranslateService
  ) { }

  ngOnInit() {
    this.i18n = this._translateService.instant('IMG_CROPPER');

    const slimSettings: any = {
      maxFileSize:            2,
      push:                   true, // remove Upload Btn and automatically upload images
      serviceFormat:          'file', // set serviceFormat to "file" to receive an array of files
      service:                this.slimService.bind(this),
      didConfirm:             this.slimDidConfirm.bind(this),
      didRemove:              this.slimDidRemove.bind(this),
      didReceiveServerError:  this.slimDidReceiveServerError.bind(this),
      label:                  this.options.category == 'group_avatar' ? this.i18n.selectAvatar : this.i18n.select,
      labelLoading:           this.i18n.uploading,
      statusUploadSuccess:    this.i18n.saved,
      buttonEditTitle:        this.i18n.edit,
      buttonRemoveTitle:      this.i18n.remove,
      buttonRotateTitle:      this.i18n.rotate,
      buttonConfirmTitle:     this.i18n.confirm,
      buttonConfirmLabel:     this.i18n.confirm,
      buttonCancelTitle:      this.i18n.cancel,
      buttonCancelLabel:      this.i18n.cancel,
      ...this.settings
    };

    const slim = SlimLib.create(this.cropper.nativeElement, slimSettings);

    if (this.initialPhotos && this.initialPhotos[0]) {
      // add param _ to disable cache
      slim.load(this.initialPhotos[0].slug + '?_=_', { blockPush: true }, (err) => {
        if (!err) {
          this.resource = this.initialPhotos[0];
        }
        else {
          slim.remove();
        }
      });
    }
  }

  slimService(blobs: any, progress: any, success: any, failure: any, slim: any) {
    // form data to post to server
    if (blobs.length == 0) return;

    this.uploading.emit(true);

    if (this.resource) {
      this._resourceService.updateResource(blobs[0], this.resource.id)
        .subscribe(
          (resource: Resource) => {
            this.resource = resource;
            this.newPhoto.emit(resource);
            this.uploading.emit(false);
            success(true);
          },
          (error) => {
            this.uploading.emit(false);
            failure(error);
          }
        );
    }
    else {
      this._resourceService.createResource(blobs[0], this.options)
        .subscribe(
          (resource: Resource) => {
            this.resource = resource;
            this.deleteOldResource();
            this.newPhoto.emit(resource);
            this.uploading.emit(false);
            success(true);
          },
          (error) => {
            this.uploading.emit(false);
            failure(error);
          }
        );
    }

    progress(500, 1000); // will put the progress indicator at 50%
  }

  slimDidConfirm(data, slim) {
    // reset resource to null when it's a default one so that a new resource will be created in slimService
    if (this.resource && this.resource.category == `default_${this.options.category}`) {
      this.resource = null;
    }
  }

  slimDidRemove(arg) {
    this.resourceToDelete = this.resource;
    this.resource = null;
  }

  slimDidReceiveServerError(error) {
    // show corresponding error returned by 'failure' callback in slimService
    return this.i18n[error];
  }

  deleteOldResource() {
    if (this.resourceToDelete && this.resourceToDelete.category != `default_${this.options.category}`) {
      this._resourceService.deleteResource(this.resourceToDelete.id)
        .subscribe(({ success }) => {
          if (success)
            this.resourceToDelete = null;
        });
    }
  }
}
