
import { combineLatest as observableCombineLatest,
  Observable
} from 'rxjs';

import { first, map } from 'rxjs/operators';
// because all of the rouerLinks and router.navigate() are in french,
// we use this guard on some french paths to assure that
// if the current language is other then French, the paths get translated

import {
  Injectable
}  from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
}  from '@angular/router';
import {
  TranslateService
}  from '@ngx-translate/core';


@Injectable()
export class UrlTranslationGuard implements CanActivate {
  queryParams: Object;

  constructor(private _translate: TranslateService, private _router: Router){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this._translate.currentLang == 'en') {
      const tree = this._router.parseUrl(state.url);
      this.queryParams = Object.keys(tree.queryParams).length > 0 ? tree.queryParams : this.queryParams;
      const segments = tree.root.children['primary'].segments;
      const translatedSegments$ = segments.map(seg => seg.path).map((path: any) => this._translate.get('ROUTES.' + path));

      return observableCombineLatest(translatedSegments$).pipe(
        map(data => {
          const url = data
            .map(path => path.substr(0, 7) == 'ROUTES.' ? path.substr(7) : path)
            .reduce((memo, path, i) => {
              return [...memo, path, segments[i].parameters];
            }, []);
          this._router.navigate(url, { queryParams: this.queryParams, replaceUrl: true });
          return true;
        }),
        first(),
      );
    }
    else {
      return true;
    }
  }
}
