import { Component } from '@angular/core';
import { ViewTypeService, IViewType } from '../shared/view-type.service';
import { Observable } from 'rxjs';

@Component({
  template: `
  <div class="gradient-animation pos-r">
    <div
      class="row pos-a col col-12 push"
      [ngClass]="{
        'p-t-60 m-x-t': (viewType$ | async) === 'desktop'
      }"
    >
      <div class="col col-4 text-center push-center">
        <h1 class="text-404 text-white">
          {{ 'PAGE_NOT_FOUND.404' | translate }}
        </h1>
        <h2 class="main-title-static">
          {{ 'PAGE_NOT_FOUND.not-found' | translate }}
				</h2>
        <p class="p-static italic-text">
					{{ 'PAGE_NOT_FOUND.explain' | translate }}
				</p>
        <p class="p-static">
					{{ 'PAGE_NOT_FOUND.why' | translate }}
				</p>
        <p class="p-static">
					{{ 'PAGE_NOT_FOUND.address' | translate }}
				</p>
        <p class="p-static m-x-b">
					{{ 'PAGE_NOT_FOUND.link' | translate }}
				</p>
        <a class="button btn-white-black" href="/">
					{{ 'PAGE_NOT_FOUND.home' | translate }}
				</a>
      </div>
    </div>
  </div>
  `
})

export class PageNotFoundComponent {

  viewType$: Observable<IViewType> = this._viewType.get();

  constructor(private _viewType: ViewTypeService) { }
}
