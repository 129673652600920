import { Group } from './group';
import { Resource } from './others';

export class User {
  constructor (
    public id?:                     number,
    public groups?:                 Array<Group>,
    public email?:                  string,
    public password?:               string,
    public first_name?:             string,
    public last_name?:              string,
    public telephone?:              string,
    public nickname?:               string,
    public status?:                 string,
    public role?:                   string,
    public providers?:              Array<{pid: string, provider: string}>,
    public admin_groups_count?:     number,
    public locale?:                 string,
    public qr_token?:              string,
    public tiller_id?:             string,
    public organized_meets_count?:  {
      draft:    number,
      pending:  number,
      future:   number,
      past:     number,
      total:    number
    },
    public registered_meets_count?:  {
      future:   number,
      past:     number,
      total:    number
    },
    public avatar?:                 Resource,
  ) {}
}
