<div class="pos-r">
  <div
    #textarea
    (mouseup)="onMouseUp($event)"
    (keyup)="onMouseUp($event)"
    (dblclick)="onMouseUp($event)"
    class="col wysiwyg-input"
    (click)="onClick($event)"
    placeholder="&#xf032; | &#xf033; | &#xf0c1; | &#xf043;"
    >
  </div>

    <!-- Wysiwyg modal -->
  <div
    id="container"
    class="hide pos-a"
    [ngClass]="{
      'hide-m': (viewType$ | async) === 'mobile'
    }"
    #wysiwyg
  >
    <div class="wysiwyg pos-a push-center">
      <div
        *ngIf="!show.inputLink && !show.colors"
        (click)="restoreSelection()"
        class="wysiwyg-dropdown"
      >
        <a
          class="select-selected align-middle flex"
          href="javascript:void(0)"
          (click)="show.dropdown = !show.dropdown; restoreSelection()"
        >
          <p *ngIf="textFormat.type == 'p'">
            {{ 'WYSIWYG.paragraph' | translate }}
          </p>
          <p *ngIf="textFormat.type == 'h2'">
            {{ 'WYSIWYG.title' | translate }}
          </p>
          <i *ngIf="!show.dropdown" class="fal fa-angle-down p-l-20 push-right"></i>
          <i *ngIf="show.dropdown" class="fal fa-angle-up p-l-20 push-right"></i>
        </a>
        <div *ngIf="show.dropdown" class="select-items">
          <a
            href="javascript:void(0)"
            *ngIf="textFormat.type == 'h2'"
            (click)="formatText('Paragraphe'); displayWysiwyg(false)"
          >
            {{ 'WYSIWYG.paragraph' | translate }}
          </a>
          <a
            href="javascript:void(0)"
            *ngIf="textFormat.type == 'p'"
            (click)="formatText('Titre'); displayWysiwyg(false)"
          >
            {{ 'WYSIWYG.title' | translate }}
          </a>
        </div>
      </div>

      <div
        (click)="restoreSelection()"
        [ngClass]="{'m-xs-l': !show.colors}"
        class="flex"
        *ngIf="!show.inputLink"
      >
        <ng-container *ngIf="!show.colors">
            <button
              type="button"
              (click)="formatText('bold');"
              [ngClass]="{
                'wysiwyg-selected': textFormat.isBold == true,
                'wysiwyg-btn-disable': textFormat.type == 'h2'
              }"
              [disabled]="textFormat.type == 'h2'"
              class="wysiwyg-btn"
            >
              <i class="far fa-bold"></i>
            </button>
            <button
              type="button"
              (click)="formatText('italic')"
              [ngClass]="{
                'wysiwyg-selected': textFormat.isItalic == true,
                'wysiwyg-btn-disable': textFormat.type == 'h2'
              }"
              [disabled]="textFormat.type == 'h2'"
              class="wysiwyg-btn"
            >
              <i class="far fa-italic"></i>
            </button>
            <button
              *ngIf="!(textFormat.isBold || textFormat.isItalic)"
              type="button"
              class="wysiwyg-btn link-input"
              (click)="formatText('createLink', $event)"
            >
              <i class="far fa-link"></i>
            </button>
        </ng-container>

        <button
          *ngIf="!show.colors && !show.inputLink"
          type="button"
          class="wysiwyg-btn color-input"
          [ngClass]="{'wysiwyg-selected': show.isColorSelected == true }"
          (click)="show.colors = !show.colors"
        >
          <i class="far fa-tint"></i>
        </button>
        <div *ngIf="show.colors" class="color-block flex align-middle">
          <button
            *ngFor="let color of textFormat.colors"
            type="button"
            class="wysiwyg-btn color-input"
            (click)="formatText('customColor', color)"
            [style.color]="color"
          >
            <i class="fas fa-circle wysiwyg-colors-icon"></i>
          </button>
          <div
            class="color-input pointer p-lr-10"
            (click)="show.colors = !show.colors; restoreSelection()"
          >
            <i class="far fa-times close-icon text-error"></i>
          </div>
        </div>
      </div>

      <div id="linkInput" *ngIf="show.inputLink" class="flex">
        <i class="far fa-link link-icon"></i>
        <input
          [ngClass]="{ 'text-error': urlInvalid }"
          type="url"
          class="input-url"
          [(ngModel)]="urlTyped"
          placeholder="www.exemple.com"
          (keydown.enter)="$event.preventDefault(); restoreSelection(); createUrl(urlTyped)"
        autofocus/>
        <i class="far fa-check validate-icon" (click)="createUrl(urlTyped)"></i>
        <i class="far fa-times close-icon" (click)="removeUrl(urlTyped)"></i>
      </div>

      <div (click)="restoreSelection()" id="colorInput">
        <span class="colorInputLegend">
          {{ 'WYSIWYG.colors' | translate }}
        </span>
        <div class="colors">
          <button type="button" class="wysiwyg-btn color-input">
            <div class="color-block"></div>
          </button>
          <button type="button" class="wysiwyg-btn color-input">
            <div class="color-block"></div>
          </button>
          <button type="button" class="wysiwyg-btn color-input">
            <div class="color-block"></div>
          </button>
          <button type="button" class="wysiwyg-btn color-input">
            <div class="color-block"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
