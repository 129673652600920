
import { debounceTime, map, filter, switchMap } from 'rxjs/operators';
import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiConfig } from '../../../core/api-config';
import { Tag, IColors } from '../../definitions';

@Component({
  selector: 'search-by-tag',
  templateUrl: './search-by-tag.component.html'
})

export class SearchByTagComponent implements OnInit, OnChanges {
  term:           FormControl = new FormControl();

  indexTag:       number = -1;
  items:          Observable<string[]>;
  availableTags:  string[] = [];
  tagButtons:     { name: string, selected: boolean }[] = [];
  tagImages:      { name: string, slug: string, size: number[], selected: boolean }[] = [];
  tagLabels:      { name: string, show: boolean }[] = [];
  totalTagsSelected: number = 0;

  @Input()        predefinedTags: string[];
  @Input()        predefinedTagsWithImage: { name: string, slug: string, size: number[] }[];
  @Input()        colors:         IColors = { primary: '' };
  @Output()       searchedTags:   EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(
    private _http:  HttpClient,
    private _api:   ApiConfig
  ) { }

  ngOnInit() {
    this.items = this.term.valueChanges.pipe(
      filter(data => data && data.length > 2 && this.indexTag == -1),
      debounceTime(400),
      switchMap(term => this.searchTags(term)),);

    this.items.subscribe(items => {
      this.indexTag = -1;
      this.availableTags = items;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.predefinedTagsWithImage && changes.predefinedTagsWithImage.currentValue) {
      this.tagImages = this.predefinedTagsWithImage.map(tag => ({ ...tag, selected: false }));
    }
    if (changes.predefinedTags && changes.predefinedTags.currentValue) {
      this.tagButtons = this.predefinedTags.map(tag => ({ name: tag, selected: false }));
    }
  }

  addTag(event) {
    if (event.code == 'Enter') {
      event.preventDefault();
      this.pushTag(this.term.value, true);
    }
    //else if (event.code == 'Backspace') {
      //this.indexTag = -1;
      //if (this.tagLabels.length > 0 && this.term.value == '')
        //this.removeTag(this.tagLabels[this.tagLabels.length - 1].name);
    //}
    else if (event.code == 'ArrowDown') {
      this.indexTag++;
      this.term.setValue(this.availableTags[this.indexTag]);
    }
   else if (event.code == 'ArrowUp') {
      this.indexTag--;
      this.term.setValue(this.availableTags[this.indexTag]);
    }
  }

  pushTag(newTag: string, show: boolean) {
    let result = this.tagLabels.filter(label => label.name == newTag);
    if (!newTag || result.length > 0)
      return ;
    this.tagLabels = [...this.tagLabels, { name: newTag, show }];

    this.searchedTags.emit(this.tagLabels.map(_ => _.name));
    this.indexTag = -1;
    this.term.setValue('');
  }

  selectTag(name: string) {
    this.tagLabels = [{ name, show: true }, ...this.tagLabels];
    this.searchedTags.emit(this.tagLabels.map(_ => _.name));
    this.indexTag = -1;
    this.term.setValue('');
  }

  removeTag(tag) {
    let index = this.tagLabels.map(_ => _.name).indexOf(tag);
    this.tagLabels = this.tagLabels.filter((el, i) => i != index);

    this.searchedTags.emit(this.tagLabels.map(_ => _.name));
  }

  searchTags(tag: string): Observable<string[]> {
    return this._http.get<{ meta: any, tags: Tag[] }>(this._api.getPath('tags?q=' + tag), { headers: this._api.headers() }).pipe(
      map(res => res.tags),
      map(tags => tags.map(tag => tag.name[0] == '#' ? tag.name.substr(1) : tag.name)),);
  }

  setTagButton(btn: { name: string, selected: boolean }) {
    btn.selected = !btn.selected;

    if (btn.selected) {
      this.pushTag(btn.name, false);
      this.totalTagsSelected += 1;
    }
    else {
      this.removeTag(btn.name);
      this.totalTagsSelected -= 1;
    }
  }
}
