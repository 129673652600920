<div *ngIf="format" class="format-list-container">
  <div class="format-list-img" [style.background-image]="'url(' + setCoverPhoto(format) + ')'"></div>
  <div class="format-list-box">
    <h3>{{ format.name }}</h3>
    <div class="format-tips row">
      <div class="duration col col-4">
        <h4>Durée</h4>
        <p>{{ setDuration(format) }}</p>
      </div>

      <div class="difficulty col col-4">
        <h4>Animation</h4>
        <p>
        <img
          *ngFor="let level of [1, 2, 3, 4, 5]"
          class="level-img"
          [src]="level <= format.modalities.difficulty ? '/assets/images/formats/strength-white.svg' : '/assets/images/formats/strength-o-white.svg'"
          alt="level"
        />
        </p>
      </div>

      <div class="participants col col-4">
        <h4>Participants</h4>
        <p>{{ setParticipants(format) }}</p>
      </div>
    </div>
    <div class="format-list-overlay"></div>
  </div>
</div>