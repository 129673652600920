import {
  Component,
}  from '@angular/core';

@Component({
  templateUrl: '../../../../src/app/main/discover/discover.component.html'
})

export class DiscoverComponent {
  navMenuTitle:   string  = 'Trouver un événement';
  showMobileMenu: boolean = false;
}
