
import { of as observableOf,  Observable } from 'rxjs';

import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { relative } from 'path';
import { AuthRedirectService } from './auth-redirect.service';


@Injectable()
export class ProviderGuardService implements CanActivate {
  private _i18n: any;

  constructor(
    private _router:              Router,
    private _route:               ActivatedRoute,
    private _translate:           TranslateService,
    private _authService:         AuthService,
    private _authRedirectService: AuthRedirectService,
  ) {
    this._translate.get('LOGIN.GUARD').subscribe(i18n => this._i18n = i18n);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const params = route.queryParams;
    const { returnUri, email } = params;
    const redirectUrl = new URL(returnUri).searchParams.get('redirectUrl'); // relative url

    if (!email) {
      const facebookError = 'true';
      this._router.navigate(
        this._authRedirectService.generateAuthUrl(redirectUrl, params._),
        { queryParams: { facebookError, redirectUrl }
      });
      return false;
    }
    else {
      return this._authService.providerLogin(route.params.provider, params).pipe(
      mergeMap(res => {
          if (redirectUrl) {
            this._router.navigateByUrl(redirectUrl);
          }
          return observableOf(true);
        }));
    }
  }
}
