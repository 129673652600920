
import { map, switchMap, debounceTime, filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output
}  from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../core/api-config';
import { HttpClient } from '@angular/common/http';
import { Tag } from '../../definitions';

@Component({
  selector: 'bo-tag',
  templateUrl: './tags.component.html'
})
export class TagsComponent implements OnInit {

  term:           FormControl;
  indexTag:       number;
  items:          Observable<Tag[]>;
  availableTags:  Tag[];

  @Input('currentTags') tags:       Tag[];
  @Output('emitTags')   tagsOutput: EventEmitter<Tag[]>;

  constructor(
    private _http: HttpClient,
    private _api: ApiConfig
  ) {
    this.tagsOutput = new EventEmitter<Tag[]>();
    this.availableTags = [];
    this.indexTag = -1;
    this.term = new FormControl();
  }

  ngOnInit() {
    this.items = this.term.valueChanges.pipe(
      filter(data => data && data.length > 2 && this.indexTag == -1),
      debounceTime(400),
      switchMap((term: string) => this._http.get<{ meta: any, tags: Tag[] }>(this._api.getPath('tags?q=' + term), { headers: this._api.headers() })),
      map( res => res.tags),);

    this.items.subscribe(items => {
      this.indexTag = -1;
      this.availableTags = items;
    });

  }

  addTag(event) {
    if (['Enter', 'Space'].indexOf(event.code) != -1) {
      event.preventDefault();
      this.pushTag(this.term.value);
    }
    else if (event.code == 'ArrowDown') {
      this.indexTag = this.indexTag == this.availableTags.length - 1 ? 0 : this.indexTag + 1;
      this.term.setValue(this.availableTags[this.indexTag].name);
    }
    else if (event.code == 'ArrowUp') {
      this.indexTag = this.indexTag == 0 ? this.availableTags.length - 1 : this.indexTag - 1;
      this.term.setValue(this.availableTags[this.indexTag].name);
    }
  }

  pushTag(newTag: string) {
    if (!newTag || this.tags.find(_ => _.name == '#' + newTag)) {
      this.term.setValue('');
      return ;
    }

    this._http.post<Tag>(
      this._api.getPath('tags'),
      { name: newTag },
      { headers: this._api.headers() }
    ).subscribe(tag => {
      this.tags = [...this.tags, tag];
      this.tagsOutput.emit(this.tags);
    });

    this.indexTag = -1;
    this.term.setValue('');
  }

  selectTag(tag) {
    this.tags = [tag, ...this.tags];
    this.tagsOutput.emit(this.tags);
    this.indexTag = -1;
    this.term.setValue('');
  }

  removeTag(tag) {
    let index = this.tags.indexOf(tag);
    this.tags = this.tags.filter((el, i) => i != index);

    this.tagsOutput.emit(this.tags);
  }

}
