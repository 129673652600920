
import { of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnChanges } from '@angular/core';
import { Group, Resource, ITranslatedValue, IColors } from '../shared/index';
import { AppStore } from '../store/index';

@Component({
  selector: 'group-photos',
  template: `
    <div *ngIf="(photos$ | async)?.length > 0" class="row align-middle content-width">
      <div class="w100 custom-content group-content m-x-b m-m-t">
        <div *ngIf="sectionTitle" class="custom-title">
          <h3
            class="section-title m-m-b"
            [ngStyle]="{'border-color': 'rgba(colors.primary)'}"
          >
            {{ sectionTitle | customTranslate }}
          </h3>
        </div>

        <photo-gallery [photos]="photos$ | async"></photo-gallery>
      </div>
    </div>
  `
})
export class GroupPhotosComponent implements OnChanges {

  @Input() group:         Group;
  @Input() config:        any;
  @Input() sectionTitle:  ITranslatedValue;
  @Input() colors:        IColors = { primary: '' };

  photos$: Observable<Resource[]> = observableOf([]);

  constructor(private _appStore: AppStore) {}

  ngOnChanges() {
    if (this.group) {
      this.photos$ = this._appStore.load('GroupResources', this.group.id.toString(), { category: 'group_photo' }).entities;
    }
  }
}
