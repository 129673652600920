import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { IColors, ITranslatedValue, IArea, Meet, Location, Group, User } from '../shared/definitions';
import { Observable, Subscription } from 'rxjs';
import { Collection, AppStore } from '../store/';
import { MeetsScopeStore, MeetsScopeEntityTypes } from './meets-scope.store';


interface IMeetsBoardConfig {
  view_type?:         'list'|'map';
  mapbox_id?:         string;
  predefined_tags?:   string[];
  predefined_tags_with_image?: { name: string, title: string, slug: string, size: number[] }[];
  search_bar?:       {
    by_tag?:            boolean,
    by_city?:           boolean,
    by_date?:           boolean,
    by_flags?:          boolean
  };
  meet_naming?:       ITranslatedValue;
  meet_creation_box?: {
    text:   ITranslatedValue,
    hide:   boolean;
  };
}

@Component({
  selector: 'meets-board',
  templateUrl: './meets-board.component.html'
})

export class MeetsBoardComponent implements OnChanges {

  @Input() group:       Group;
  @Input() user:        User;
  @Input() entityType:  MeetsScopeEntityTypes;
  @Input() config:      IMeetsBoardConfig;

  @Output() selectMeet: EventEmitter<Meet> = new EventEmitter<Meet>();
  @Output() createMeet: EventEmitter<boolean> = new EventEmitter<boolean>();

  collection:           Collection<Meet>;
  locations$:           Observable<Location[]>;

  colors:               IColors;
  viewType:             'map'|'list' = 'list';
  mapboxId:             string;
  meetNaming:           ITranslatedValue;
  creationBox:          { text: ITranslatedValue, hide: boolean } = {
    text: { fr: 'Proposer un événement', en: 'Create an event' },
    hide: false
  };
  predefinedTags:           string[] = [];
  predefinedTagsWithImage:  { name: string, title: string, slug: string, size: number[] }[] = [];
  timeScope:                 string = 'future';
  timePeriod:                string;
  showTimeScope:             boolean = true;
  meetsCount:               { 'future': number, 'past': number };

  subscription:             Subscription;
  meetsScopeStore:          MeetsScopeStore;

  constructor(
    private _appStore:        AppStore,
  ) {
    // it's necessary to initilize meetsScopeStore in this way
    // oherwise timeScopes get mixed in AccountMeetsParticipations and AccountMeetsOrganizations
    this.meetsScopeStore = new MeetsScopeStore(_appStore);
  }

  ngOnChanges() {
    if (this.config && this.config.view_type) {
      this.viewType = this.config.view_type;
    }
    if (this.config && this.config.mapbox_id) {
      this.mapboxId = this.config.mapbox_id;
    }
    if (this.config && this.config.predefined_tags) {
      this.predefinedTags = this.config.predefined_tags;
    }
    if (this.config && this.config.predefined_tags_with_image) {
      this.predefinedTagsWithImage = this.config.predefined_tags_with_image;
    }
    if (this.config && this.config.meet_creation_box) {
      this.creationBox = this.config.meet_creation_box;
    }
    if (this.config && this.config.meet_naming) {
      this.meetNaming = this.config.meet_naming;
    }

    if (this.group && this.group.options && this.group.options.meet_submission == 'closed') {
      this.creationBox.hide = true;
    }

    if (this.group && !this.group.has_future_meets) {
      this.timeScope = 'past';
    }
    else if (this.entityType == 'UserMeetsOrganizations' && this.user && this.user.organized_meets_count.future == 0) {
      this.timeScope = 'past';
    }
    else if (this.entityType == 'AccountMeetsOrganizations' && this.user && this.user.organized_meets_count.future == 0) {
      this.timeScope = 'past';
    }
    else if (this.entityType == 'AccountMeetsParticipations' && this.user && this.user.registered_meets_count.future == 0) {
      this.timeScope = 'past';
    }

    // collection and loactions after setting timeScope
    this.meetsScopeStore.setScopeParams({ timeScope: this.timeScope });

    this.subscription = this.meetsScopeStore
      .getMeetsCollection(this.entityType, this.group, this.user)
      .subscribe((collection: Collection<Meet>) =>  {
        this.collection = collection;
      });

    this.meetsScopeStore.getMeetsCount(this.entityType, this.group, this.user)
      .subscribe(count => this.meetsCount = count);

    this.locations$ = this.meetsScopeStore.getMeetsLocations(this.entityType, this.group, this.user);

    // colors
    if (this.group && this.group.options && this.group.options.colors) {
      this.colors = this.group.options.colors;
    }
    else {
      this.colors = { primary: '#00A0A9' };
    }
  }

  onSelectMeet(meet: Meet) {
    this.selectMeet.emit(meet);
  }

  onCreateMeet() {
    this.createMeet.emit(true);
  }

  onLoadNextMeets() {
    this.collection.collectNext();
  }

  onSearchTags(tags: string[]) {
    this.meetsScopeStore.onSearchTags(tags);
  }

  onSearchCity(area: IArea) {
    this.meetsScopeStore.onSearchCity(area);
  }

  onChangeTimeScope(timeScope: 'future'|'past'|'today'|'thisweek') {
    this.timeScope = timeScope;
    this.meetsScopeStore.onChangeTimeScope(this.timeScope);
  }

  onChangeTimePeriod(period: string) {
    this.timePeriod = period;

    if(this.timePeriod == '') {
      this.meetsScopeStore.onChangeTimeScope(this.timeScope);
    } else {
      this.meetsScopeStore.onChangeTimeScope(this.timePeriod);
    }
    this.showTimeScope = this.timePeriod ? false : true;
  }

  onChangeFlags(flags: string[]) {
    this.meetsScopeStore.onChangeFlags(flags);
  }

  onSelectMarker(coord: { lat: number, lng: number }) {
    this.meetsScopeStore.onSelectMarker(coord);
  }

  onCloseMarker() {
    this.meetsScopeStore.onCloseMarker();
  }
}
