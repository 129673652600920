<div *ngIf="format" class="w100 banner-static" [style.background-image]="'url(' + setCoverPhoto(format) + ')'">
  <div class="overlay-dark"></div>
  <div class="row align-middle content-width content-head">
    <div class="w70 w100-m push-left">
      <h1 class="text-left">{{ format.name }}</h1>
    </div>

    <div class="banner-stats w30 hide-m push-right">
       <count-to
        *ngIf="format.meets_count > 0"
        [step]="100"
        [countTo]="format.meets_count"
        [countFrom]="0"
        [duration]="3"
        (countoChange)="meets_count = $event"
      >
        <h3 [style.color]="'#FAFAFA'">
          <span class="number-stats">
            {{ meets_count }}<br />
          </span>
          événements
        </h3>
       </count-to>

       <count-to
        *ngIf="format.meets_count < format.participants_count / 3"
        [step]="100"
        [countTo]="format.participants_count"
        [countFrom]="0"
        [duration]="3"
        (countoChange)="participants_count = $event"
      >
        <h3 [style.color]="'#FAFAFA'" style="margin-bottom: 0px">
          <span class="number-stats">
            {{ participants_count }}<br />
          </span>
          participants
        </h3>
       </count-to>
    </div>
  </div>
</div>

<div *ngIf="format" class="w100 format-details">
  <div class="row align-middle content-width format-tips">
    <div class="col col-3 time-tips">
      <h3 class="inter-static-title text-primary">Durée</h3>
      <p class="p-static">{{ setDuration(format) }}</p>
    </div>

    <div class="col col-3 level-tips">
      <h3 class="inter-static-title text-primary">Animation</h3>
      <p>
        <img
          *ngFor="let level of [1, 2, 3, 4, 5]"
          class="level-img"
          [src]="level <= format.modalities.difficulty ? '/assets/images/formats/strength.svg' : '/assets/images/formats/strength-o.svg'"
          alt="level"
        />
      </p>
    </div>

    <div class="col col-3 people-tips">
      <h3 class="inter-static-title text-primary">Participants</h3>
      <p class="p-static">{{ setParticipants(format) }}</p>
    </div>

  </div>

  <div class="w100 block-top-plus bg-lighter-grey" [ngStyle]="{'background-color': 'rgba(' + hexToRgbaColor(colors.primary, 0.2) + ')'}">
    <div class="row align-middle content-width">
      <div class="w100 main-content-static group-content-static">
        <p class="p-static" [innerHtml]="format.description | linky | sanitizeHtml"></p>
      </div>
    </div>
  </div>

  <div *ngIf="videos?.length > 0" class="row content-width format-video" id="block-1">
    <div class="col col-7">
      <div [ngClass]="video.active ?'':'video-hide'" *ngFor="let video of videos">
        <iframe class="vid-1" [src]="video.sanitizedUrl" frameborder="0"></iframe>
      </div>
    </div>

    <div *ngIf="videos?.length > 0" class="col col-4 push-right">
      <div class="desc-video subtitle-1"  *ngFor="let video of videos; let i = index" (click)="onClickVideo(i)" [ngClass]="video.active ?'':'desc-video-hide'">
        <h3 class="inter-static-title text-dark-black">{{ video.title }}</h3>
        <p class="p-static">{{ video.legend }}</p>
      </div>

    </div>
  </div>

  <photo-gallery [photos]="format.photos"></photo-gallery>

  <div *ngFor="let post of format.posts" class="content-width" id="block-3">
    <div class="m-x-b w100">
      <div class="row">
        <div class="col col-12 bg-lighter-grey format-content">
          <h3
            class="section-title-small m-s-b"
            [ngStyle]="{'border-color': colors.primary}"
          >
            {{ post.title }}
          </h3>
          <p class="dynamic-content p-static" [innerHtml]="post.content | linky | sanitizeHtml"></p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="format?.docs?.length > 0" class="row content-width" id="block-6">
    <div class="row content-width">
      <div class="m-m-b w100">
        <div class="col col-12 bg-lighter-grey format-content">
          <h3
            class="section-title-small m-s-b"
            [ngStyle]="{'border-color': colors.primary}"
          >
           Outils disponibles
          </h3>
          <div class="row">
            <div class="col col-6 push-left">
              <div *ngFor="let doc of format.docs">
                <ng-template #downloadLabel>
                  <div class="download-label">
                    <h4>{{ doc.legend }}</h4>
                    <div class="label-arrow"></div>
                  </div>
                </ng-template>

                <p class="p-static dynamic-content">
                  <a [href]="doc.slug" target="_blank" rel="noopener" [attr.aria-label]="doc.legend" data-microtip-position="right" role="tooltip">- {{ doc.title }}</a>
                </p>
              </div>
            </div>

            <div class="col col-6 text-right push-right">
              <img src="/assets/images/formats/download.svg" fillSvgColors="colors.primary" alt="Télécharger les outils Kawaa" class="push-right medium-img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(meetsCollection?.meta | async).total > 0" class="row content-width" id="block-9">
    <h3
      class="section-title m-m-b"
      [ngStyle]="{'border-color': colors.primary}"
    >
      Les derniers événements avec ce format
    </h3>
    <div class="row content-width">
      <meets-list
        [meetsCollection]="meetsCollection"
        [colors]="colors"
        (selectMeet)="onClickMeet($event)"
        (create)="onCreateMeet()"
      >
      </meets-list>
    </div>
  </div>
</div>
