<meet-banner
  [meet]="meet"
  [action]="action"
  [organizators]="organizators"
  [participants]="participants"
  [isMeetFetching]="isMeetFetching"
  [isOrganizatorsFetching]="isOrganizatorsFetching"
  [isParticipantsFetching]="isParticipantsFetching"
  [viewType]="viewType$ | async"
  [userAsParticipant]="userAsParticipant"
></meet-banner>

<div
  class="row align-middle"
  [ngClass]="{
    'content-width p-b-60': (viewType$ | async) == 'desktop',
    'm-s-t m-s-l m-s-r': (viewType$ | async) == 'mobile'
  }"
  [ngStyle]="{ 'margin-bottom': (viewType$ | async) == 'mobile' && '75px'}"
>
  <!-- >>> MAIN LAYOUT -->
  <div class="col col-12 main-meet-section">
    <div class="row" [ngClass]="{'gutters': (viewType$ | async) == 'desktop'}">
      <div class="col col-9" [class.m-s-b]="(viewType$ | async) == 'mobile'">

        <confirm-participation
         *ngIf="meet && (action == 'going' || action == 'invitation')"
          [meet]="meet"
          [action]="action"
          (confirm)="onJoinMeet($event)"
          (cancel)="closeConfirm()"
        ></confirm-participation>

        <div *ngIf="!action">
          <meet-description
            [meet]="meet"
            [isMeetFetching]="isMeetFetching"
            [organizators]="organizators"
            [isOrganizatorsFetching]="isOrganizatorsFetching"
            [viewType]="viewType$ | async"
          ></meet-description>
          <snippet-participant
            *ngIf="(isMeetFetching || (participants?.length && meet?.participants_count / meet?.options?.max_participants > 0.3))
            && (viewType$ | async) == 'mobile'"
            [participantCollection]="participantCollection"
            [participantsCount]="meet?.participants_count"
            [participantsMax]="meet?.options?.max_participants"
            [viewType]="viewType$ | async"
          ></snippet-participant>
          <meet-comments
            [userAsParticipant]="userAsParticipant"
          ></meet-comments>
          <meet-place
            *ngIf="meet?.mode == 'onsite' || meet?.mode == 'hybrid'"
            [meet]="meet"
            [isMeetFetching]="isMeetFetching"
            [viewType]="viewType$ | async"
          ></meet-place>
        </div>
      </div>
      <!-- <<< MAIN LAYOUT -->

      <!-- >>> ASIDE LAYOUT / widgets -->
      <div class="col col-3 aside-meet-section">
        <ng-container *ngFor="let snippet of snippets">
          <ng-container *ngIf="snippet.name === 'share' && snippet.display">
            <snippet-share
              [meet]="meet"
              *ngIf="(viewType$ | async) == 'desktop'"
            ></snippet-share>
          </ng-container>

          <ng-container *ngIf="snippet.name === 'price' && snippet.display">
            <snippet-price
              *ngIf="isMeetFetching || isMeetPaid"
              [meetOptions]="meet?.options"
            ></snippet-price>
          </ng-container>

          <ng-container *ngIf="snippet.name === 'group' && snippet.display">
            <snippet-group
              *ngIf="isMeetFetching || meet?.groups?.length"
              [title]="snippet.title"
              [groups]="meet?.groups"
            ></snippet-group>
          </ng-container>

          <ng-container *ngIf="snippet.name === 'format' && snippet.display">
            <snippet-format
              *ngIf="isMeetFetching || meet?.animation_format"
              [format]="meet?.animation_format"
            ></snippet-format>
          </ng-container>

          <ng-container *ngIf="snippet.name === 'participant' && snippet.display">
            <snippet-participant
              *ngIf="isMeetFetching || participants?.length && (viewType$ | async) == 'desktop'"
              [participantCollection]="participantCollection"
              [participantsCount]="meet?.participants_count"
              [viewType]="viewType$ | async"
            ></snippet-participant>
          </ng-container>

          <ng-container *ngIf="snippet.name === 'tags' && snippet.display">
            <snippet-tags
              *ngIf="isMeetFetching || meet?.tags?.length"
              [tags]="meet?.tags"
            ></snippet-tags>
          </ng-container>
        </ng-container>
      </div>
      <!-- <<< ASIDE LAYOUT / widgets -->
    </div>
  </div>
</div>
