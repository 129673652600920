import { NgModule }  from '@angular/core';

import { SharedModule }  from '../shared/shared.module';
import { MeetService } from './meet.service';
import { MeetPageModule } from './meet-page/meet-page.module';

import { MeetsMapComponent } from './meets-map.component';
import { MeetsListComponent } from './meets-list.component';
import { MeetDetailsBoxComponent } from './meet-details-box.component';
import { MeetsBoardComponent } from './meets-board.component';
import { MeetsScopeStore } from './meets-scope.store';
import { MeetFeedbackComponent } from './meet-feedbacks.component';

@NgModule({
  imports: [
    SharedModule,
    MeetPageModule
  ],
  declarations: [
    MeetsListComponent,
    MeetsMapComponent,
    MeetDetailsBoxComponent,
    MeetsBoardComponent,
    MeetFeedbackComponent,
  ],
  exports: [
    MeetsListComponent,
    MeetsMapComponent,
    MeetDetailsBoxComponent,
    MeetsBoardComponent,
    MeetFeedbackComponent,
  ],
  providers: [
    MeetService,
    MeetsScopeStore
  ],
})
export class MeetsModule { }
