
import { of as observableOf,  Observable } from 'rxjs';

import { first, mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable }  from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,
  RouterStateSnapshot }  from '@angular/router';

import { AuthService }  from './auth.service';
import { ISuccess }  from '../shared';
import { AppStore }  from '../store/';

@Injectable()
export class AuthAccessGuard implements CanActivate {

  constructor(
    private _router:              Router,
    private _appStore:            AppStore,
    private _authService:      AuthService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const groupId = route.queryParams['_'] ? route.queryParams['_'] : route.params['id'];

    if (!groupId)
      return this._authService.universalAuth().pipe(map(_ => _.success));

    return this._appStore.load('Group', groupId)
      .entity.pipe(
      catchError(_ => observableOf(null)),
      mergeMap(group => {
        if (group && group.options && group.options.auth_schema && group.options.auth_schema.howtank_connect) {
          return this._authService.howtankConnectAuth(group);
        }
        else if (group && group.options && group.options.auth_schema && group.options.auth_schema.paris_connect) {
          return this._authService.parisConnectAuth();
        }
        else {
          return this._authService.universalAuth();
        }
      }),
      map((result: ISuccess) => {
        if (result.success) {
          return true;
        }
        else {
          return false;
        }
      }),
      first(),);
  }
}
