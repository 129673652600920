import {
  Component,
  OnChanges,
  SimpleChanges,
  Input
}  from '@angular/core';

import {
  Resource
}  from '../../definitions';

@Component({
  selector: 'photo-gallery',
  template: `
  <div class="m-m-t m-m-b">
    <div class="row content-width format-pictures" id="block-2">
      <div class="col col-4 push-left little-pictures">
        <div class="row">
          <div *ngFor="let photo of photos; let i = index" (click)="onClickPhoto(i)" class="col col-6 little-pictures-tiles">
            <img [src]="photo.slug" [alt]="photo.title"/>
          </div>
        </div>
      </div>
      <div *ngIf="selectedPhoto" class="col col-7 main-picture">
        <img [src]="selectedPhoto.slug" [alt]="selectedPhoto.title" />
        <div class="desc-main-pictures">
          <h4>{{ selectedPhoto.title }}</h4>
          <p>{{ selectedPhoto.legend }}</p>
        </div>
      </div>
    </div>
  </div>
  `
})
export class PhotoGalleryComponent implements OnChanges {
  @Input() photos:  Resource[] = [];

  selectedPhoto:    Resource;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['photos'] && changes['photos']['currentValue'] && changes['photos']['currentValue'].length > 0)
      this.selectedPhoto = changes['photos']['currentValue'][0];
  }

  onClickPhoto(clickedPhotoIndex: number): void {
    if (this.photos && this.photos.length > clickedPhotoIndex) {
      this.selectedPhoto = this.photos[clickedPhotoIndex];
    }
  }
}
