import { Component } from '@angular/core';

// Definitions
import { Tag } from '../../../shared/definitions';

@Component({
  selector: 'snippet-loader',
  templateUrl: './snippet-loader.component.html'
})
/**
 * Generic and dumb widget loader component
 * On title, on full line, on half line.
 */
export class SnippetLoaderComponent {}
