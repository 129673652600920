
import { of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnChanges, OnInit }  from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User, ITranslatedValue, Member } from '../../shared';
import { Group, IAnswerField, Tag }  from '../../shared/definitions';
import { AppStore } from '../../store';
import { GroupsService } from '../groups.service';
import { ViewTypeService, IViewType } from '../../shared/view-type.service';
import { AuthRedirectService } from '../../auth/auth-redirect.service';
import { AuthService } from '../../auth';
import { MetaService } from 'src/app/core/meta.service';

interface IConfigField {
  hide?:    boolean;
  naming?:  ITranslatedValue;
}

@Component({
  selector: 'group-details',
  templateUrl: './group-details.component.html'
})

export class GroupDetailsComponent implements OnChanges, OnInit {

  @Input() group:             Group;
  @Input() sectionTitle:      ITranslatedValue;
  @Input() config:            { join_cta: IConfigField, hide_share: boolean, color: string };

  joinCta:                    IConfigField = {
    naming: {
      fr: 'Rejoindre',
      en: 'Join'
    },
    hide: true
  };
  hideShare:                  boolean;

  onHoverCTA:                 boolean;
  sliceDescription:           boolean = true;
  color:                      string = '';
  viewType$:                  Observable<IViewType>;

  facebookUrl:                string;
  twitterUrl:                 string;
  linkedinUrl:                string;
  instagramUrl:               string;

  showMembersCount:           boolean = false;

  otherExtraInfos:            Array<any> = [];

  member$:                    Observable<Member> = observableOf(null);

  constructor(
    private _viewType:        ViewTypeService,
    private _authService:     AuthService,
    private _appStore:        AppStore,
    private _groupsService:   GroupsService,
    private _metaService:     MetaService,
    private _route:           ActivatedRoute,
    private _router:          Router,
    private _authRedirectService: AuthRedirectService,
  ) {}

  ngOnInit() {
    this.viewType$ = this._viewType.get();
    const groupId = this._route.snapshot.params.id;

    this._groupsService.loadMyMember(groupId);
    this.member$ = this._groupsService.myMember$;
  }

  ngOnChanges() {
    if (this.group) {
      this.setSocialIcons(this.group);
      this.setMetaTags(this.group);

      if (this.group.members_count > 10 && this.group.meets_count < this.group.members_count / 3)
        this.showMembersCount = true;
    }
    if (this.config && this.config.join_cta) {
      this.joinCta = this.config.join_cta;
    }
    if (this.config && this.config.hide_share) {
      this.hideShare = true;
    }
    if (this.config && this.config.hide_share) {
      this.hideShare = true;
    }

    if (this.group && this.group.options && this.group.options.colors) {
      this.color = this.group.options.colors.primary;
    }
    else if (this.config && this.config.color) {
      this.color = this.config.color;
    }
  }

  setGroupBackground(group: Group) {
    if (group && group.photos && group.photos.length > 0 && group.photos[0].category != 'default_group_photo') {
      return { 'background-image': `url(${group.photos[0].slug})` };
    }
    else if (this.config && this.config.color ) {
      return { 'background':  this.config.color };
    }
    else if (group && group.options && group.options.colors) {
      return { 'background':  group.options.colors.primary };
    }
    else {
      return {};
    }
  }

  setSocialIcons(group: Group) {
    if (group.extra_infos) {
      group.extra_infos.forEach((info: IAnswerField) => {
        if (info.name == 'facebook' && info.value) {
          this.facebookUrl = info.value.match('^.*\/\/.*') ? info.value : '//' + info.value;
        }
        else if (info.name == 'twitter' && info.value) {
          this.twitterUrl = info.value.match('^.*\/\/.*') ? info.value : '//' + info.value;
        }
        else if (info.name == 'linkedin' && info.value) {
          this.linkedinUrl = info.value.match('^.*\/\/.*') ? info.value : '//' + info.value;
        }
        else if (info.name == 'instagram' && info.value) {
          this.instagramUrl = info.value.match('^.*\/\/.*') ? info.value : '//' + info.value;
        }
      });
    }
  }

  setMetaTags(group: Group) {
    if (group.description) {
      // remove html tags and slice
      const desc: string = group.description.replace(/<.*?>/g, '').slice(0, 140) + '...';

      this._metaService.setTag('description', desc);
      this._metaService.setTag('og:description', desc);
      this._metaService.setTag('twitter:description', desc);
    }
    if (group.photos && group.photos[0] && group.photos[0].slug) {
      this._metaService.setTag('og:image', group.photos[0].slug);
      this._metaService.setTag('twitter:image', group.photos[0].slug);
    }

    if (group.tags) {
      let keywords: string = '';

      keywords += group.tags.map((tag: Tag) => tag.name.replace('#', '')).join(',');
      keywords += `,${group.name}`;

      if (group.location && group.location.city) {
        keywords += `,${group.location.city}`;
      }
      if (group.location && group.location.country) {
        keywords += `,${group.location.country}`;
      }

      this._metaService.setTag('keywords', keywords);
    }
  }

  joinGroup() {
    const groupId: string = this._route.snapshot.params.id;
    const user: User = this._authService.user;
    const member: Member = this._groupsService.myMember;

    if (!user.id) {
      this._authRedirectService.authenticateThenGoBack(
        { tab: 'register', warningMessage: 'need-authentication' },
        this._route.snapshot.queryParams['_']
      );
    }
    else if (member && member.status != 'active') {
      this._groupsService.updateMyMemberStatus(groupId, 'active')
        .subscribe(_ => this._appStore.reload('Members', groupId));
    }
    else {
      this._groupsService.createMyMember(groupId)
        .subscribe(_ => this._appStore.reload('Members', groupId));
    }
  }

  leaveGroup() {
    const groupId = this._route.snapshot.params.id;

    this._groupsService.updateMyMemberStatus(groupId, 'ghost')
        .subscribe(_ => this._appStore.reload('Members', groupId));
  }

  manageGroup() {
    const groupId = this._route.snapshot.params.id;
    const spaceId = this._route.parent.snapshot.params.id;

    this._router.navigate(['/admin', 'groupes', groupId], { queryParams: { '_': spaceId } });
  }
}
