import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface INotification {
  type: 'default' | 'error' | 'warning' | 'success';
  content: string;
}
@Injectable()
export class NotificationService {
  private _notification$: ReplaySubject<INotification>;

  constructor() {
    this._notification$ = new ReplaySubject();
  }

  get notification$() {
    return this._notification$.asObservable();
  }

  setNotification(n: INotification, duration: number = 5000) {
    this._notification$.next(n);
    setTimeout(
      () => this._notification$.next(null),
      duration,
    );
  }
}
