import { Component, Input } from '@angular/core';

@Component({
  selector: 'group-meets-carousel',
  templateUrl: './group-meets-carousel.component.html'
})

export class GroupMeetsCarouselComponent {

  @Input() config: any = {};

  index = 0;

  nextSlide() {
    this.index++;
    if (this.index >= this.config.progContents.length) {
      this.index = 0;
    }
  }

  previousSlide() {
    this.index = this.index > 0 ? this.index - 1 : this.index = 5;
  }
}
