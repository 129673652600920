import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { MeetsModule } from '../meets/';
import { GroupsModule } from '../groups/';
import { FormatsModule } from '../formats/formats.module';

import { CustomComponent } from './custom.component';
import { CustomPageComponent } from './custom-page.component';

import { CustomService } from './custom.service';

import { customRouting } from './custom.routing';
import { AuthModule } from '../auth/auth.module';
import { HeadersFootersModule } from '../headers-footers/headers-footers.module';

@NgModule({
  imports:      [
    AuthModule,
    SharedModule,
    GroupsModule,
    MeetsModule,
    FormatsModule,
    HeadersFootersModule,
    customRouting,
  ],
  declarations: [
    CustomComponent,
    CustomPageComponent,
  ],
  providers:    [
    CustomService,
  ]
})
export class CustomModule { }
