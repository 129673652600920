import { FormControl, FormGroup, AbstractControl } from '@angular/forms';

export function validateEmail(c: FormControl) {
  const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return EMAIL_REGEXP.test(c.value) ? null : {
    validateEmail: {
      valid: false
    }
  };
}

// *1
export function validatePhoneNumber(c: FormControl) {
  const PHONE_REGEXP = /^[0-9+\(\)#\.\s\/ext-]+$/;

  return PHONE_REGEXP.test(c.value) ? null : {
    validatePhoneNumber: {
      valid: false
    }
  };
}

export function validateTimeFormat(fc: AbstractControl): any {
  if (fc.value.length != 5)
    return { timeFormat: { valid: false } };

  const HOUR_REGEXP = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return HOUR_REGEXP.test(fc.value) ? null : {
    timeFormat: {
      valid: false
    }
  };
}

export function validateDatetime(fg: FormGroup): any {
  const start = fg.get('startDate').value;
  const end = fg.get('endDate') ? fg.get('endDate').value : null;

  if (fg.get('startTime')) {
    const startTime = fg.get('startTime').value;

    start.setHours(startTime.slice(0, 2));
    start.setMinutes(startTime.slice(3, 5));
  }

  if (end && fg.get('endTime')) {
    const endTime = fg.get('endTime').value;

    end.setHours(endTime.slice(0, 2));
    end.setMinutes(endTime.slice(3, 5));
  }

  let errors: any = {};
  if (start <= new Date()) {
    errors.futureDatetime = { valid: false };
  }

  if (end && start >= end) {
    errors.startIsBeforeEnd = { valid: false };
  }

  if (Object.keys(errors).length == 0) {
    return null;
  }
  else {
    return errors;
  }
}

export function validateChangingPassword(fg: FormGroup): any {
  if (fg.get('newPassword').value != fg.get('confirmPassword').value)
    return { matchingPassword: { valid: false } };
}
