<!-- Popup overlay -->
<div
  *ngIf="showConfInfo"
  class="pos-f w100 h100 z-over-all"
  style="background-color: rgba(177, 177, 177, 0.6); top:0;"
  (click)="showConfInfo = !showConfInfo"
></div>
<!--
  DESKTOP TEMPLATE
-->
<div *ngIf="viewType == 'desktop'" class="w100 meet-page">
  <div class="container m-xs-b" *ngIf="(meet?.mode == 'online' || meet?.mode == 'hybrid') && timeStep != 'other'">
    <div class="row align-middle content-width">
      <div class="col col-12">
        <!-- Afficher le bandeau le jour même de la conférence -->
        <div class="green-alert w100 flex">
          <div class="green-alert-text">
            <span *ngIf="timeStep == 'before-start'">
              {{ 'MEET_PAGE.visio-will-start' | translate }}
            </span>
            <span *ngIf="timeStep == 'in-progress'">
              {{ 'MEET_PAGE.visio-has-started' | translate }}
            </span>
          </div>

          <a
            *ngIf="!conferenceUrl"
            [routerLink]="['/home', theme.group_id, 'visio', meet.id]"
            class="button btn-validate"
          >
            <span><i class="far fa-video p-r-10" aria-hidden="true"></i></span>
            <span>{{ 'MEET_PAGE.access-visio' | translate }}</span>
          </a>
          <button
            *ngIf="conferenceUrl"
            (click)="showConfInfo = !showConfInfo"
            class="button btn-validate"
          >
            <span><i class="far fa-video p-r-10" aria-hidden="true"></i></span>
            <span>{{ 'MEET_PAGE.access-visio' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container m-x-b">
    <div class="row align-middle content-width">
      <div class="col col-12">
        <div class="row">
          <div
            class="col"
            [ngClass]="{
              'col-12': !showParticipantsNb,
              'col-9': showParticipantsNb
            }"
          >
            <div
              class="banner-img border-t border-l"
              [ngClass]="{ 'banner-img__full border-r': !showParticipantsNb }"
              [style.background-image]="'url(' + (meetBannerUrl | imageResize:1200:401) + ')' ">
              <h1>{{ meet?.name }}</h1>
            </div>
          </div>

          <div
            *ngIf="showParticipantsNb"
            class="col col-3 banner-aside"
          >
            <div class="banner-aside-title" >
              <h2 class="text-center">{{ 'MEET_PAGE.join-them' | translate }}</h2>
            </div>

            <div class="banner-aside-stats">
              <div
                class="stats"
                [class.is-fetching]="isMeetFetching"
              >
              <span class="number">{{ meet.participants_count }}</span>
              <span *ngIf="meetParticipantsMax && meet.mode !== 'hybrid'">
                / {{ meetParticipantsMax }}
              </span>
              </div>
              <div class="stats-legend">
                {{ 'MEET_PAGE.participants' | translate }}
              </div>
            </div>

            <div class="banner-aside-participants">
              <div
                *ngIf="visibleParticipants.length >= 2"
                class="participants"
              > <!-- TODO isMeetFetching -->
                <div
                  *ngFor="let participant of visibleParticipants"
                  class="face-img"
                  [style.border-color]="theme?.colors?.primary"
                >
                  <!--
                    Image is oversized, but will be the same as the one in
                    snippet-participants.component. So we will load it only once.
                   -->
                  <img
                    [lazyLoad]="participant?.member?.avatar?.slug | imageResizeWidth:90"
                    [defaultImage]="'/assets/images/avatar_default.jpg'"
                    [alt]="getParticipantShortName(participant)"
                    (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="sticky-header"
        class="col col-12 infos-join"
      >
        <!-- >>> Classic header with details -->
        <div class="row" id="confirmation">
          <div class="col col-9 banner-infos">
            <div class="row gutters auto">
              <div class="col">
                <div class="banner-info-section">
                  <!--
                    Image is oversized, but will be the same as the one in
                    snippet-participants.component. So we will load it only once.
                   -->
                  <img
                    class="img-organisator"
                    [alt]="organizatorFullName"
                    [lazyLoad]="organizatorPictureSlug | imageResizeWidth:90"
                    [defaultImage]="'/assets/images/avatar_default.jpg'"
                    (error)="$event.target.src = '/assets/images/avatar_default.jpg'"
                    [style.border-color]="theme?.colors?.primary"
                  />
                  <div class="banner-info-desc">
                    <legend>
                      {{ 'MEET_PAGE.organizer' | translate }}
                    </legend>
                    <div [class.is-fetching]="isOrganizatorsFetching">
                      <a
                        [routerLink]="['/profile', organizatorId, 'organisations']"
                        [queryParams]="{'_': theme?.group_id}"
                        class="underline"
                      >
                        {{ organizatorFullName }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="banner-info-section">
                  <i
                    class="fal fa-calendar-alt"
                    aria-hidden="true"
                    [style.color]="theme?.colors?.primary"
                  ></i>
                  <div class="banner-info-desc">
                    <legend>
                      {{ 'MEET_PAGE.when' | translate }}
                    </legend>
                    <div [class.is-fetching]="isMeetFetching">
                      <span>
                        {{ meetDateTime | meetDate }}
                        {{ 'MEET_PAGE.at' | translate }}
                        {{ meetDateTime | meetTime }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="banner-info-section">
                  <i
                    *ngIf="meet?.mode == 'onsite'"
                    class="fal fa-map-marker-alt"
                    aria-hidden="true"
                    [style.color]="theme?.colors?.primary"
                  ></i>
                  <i
                    *ngIf="meet?.mode == 'online'"
                    class="far fa-video"
                    aria-hidden="true"
                    [style.color]="theme?.colors?.primary"
                  ></i>
                  <i
                    *ngIf="meet?.mode == 'hybrid'"
                    class="far fa-map-marker-alt"
                    aria-hidden="true"
                    [style.color]="theme?.colors?.primary"
                  ></i>
                  <div class="banner-info-desc">
                    <legend>
                      {{ 'MEET_PAGE.where' | translate }}
                    </legend>
                    <div [class.is-fetching]="isMeetFetching">
                      <ng-container *ngTemplateOutlet="meetMode"></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <primary-button
            *ngIf="!action"
            class="col col-3 participate-btn"
            [userAsParticipant]="userAsParticipant"
            [viewType]="viewType"
            [meet]="meet"
          ></primary-button>
        </div>

        <!-- <<< Classic header with details -->
        <div class="col col-12 bg-white" [class.sticky]="shouldHeaderBeSticky" *ngIf="shouldHeaderBeSticky">
          <!-- >>> Sticky Header with title only -->
          <div class="row overlay-white-gradient-bottom">
            <div class="col col-9 banner-infos">
              <div class="row">
                <div class="col col-12">
                  <div class="banner-info-section">
                    <div class="banner-info-desc">
                      <h1>
                        {{ meet?.name }}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <primary-button
              class="col col-3 participate-btn"
              [class.pulse]="shouldHeaderBeSticky"
              [userAsParticipant]="userAsParticipant"
              [viewType]="viewType"
              [meet]="meet"
            ></primary-button>
          </div>
          <!-- <<< Sticky Header with title only -->
        </div>

      </div>
    </div>
  </div>
</div>

<!--
  MOBILE TEMPLATE
-->
<div
  *ngIf="viewType == 'mobile'"
  class="w100 meet-page m-view"
  id="confirmation"
>
  <div
    class="banner-img"
    [style.background-image]="'url(' + (meetBannerUrl | imageResizeWidth:768) + ')' "
  ></div>

  <div class="banner-infos">
    <div
      class="banner-info-section meet-title"
      [class.is-fetching]="isMeetFetching"
      [style.background-color]="theme?.colors?.primary"
    >
      <h1>{{ meetName }}</h1>
    </div>
  </div>

  <div class="banner-infos">
    <div class="banner-info-section">
      <i
        class="fal fa-calendar-alt"
        aria-hidden="true"
        [style.color]="theme?.colors?.primary"
      ></i>
      <div
        class="banner-info-desc"
        [class.is-fetching]="isMeetFetching"
      >
        <span>{{ meetDateTime | meetDate }} à {{ meetDateTime | meetTime }}</span>
      </div>
    </div>
  </div>

  <div class="banner-infos">
    <div class="banner-info-section">
      <i
        *ngIf="meet?.mode == 'onsite'"
        class="fal fa-map-marker-alt"
        aria-hidden="true"
        [style.color]="theme?.colors?.primary"
      ></i>
      <i
        *ngIf="meet?.mode == 'online'"
        class="far fa-video"
        aria-hidden="true"
        [style.color]="theme?.colors?.primary"
      ></i>
      <i
        *ngIf="meet?.mode == 'hybrid'"
        class="far fa-map-marker-alt"
        aria-hidden="true"
        [style.color]="theme?.colors?.primary"
      ></i>
      <div
        class="banner-info-desc"
        [class.is-fetching]="isMeetFetching"
      >
        <ng-container *ngTemplateOutlet="meetMode"></ng-container>
      </div>
    </div>
  </div>

  <primary-button
    class="w100 participate-btn"
    *ngIf="!action && meet"
    [userAsParticipant]="userAsParticipant"
    [viewType]="viewType"
    [meet]="meet"
  ></primary-button>
</div>

<!-- popup Visio-->
<div
  [ngClass]="{ 'hide': !showConfInfo, 'show': showConfInfo }"
  class="pos-f bg-white borders-all border-radius p-tb-30 p-lr-30"
  style="height: auto; width: 400px;top: 50%;left: 50%;transform: translate(-50%, -50%); z-index: 1000;"
>
  <i
    class="far fa-times-circle pos-a text-big text-error pointer"
    style="top: 10px; right: 10px;"
    (click)="showConfInfo = !showConfInfo"
  ></i>
  <ng-container *ngIf="userAsParticipant?.status == 'active'">
    <p class="text-big bold m-xs-b">
      Afin de rejoindre la conférence cliquez sur le lien suivant :
    </p>
    <a
      class="text-base break-all pointer underline text-center flex border-radius borders-all p-lr-10 p-tb-10"
      *ngIf="confUrlIsValid"
      [href]="conferenceUrl"
      target="_blank"
    >
      {{ conferenceUrl }}
    </a>
    <span
      class="text-base break-all text-center flex border-radius borders-all p-lr-10 p-tb-10"
      *ngIf="!confUrlIsValid"
    >
      {{ conferenceUrl }}
    </span>
    <ng-container *ngIf="meet?.options?.conference_info">
      <p class="m-m-t text-big bold">
        Voici les informations supplémentaires laissées par l'organisateur :
      </p>
      <p class="m-xs-t">
        {{ meet.options.conference_info }}
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="userAsParticipant?.status == 'pending'">
    <p class="text-big">
      <i class="far fa-exclamation-triangle m-xs-r"></i>
      Vous n'avez pas accès au lien de la visioconférence parce que l'organisateur n'a pas encore validé votre inscription.
    </p>
  </ng-container>

  <ng-container *ngIf="userAsParticipant?.status == 'invited'">
    <p class="text-big">
      <i class="far fa-smile-wink m-xs-r"></i>
      Pour accéder au lien de la visioconférence, acceptez l'invitation de l'organisateur.
    </p>
  </ng-container>

  <ng-container *ngIf="userAsParticipant?.status == 'refused'">
    <p class="text-big">
      <i class="far fa-exclamation-triangle m-xs-r"></i>
      Vous n'avez pas accès au lien de la visioconférence parce que votre inscription a été refusée.
    </p>
  </ng-container>

  <ng-container *ngIf="userAsParticipant?.status == 'suspended'">
    <p class="text-big">
      <i class="far fa-exclamation-triangle m-xs-r"></i>
      Vous n'avez pas accès au lien de la visioconférence parce que votre inscription a été suspendue.
    </p>
  </ng-container>

  <ng-container *ngIf="!userAsParticipant">
    <p class="text-big">
      <i class="far fa-smile-wink m-xs-r"></i>
      Pour accéder au lien de la visioconférence, inscrivez-vous à l'événement.
    </p>
  </ng-container>
</div>

<ng-template #meetMode>
  <a *ngIf="meet?.mode == 'onsite'" [routerLink]="['.', {}]" fragment="lieu">
    <span class="underline">{{ meetPlaceName }}</span>
    <span *ngIf="meetPlaceCity">, {{ meetPlaceCity }}</span>
  </a>
  <ng-container *ngIf="meet?.mode == 'online'">
    <a
      *ngIf="!conferenceUrl"
      [routerLink]="['/home', theme.group_id, 'visio', meet.id]"
    >
      <span class="underline">{{ 'MEET_PAGE.meet-online' | translate }}</span>
    </a>
    <span
      *ngIf="conferenceUrl"
      (click)="showConfInfo = !showConfInfo"
      class="underline pointer"
    >
      {{ 'MEET_PAGE.meet-online' | translate }}
    </span>
  </ng-container>

  <ng-container *ngIf="meet?.mode == 'hybrid'">
    <a
      *ngIf="!conferenceUrl"
      [routerLink]="['/home', theme.group_id, 'visio', meet.id]"
    >
      <span class="underline">{{ 'MEET_PAGE.meet-online' | translate }}</span>
    </a>
    <span
      *ngIf="conferenceUrl"
      (click)="showConfInfo = !showConfInfo"
      class="underline pointer"
    >En ligne</span>
     ou
     <a [routerLink]="['.', {}]" fragment="lieu">
      <span class="underline">{{ meetPlaceName }}</span>
      <span *ngIf="meetPlaceCity">, {{ meetPlaceCity }}</span>
    </a>
  </ng-container>
</ng-template>
