<div
  class="row align-middle"
  [ngClass]="postClasses"
  [ngStyle]="post.options?.post_styles"
>
  <div class="col" [ngClass]="contentClasses">
    <h2
      class="text-large text-dark-black p-tb-30"
      *ngIf="post.options?.title != 'hide'"
    >
      {{ post.title }}
    </h2>
    <div class="w100">
      <p
        *ngIf="post?.content"
        [ngClass]="textClasses"
        [ngStyle]="post.options.text_styles"
        [innerHtml]="post.content | linky | sanitizeHtml"
      ></p>
      <div class="flex row-wrap text-center">
        <a
          *ngFor="let action of post.options?.actions"
          [href]="action.url"
          [target]="action.open_in_new_tab ? '_blank' : '_self'"
          [animation-scroll]="'block-appear-b-t'"
          [ngStyle]="post.options.action_styles"
          [ngClass]="actionClasses"
          class="m-s-r m-m-b"
        >
          <div *ngIf="action.kind == 'button'" class="button" [style.background-color]="colors.primary">
            {{ action.text }}
          </div>
          <div *ngIf="action.kind == 'image'">
            <img class="img-auto img-contain" [src]="action.image_slug" [alt]="action.text">
            <p *ngIf="action.text" [innerHtml]="action.text"></p>
          </div>
        </a>
      </div>
    </div>
    <div class="flex" *ngIf="post.resources?.length > 0">
      <div *ngFor="let resource of post.resources" class="dynamic-content m-s-r">
        <img class="img-auto img-contain" *ngIf="resource?.nature == 'photo'" [src]="resource.slug" [alt]="resource.title">
        <a *ngIf="resource?.nature == 'doc'" [href]="resource.slug" target="_blank" rel="noopener">{{ resource.legend }}</a>
      </div>
    </div>
  </div>
</div>
