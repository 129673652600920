<div class="append w100 w100-m">
	<input
		class="mc-input"
		type="text"
		[formControl]="term"
		(keydown)="addTag($event)"
		#inputTag
	/>
	<button
		type="button"
		class="button btn-white-black"
		(click)="pushTag(inputTag.value)"
	>
		<i class="fa fa-plus" aria-hidden="true"></i>
	</button>
</div>

<div [hidden]="term.value == '' || availableTags.length == 0">
	<ul>
		<li *ngFor="let item of items | async; let i=index" (click)="selectTag(item)">
			{{ item?.name }}
		</li>
	</ul>
</div>

<div class="searchbar-tags">
  <span class="loop-tags" *ngFor="let tag of tags">
    <div class="searchbar-tag" (click)="removeTag(tag)">
      <span>{{ tag?.name }}</span>
      <i class="m-xs-l fal fa-times-circle" aria-hidden="true"></i>
    </div>
  </span>
</div>
