import {
  Input,
  Output,
  Component,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  IColors,
  AnimationFormat,
  Group
} from '../shared/';

import {
  AppStore,
  Collection
} from '../store/';

@Component({
  selector: 'group-formats',
  templateUrl: '../../../src/app/groups/group-formats.component.html'
})


export class GroupFormatsComponent implements OnChanges  {

  @Input() group:         Group;
  @Input() colors:        IColors = { primary: '' };

  @Output() selectFormat: EventEmitter<AnimationFormat> = new EventEmitter<AnimationFormat>();

  collection:             Collection<AnimationFormat>;

  constructor(
    private _appStore:       AppStore
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.group) {
      this.setFormatsOf(this.group.id.toString());
    }
  }

  setFormatsOf(id: string) {
    this.collection = this._appStore.load('GroupAnimationFormats', id);
  }

  onSelectFormat(format: AnimationFormat): void {
    this.selectFormat.emit(format);
  }

  loadNextFormats(): void {
    this.collection.collectNext();
  }
}
