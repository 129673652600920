
import { pluck } from 'rxjs/operators';
import { Component, Input, OnChanges,
  OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

// Services
import { TranslateService }  from '@ngx-translate/core';

// Interfaces
import { Meet, Participant, ITheme } from '../../../shared/';

@Component({
  selector: 'meet-banner',
  templateUrl: './meet-banner.component.html',
})

export class MeetBannerComponent implements OnInit, OnChanges {
  @Input() meet:                   Meet;
  @Input() action:                 'going'|'invitation';
  @Input() organizators:           Participant[];
  @Input() participants:           Participant[];
  @Input() isMeetFetching:         boolean = true;
  @Input() isOrganizatorsFetching: boolean = true;
  @Input() isParticipantsFetching: boolean = true;
  @Input() viewType:               string;
  @Input() userAsParticipant:      Participant;

  /**
   * Data used in the template
   */
  // Coming from meet input
  meetBannerUrl:              string = '';
  meetDateTime:               string = '';
  meetParticipantsMax:        number = 0;
  meetPlaceName:              string = '';
  meetPlaceCity:              string = '';

  showParticipantsNb:       boolean = false;

  // Coming from organizator input
  organizatorId:          number = 0;
  organizatorFullName:    string = '';
  organizatorPictureSlug: string = '';

  // 5 hours before and 5 hours after
  timeStep:               'before-start'|'in-progress'|'other' = 'other';

  conferenceUrl:          string;
  confUrlIsValid:         boolean;

  // Coming from participants input
  visibleParticipants: Participant[] = [];

  shouldHeaderBeSticky:     boolean = false;
  notStickyHeaderOffsetTop: number;

  showConfInfo:             boolean = false;

  theme:     ITheme;

  constructor(
    private _translate: TranslateService,
    private _route: ActivatedRoute
  ) {
    _route.data
      .pipe(pluck('theme'))
      .subscribe((theme: ITheme) => this.theme = theme);
  }

  ngOnInit(): void {
    if (window) {
      const evtLstnr = window.addEventListener('scroll', () => this.checkIfHeaderShouldBeSticky());
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.meet && change.meet.currentValue) {
      this.updateMeetValues(change.meet.currentValue);
    }
    if (change.organizators && change.organizators.currentValue) {
      this.updateOrganisatorValues(change.organizators.currentValue);
    }
    if (change.participants && change.participants.currentValue) {
      this.updateParticipantsValues(change.participants.currentValue);
    }
  }

  updateMeetValues(meet: Meet): void {
    if (meet.photos.length > 0 && meet.photos[0].slug) {
      this.meetBannerUrl = meet.photos[0].slug;
    }
    if (meet.options.max_participants) {
      this.meetParticipantsMax = meet.options.max_participants;
    }
    if (meet.datetime) {
      this.meetDateTime = meet.datetime;

      const now = new Date();
      const mdt = new Date(meet.datetime);
      const distance = (mdt.getTime() - now.getTime()) / 1000;
        // 18 000 == 5 hours
      if (distance < 18000 && distance > 0) {
        this.timeStep = 'before-start';
      }
      else if (distance < 0 && distance > -18000) {
        this.timeStep = 'in-progress';
      }
    }

    if (meet.location) {
      if (meet.location.city)
        this.meetPlaceCity = meet.location.city;
      if (meet.location.name)
        this.meetPlaceName = meet.location.name;
    }
    else if (meet.place && meet.place.location) {
      const loc = meet.place.location;
      if (loc.city)
        this.meetPlaceCity = loc.city;
      if (loc.name)
        this.meetPlaceName = loc.name;
    }

    if (meet.options && meet.options.organizator) {
      this.organizatorFullName = meet.options.organizator;
    }

    if (meet.options && meet.options.conference_url) {
      this.conferenceUrl = meet.options.conference_url;
      this.confUrlIsValid = this.conferenceUrl.startsWith('http');
    }

    if (meet.options && meet.options.participants_counter && meet.options.participants_counter !== 'default') {
      this.showParticipantsNb = meet.options.participants_counter === 'show';
    }
    else if (meet.participants_count && meet.options && meet.options.max_participants) {
      const count = meet.participants_count;
      const max = meet.options.max_participants;
      this.showParticipantsNb = count / max > 0.3;
    }
    else if (meet.participants_count >= 3) {
      this.showParticipantsNb = true;
    }
  }

  updateOrganisatorValues(organisators: Participant[]) {
    // Name
    if (this.meet && this.meet.options && this.meet.options.organizator) {
      this.organizatorFullName = this.meet.options.organizator;
    }
    else if (organisators[0]) {
      const o = organisators[0].member;
      this.organizatorFullName = `${o.first_name} ${o.last_name && o.last_name[0]}`;
    }
    // Picture && id
    if (organisators[0]) {
      this.organizatorId = organisators[0].member.user_id;
      this.organizatorPictureSlug = organisators[0].member.avatar.slug;
    }
  }

  updateParticipantsValues(participants: Participant[]) {
    this.visibleParticipants = this.participants.slice(0, 5);
  }

  getParticipantShortName(p: Participant) {
    if (p === undefined) {
      return '';
    }
    const { first_name, last_name } = p.member;
    if (!first_name && !last_name) {
      if (p.role === 'organizator')
        return 'Organisateur';
      return 'Anonyme';
    }

    if (p.role === 'organizator') {
      const status = (this._translate.currentLang == 'en') ? 'organizer' : 'organisateur';
      if (this.meet.options.organizator)
        return this.meet.options.organizator + ' (' + status + ')';
      return `${first_name} ${last_name ? last_name[0] : ''} (${status})`;
    }
    return `${first_name} ${last_name ? last_name[0] : ''}`;
  }

  checkIfHeaderShouldBeSticky(): void {
    if (this.viewType == 'desktop') {
      if (!this.notStickyHeaderOffsetTop && document.getElementById('sticky-header')) {
        this.notStickyHeaderOffsetTop = document.getElementById('sticky-header').offsetTop;
      }
      this.shouldHeaderBeSticky = window.pageYOffset > this.notStickyHeaderOffsetTop && !this.action;
    }
  }
}
