import { Component, Renderer2, Inject, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '../core/';
import { AuthService } from '../auth/';
import { Group, Tag, Meet } from '../shared/definitions';
import { AppStore } from '../store/';
import { filter } from 'rxjs/operators';
import { Subscription, Observable, combineLatest as observableCombineLatest, BehaviorSubject  } from 'rxjs';

@Component({
  template: `
    <custom-header [group]="group"></custom-header>
    <div class="content premium-account">
      <router-outlet></router-outlet>
    </div>
    <custom-footer></custom-footer>
  `
})

export class CustomComponent implements OnInit, OnDestroy {

  group: Group;

  currentUrl$:   BehaviorSubject<string> = new BehaviorSubject<string>('');
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(DOCUMENT)
    private _doc:                   any,
    private _customEl:              ElementRef,
    private _renderer:              Renderer2,
    private _route:                 ActivatedRoute,
    private _router:                Router,
    private _translate:             TranslateService,
    private _metaService:           MetaService,
    private _authService:           AuthService,
    private _appStore:              AppStore
  ) {
    this.determineLang();
  }

  ngOnInit() {
    const id = this._route.snapshot.params['id'];
    const group$: Observable<Group> = this._appStore.load('Group', id).entity;

    const sub1 = this._router.events
      .pipe(
        filter((ev: any) => ev instanceof NavigationEnd)
      )
      .subscribe((ev: any) => {
        const tree = this._router.parseUrl(ev.url);
        const currentUrl: string = tree.root.children['primary'].segments.map(_ => _.path).join('/');
        this.currentUrl$.next(currentUrl);
      });

    const sub2 = observableCombineLatest(group$, this.currentUrl$)
      .subscribe(([group, currentUrl]) => {
        this.group = group;

        this.setMetaTags(group);

        //if (group && group.options && group.options.howtank_widget) {
          //this.howtankWidget('init');
        //}

        if (group.options && group.options.font) {
          this.changeFont(group.options.font);
        }

        const host: string = group.options && group.options.own_url ? group.options.own_url : 'https://www.kawaa.co/'

        let url: string;
        if (currentUrl == '') {
          url = host + this._router.url.replace(/^\//, '');
        }
        else {
          url = host + currentUrl;
        }
        this._metaService.setTag('og:url', url);
        this._metaService.setLinkTag({ rel: 'canonical', href: url })

        if (group.options && group.options.matomo_tag_manager_id) {
          this._metaService.setMatomoTagManager(group.options.matomo_tag_manager_id); 
        }
      });

    this.subscriptions.push(sub1, sub2);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(_ => _.unsubscribe());
  }

  setMetaTags(group: Group) {
    this._metaService.setTitle(group.name);
    this._metaService.setGropHomeStructuredData(group);

    if (group.options && group.options.colors) {
      this._metaService.setTag('msapplication-TileColor', group.options.colors.primary);
      this._metaService.setTag('theme-color', group.options.colors.primary);
    }

    if (group.options && group.options.favicon_dir) {
      this._metaService.setFavicon(group.options.favicon_dir);
    }

    if (group.description) {
      // remove html tags and slice
      const desc: string = group.description.replace(/<.*?>/g, '').slice(0, 140) + '...';

      this._metaService.setTag('description', desc);
      this._metaService.setTag('og:description', desc);
      this._metaService.setTag('twitter:description', desc);
    }

    if (group.photos && group.photos[0] && group.photos[0].slug) {
      this._metaService.setTag('og:image', group.photos[0].slug);
      this._metaService.setTag('twitter:image', group.photos[0].slug);
    }

    if (group.access == 'general') {
      this._metaService.setTag('robots', 'index, follow');
    }

    if (group.tags) {
      let keywords: string = '';

      keywords += group.tags.map((tag: Tag) => tag.name.replace('#', '')).join(',');
      keywords += `,${group.name}`;

      if (group.location && group.location.city) {
        keywords += `,${group.location.city}`;
      }
      if (group.location && group.location.country) {
        keywords += `,${group.location.country}`;
      }

      this._metaService.setTag('keywords', keywords);
    }
  }

  determineLang() {
    const lang = this._route.snapshot.firstChild.data['lang'];
    const sub = this._authService.getUser()
      .subscribe(user => {
        if (lang) {
          this._translate.use(lang);
        }
        else if (user.id && user.locale) {
          this._translate.use(user.locale);
        }
        else if (!user.id && this._translate.getBrowserLang()) {
          this._translate.use(this._translate.getBrowserLang());
        }
      });

    this.subscriptions.push(sub);
  }

  changeFont({ url, fontFamily }) {
    const node = this._renderer.createElement('link');
    node.href = url;
    node.rel = 'stylesheet';
    this._renderer.appendChild(this._doc.head, node);

    if (fontFamily) {
      this._renderer.setStyle(this._customEl.nativeElement, 'font-family', fontFamily);
    }
  }
}
