<div class="content-width m-m-tb">
  <h3 class="section-title" [style.border-bottom-color]="color">
    {{ sectionTitle | customTranslate }}
  </h3>
  <div class="col col-12">
    <ng-container *ngFor="let queryForm of queryForms$ | async; let i = index">
      <div
        class="borders-all border-radius row m-m-tb align-top align-middle text-center"
        style="position: relative"
        >
        <div class="query-form-number" >
          #{{ i + 1 }}
        </div>
        <ng-container
          *ngFor="let info of queryForm.queries"
          [ngSwitch]="typeOf(info.value)"
        >

          <ng-container *ngSwitchCase="'array'">
            <div *ngIf="info.value?.length > 0" class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
              <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title | customTranslate }}</b></p>
              <div class="row gutters auto text-center">
                <div
                  *ngFor="let value of info.value"
                  class="borders-all bg-white p-tb-5 p-lr-5 m-xxs-b m-xxs-r border-radius text-base"
                >
                  {{ value | customTranslate }}
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'object'">
            <div class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
              <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title | customTranslate }}</b></p>

              <div class="borders-all bg-white p-tb-5 p-lr-5 m-xxs-b m-xxs-r border-radius text-base text-center">
                {{ info.value | customTranslate }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'string'">
            <div *ngIf="info.value != ''" class="col col-3 p-lr-10 p-tb-10 m-xs-tb m-s-lr text-center bg-lighter-grey">
              <p class="p-static m-xs-b" [style.color]="color"><b>{{ info.title | customTranslate }}</b></p>
              <div class="row gutters auto text-center">
                <div class="p-tb-5 p-lr-5 m-xxs-b m-xxs-r border-radius text-base">
                  {{ info.value }}
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>
      </div>

    </ng-container>
  </div>
</div>


