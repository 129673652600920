<!-- Orga view -->
<!-- Step 1 : In future -->
<div class="row p-t-30" *ngIf="step == 'in-future'">
  <div class="col col-11 gutters align-center">
    <p class="text-center text-large m-s-b">
      <b>{{ 'VISIO_ONBOARDING.ready' | translate }}</b>
    </p>
  </div>

  <div
    class="col col-11 bg-color-profile push-center border-radius borders-all m-xs-t m-x-b"
    [ngClass]="{
      'p-tb-30 p-lr-60': (viewType$ | async) === 'desktop',
      'p-tb-20 p-lr-20': (viewType$ | async) === 'mobile'
    }"
  >
    <p class="p-static p-b-10" [ngClass]="{'text-center': (viewType$ | async) === 'desktop'}">
      <span class="bold">
        <a
          href="https://www.notion.so/kawaaco/Kawaa-Live-342669aaa7e24180bc55092b87cafb5d"
          target="_blank"
          class="link-underline"
        >
          {{ 'VISIO_ONBOARDING.discover' | translate }}
        </a>
      </span>
        {{ 'VISIO_ONBOARDING.and' | translate }}
      <span class="bold">
        <a
          href="https://www.notion.so/kawaaco/Comment-animer-son-v-nement-virtuel-639a0485faa74d78894ddd0afd0b1e40"
          target="_blank"
          class="link-underline"
        >
          {{ 'VISIO_ONBOARDING.inspiration' | translate }}
        </a>
      </span>,
      <br/>
      {{ 'VISIO_ONBOARDING.conception' | translate }}
    </p>

    <p class="p-static" [ngClass]="{'text-center': (viewType$ | async) === 'desktop'}">
      {{ 'VISIO_ONBOARDING.need-help' | translate }}
      <span class="bold">
          <a
            href="https://www.notion.so/kawaaco/Kawaa-Live-342669aaa7e24180bc55092b87cafb5d"
            target="_blank"
            class="link-underline"
          >
          {{ 'VISIO_ONBOARDING.faq' | translate }}
        </a>
      </span>
      {{ 'VISIO_ONBOARDING.or' | translate }}
      <span (click)="contactUs()" class="link-underline pointer bold">
        {{ 'VISIO_ONBOARDING.contact-us' | translate }}
      </span>
    </p>
  </div>
</div>

<!-- Step 2 : Today & Step 3 : Starting soon & Step 4 : Started -->
<div
  class="row p-t-30"
  *ngIf="step == 'today' || step == 'starting-soon' || step == 'started'"
>
  <div *ngIf="step !== 'started'">
    <div class="col col-11 gutters align-center">
      <p class="text-center text-large m-s-b">
        <b>{{ 'VISIO_ONBOARDING.verification' | translate }}</b>
      </p>
    </div>
  
    <div class="col col-11 bg-color-profile push-center border-radius borders-all m-xs-t p-tb-30 p-lr-60">
      <p class="p-static text-center p-tb-10">
        <span class="bold">
          <a
            href="https://www.notion.so/kawaaco/Kawaa-Live-342669aaa7e24180bc55092b87cafb5d"
            target="_blank"
            class="link-underline"
          >
            {{ 'VISIO_ONBOARDING.discover' | translate }}
          </a>
        </span>,
        <span class="bold">
          <a
            href="https://www.notion.so/kawaaco/Comment-animer-son-v-nement-virtuel-639a0485faa74d78894ddd0afd0b1e40"
            target="_blank"
            class="link-underline"
          >
            {{ 'VISIO_ONBOARDING.inspiration' | translate }}
          </a>
        </span>
        {{ 'VISIO_ONBOARDING.conception' | translate }} {{ 'VISIO_ONBOARDING.and' | translate }}
        <span class="bold">
          <a
            href="https://www.notion.so/kawaaco/Comment-animer-son-v-nement-virtuel-639a0485faa74d78894ddd0afd0b1e40#a990a48e2de247c6b132668537b77420"
            target="_blank"
            class="link-underline"
          >
            {{ 'VISIO_ONBOARDING.rules' | translate }}
          </a>
        </span> !
      </p>
    </div>
  </div>

  <!-- 3 blocks for Today || starting soon -->
  <div
    *ngIf="step == 'today' || step == 'starting-soon' && (viewType$ | async) === 'desktop'"
    class="col col-11 push-center m-s-t"
  >
    <div class="flex">
      <div class="flex-column borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4">
        <img
          [src]="step == 'today' ? '/assets/images/kvisio/computers.png' : '/assets/images/kvisio/smiley.png'"
          style="height: 90px;"
          class="img-contain col col-6 push-center m-s-t"
          alt="Ordinateur"
        >
        <p *ngIf="step == 'today'" class="p-static m-m-t text-center w80 push-center" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-computer' | translate }}
        </p>
        <p *ngIf="step == 'starting-soon'" class="p-static m-m-t text-center w80 push-center" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-smile' | translate }}
        </p>
      </div>
      <div class="flex-column m-s-lr borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4">
        <img
          [src]="step == 'today' ?'/assets/images/kvisio/navigator.png' : '/assets/images/kvisio/clock.png'"
          style="height: 90px;"
          class="img-contain push-center m-s-t col col-6"
          alt="Navigateur"
        >
        <p *ngIf="step == 'today'" class="p-static m-m-t text-center" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-navigator' | translate }}
        </p>
        <p *ngIf="step == 'starting-soon'" class="p-static m-m-t text-center" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-wait' | translate }}
        </p>
      </div>
      <div class="flex-column borders-all border-radius bg-color-profile p-tb-30 p-lr-30 col col-4">
        <img
          [src]="step == 'today' ? '/assets/images/kvisio/stuff.png' : '/assets/images/kvisio/micro-cam.png'"
          style="height: 90px;"
          class="img-contain col col-6 push-center m-s-t"
          alt="Clavier, micro, webcam"
        >
        <p *ngIf="step == 'today'" class="p-static m-m-t text-center overflow" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-stuff' | translate }}
          <span class="text-small italic-text">
            {{ 'VISIO_ONBOARDING.rule-stuff-bis' | translate }}
          </span>
        </p>
        <p *ngIf="step == 'starting-soon'" class="p-static m-m-t text-center overflow" style="height: 90px;">
          {{ 'VISIO_ONBOARDING.rule-autorize' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="(viewType$ | async) === 'desktop'" class="col col-11 push-center m-s-t">
    <img src="/assets/images/kvisio/saloon-orga.jpg" alt="Salon virtuel">
  </div>

  <div *ngIf="(viewType$ | async) === 'mobile' && step == 'started'" class="col col-11">
    <p class="bold text-bigger">
      Comment rejoindre le salon virtuel sur mobile ?
    </p>
    <p class="m-xs-tb">
      Vous pouvez télécharger l’application
      <a
        [href]="'https://w2atb.app.goo.gl/?link=https%3A%2F%2Fmeet.jit.si%2Fe' + conf_name + '-kawaa&apn=org.jitsi.meet&ibi=com.atlassian.JitsiMeet.ios&isi=1165103905&ius=org.jitsi.meet&efr=1'"
        class="link-underline m-xs-t">
        jitsi
      </a>
      et rejoindre le salon virtuel.
    </p>
    <a
      [href]="'https://w2atb.app.goo.gl/?link=https%3A%2F%2Fmeet.jit.si%2Fe' + conf_name + '-kawaa&apn=org.jitsi.meet&ibi=com.atlassian.JitsiMeet.ios&isi=1165103905&ius=org.jitsi.meet&efr=1'"
      class="w-auto m-m-b flex align-center"
    >
      <img
        [src]="!isIphone ? '/assets/images/kvisio/google-play.png' : '/assets/images/kvisio/app-store.png'"
        class="img-contain"
        style="height: 50px; width: 165px;"
        alt="Telecharger"
      >
    </a>
  </div>
  <div
    [ngClass]="{
      'p-tb-30 p-lr-60': (viewType$ | async) === 'desktop',
      'p-lr-20 p-tb-20': (viewType$ | async) === 'mobile'
    }"
    class="col col-11 bg-color-profile push-center border-radius borders-all m-s-t m-x-b"
  >
    <p class="p-static text-center">
      {{ 'VISIO_ONBOARDING.need-help' | translate }}
      <b>
        <br *ngIf="(viewType$ | async) === 'mobile'">
        <a
          href="https://www.notion.so/kawaaco/Kawaa-Live-342669aaa7e24180bc55092b87cafb5d"
          target="_blank"
          class="link-underline"
        >
          {{ 'VISIO_ONBOARDING.faq' | translate }}
        </a>
      </b>
      {{ 'VISIO_ONBOARDING.or' | translate }}
      <b>
        <span (click)="contactUs()" class="link-underline pointer">
          {{ 'VISIO_ONBOARDING.contact-us' | translate }}
        </span>
      </b>
    </p>
  </div>
</div>

<!-- Step 5 : Past -->
<div class="row p-t-30 m-x-b" *ngIf="step == 'past'">
  <div
    class="col col-11 bg-color-profile push-center border-radius borders-all m-s-t"
    [ngClass]="{
      'p-tb-30 p-lr-60': (viewType$ | async) === 'desktop',
      'p-lr-15 p-tb-20': (viewType$ | async) === 'mobile'
    }"
  >
    <p class="p-static p-b-10">
      {{ 'VISIO_ONBOARDING.mail' | translate }}
      <b>
        “<a
          [routerLink]="['/admin', 'rencontres', meet.id, 'avis']"
          queryParamsHandling="preserve"
          class="link-underline"
        >
          {{ 'VISIO_ONBOARDING.mail-link' | translate }}
        </a>”
      </b>.
    </p>

    <p class="p-static m-xs-t">
      {{ 'VISIO_ONBOARDING.discussions' | translate }}
      <b>
        “<a
            [routerLink]="['/admin', 'rencontres', meet.id, 'discussions']"
            queryParamsHandling="preserve"
            class="link-underline"
          >
          {{ 'VISIO_ONBOARDING.discussions-link' | translate }}
        </a>”
      </b>
      {{ 'VISIO_ONBOARDING.discussions-end' | translate }} 
    </p>

    <p class="p-static m-xs-t">
      {{ 'VISIO_ONBOARDING.contact' | translate }}
      <b (click)="contactUs()" class="link-underline">
        {{ 'VISIO_ONBOARDING.contact-us' | translate }}
      </b>
    </p> 
    
    <p class="p-static m-xs-t">
      {{ 'VISIO_ONBOARDING.next' | translate }}
    </p>
  </div>

  <div
    [ngClass]="{
      'p-tb-30 p-lr-60': (viewType$ | async) === 'desktop',
      'p-lr-20 p-tb-20': (viewType$ | async) === 'mobile'
    }"
    class="col col-11 bg-color-profile push-center border-radius borders-all m-s-t m-x-b"
  >
    <p class="p-static text-center">
      {{ 'VISIO_ONBOARDING.need-help' | translate }}
      <b>
        <br *ngIf="(viewType$ | async) === 'mobile'">
        <a href="https://aide.kawaa.co/category/179-les-evenements-virtuels---kawaa-live" target="_blank"
          class="link-underline">
          {{ 'VISIO_ONBOARDING.faq' | translate }}
        </a>
      </b>
      {{ 'VISIO_ONBOARDING.or' | translate }}
      <b>
        <span (click)="contactUs()" class="link-underline pointer">
          {{ 'VISIO_ONBOARDING.contact-us' | translate }}
        </span>
      </b>
    </p>
  </div>
</div>


