<div class="row content-width p-t-20">
  <h2 class="w100 text-large text-black m-s-t">
    <span>{{ 'FEEDBACK.hello' | translate }}</span>
    <span *ngIf="user?.first_name"> {{ user.first_name }}</span> !
    {{ 'FEEDBACK.title' | translate }}
  </h2>
  <p class="w100 text-black text-small italic-text m-s-b">
    {{ 'FEEDBACK.anonym' | translate }}
  </p>
</div> 

<div class="row content-width">
  <div class="align-left col col-4">
    <meet-details-box
      *ngIf="queryForm?.meet"
      (selectMeet)="navigateToMeet()"
      [meet]="queryForm.meet"
    ></meet-details-box>
  </div>

  <form
    class="offset-1 col col-7"
    *ngIf="queryForm"
    novalidate
    (ngSubmit)="onSubmit(queryForm, form)"
    #ngForm="ngForm"
    [formGroup]="form"
  >
    <p [innerHtml]="queryForm.message | linky"></p>

    <div class="p-t-10">
      <div class="k-form k-form-center">

        <div class="form-item w100">
          <h2 class="text-large m-xs-b m-x-t">
            {{ 'FEEDBACK.rating-question' | translate }}
          </h2>
          <div class="w50 smileys">
            <div class="smiley smiley-1" (click)="rate(1)">
              <img
                fillSvgColors src="/assets/images/smileys/smiley1.svg"
                [ngStyle]="{ 'transform': queryForm.rating == 1 ? 'scale(1.2)': null, 'transition': 'all 200ms ease-in' }"
              />
            </div>
            <div class="smiley smiley-2" (click)="rate(2)">
              <img
                fillSvgColors src="/assets/images/smileys/smiley2.svg"
                [ngStyle]="{ 'transform': queryForm.rating == 2 ? 'scale(1.2)': null, 'transition': 'all 200ms ease-in' }"
              />
            </div>
            <div class="smiley smiley-3" (click)="rate(3)">
              <img
                fillSvgColors src="/assets/images/smileys/smiley3.svg"
                [ngStyle]="{ 'transform': queryForm.rating == 3 ? 'scale(1.2)': null, 'transition': 'all 200ms ease-in' }"
              />
            </div>
            <div class="smiley smiley-4" (click)="rate(4)">
              <img
                fillSvgColors src="/assets/images/smileys/smiley4.svg"
                [ngStyle]="{ 'transform': queryForm.rating == 4 ? 'scale(1.2)': null, 'transition': 'all 200ms ease-in' }"
              />
            </div>
            <div class="smiley smiley-5" (click)="rate(5)">
              <img
                fillSvgColors src="/assets/images/smileys/smiley5.svg"
                [ngStyle]="{ 'transform': queryForm.rating == 5 ? 'scale(1.2)': null, 'transition': 'all 200ms ease-in' }"
              />
            </div>
          </div>
        </div>

        <div class="form-item w100">
          <h2 class="text-large m-xs-b m-x-t">
            {{ 'FEEDBACK.send-claps' | translate }}
            <svg
              style="cursor: default !important"
              width="33"
              height="33"
            >
              <g fill-rule="evenodd">
                <path d="M29.58 17.1l-3.854-6.78c-.365-.543-.876-.899-1.431-.989a1.491 1.491 0 0 0-1.16.281c-.42.327-.65.736-.7 1.207v.001l3.623 6.367c2.46 4.498 1.67 8.802-2.333 12.807-.265.265-.536.505-.81.728 1.973-.222 3.474-1.286 4.45-2.263 4.166-4.165 3.875-8.6 2.215-11.36zm-4.831.82l-3.581-6.3c-.296-.439-.725-.742-1.183-.815a1.105 1.105 0 0 0-.89.213c-.647.502-.755 1.188-.33 2.098l1.825 3.858a.601.601 0 0 1-.197.747.596.596 0 0 1-.77-.067L10.178 8.21c-.508-.506-1.393-.506-1.901 0a1.335 1.335 0 0 0-.393.95c0 .36.139.698.393.95v.001l5.61 5.61a.599.599 0 1 1-.848.847l-5.606-5.606c-.001 0-.002 0-.003-.002L5.848 9.375a1.349 1.349 0 0 0-1.902 0 1.348 1.348 0 0 0 0 1.901l1.582 1.582 5.61 5.61a.6.6 0 0 1-.848.848l-5.61-5.61c-.51-.508-1.393-.508-1.9 0a1.332 1.332 0 0 0-.394.95c0 .36.139.697.393.952l2.363 2.362c.002.001.002.002.002.003l3.52 3.52a.6.6 0 0 1-.848.847l-3.522-3.523h-.001a1.336 1.336 0 0 0-.95-.393 1.345 1.345 0 0 0-.949 2.295l6.779 6.78c3.715 3.713 9.327 5.598 13.49 1.434 3.527-3.528 4.21-7.13 2.086-11.015zM11.817 7.727c.06-.328.213-.64.466-.893.64-.64 1.755-.64 2.396 0l3.232 3.232c-.82.783-1.09 1.833-.764 2.992l-5.33-5.33z"></path>
              </g>
            </svg>
            {{ 'FEEDBACK.send-claps-to' | translate }}
            <a
              *ngIf="organizator"
              class="link-underline"
              [routerLink]="['/profile', organizator.member.user_id, 'organisations']"
              target="_blank"
              [queryParams]="{ '_': groupId }"
            >
              {{ queryForm.meet?.options?.organizator ? queryForm.meet.options.organizator : '' }}
            </a>
          </h2>
          <div
            class="clap text-center m-m-t pulse"
            [style.border-width]="clapper.isClapping ? '1.5px' : '1px'"
            (click)="onClap()"
          >
            <svg
              [class.checked]="clapper.myTotalClaps > 0"
              width="33"
              height="33"
            >
              <g fill-rule="evenodd">
                <path d="M29.58 17.1l-3.854-6.78c-.365-.543-.876-.899-1.431-.989a1.491 1.491 0 0 0-1.16.281c-.42.327-.65.736-.7 1.207v.001l3.623 6.367c2.46 4.498 1.67 8.802-2.333 12.807-.265.265-.536.505-.81.728 1.973-.222 3.474-1.286 4.45-2.263 4.166-4.165 3.875-8.6 2.215-11.36zm-4.831.82l-3.581-6.3c-.296-.439-.725-.742-1.183-.815a1.105 1.105 0 0 0-.89.213c-.647.502-.755 1.188-.33 2.098l1.825 3.858a.601.601 0 0 1-.197.747.596.596 0 0 1-.77-.067L10.178 8.21c-.508-.506-1.393-.506-1.901 0a1.335 1.335 0 0 0-.393.95c0 .36.139.698.393.95v.001l5.61 5.61a.599.599 0 1 1-.848.847l-5.606-5.606c-.001 0-.002 0-.003-.002L5.848 9.375a1.349 1.349 0 0 0-1.902 0 1.348 1.348 0 0 0 0 1.901l1.582 1.582 5.61 5.61a.6.6 0 0 1-.848.848l-5.61-5.61c-.51-.508-1.393-.508-1.9 0a1.332 1.332 0 0 0-.394.95c0 .36.139.697.393.952l2.363 2.362c.002.001.002.002.002.003l3.52 3.52a.6.6 0 0 1-.848.847l-3.522-3.523h-.001a1.336 1.336 0 0 0-.95-.393 1.345 1.345 0 0 0-.949 2.295l6.779 6.78c3.715 3.713 9.327 5.598 13.49 1.434 3.527-3.528 4.21-7.13 2.086-11.015zM11.817 7.727c.06-.328.213-.64.466-.893.64-.64 1.755-.64 2.396 0l3.232 3.232c-.82.783-1.09 1.833-.764 2.992l-5.33-5.33z"></path>
              </g>
            </svg>

            <span
              [@clapCount]="clapper.isClapping ? 'show' : 'hide'"
              class="clap-count"
            >
              {{ clapper.myTotalClaps }}
            </span>
            <span
              *ngIf="!clapper.isClapping"
              class="clap-count-total"
            >
              {{ clapper.myTotalClaps + queryForm.claps }}
            </span>
          </div>
        </div>

        <div class="form-item w100">
          <ask-questions-form
            [questions]="queryForm.queries"
            [parentForm]="form"
            [parentFormIsSubmitted]="ngForm.submitted"
          >
          </ask-questions-form>
        </div>

        <div class="form-item w100">
          <h2 class="text-large m-xs-b m-x-t">
            {{ 'FEEDBACK.write-comment' | translate }}
          </h2>
          <textarea
            class="form-control"
            row="4"
            name="comment"
            formControlName="comment"
          ></textarea>
        </div>

        <button
          class="button btn-validate button-big push-right"
          [ngClass]="{
            'disabled': isSubmitting
          }"
          type="submit"
        >
          {{ 'FEEDBACK.btn-feedback' | translate }}
        </button>

      </div>
    </div>
  </form>
</div>
