<div id="group-query-form" class="row align-center" style="display: none">
  <form
    *ngIf="form && showForm"
    class="flipIn p-lr-10 p-tb-30 content-width"
    style="box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2)"
    novalidate
    (ngSubmit)="onSubmit(form)"
    #ngForm="ngForm"
    [formGroup]="form"
  >
    <div class="text-center push-center m-x-t">
      <h2 [style.color]="config?.text_color">{{ config?.rsvp_title }}</h2>
      <p [style.color]="config?.text_color" class="m-xs-tb">{{ config?.rsvp_subtitle }}</p>
      <p [style.color]="config?.text_color" class="m-m-b light-weight-text">{{ config?.rsvp_note }}</p>
    </div>

    <div class="k-form k-form-center">
      <div class="m-s-tb w80 w100-m">
        <ask-questions-form
          [questions]="queryForm.queries"
          [parentForm]="form"
          [parentFormIsSubmitted]="ngForm.submitted"
        >
        </ask-questions-form>
      </div>

      <div class="m-s-tb w70 w100-m text-right m-s-r">
        <button
          class="button btn-black-white buttonn-big"
          type="submit"
          [routerLink]="['.']"
          replaceUrl="true"
        >
          {{ 'GROUP_QUERY_FORMS.cancel' | translate }}
        </button>
        <button
          class="button btn-validate button-big"
          [style.background-color]="color"
          type="submit"
          (click)="submitted = true"
        >
          {{ 'GROUP_QUERY_FORMS.submit' | translate }}
        </button>
      </div>
    </div>

  </form>
</div>

<div *ngIf="!showForm && !(member$ | async)" class="row align-middle content-width">
  <div class="w100 bg-lighter-grey text-center p-tb-60 m-s-tb">
    <a role="button" href="javascript:void(0)" (click)="openForm()" [animation-scroll]="'block-appear-b-t'">
      <div
        class="button button-large"
        [ngStyle]="{
          'background-color': color
        }"
        [innerHtml]="config?.rsvp_cta"
      >
      </div>
    </a>
  </div>
</div>

<div
  *ngIf="!showForm && (member$ | async)?.role == 'member' && config?.rsvp_after"
  class="row align-center content-width"
>
  <div class="col col-7 bg-lighter-grey text-center p-tb-60 m-s-tb">
    <div
      [animation-scroll]="'block-appear-b-t'"
      class="p-lr-5 text-bigger bold"
      [innerHtml]="config.rsvp_after"
    >
    </div>
  </div>
</div>
