import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomComponent } from './custom.component';
import { CustomPageComponent } from './custom-page.component';
import { MeetFeedbackComponent } from '../meets/meet-feedbacks.component';
import { FormatDetailsComponent } from '../formats/format-details.component';
import { MeetPageComponent } from '../meets/meet-page/meet-page.component';
import { VisioComponent } from '../meets/meet-page/visio/visio.component';

import { ThemeResolver } from '../core/';
import { AuthAccessGuard, authRoutes } from '../auth/';
import { PageNotFoundComponent } from '../core/page-not-found.component';

const childRoutes: Routes = [
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: 'rencontre/:id',
    component: MeetPageComponent,
    resolve: { theme: ThemeResolver },
    data: { lang: 'fr' }
  },
  {
    path: 'event/:id',
    component: MeetPageComponent,
    resolve: { theme: ThemeResolver },
    data: { lang: 'en' }
  },
  {
    path: 'visio/:id',
    component: VisioComponent,
    resolve: { theme: ThemeResolver },
  },

  {
    path: 'avis/:id',
    component: MeetFeedbackComponent,
    resolve: { theme: ThemeResolver },
    data: { lang: 'fr' }
  },
  {
    path: 'feedback/:id',
    component: MeetFeedbackComponent,
    resolve: { theme: ThemeResolver },
    data: { lang: 'en' }
  },
  {
    path: 'format/:id',
    component: FormatDetailsComponent,
    resolve: { theme: ThemeResolver },
  },
  ...authRoutes,
];

const customRoutes: Routes = [
  {
    path: 'home/:id',
    component: CustomComponent,
    canActivate: [AuthAccessGuard],
    children: [
      {
        path: '',
        component: CustomPageComponent
      },
      ...childRoutes,
      {
        path: ':page_name',
        component: CustomPageComponent
      },
      {
        path: ':page_name/:id',
        component: CustomPageComponent
      },
    ]
  },
  {
    path: 'groupe/:id', redirectTo: 'home/:id'
  },
  {
    path: 'g/:id', redirectTo: 'home/:id'
  },
  {
    path: 'pvt/:id', redirectTo: 'home/:id'
  }
];

export const customRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(customRoutes);
