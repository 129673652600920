<div class="fade-in-anim">
  <div class="page-title hidden-if-not-mobile text-nav-green p-lr-40">
    activités
    <svg class="icon" fill="currentColor">
      <use href="assets/images/logo/logo-no-color.svg#logo-no-color" />
    </svg>
    rencontres
  </div>
  <div class="main-image flex">
    <img src="assets/images/home/workshop.png" alt="people in a social workshop">
  </div>
  <div class="post-block">
    <div class="post bg-light-yellow text-green">
      <div class="text">
        <p class="paragraph">
          Vous êtes déjà 300.000 à avoir participé à des ateliers et rencontres depuis le lancement de la plateforme kawaa ! Et ça continue maintenant dans nos lieux ! Nos voisins en organisent toute la semaine. Il y en a pour tous les goûts et sur tous les thèmes. Un thème, un cours ou un atelier n'existe pas encore ? Viens, on l'inventera avec toi !
        </p>
      </div>
      <div class="borders">
        <div class="border-t-green"></div>
        <div class="border-r-green"></div>
        <div class="border-b-green"></div>
        <div class="border-l-green"></div>
      </div>
    </div>
    <div class="agenda-block text-green">
      <h2>agenda</h2>
      <div class="agenda" *ngFor="let group of groupsCollection?.entities | async">
        <ul class="meets-grid" >
          <li class="meet-grid-element p-lr-25 meet image-bg" *ngFor="let meet of meetsCollection?.entities | async | slice:0:6" [ngStyle]="{
            'background-image': 'url(' + (meet.photos?.length > 0 ? meet.photos[0].slug : '') + ')'
          }">
            <a href="{{urlLocation}}/home/{{group.id}}/rencontre/{{meet.id}}" target="_blank"></a>
            <div class="meet-info">
              <h4>{{ meet.name }}</h4>
              <p class="date m-xs-t">
                <span class="date-day">
                  {{ meet.datetime?.substr(0,10) | date:'d' }}
                </span>
                <span class="date-month">
                  {{ meet.datetime?.substr(0,10) | date:'MMM' }}
                </span>
                <span class="date-hour">
                  {{ meet.datetime | meetTime }}
                </span>
              </p>
              <div class="place">{{ meet.location?.address }}</div>
            </div>
          </li>
        </ul>
        <div class="place-link">
          <a class="text-brown" href="{{urlLocation}}/home/{{group.id}}" target="_blank">
            voir tous les évènements
          </a>
        </div>
      </div>
    </div>
  </div>
</div>