import {
  Component,
  Input
} from '@angular/core';
import {
  AnimationFormat
} from '../shared/';
import {
  humanizeDuration,
  humanizeDurations,
} from '../shared/helpers/common.funcs';

@Component({
  selector: 'format-details-box',
  templateUrl: '../../../src/app/formats/format-details-box.component.html'
})


export class FormatDetailsBoxComponent {

  @Input('format')  format: AnimationFormat;

  constructor(
  ) {
  }

  setCoverPhoto(format: AnimationFormat): string {
    if (format && format.cover_photo) {
      return format.cover_photo.slug;
    }
    else {
      return '/assets/images/static/format-temps-parler.jpg';
    }
  }

  setDuration(format: AnimationFormat): string {
    if (!format || !format.modalities || !format.modalities.duration)
    return '';

    let { min, max, fix } = format.modalities.duration;

    if (!min && !max && !fix)
      return '';

    if (min && max) {
      return humanizeDurations(min, max);
    }
    else if (min)
      return 'Environ ' + humanizeDuration(min);
    else if (max)
      return 'Environ ' + humanizeDuration(max);
    else if (fix)
      return humanizeDuration(fix);
    else
      return '';
  }

  setParticipants(format: AnimationFormat): string {
    const participants = format.modalities.participants;

    if (participants.min && participants.max) {
      return `Entre ${participants.min} et ${participants.max} participants`;
    }
    else if (participants.min) {
      return `${participants.min} participants min`;
    }
    else if (participants.max) {
      return `${participants.max} participants max`;
    }
    else if (participants.fix) {
      return `${participants.fix} participants`;
    }
  }
}
