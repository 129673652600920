<div class="content-width">
  <div class="borders-all p-tb-30 p-lr-30 flex align-center">
    <div class="flex align-center">
      <div class="m-m-lr m-m-tb text-center">
        <p class="text-very-large">
          <b>{{ group?.meets_count | json }}</b>
        </p>
        <p class="text-big">
          <i>{{ localConfig.meet_naming | customTranslate | pluralize }}</i>
        </p>
      </div>
      <div class="m-m-lr m-m-tb text-center">
        <p class="text-very-large">
          <b>{{ group?.members_count | json }}</b>
        </p>
        <p class="text-big">
          <i>{{ localConfig.member_naming | customTranslate | pluralize }}</i>
        </p>
      </div>
    </div>
  </div>
</div>