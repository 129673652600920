import { of as observableOf,  Observable, Subscription } from 'rxjs';

import { distinctUntilChanged, map, mergeMap, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStore } from '../store';
import { ActivatedRoute } from '@angular/router';
import { Group } from '../shared';

@Component({
  selector: 'auth-page',
  templateUrl: './auth-page.component.html'
})
export class AuthPageComponent implements OnInit, OnDestroy {

  showKawaaPrivacy: boolean = true; // Used to show or not all the blabla around RGPD
  isGroup:					boolean = false; // Used to show the left content of auth-box

  subscription:     Subscription;

  constructor(
    private _appStore:	AppStore,
    private _route:			ActivatedRoute,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);

    const masterGroupId: string = this._route.snapshot.params['id'] || this._route.parent.snapshot.params['id'];

    if (masterGroupId) {
      this.subscription = this._appStore.load('Group', masterGroupId)
        .entity
        .subscribe((group: Group) => {
          this.isGroup = true;

          if (group.options && group.options.cgu_link) {
            this.showKawaaPrivacy = false;
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
