
import { of as observableOf,  Observable ,  Subscription } from 'rxjs';

import { distinctUntilChanged, map, mergeMap, filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { User, ITheme, Group, IColors, IAuthSchema, ITranslatedValue } from '../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AppStore } from '../store';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '../core/tracking.service';
import { HttpClient } from '@angular/common/http';
import { Airtable, Table } from 'ngx-airtable';
import { formatDate } from '@angular/common';




export type Ttab = 'login'
  | 'register'
  | 'resetPassword'
  | 'resetPasswordMailSent'
  | 'resetPasswordWithToken';

declare var env: any;


@Component({
  selector: 'auth-box',
  templateUrl: './auth-box.component.html',
})


export class AuthBoxComponent implements OnInit, OnDestroy {
  user:               User;
  masterGroupId:      string;

  loginError:         string;
  registerError:      string;
  resetPasswordError: string;
  facebookError:      string;

  activeTab:          Ttab = 'login';
  showPassword:       boolean = false;
  token:              string;
  authSchema:         IAuthSchema = { facebook_connect: true, kawaa_connect: true, paris_connect: false, harmonie_connect: false };

  // If a group requieres an explicit opt-in of CGU.
  // By default it isn't necessary and that's why
  // we set rgpdOptedIn to be true
  rgpdOptIn:          ITranslatedValue;
  rgpdOptedIn:        boolean = true;
  newsletterOptIn:    ITranslatedValue;
  newsletterOptedIn:  boolean = false;

  // Must be an absolute url relative to our app. You can get the url from
  // current page with Router.url
  redirectUrl:         string;

  /**
   *  All messages are hooked from url queryparams, the value of each string
   * is a key to access to a value in our i18n files (AUTH.MESSAGES[key])
   *
   * Important note : warning message will be shown ONLY on 'register' and 'login' tabs.
   * Other messages will be shown in all tabs.
  */
  errorMessage:        string;
  warningMessage:      string;
  infoMessage:         string;

  colors: IColors = { primary: '' };

  timer:               any;
  subscriptions:       Subscription[] = [];

  constructor(
    private _route:           ActivatedRoute,
    private _router:          Router,
    private _auth:            AuthService,
    private _appStore:        AppStore,
    private _translate:       TranslateService,
    private _trackingService: TrackingService,
    private _http:            HttpClient,
    private _airtable:        Airtable
  ) {}

  ngOnInit(): void {
    const sub1: Subscription = this._route.queryParams.subscribe((params) => {
      if (params.tab) {
        const validValues = ['login', 'register'];
        if (validValues.indexOf(params.tab) != -1) {
          this.activeTab = params.tab;
        } else {
          console.error('Invalid url param : tab. Valid values are :', validValues);
        }
      }
      if (params.redirectUrl) {
        // TODO check if it's valid url and log error if it's not
        this.redirectUrl = params.redirectUrl;
      }
      if (params.facebookError) {
        this.facebookError = params.facebookError;
      }
      if (params.token) {
        this.activeTab = 'resetPasswordWithToken';
        this.token = params.token;
      }
      if (params.warningMessage) {
        this.warningMessage = params.warningMessage;
      }
      if (params.errorMessage) {
        this.errorMessage = params.errorMessage;
      }
      if (params.infoMessage) {
        this.infoMessage = params.infoMessage;
      }
      if (params.lang) {
        this._translate.use(params.lang);
      }
    });

    const sub2: Subscription = this._auth.getUser().subscribe((user) => {
      this.user = user;
      if (user.id) {
        this.redirect();
      }
    });
    this.subscriptions.push(sub1, sub2);
    this.setGroupOptions();
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.subscriptions.forEach(_ => _.unsubscribe());
  }

  /**
   *
   * Kawaa account operations
   *
   */

  login(): void {
    const sub: Subscription = this._auth.login(this.user).subscribe(
      (res) => this._trackingService.trackEvent('Auth Page', 'authenticate', 'login'), // If success, anew user will be pushed by _account.getUser() in ngOnInit()
      (err) => this.loginError = err,
    );

    this.subscriptions.push(sub);
  }

  register(): void {
    const sub: Subscription = this._auth.register(this.user).subscribe(
      (res) => {
        this._trackingService.trackEvent('Auth Page', 'authenticate', 'register');
        if (this.newsletterOptedIn) {
          this.airtableNewEntry();
        }
      }, // If success, anew user will be pushed by _account.getUser() in ngOnInit()
      (err) => this.registerError = err, // The only error which can happens here is when the email is already used.
    );
    this.subscriptions.push(sub);
  }

  airtableNewEntry() {
    const base = this._airtable.base('appZ46b4lnRpIRWvh').table({ tableName: 'Optin_web'});
    const currentDate = new Date();
    const formatedDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    setTimeout(() => {
      if (base && !this.registerError) {
        base.create(
          {
            'fields': {
              'Email': this.user.email,
              'Prénom': this.user.first_name,
              'Nom': this.user.last_name,
              'Consentement': true,
              'origine_group_id': parseInt(this.masterGroupId),
              'Created': formatedDate
            }
          }
        ).subscribe(res => console.log(res), err => console.log(err));
      }
    }, 0);
  }

  sendMailWithToken(): void {
    const sub: Subscription = this._auth
      .forgotPassword(this.user.email, this.masterGroupId)
      .subscribe(
        (res) => (this.activeTab = 'resetPasswordMailSent'),
        (err) => (this.resetPasswordError = err),
      );

    this.subscriptions.push(sub);
  }

  resetPasswordWithToken(): void {
    const sub: Subscription = this._auth
      .resetPassword(this.user.password, this.token)
      .subscribe(
        (res) => 1,
        (err) => (this.resetPasswordError = err),
      );

    this.subscriptions.push(sub);
  }

  /**
   *
   * Providers auth
   *
   */

  loginWithFacebook(): void {
    const { protocol, host, pathname } = location;
    const fbAuthUrl = this.facebookError
      ? `/auth/facebook?returnUri=${encodeURIComponent(protocol + '//' + host + pathname)}&auth_type=rerequest`
      : `/auth/facebook?returnUri=${encodeURIComponent(location.href)}`;
    location.href = fbAuthUrl;
  }

  loginWithParis(): void {
    const monCompteBaseUrl = 'https://moncompte.paris.fr/moncompte/';
    const monCompte_backUrlAuth = encodeURIComponent(window.location.href); // works only when *.paris.fr
    const wW = 600;
    const wH = 800;
    window.open(monCompteBaseUrl + 'jsp/site/Portal.jsp?page=myluteceusergu&view=createAccountModal&back_url=' + monCompte_backUrlAuth, '_blank', 'width='+ wW + ',height=' + wH +',scrollbars=yes,status=yes,resizable=yes,toolbar=0,menubar=0,location=0,screenx=0,screeny=0');
  }

  /**
   *
   * Other methods
   *
   */
  redirect(): void {
    if (this.redirectUrl) {
      this._router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
    }
    else if (this.masterGroupId) {
      this._router.navigate(['/home', this.masterGroupId], { relativeTo: this._route, replaceUrl: true });
    }
    else {
      this._router.navigate(['/'], { relativeTo: this._route, replaceUrl: true });
    }
  }

  /**
   * Set primary color and group options
   */
  setGroupOptions(): void {
    this.masterGroupId  = this._route.snapshot.params['id'] || this._route.parent.snapshot.params['id'];

    if (this.masterGroupId) {
      const sub: Subscription = this._appStore.load('Group', this.masterGroupId)
        .entity
        .subscribe((group: Group) => {
          if (group.options && group.options.colors) {
            this.colors = group.options.colors;
          }
          if (group.options && group.options.auth_schema) {
            this.authSchema = group.options.auth_schema;
          }
          if (group.options && group.options.rgpd_opt_in) {
            this.rgpdOptIn = group.options.rgpd_opt_in;
            this.rgpdOptedIn = false;
          }
          if (group.options && group.options.newsletter_opt_in) {
            this.newsletterOptIn = group.options.newsletter_opt_in;
            this.newsletterOptedIn = false;
          }
        });

      this.subscriptions.push(sub);
    }
  }
}
