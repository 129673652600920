<div
  *ngIf="showPopUpPhone"
  class="pos-f w100 h100 z-over-all"
  (click)="showPopUpPhone = !showPopUpPhone"
  style="background-color: rgba(177, 177, 177, 0.6); top:0;"
></div>
<div
  *ngIf="showConfInfo"
  class="pos-f w100 h100 z-over-all"
  (click)="showConfInfo = !showConfInfo"
  style="background-color: rgba(177, 177, 177, 0.6); top:0;"
></div>

<div *ngIf="step" class="content-width">
  <div
    *ngIf="meet"
    class="flex align-middle push-center p-b-20 p-t-10"
    [ngClass]="{'m-m-tb col col-11': (viewType$ | async) === 'desktop'}"
  >
    <i class="fas fa-arrow-left m-xs-r"></i>
    <a
      [routerLink]="['../..' ,'rencontre', meet.id]"
      class="text-small link-underline-rollover"
    >
      <p class="text-base bold">
        {{ meet.name }}
      </p>
    </a>
  </div>

  <div
    *ngIf="!isNavigatorValid && (viewType$ | async) === 'desktop'"
    class="border-danger bg-light-error border-radius m-s-b col col-11 push-center"
  >
    <p class="p-lr-20 p-tb-20">
      <span class="bold">
        {{ 'VISIO.navigator-warning' | translate }}
      </span>
      {{ 'VISIO.navigator-warning-details' | translate }}
      <span class="bold">
        {{ 'VISIO.navigator-warning-tips' | translate }}
      </span>
    </p>
  </div>
  <!-- Warning mobile display organizator -->
  <div
    *ngIf="step !== 'past' && participant?.role == 'organizator' && (viewType$ | async) === 'mobile'"
    class="align-middle push-center bg-warning-light border-warning border-radius m-xs-b"
  >
    <p class="p-lr-15 p-tb-20">
      Nous vous conseillons d’animer ce salon virtuel 
      <span class="bold">depuis un ordinateur </span>
      pour plus de simplicité !
    </p>
  </div>
  <div
    *ngIf="!onBoardingValidated && step !== 'past'"
    class="borders-all border-radius col col-11 align-center m-m-t"
    [ngClass]="{
      'col col-11': (viewType$ | async) === 'desktop',
      'col col-12': (viewType$ | async) === 'mobile'
    }"
  >
    <div
      *ngIf="step == 'in-future'"
      class="push-center text-center p-tb-30 m-xs-tb flex-column"
    >
      <p *ngIf="participant?.role == 'participant'" class="m-xs-b text-large">
        {{ 'VISIO.start-on' | translate }}
      </p>
      <p *ngIf="participant?.role == 'organizator'" class="m-xs-b text-large">
        Le salon virtuel ouvrira ses portes
      </p>
      <!-- For orga view only -->
      <p
        *ngIf="participant?.role == 'organizator' && step !== 'in-future'"
        class="p-static text-dark-grey m-s-t"
        [ngClass]="{
          'text-center': (viewType$ | async) === 'desktop',
          'text-left': (viewType$ | async) === 'mobile'
        }"
      >
        {{ 'VISIO.peace-reminder' | translate }}
      </p>
      <p
        class="bold"
        [ngClass]="{
          'text-very-large m-s-lr': (viewType$ | async) === 'mobile',
          'medium-title': (viewType$ | async) === 'desktop'
        }"
      >
        {{ meet.datetime | meetDate }} {{ 'VISIO.at' | translate }} {{ meet.datetime | meetTime }}
      </p>
      <p
        *ngIf="participant?.role == 'organizator' && step === 'in-future'"
        class="p-static text-dark-grey m-s-t m-s-lr"
        [ngClass]="{
          'text-center': (viewType$ | async) === 'desktop',
          'text-left': (viewType$ | async) === 'mobile'
        }"
        >
        {{ 'VISIO.peace-reminder' | translate }}
      </p>
    </div>

    <div
      *ngIf="step == 'today' || step == 'starting-soon' || step == 'started'"
      [ngClass]="{
        'p-tb-30 p-lr-10 m-xs-tb': (viewType$ | async) === 'desktop',
        'p-tb-20 p-lr-15': (viewType$ | async) === 'mobile'
      }"
    >
      <div *ngIf="step !== 'started'">
        <p
          *ngIf="step == 'today'"
          [ngClass]="{'m-s-b': participant?.role == 'participant'}"
          class="m-xxs-b text-large text-center"
        >
          {{ 'VISIO.start-in' | translate }}
        </p>
        <p
          *ngIf="step == 'starting-soon'"
          [ngClass]="{'m-s-b': participant?.role == 'participant'}"
          class="m-xxs-b text-large text-center"
        >
          Le salon virutel débute dans moins de 15 minutes
        </p>
        <!-- For orga view only -->
        <p
          *ngIf="participant?.role == 'organizator' && step !== 'started'"
          class="p-static text-dark-gey text-center m-s-b m-s-lr"
        >
          {{ 'VISIO.peace' | translate }}
        </p>
        <div 
          [ngClass]="{
            'extra-large-title': (viewType$ | async) === 'desktop',
            'text-larger': (viewType$ | async) === 'mobile'
          }"
          class="flex bold align-middle m-s-t align-center"
        >
          <p [ngClass]="{'p-lr-30 p-tb-20 borders-all bg-lesswhite border-radius': (viewType$ | async) === 'desktop'}">
            {{ formatedCounter.hours }}
          </p>
          <span class="m-s-lr text-dark-grey">:</span>
          <p [ngClass]="{'p-lr-30 p-tb-20 borders-all bg-lesswhite border-radius': (viewType$ | async) === 'desktop'}">
            {{ formatedCounter.minutes }}
          </p>
          <span class="m-s-lr text-dark-grey">:</span>
          <p [ngClass]="{'p-lr-30 p-tb-20 borders-all bg-lesswhite border-radius': (viewType$ | async) === 'desktop'}">
            {{ formatedCounter.seconds }}
          </p>
        </div>
      </div>

      <div *ngIf="step == 'started'">
        <div class="text-center text-bigger align-center p-lr-10">
          <p class="text-larger bold">
            {{ 'VISIO.event-started' | translate }}
          </p>
          <p *ngIf="(viewType$ | async) === 'desktop' && !meet?.options?.conference_url" class="m-s-t">
            {{ 'VISIO.rules-title' | translate }}
          </p>
        </div>
      </div>

      <div *ngIf="!onBoardingValidated && (viewType$ | async) === 'desktop'" class="m-s-t">
        <div class="text-center push-center">
          <ng-container *ngIf="!meet?.options?.conference_url">
            <button
              [ngClass]="{ 'button-disabled-grey': disableButton || !isNavigatorValid }"
              (click)="startVisio()"
              class="p-tb-10 p-lr-30 ka-button-agency no-margin"
            >
              {{ 'VISIO_ONBOARDING.join' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="meet?.options?.conference_url">
            <button
              (click)="showConfInfo = !showConfInfo"
              [ngClass]="{ 'button-disabled-grey': disableButton || !isNavigatorValid }"
              class="button p-tb-10 p-lr-30 ka-button-agency no-margin"
            >
              {{ 'VISIO_ONBOARDING.join' | translate }}
            </button>
          </ng-container>
          <p *ngIf="disableButton" class="italic-text m-xs-t">
            {{ 'VISIO_ONBOARDING.btn-disabled-description' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="step == 'started' && (viewType$ | async) === 'desktop' && !meet?.options?.conference_url"
    class="col col-11 align-middle push-center bg-warning-light border-warning border-radius m-m-t"
  >
    <div class="p-tb-20 p-lr-20 flex align-middle">
      <p class="text-base">
        Vos échanges sont <span class="bold">entièrement cryptés</span> et donc très gourmands en termes de connectivité.
        Pour améliorer votre expérience, nous n'affichons que les flux vidéo des trois derniers intervenants.
      </p>
    </div>
    <div *ngIf="participant?.role == 'participant'" class="p-b-20 p-lr-20 flex align-middle">
      <p class="text-base">
        <span class="bold">
          Votre connexion n’est pas stable ?
        </span>
        Vous pouvez rejoindre le salon virtuel via un appel téléphonique
      </p>
      <button (click)="showPopUpPhone = !showPopUpPhone" class="btn-white-black push-right">
        Appeler le salon virtuel
      </button>
    </div>
  </div>

  <!-- popup Phone -->
  <div
    *ngIf="participant?.role == 'participant'"
    [ngClass]="{
      'hide': !showPopUpPhone,
      'show': showPopUpPhone
    }"
    class="pos-f bg-white borders-all border-radius p-tb-30 p-lr-30"
    style="height: auto; width: 400px;top: 50%;left: 50%;transform: translate(-50%, -50%); z-index: 1000;"
  >
    <p class="text-bigger m-m-b">
      Vous pouvez accèder au salon virtuel avec un appel téléphonique (gratuit)
    </p>
    <p>
      Appelez ce numéro :
    </p>
    <div class="bg-color-profile borders-all border-radius m-xxs-t">
      <div class="flex p-tb-10 p-lr-10 align-middle">
        <p *ngIf="step !== 'started'" class="text-dark-grey m-s-r text-base italic-text">
          Disponible dès l'ouverture du salon virtuel
        </p>
        <a
          *ngIf="step == 'started'"
          class="m-s-r text-big italic-text link-underline"
          href="tel:+33184886478"
        >
          +33.1.84.88.6478
        </a>
        <div
          class="push-right border-radius pointer"
          (click)="callConference()"
          [ngClass]="{
            'bg-success': step == 'started',
            'bg-grey disabled': step !== 'started'
          }"
        >
          <i class="fas fa-phone p-tb-10 p-lr-10 text-white"></i>
        </div>
      </div>
    </div>
    <p class="m-xs-t">
      Et renseignez ce code :
    </p>
    <div class="bg-color-profile borders-all border-radius m-xxs-t">
      <div class="flex p-tb-10 p-lr-10 align-middle">
        <div class="p-tb-10">
          <p *ngIf="step == 'today'" class="text-dark-grey m-s-r text-base italic-text">
            Disponible dès l'ouverture du salon virtuel
          </p>
          <p *ngIf="step == 'starting-soon' || step == 'started'" class="m-s-r text-big">
            {{ confCode }}
          </p>
        </div>
      </div>
    </div>
    <p class="text-base text-dark-grey italic-text m-xxs-t">
      Tapez le code suivi par #
    </p>
    <button
      (click)="showPopUpPhone = !showPopUpPhone"
      class="btn-white-black flex push-right"
    >
      Fermer
    </button>
  </div>
  <!-- Popup External Conference  -->
  <div
    [ngClass]="{ 'hide': !showConfInfo, 'show': showConfInfo }"
    class="pos-f bg-white borders-all border-radius p-tb-30 p-lr-30"
    style="height: auto; width: 400px;top: 50%;left: 50%;transform: translate(-50%, -50%); z-index: 1000;"
  >
    <i
      class="far fa-times-circle pos-a text-big text-error pointer"
      style="top: 10px; right: 10px;"
      (click)="showConfInfo = !showConfInfo"
    ></i>
    <p class="text-big bold m-xs-b">
      Afin de rejoindre la conférence cliquez sur le lien suivant :
    </p>
    <a
      class="text-base break-all pointer underline text-center flex border-radius borders-all p-lr-10 p-tb-10"
      [href]="meet?.options?.conference_url"
      target="_blank"
    >
      {{ meet?.options?.conference_url }}
    </a>
    <ng-container *ngIf="meet?.options?.conference_info">
      <p class="m-m-t text-big bold">
        Voici les informations supplémentaires laissées par l'organisateur :
      </p>
      <p class="m-xs-t">
        {{ meet.options.conference_info }}
      </p>
    </ng-container>
  </div>

  <!-- For orga view -->
  <div *ngIf="participant?.role == 'organizator' && step == 'past'">
    <div class="col col-11 gutters align-center">
      <p class="text-center text-large m-s-t">
        <b>{{ 'VISIO.end' | translate }}</b>
      </p>
      <p class="text-center text-large m-xs-t">
        {{ 'VISIO.thanks' | translate }}
      </p>
    </div>
  </div>

  <onboarding-participant
    *ngIf="participant?.role == 'participant' && !onBoardingValidated && !meet?.options?.conference_url"
    [step]="step"
    [meet]="meet"
    [confCode]="confCode"
  ></onboarding-participant>

  <onboarding-organizator
    *ngIf="participant?.role == 'organizator' && !onBoardingValidated && !meet?.options?.conference_url"
    [step]="step"
    [meet]="meet"
  ></onboarding-organizator>

  <div
    [ngClass]="{ 'hide': (viewType$ | async) === 'mobile' || !onBoardingValidated }"
    class="p-tb-30"
    id="visioboard"
    style="height: 90vh"
  ></div>
</div>

