
import { pluck } from 'rxjs/operators';
// Core
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITheme } from '../../../shared/index';

// Definitions
import { Group } from '../../../shared/definitions';
import { AppStore } from 'src/app/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'snippet-group',
  templateUrl: './snippet-group.component.html'
})
export class SnippetGroupComponent implements OnChanges {
  @Input() groups:  Group[];
  @Input() title:   { [lang: string]: string };

  selectedGroup:                 Group;
  theme:                         ITheme;
  subscriptions: Subscription[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _appStore: AppStore
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  ngOnChanges(change: SimpleChanges) {
    // console.log('onChange group', change); // Debug purpose only
    if (change.groups
      && change.groups.currentValue
      && change.groups.currentValue.length
    ) {
      this.selectedGroup = change.groups.currentValue[0];
    }
    // console.log('onChange group', this); // Debug purpose only
  }

  navigateToGroup(group: Group) {
    const principalGroupId = this._route.parent.snapshot.params['id'];

    if (!principalGroupId) {
      if (group.options && group.options.master_group_id) {
        this.navigateToGroupLink(`${group.options.master_group_id}`, `${group.id}`);
      }
      else {
        this._router.navigate(['/home', group.id])
      }
    }
    else if (group.id == parseInt(principalGroupId)) {
      this._router.navigate(['/home', group.id])
    }
    else if (group.options && group.options.own_url) {
      window.open(group.options.own_url, '_blank');
    }
    else {
      this.navigateToGroupLink(principalGroupId, `${group.id}`);
    }
  }

  navigateToGroupLink(principalGroupId: string, groupId: string) {
    this._appStore.load('GroupLink', principalGroupId, { origin_group_id: groupId })
      .entityByProps
      .subscribe(
        link => this._router.navigate(['/home', principalGroupId, link.flag, groupId]),
        error => this._router.navigate(['/home', principalGroupId, 'basic', groupId])
      );
  }

  selectGroup(group: Group) {
    this.selectedGroup = group;
  }
}
