import { of as observableOf,  Observable, Subscription } from 'rxjs';

import { map, tap, mergeMap } from 'rxjs/operators';
import { Component, OnInit, Input,
  Output, EventEmitter, ViewChild }  from '@angular/core';
import { AppStore }  from '../store/';
import { Meet, QueryForm, Participant,
  User, Invitation, AskQuestionsFormComponent }  from '../shared/';
import { AuthService } from '../auth/index';
import { ActivatedRoute } from '@angular/router';
import { MeetService } from './meet.service';
import { AuthRedirectService } from '../auth/auth-redirect.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'confirm-participation',
  templateUrl: './confirm-participation.component.html'
})

export class ConfirmParticipationComponent implements OnInit {

  @ViewChild(AskQuestionsFormComponent) questionsForm: AskQuestionsFormComponent;

  @Output() confirm: EventEmitter<Participant> = new EventEmitter<Participant>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() meet: Meet;
  @Input() action: 'going'|'invitation';

  user:         User;
  userIsNew:    boolean;

  message:      string;
  code:         string = '';

  errorMsg:         string = '';
  queryForm:        QueryForm;
  showNewUserForm:  boolean;

  form:             FormGroup;

  subsrciptions: Subscription[] = [];

  fetchingUserAsParticipant:  boolean;
  userAsParticipant:          Participant;

  isButtonDisabled:           boolean;

  constructor(
    private _appStore:            AppStore,
    private _authService:         AuthService,
    private _authRedirectService: AuthRedirectService,
    private _meetService:         MeetService,
    private _route:               ActivatedRoute,
    private _fb:                  FormBuilder,
  ) { }

  ngOnInit() {
    if (this.meet.moderation == 'on_invitation') {
      this.fetchingUserAsParticipant = true;
      this._meetService.loadMyParticipant(`${this.meet.id}`)
        .subscribe(res => {
          if (res && res.id) {
            this.userAsParticipant = res;
          }
          else {
            // If user is not a participant, then he is not invited. So we delete the message to only display
            // a warning saying he's not invited.
            this.message = '';
          }
          this.fetchingUserAsParticipant = false;
        });
    }

    if (this.action == 'invitation') {
      this.onInvitation();
    }

    this.setInfoMessage();

    if (this.meet.mode === 'hybrid') {
      this.form = this._fb.group({
        location: ['', Validators.required]
      });
    } else {
      this.form = this._fb.group({});
    }

    const sub = this._appStore.load('MeetQueryForms', `${this.meet.id}`, { category: 'poll', kind: 'blueprint' })
      .entities
      .subscribe(queryForms => {
        if (queryForms.length > 0) {
          this.queryForm = queryForms[0];
        }
      });

    this.subsrciptions.push(sub);
  }

  ngOnDestroy() {
    this.subsrciptions.forEach(sub => sub.unsubscribe());
  }

  onInvitation() {
    this.userIsNew = (this._route.snapshot.params['new_user'] == 'true');
    if (!this._authService.isLoggedIn && this.userIsNew) {
      this.user = new User();
      this.showNewUserForm = true;
    }
  }

  setInfoMessage() {
    // If meet is hybrid, we disable waiting list
    if (this.meet.mode === 'hybrid') {
      return;
    }
    if (this.meet.moderation == 'moderate') {
      this.message = 'put-on-waiting-list';
    }
    else if (this.meet.options && this.meet.options.max_participants && +this.meet.options.max_participants <= this.meet.participants_count) {
      this.message = 'put-on-waiting-list';
    }
  }

  onSubmit() {
    if (!this.userIsValid() || !this.form.valid) {
      this.errorMsg = 'err-user'
      return ;
    }

    if (!this._authService.isLoggedIn && this.action == 'going') {
      this._authRedirectService.authenticateThenGoBack({ tab: 'register' }, this._route.snapshot.queryParams['_']);
    }

    if (this.action == 'invitation') {
      this.acceptInvitation();
    }
    else {
      this.joinMeet();
    }
  }

  joinMeet() {
    this.isButtonDisabled = true;
    let toCreate = { role: 'participant' };

    if (this.queryForm && this.queryForm.queries && this.queryForm.queries.length > 0) {
      toCreate['queries'] = this.questionsForm.prepareToSubmit(this.form.value.questions);
    }

    if (this.meet.mode === 'hybrid') {
      toCreate['location'] = this.form.value.location;
    }

    const sub = this._appStore.create('Participants', `${this.meet.id}`, toCreate)
      .subscribe(
        participant => {
          this.confirm.emit(participant);
          this.isButtonDisabled = false;
        },
        error => {
          if (error == 'Participant is already appended, with status active') {
            this.errorMsg = 'err-already-participant-active';
          }
          else if (error == 'Participant is already appended, with status pending') {
            this.errorMsg = 'err-already-participant-pending';
          }
          else if (error == 'Participant is already appended, with status suspended') {
            this.errorMsg = 'err-participant-suspended';
          }
          else {
            this.errorMsg = 'err-general';
          }
        });
    this.subsrciptions.push(sub);
  }

  acceptInvitation() {
    const { token } = this._route.snapshot.params;

    this._meetService.manageInvitations('accept', token).pipe(
      tap(({ auth_key, user, participant }) => this._authService.init({ auth_key, user })),
      tap(_ => {
        if (this.userIsNew) {
          this._authService.updateAccount({
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            password: this.user.password
          });
        }
      }),
      mergeMap(({ participant }) => {

        if (this.queryForm && this.queryForm.queries && this.queryForm.queries.length > 0) {
          return this.createQueryReportFor(participant);
        }
        else {
          return observableOf(participant);
        }
      }),)
      .subscribe(
        (participant: Participant) => this.confirm.emit(participant),
        ({ error }) => {
          if (error.error == "Couldn't find Invitation") {
            this.errorMsg = 'err-no-invitation';
          }
          else {
            this.errorMsg = 'err-general';
          }
        }
      );
  }

  userIsValid() {
    if (!this.userIsNew)
      return true;

    if (this.user && (!this.user.first_name || this.user.first_name.length < 3)) {
      this.errorMsg = 'err-user';
      return false;
    }
    else if (this.user && (!this.user.last_name || this.user.last_name.length < 3)) {
      this.errorMsg = 'err-user';
      return false;
    }
    else if (this.user && (!this.user.password || this.user.password.length < 8)) {
      this.errorMsg = 'err-user';
      return false;
    }

    return true;
  }

  onClickBack() {
    this.cancel.next();
  }

  createQueryReportFor(participant: Participant): Observable<Participant> {
    const toCreate = {
      target_type: 'Participant',
      target_id: participant.id,
      blueprint_id: this.queryForm.id,
      queries: this.questionsForm.prepareToSubmit(this.form.value.questions),
      category: 'poll',
      kind: 'report'
    };

    return this._appStore.create('QueryForm', null, toCreate).pipe(map(({ participant }) => participant));
  }
}
