import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AppStore } from '../store/';
import { Meet, QueryForm, Participant, User, AskQuestionsFormComponent } from '../shared/';
import { NotificationService } from '../core/notification/notification.service';
import { timer, Subscription } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { TrackingService } from '../core/tracking.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  animations: [
    trigger('clapCount', [
      state('show', style({
        opacity: 1,
        top: '-40px'
      })),
      state('hide', style({
        opacity: 0,
        top: '0px'
      })),
      transition('hide => show', [
        animate('.3s')
      ]),
      transition('show => hide', [
        animate('.1s .5s ease-out')
      ])
    ]),
    trigger('clapBtnBorder', [
      state('normal', style({
        borderColor: 'rgba(189, 195, 199, 1)'
      })),
      state('active', style({
        borderColor: 'rgba(39, 174, 96, 1)'
      })),
      transition('normal => active', [
        animate('.3s')
      ]),
      transition('active => normal', [
        animate('.1s .2s ease-in')
      ])
    ])
  ],
  templateUrl: './meet-feedbacks.component.html',
  styleUrls: ['./meet-feedbacks.component.sass']
})

export class MeetFeedbackComponent implements OnInit {

  @ViewChild(AskQuestionsFormComponent) questionsForm: AskQuestionsFormComponent;

  user:           User;

  meet:           Meet;
  queryForm:      QueryForm;
  form:           FormGroup;

  groupId:        string;
  organizator:    Participant;

  isSubmitting:   boolean;
  clapper:        { isClapping: boolean, myCurrentClaps: number, myTotalClaps: number } = { isClapping: false, myCurrentClaps: 0, myTotalClaps: 0 };

  sub:            Subscription;

  constructor(
    private _appStore:  AppStore,
    private _route:     ActivatedRoute,
    private _router:    Router,
    private _fb:                  FormBuilder,
    private _authService:         AuthService,
    private _trackingService:     TrackingService,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.groupId = this._route.parent.snapshot.params['id'];

    const queryFormId = this._route.snapshot.params['id'];

    this.sub = this._appStore.load('QueryForm', queryFormId)
      .entity
      .pipe(
        map(
          (queryForm: QueryForm) => {
            this.form = this._fb.group({
              comment: ['']
            });

            this.queryForm = queryForm;
            this.queryForm.rating = undefined;
            if (queryForm.message) {
              this.queryForm.message = queryForm.message.replace(/\n|\r/g, '<br>');
            }
            return queryForm.meet;
          },
        ),
        switchMap((meet: Meet) => this._appStore.load('Participants', `${meet.id}`, { role: 'organizator' }).entities)
      )
      .subscribe(
        (orgs: Participant[]) => {
          if (orgs.length > 0) {
            this.organizator = orgs[0];
          }
        }
      );

    this._authService.getUser()
      .subscribe((user: User) => this.user = user);

    this._trackingService.trackEvent('Meet Feedback', 'feedback_init', queryFormId);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  rate(mark: number) {
    this.queryForm.rating = mark;
  }

  onClap() {
    this.clapper.isClapping = true;
    this.clapper.myCurrentClaps += 1;
    this.clapper.myTotalClaps += 1;

    const myCurrentClaps: number = this.clapper.myCurrentClaps;
    timer(500).subscribe(_ => {
      if (this.clapper.myCurrentClaps == myCurrentClaps) {
        this.clapper.isClapping = false;
        this.clapper.myCurrentClaps = 0;
      }
    });

  }

  navigateToMeet(fragment?: string) {
    if (this.groupId) {
      this._router.navigate(['/home', this.groupId, 'rencontre', this.queryForm.meet.id], { fragment, replaceUrl: !!fragment });
    }
    else {
      this._router.navigate(['/fr', 'rencontre', this.queryForm.meet.id, { fragment, replaceUrl: !!fragment }]);
    }
  }

  onSubmit(queryForm: QueryForm, form: FormGroup) {
    if (!form.valid) {
      this._notificationService.setNotification({
        type: 'error',
        content: 'FEEDBACK.err-all-questions'
      }, 10000);
      return ;
    }

    this.isSubmitting = true;

    const queries = this.questionsForm.prepareToSubmit(form.value.questions);

    const report = {
      category: 'feedback',
      kind: 'report',
      blueprint_id: queryForm.id,
      target_id: queryForm.meet.id,
      target_type: 'Meet',
      claps: this.clapper.myTotalClaps,
      options: { comment: form.controls.comment.value },
      rating: queryForm.rating,
      queries
    };

    this._appStore.create('QueryForm', null, report)
      .subscribe(
        res => {
          this.isSubmitting = false;
          this._trackingService.trackEvent('Meet Feedback', 'feedback_submitted', `${queryForm.id}`);
          this._notificationService.setNotification({
            type: 'success',
            content: 'FEEDBACK.success'
          });
          this.navigateToMeet('chat');
        },
        err => {
          this.isSubmitting = false;
          this._notificationService.setNotification({
            type: 'error',
            content: 'FEEDBACK.err-server'
          });
        }
      );
  }
}
