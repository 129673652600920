<div class="input-mails">

  <div *ngFor="let mail of mails; index as i" class="mail zoom-in" (click)="editMail($event, i)">
    <input *ngIf="mail.isEditable" type="mail" class="edit-input" [class.error]="!mail.isValid" [(ngModel)]="mail.value" (blur)="lockMail(i)"
      (keydown.enter)="$event.preventDefault(); lockMail(i)" autofocus>
    <span *ngIf="!mail.isEditable">{{ mail.value }}</span>
    <ng-container *ngIf="!mail.isEditable">
      <span class="remove" (click)="removeMail($event, i)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </ng-container>
  </div>

  <input class="main-input" type="mail" [id]="inputId" [name]="name" [(ngModel)]="localValue" (ngModelChange)="onChange()"
    (keydown.backspace)="editLastMail()" (blur)="extractMails()" (keydown.enter)="$event.preventDefault(); extractMails()"
  />

</div>

<div *ngIf="hasError" class="error">
  {{ 'INPUT-MAILS.error' | translate }}
</div>