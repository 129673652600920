import { Component, OnInit }  from '@angular/core';

@Component({
  template: `
    <div class="fade-in-anim">
      <div class="page-title hidden-if-not-mobile text-orange p-lr-40">bureau partagé</div>
      <div class="main-image flex">
        <img src="assets/images/home/shared-office.jpeg" alt="people sharing an opened office">
      </div>
      <div class="post-block">
        <div class="post bg-light-yellow text-post-orange">
          <div class="text">
            <p class="paragraph">
              Vous voulez créer une structure en lien avec l'économie sociale et solidaire ? C'est déjà fait ? Rejoignez l'un de nos bureaux partagés. On partage des espaces de travail, des salles de réunion, des projets et la gouvernance de kawaa. Si ça te dit, une place t'attend à Paris, Lille… Il n'y a pas de kawaa là où tu es ? C'est pas grave, on va le créer ensemble !
            </p>
          </div>
          <div class="borders">
            <div class="border-t-post-orange"></div>
            <div class="border-r-post-orange"></div>
            <div class="border-b-post-orange"></div>
            <div class="border-l-post-orange"></div>
          </div>
        </div>
      </div>
      <div class="flex image-banner">
        <div class="left-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/office-plant.jpeg" alt="a plant on a office desk">
        </div>
        <div class="right-image inline-block half-width">
          <img class="image-cover" src="assets/images/home/office-working.jpeg" alt="3 people coworking in an office">
        </div>
      </div>
    </div>
  `
})

export class SharedOfficeComponent implements OnInit {

  ngOnInit() {}
}
