import { Inject, Injectable, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Event as NavigationEvent, ActivatedRoute } from '@angular/router';
import { Meet } from '../shared/definitions/meet';
import { Group } from '../shared/definitions/group';
import { TranslateService } from '@ngx-translate/core';

declare var _mtm: any;

/*<link rel="apple-touch-icon-precomposed" sizes="57x57" href="apple-touch-icon-57x57.png" />*/
//<link rel="apple-touch-icon-precomposed" sizes="114x114" href="apple-touch-icon-114x114.png" />
//<link rel="apple-touch-icon-precomposed" sizes="72x72" href="apple-touch-icon-72x72.png" />
//<link rel="apple-touch-icon-precomposed" sizes="144x144" href="apple-touch-icon-144x144.png" />
//<link rel="apple-touch-icon-precomposed" sizes="60x60" href="apple-touch-icon-60x60.png" />
//<link rel="apple-touch-icon-precomposed" sizes="120x120" href="apple-touch-icon-120x120.png" />
//<link rel="apple-touch-icon-precomposed" sizes="76x76" href="apple-touch-icon-76x76.png" />
//<link rel="apple-touch-icon-precomposed" sizes="152x152" href="apple-touch-icon-152x152.png" />
//<link rel="icon" type="image/png" href="favicon-196x196.png" sizes="196x196" />
//<link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96" />
//<link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
//<link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
//<link rel="icon" type="image/png" href="favicon-128.png" sizes="128x128" />
//<meta name="application-name" content="&nbsp;"/>
//<meta name="msapplication-TileColor" content="#FFFFFF" />
//<meta name="msapplication-TileImage" content="mstile-144x144.png" />
//<meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
//<meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
//<meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
/*<meta name="msapplication-square310x310logo" content="mstile-310x310.png" />*/

@Injectable()
export class MetaService {
  private _favIconsLinks: any = [
    { rel: 'icon', type: 'image/png', sizes: '196x196', href: 'favicon-196x196.png' },
    { rel: 'icon', type: 'image/png', sizes: '96x96',   href: 'favicon-96x96.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32',   href: 'favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16',   href: 'favicon-16x16.png' },
    { rel: 'icon', type: 'image/png', sizes: '128x128', href: 'favicon-128.png' },
    //{ rel: "apple-touch-icon-precomposed", sizes: "57x57",    href: "apple-touch-icon-57x57.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "114x114",  href: "apple-touch-icon-114x114.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "72x72",    href: "apple-touch-icon-72x72.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "144x144",  href: "apple-touch-icon-144x144.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "60x60",    href: "apple-touch-icon-60x60.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "120x120",  href: "apple-touch-icon-120x120.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "76x76",    href: "apple-touch-icon-76x76.png" },
    //{ rel: "apple-touch-icon-precomposed", sizes: "152x152",  href: "apple-touch-icon-152x152.png" },
  ];

  constructor(
    @Inject(DOCUMENT)
    private _doc:      any,
    private _title:    Title,
    private _route:    ActivatedRoute,
    private _translate: TranslateService,
  ) { }

  findLastChild(_route: ActivatedRoute) {
    const snapshot = _route.snapshot;

    let child = snapshot.firstChild;
    while (child && child.firstChild !== null) {
      child = child.firstChild;
    }

    return (child ? child.data : {});
  }

  setLinkTag(props: { rel: string, href: string, type?: string }) {
    let el: HTMLElement = this._doc.querySelector(`link[rel='${props.rel}']`);

    if (!el) {
      el = this._doc.createElement('link');
      if (props.type) {
        el.setAttribute('type', props.type);
      }
      el.setAttribute('rel', props.rel);
      el.setAttribute('href', props.href);

      this._doc.head.appendChild(el);
    }
    else {
      if (props.type) {
        el.setAttribute('type', props.type);
      }
      el.setAttribute('rel', props.rel);
      el.setAttribute('href', props.href);
    }
  }

  setMatomoTagManager(containerId: string) {
    try {
      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    }
    catch(err) {
      console.log('_mtm is not defined');
    }

    const el = this._doc.createElement('script'); 
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', true);
    el.setAttribute('defer', true);
    el.setAttribute('src', `https://piwik.kawaa.co/js/container_${containerId}.js`);

    this._doc.head.appendChild(el);
  }

  setTitle(title: string = ''): MetaService {
    this._getOrCreateMetaTag('title').setAttribute('content', title);
    this._getOrCreateMetaTag('og:title').setAttribute('content', title);
    this._getOrCreateMetaTag('twitter:title').setAttribute('content', title);

    this._title.setTitle(title);
    return this;
  }

  setTag(tag: string, value: string = ''): MetaService {
    if (tag === 'title') {
      throw new Error(`Attempt to set ${tag} through 'setTag': 'title' is reserved tag name.
      Please use 'MetaService.setTitle' instead`);
    }

    this._getOrCreateMetaTag(tag).setAttribute('content', value);

    return this;
  }

  updateMetaTags(meta: any = {}) {
    this.setTitle(meta.title);

    Object.keys(meta).forEach(key => {
      if (key === 'title') {
        return;
      }
      this.setTag(key, meta[key]);
    });
  }

  setDefaultFavicon() {
    let el: HTMLElement = this._doc.createElement('link');
    el.setAttribute('rel', 'icon');
    el.setAttribute('type', 'image/png');
    el.setAttribute('href', '/assets/images/favicons/kawaa.png');
    this._doc.head.appendChild(el);
  }

  setHomepageFavicon() {
    let el: HTMLElement = this._doc.createElement('link');
    el.setAttribute('rel', 'icon');
    el.setAttribute('type', 'image/png');
    el.setAttribute('id', 'default-homepage-favicon');
    el.setAttribute('href', '/assets/images/favicons/homepage/favicon.ico');
    this._doc.head.appendChild(el);
  }

  removeHomepageFavicon() {
    let el: HTMLElement = document.getElementById('default-homepage-favicon')
    if (el !== null)
      el.remove()
  }

  setFavicon(iconsDir: string) {
    this._favIconsLinks.forEach(fav => {

      const query = Object.keys(fav).reduce((prev, key) => {
        if (key != 'href')
          prev += `[${key}='${fav[key]}']`;
        return prev;
      }, 'link');

      let el: HTMLElement = this._doc.querySelector(query);
      if (!el) {
        el = this._doc.createElement('link');
        Object.keys(fav).forEach(key => {
          if (key == 'href') {
            const dir = iconsDir.substr(-1) == '/' ? iconsDir : iconsDir + '/';
            el.setAttribute('href', `${dir}${fav[key]}`);
          }
          else {
            el.setAttribute(key, fav[key]);
          }
        });
        this._doc.head.appendChild(el);
      }
    });

    return this;
  }

  setGropHomeStructuredData(group: Group) {
    const el: HTMLElement = this._doc.querySelector('script[k-name="group-home"]');

    if (!el) return;

    el.innerHTML = JSON.stringify(this._contructGroupStructData(group));
  }

  setGroupMeetsStructuredData(meets: Meet[], group: Group) {
    const el: HTMLElement = this._doc.querySelector('script[k-name="group-meets"]');

    if (!el) return;

    const data = meets.map((meet: Meet) => this._contructMeetStructData(meet, group));
    el.innerHTML = JSON.stringify(data);
  }

  setSingleMeetStructedData(meet: Meet) {
    const el: HTMLElement = this._doc.querySelector('script[k-name="single-meet"]');

    if (!el) return;

    el.innerHTML = JSON.stringify(this._contructMeetStructData(meet));
  }

  removeStructuredData(name: 'single-meet'|'group-meets'|'group-home') {
    const el: HTMLElement = this._doc.querySelector(`script[k-name="${name}"]`);

    if (!el) return;

    el.innerHTML = '';
  }

  private _contructGroupStructData(group: Group) {
    let data: any = {
      '@context': 'http://schema.org/',
      '@type': 'Organization',
      "name": group.name,
      'url': group.options && group.options.own_url ? `${group.options.own_url}home/${group.id}`: `https://www.kawaa.co/home/${group.id}`,
      'description': group.description
    };

    if (group.avatar && group.avatar.category != 'default_group_avatar') {
      data.logo = group.avatar.slug;
    }
    if (group.photos && group.photos.length > 0 && group.photos[0].category != 'default_group_photo') {
      data.image = group.photos[0].slug;
    }
    if (group.extra_infos && group.extra_infos.length > 0) {
      const website = group.extra_infos.find(_ => _.name == 'website');
      const twitter = group.extra_infos.find(_ => _.name == 'twitter');
      const fb = group.extra_infos.find(_ => _.name == 'facebook');
      if (website) {
        data.sameAs = website.value;
      }
      else if (fb) {
        data.sameAs = fb.value;
      }
      else if (twitter) {
        data.sameAs = twitter.value;
      }
    }
    if (group.location) {
      data.address = {
        '@type': 'PostalAddress',
        'addressLocality': group.location.city,
        'addressCountry': group.location.country
      }
    }

    return data;
  }

  private _contructMeetStructData(meet: Meet, group?: Group) {
    const loc_name = meet.place ? meet.place.name : meet.location.name;
    const loc = meet.place ? meet.place.location : meet.location;

    const meetDateTimeStart = meet.datetime;
    let meetDateTimeEnd;

    try {
      const date = meetDateTimeStart.split('T')[0];
      const timeZone = meetDateTimeStart.split('.')[1];
      const d = new Date(meetDateTimeStart);
      d.setMinutes(d.getMinutes() + 90);
      meetDateTimeEnd = `${date}T${d.toLocaleTimeString()}.${timeZone}`;
    }
    catch(e) {
      meetDateTimeEnd = meetDateTimeStart;
    }

    let url: string;

    if (group) {
      const prefixUrl = group.options && group.options.own_url ? group.options.own_url : `https://www.kawaa.co/home/${group.id}/`;
      url = prefixUrl + `rencontre/${meet.id}`;
    }


    return {
      '@context': 'http://schema.org/',
      '@type': 'Event',
      'name': meet.name,
      'url': url || this._getTagContent('og:url'),
      'description': meet.description,
      'startDate': meetDateTimeStart,
      'endDate': meetDateTimeEnd,
      'image': meet.photos.map(_ => _.slug),
      'location': {
        '@type': 'Place',
        'name': loc_name,
        'address': {
          '@type': 'PostalAddress',
          'streetAddress': loc.address,
          'addressLocality': loc.city,
          'postalCode': loc.zip_code,
          'addressCountry': loc.country
        },
        'geo': {
          '@type': 'GeoCoordinates',
          'latitude': loc.lat,
          'longitude': loc.lng
        }
      },
      'offers': {
        '@type': 'Offer',
        'availability': meet.options && parseInt(`${meet.options.max_participants}`) < meet.participants_count ? 'http://schema.org/SoldOut' : 'http://schema.org/InStock',
        'price': meet.options && meet.options.price ? meet.options.price : 0,
        'validFrom': meet.created_at,
        'url': url || this._getTagContent('og:url'),
        'priceCurrency': 'EUR'
      },
      'performer': {
        '@type': 'Person',
        'name': meet.options && meet.options.organizator ? meet.options.organizator : ''
      }
    };
  }

  private _getTagContent(tag: string): string {
    const el: HTMLElement = this._doc.querySelector(`meta[name='${tag}']`);

    return el.getAttribute('content');
  }

  private _getOrCreateMetaTag(name: string): HTMLElement {
    let el: HTMLElement = this._doc.querySelector(`meta[name='${name}']`);
    if (!el) {
      el = this._doc.createElement('meta');
      el.setAttribute('name', name);
      el.setAttribute('property', name);
      this._doc.head.appendChild(el);
    }
    else {
      el.setAttribute('property', name);
    }
    return el;
  }
}
