import { Input, Output, EventEmitter, Component, OnInit, OnDestroy }  from '@angular/core';
import { Group, Meet, ITranslatedValue }  from '../shared/';
import { TrackingService } from '../core/tracking.service';
import { MetaService } from '../core/meta.service';
import { AppStore } from '../store/app.store';
import { first } from 'rxjs/operators';

interface IGroupMeetsConfig {
  view_type?:         'list'|'map';
  mapbox_id?:         string;
  predefined_tags?:   string[];
  predefined_tags_with_image?: { name: string, slug: string, size: number[] }[];
  search_bar?:       {
    by_tag?:            boolean,
    by_city?:           boolean,
    by_arrondissement?: boolean,
    by_date?:           boolean,
    by_language?:       boolean
  };
  meet_naming?:       ITranslatedValue;
  meet_creation_box?: {
    text:   ITranslatedValue,
    hide:   boolean;
  };
}

@Component({
  selector: 'group-meets',
  templateUrl: './group-meets.component.html'
})

export class GroupMeetsComponent implements OnInit, OnDestroy {

  @Input() group:         Group;
  @Input() config:        IGroupMeetsConfig = {};
  @Input() sectionTitle:  ITranslatedValue;

  @Output() selectMeet:   EventEmitter<Meet> = new EventEmitter<Meet>();
  @Output() createMeet:   EventEmitter<boolean> = new EventEmitter<boolean>();

  creationBox: { text: ITranslatedValue, hide: boolean } = { text: { fr: 'Proposer un événement' }, hide: false };
  color: string;

  constructor(
    private _metaService:     MetaService,
    private _appStore:        AppStore,
    private _trackingService: TrackingService
  ) { }

  ngOnInit() {
    if (this.config && this.config.meet_creation_box) {
      this.creationBox = this.config.meet_creation_box;
    }

    if (this.group && this.group.options && this.group.options.colors) {
      this.color = this.group.options.colors.primary;

      this._appStore.load('GroupMeets', `${this.group.id}`, { time_scope: 'future' })
        .entities
        .pipe(first())
        .subscribe((meets: Meet[]) => {
          this._metaService.setGroupMeetsStructuredData(meets, this.group);
        });
    }
  }

  ngOnDestroy() {
    this._metaService.removeStructuredData('group-meets');
  }

  onSelectMeet(meet: Meet) {
    this.selectMeet.emit(meet);
  }

  onCreateMeet() {
    this._trackingService.trackEvent('Group Page', 'create_meet_from_list', `${this.group.id}`);
    this.createMeet.emit(true);
  }
}
