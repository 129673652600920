import {
  Injectable
}             from '@angular/core';
import {
  ReplaySubject
,
  Observable
}             from 'rxjs';



@Injectable()
export class LoaderService {

  loader: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
  ) {

  }

  getEvent(): Observable<boolean> {
    return this.loader.asObservable();
  }

  show(value: boolean) {
    this.loader.next(value);
  }

}
