
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { Renderer2, ElementRef, OnInit,
  Directive, Inject, forwardRef,
  Input } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { AppStore } from '../../store/app.store';

@Directive({
    selector: 'img[fillSvgColors][src]'
})

export class FillSvgColorsDirective implements OnInit {

  @Input('src')               src:      string;
  @Input('color')             color:    string;
  @Input('opacity')           opacity:  number = 0.5;

  public defaultColor: string = '#E1E3E4';
  constructor(
    private _http:                HttpClient,
    private _route:               ActivatedRoute,
    private _renderer:            Renderer2,
    private _el:                  ElementRef,
    @Inject(forwardRef(() => AppStore)) private _appStore:            AppStore
  ) {
  }

  ngOnInit(): void {
    this._route.queryParams.pipe(
      filter(params => params['_'] ? true : false),
      first(),
      map(params => params['_']),
      mergeMap(id => this._appStore.load('Groups', id).entity),)
      .subscribe(group => {
        let colorToSet: string;
        if (group.options && group.options.colors && group.options.colors.primary) {
          colorToSet = group.options.colors.primary;
        }
        if (this.color)
          colorToSet = this.color;

        const newColor = `rgba(${this.hexToRgbaColor(colorToSet, this.opacity)})`;
        this.replaceColorInSvg(newColor);
      });

    this._route.queryParams.pipe(
         filter(params => params['_'] ? false : true),
         first(),)
         .subscribe(group => {
           const colorToSet = this.color ? this.color : this.defaultColor;
           const newColor = `rgba(${this.hexToRgbaColor(colorToSet, 1)})`;
           this.replaceColorInSvg(newColor);
         });
  }

  replaceColorInSvg(newColor: string) {
    this._http.get(this.src, { responseType: 'text' })
      .subscribe(data => {
        const replaced = data.replace(/{{ color }}/g, newColor);
        const newSrc = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(replaced);
        this._renderer.setAttribute(this._el.nativeElement, 'src', newSrc);
      });
  }


  private hexToRgbaColor(color: string, opacity: number): string {
    if (color == '' || !color)
      return '';

    const tranformToHex = (n: string) => parseInt('0x' + n);

    const first = tranformToHex(color.substr(1, 2)).toString();
    const second = tranformToHex(color.substr(3, 2)).toString();
    const third = tranformToHex(color.substr(5, 2)).toString();

    return first + ',' + second + ',' + third + ',' + opacity.toString();
  }

}
