import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { filter, first, map } from 'rxjs/operators';
import { User } from '../shared/definitions/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '.';


@Injectable()
export class HelpscoutService {

  isInit: boolean;
  headers: HttpHeaders;
  suggestedData: any;
  foundedIds: string[];

  constructor(
    private _auth: AuthService,
    private http: HttpClient,
    private api: ApiConfig
  ) {
  }

  init() {
    const win = window as any;

    if (!win && !win.Beacon) {
      console.error('Beacon not defined');
      return;
    }

    if (!this.isInit) {
      win.Beacon('init', 'e6c144f6-41eb-41da-a95a-e764599af3c5');
      this.isInit = true;
      this.identify();
    }
    else {
      console.error('Beacon already init');
    }
  }

  destroy() {
    const win = window as any;

    if (win && win.Beacon && this.isInit) {
      win.Beacon('reset');
      win.Beacon('destroy');
      this.isInit = false;
    }
    else {
      console.error('Beacon not init or not defined');
    }
  }

  open() {
    const win = window as any;

    if (!win && !win.Beacon) {
      console.error('Beacon not defined');
      return;
    }

    if (!this.isInit) {
      this.init();
    }

    win.Beacon('config', { mode: 'askFirst' });
    win.Beacon('open');
  }

  prefillAndOpen(data: { subject: string, text: string, email?: string }) {
    const win = window as any;

    if (!win && !win.Beacon) {
      console.error('Beacon not defined');
      return;
    }

    if (!this.isInit) {
      this.init();
    }

    win.Beacon('config', { mode: 'askFirst' });
    win.Beacon('open');
    win.Beacon('prefill', data);
    win.Beacon('on', 'close', () => win.Beacon('reset'));
  }

  private identify() {
    const win = window as any;

    this._auth.getUser()
      .pipe(
        filter(user => user && user.id ? true : false),
        first()
      )
      .subscribe((user: User) => {
        win.Beacon('identify', {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          avatar: user.avatar && user.avatar.slug ? user.avatar.slug : null
        });
      });
  }

  suggestArticle(param: string) {
    const win = window as any;
    if (!this.isInit) {
      this.init();
    }
    win.Beacon('config', { style: 'icon', iconImage: 'search', hideFABOnMobile: true });
    this.http.get(this.api.getPath('options/web/helpscoutSuggestion'), { headers: this.api.headers() })
      .subscribe((res: { data: { articles: {} } }) => {
        if (res && res.data && res.data.articles) {
          this.suggestedData = res.data.articles;
          this.suggestedData.find(el => {
            if (el.name == param) {
              this.foundedIds = el.ids;
            }
          });
          win.Beacon('suggest', this.foundedIds);
          win.Beacon('on', 'close', () => win.Beacon('reset'));
        }
      });
  }
}

