<div class="w100 m-x-t">
  <div class="content-width">
    <!--<div class="text-very-large">-->
      <!--<span class="big-title bold m-xs-r">-->
      <!--</span>-->
    <!--</div>-->
    <div class="m-m-b p-b-20">
        <span class="big-title bold m-xs-r m-m-b">
          {{ 'OFFERS.discover' | translate }}
        </span>
        <a routerLink="/st/nos-offres" class="text-very-large link-underline">
          {{ 'OFFERS.offer' | translate }}
        </a>
    </div>
    <div
      [ngClass]="{
        'row-wrap': (viewType$ | async) === 'mobile'
      }"
      class="row align-middle justify-space-between m-x-b"
    >
      <div
        [ngStyle]="{
          'border-right': (viewType$ | async) === 'desktop' ? '1px solid #efefef' : 'none'
        }"
        class="col col-4 flex-column space-around text-center p-lr-10 p-tb-10"
      >
        <div class="m-m-b">
          <img
            src="/assets/images/premium1.svg"
            alt="Free offer"
            class="grayscale-img align-center img-contain img-offer"
          />
          <h3 class="m-s-t text-very-large bold">
            {{ 'OFFERS.free-title' | translate }}
          </h3>
          <p
            class="m-m-t text-large p-baseline"
            [innerHtml]="'OFFERS.free-baseline' | translate"
          >
          </p>
        </div>
        <div *ngIf="showActions" class="push-bottom">
          <a
            href="/st/creer-un-groupe-c-est"
            class="button button-big outline-black cta align-center"
          >
            {{ 'OFFERS.free-cta' | translate }}
          </a>
        </div>
      </div>
      <div
        [ngStyle]="{
          'border-right': (viewType$ | async) === 'desktop' ? '1px solid #efefef' : 'none'
        }"
        class="col col-4 flex-column space-around text-center p-lr-10 p-tb-10"
      >
        <div class="m-m-b">
          <img
            src="/assets/images/premium2.svg"
            alt="Premium offer"
            class="grayscale-img align-center img-contain img-offer"
          />
          <h3 class="m-s-t text-very-large bold">
            {{ 'OFFERS.premium-title' | translate }}
          </h3>
          <p
            class="m-m-t text-large p-baseline"
            [innerHtml]="'OFFERS.premium-baseline' | translate"
          >
          </p>
        </div>
        <div *ngIf="showActions" class="push-bottom">
          <a
            href="javascript:void(0)"
            (click)="onGetDemo()"
            class="button button-big outline-black cta align-center"
          >
            {{ 'OFFERS.premium-cta' | translate }}
          </a>
        </div>
      </div>
      <div class="col col-4 flex-column space-around text-center p-lr-10 p-tb-10">
        <div class="m-m-b">
          <img
            src="/assets/images/premium3.svg"
            alt="Premium plus offer"
            class="grayscale-img align-center img-contain img-offer"
          />
          <h3 class="m-s-t text-very-large bold">
            {{ 'OFFERS.premium-plus-title' | translate }}
          </h3>
          <p
            class="m-m-t text-large p-baseline"
            [innerHtml]="'OFFERS.premium-plus-baseline' | translate"
          >
          </p>
        </div>
        <div *ngIf="showActions" class="push-bottom">
          <a
            href="javascript:void(0)"
            (click)="onContact()"
            class="button button-big outline-black cta align-center"
          >
            {{ 'OFFERS.premium-plus-cta' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

