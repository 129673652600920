import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { MainHeaderComponent } from './main-header.component';
import { MainFooterComponent } from './main-footer.component';
import { CustomHeaderComponent } from './custom-header.component';
import { CustomFooterComponent } from './custom-footer.component';
import { HeaderMenuComponent } from './header-menu.component';
import { HomeHeaderComponent } from './home-header.component';
import { HomeFooterComponent } from './home-footer.component';

@NgModule({
  imports:      [
    SharedModule,
  ],
  declarations: [
    MainHeaderComponent,
    MainFooterComponent,
    CustomHeaderComponent,
    CustomFooterComponent,
    HeaderMenuComponent,
    HomeHeaderComponent,
    HomeFooterComponent
  ],
  exports: [
    MainHeaderComponent,
    MainFooterComponent,
    CustomHeaderComponent,
    CustomFooterComponent,
    HeaderMenuComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
  ]
})
export class HeadersFootersModule { }
