import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[showHide]',
    host: {'(window:scroll)': 'onScrolled($event)'},
})

export class ShowHideDirective {
  lastScrollTop: number;
  stScroll : number;
  navbarHeight: number;
  @HostBinding('class.shrink-header') showClass: boolean = false;

  constructor(el: ElementRef) {
    this.lastScrollTop = 0;
    this.navbarHeight = 250;

  }

  onScrolled( event ) {
    const yPos = document.body.scrollTop;

    this.stScroll = yPos;

    if ( this.stScroll > this.lastScrollTop && this.stScroll > this.navbarHeight) {
      this.showClass = true;
    }
    else {
      this.showClass = false;
    }
    this.lastScrollTop = this.stScroll;
  }
}
