
import { pluck } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Definitions
import { Tag, ITheme } from '../../../shared/definitions';

@Component({
  selector: 'snippet-tags',
  templateUrl: './snippet-tags.component.html'
})
export class SnippetTagsComponent {
  // Note : this component should be dumb : pass it a sharing message and
  // it handle the rest...
  @Input() tags: Tag[];

  theme: ITheme;

  constructor(private _route: ActivatedRoute) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }
}
