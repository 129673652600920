import { Pipe, PipeTransform }          from '@angular/core';
import { TranslateService }       from '@ngx-translate/core';

@Pipe({
    name: 'meetDate'
})
export class MeetDatePipe implements PipeTransform  {
  private _lang: string = 'fr';

  private _days: { [lang: string]: string[] } = {
    fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  };
  private _months: { [lang: string]: string[] } = {
    fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
  };

  constructor(private _translate: TranslateService) {
    this._translate.onLangChange
      .subscribe(data => {
        this._lang = data.lang;
      });
  }

  transform(input: string, lang: string)  {

    const dt = this.getMeetDate(input.substr(0,19));

    if (dt.fromNow == 'tomorrow') {
      return (this._lang == 'fr' ? 'Demain' : 'Tomorrow');
    }
    else if (dt.fromNow == 'today') {
      return (this._lang == 'fr' ? 'Aujourd\'hui' : 'Today');
    }
    else if (dt.fromNow == 'yesterday') {
      return (this._lang == 'fr' ? 'Hier' : 'Yesterday');
    }
    else {
      return `
        ${this._days[this._lang][dt.date.getDay()]} \
        ${dt.date.getDate()} \
        ${this._months[this._lang][dt.date.getMonth()]} \
        ${dt.date.getFullYear()}
      `;
    }
  }

  getMeetDate(date) {
    if (!date)
      date = new Date().toISOString();

    const now = new Date();

    const mdt = new Date(date);

    const sameMonthYear = mdt.getMonth() == now.getMonth() && mdt.getFullYear() == now.getFullYear();
    let fromNow;
    if (sameMonthYear && mdt.getDate() - now.getDate() == 1) {
      return { 'date': mdt, 'fromNow': 'tomorrow' };
    }
    else if (sameMonthYear && mdt.getDate() - now.getDate() == 0) {
      return { 'date': mdt, 'fromNow': 'today' };
    }
    else if (sameMonthYear && mdt.getDate() - now.getDate() == -1) {
      return { 'date': mdt, 'fromNow': 'yesterday' };
    }
    else {
      return { 'date': mdt, 'fromNow': null };
    }
  }
}

@Pipe({
    name: 'meetTime'
})

export class MeetTimePipe implements PipeTransform  {

  transform(input: string)  {
    return input ? input.substr(11, 5) : '';
  }
}
