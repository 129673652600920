<div class="row align-middle content-width hide-m">
  <div class="tab-menu-discover w100 m-m-b">
    <a class="col col-4 tab-nav group-tab" [routerLink]="'/fr/decouvrir/groupes'" routerLinkActive="active-tab">groupes</a>
    <a class="col col-4 tab-nav meet-tab" [routerLink]="'/fr/decouvrir/rencontres'" routerLinkActive="active-tab">événements</a>
    <a class="col col-4 tab-nav place-tab" [routerLink]="'/fr/decouvrir/lieux'" routerLinkActive="active-tab">lieux</a>
  </div>
</div>


<div class="w100 discover-content">
  <div class="w100 header-discover show-m">
    <nav class="w90 header-disover-nav">
      <ul>
        <li><a [routerLink]="'/fr'"><img src="/assets/images/k-icons/ic_back.svg"></a></li>
        <li><span>{{ navMenuTitle }}</span></li>
        <li><a href="javascript:void(0)" (click)="showMobileMenu = !showMobileMenu"><img src="/assets/images/k-icons/ic_options.svg" [class]="showMobileMenu ? 'rotate-options' : ''"></a></li>
      </ul>
    </nav>

    <nav class="nav-options" [ngClass]="showMobileMenu ? '' : 'hide-view'">
      <ul>
        <li
          class="group-choice"
          [routerLink]="'/fr/decouvrir/groupes'"
          (click)="showMobileMenu = !showMobileMenu; navMenuTitle = 'Trouver un groupe'"
        >
          <a>
            Trouver un groupe
          </a>
        </li>
        <li
          class="meet-choice"
          [routerLink]="'/fr/decouvrir/rencontres'"
          (click)="showMobileMenu = !showMobileMenu; navMenuTitle = 'Trouver un événement'"
        >
          <a>
            Trouver un événement
          </a>
        </li>
        <li
          class="place-choice"
          [routerLink]="'/fr/decouvrir/lieux'"
          (click)="showMobileMenu = !showMobileMenu; navMenuTitle = 'Trouver un lieu'"
        >
          <a>
            Trouver un lieu
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <router-outlet></router-outlet>
</div>
