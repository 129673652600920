import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpscoutService } from '../core/helpscout.service';
import { MainService } from '../main/main.service';
import { timer } from 'rxjs';

@Component({
  selector: 'main-footer',
  templateUrl: './main-footer.component.html'
})
export class MainFooterComponent implements OnInit {

  currentLang: string;

  newsletterRes: { result: 'success'|'error', manageLink?: string };

  constructor(
    private _translate:   TranslateService,
    private _mainService: MainService,
    private _helpscout:   HelpscoutService
  ) { }

  ngOnInit() {
    this.currentLang = this._translate.currentLang;

    this._translate.onLangChange
      .subscribe(({ lang }) => {
        this.currentLang = lang;
      });
  }

  changeLang(lang: string):void {
    this._translate.use(lang);
  }

  onClickContact() {
    this._helpscout.open();
  }

  onSubscribeNewsletter(email: string) {
    this._mainService.mailchimpNewsletterSub(email.toLowerCase())
      .subscribe(({ result, msg }) => {
        if (result == 'success') {
          this.newsletterRes = { result: 'success' };
        }
        else if (result == 'error' && msg.indexOf("is already subscribed to list") != -1){
          const manageLink = msg.match(/href="(.+?)"/)[1];
          this.newsletterRes = { result: 'error', manageLink };
        }
        else {
          this.newsletterRes = { result: 'error' };
          timer(2000).subscribe(_ => this.newsletterRes = null);
        }
      });
  }
}
