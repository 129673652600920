<ul
  *ngIf="tagImages?.length == 0"
  id="main-search"
  class="search-input"
  [style.color]="colors.primary"
  [style.border-color]="colors.primary"
>
  <li>
     <input
      #inputTag
      [attr.placeholder]="'GROUP_PAGE.find-by-tag' | translate"
      type="search"
      [formControl]="term"
      [style.color]="colors.primary"
      [style.border-color]="colors.primary"
      (keyup)="addTag($event)"
      class="border-radius unseleced-input"
     autofocus>
  </li>
</ul>

<div class="search-result"
  [hidden]="this.term.value == '' || availableTags.length == 0"
  [style.border-color]="colors.primary"
>
  <div class="search-result-tag"
    *ngFor="let item of items | async; let i = index"
    (click)="selectTag(item)"
    [ngClass]="{ 'active': i == indexTag }"
  >
    {{ item }}
  </div>
</div>

<div class="searchbar-tags">
  <ng-container *ngFor="let label of tagLabels">
    <span class="loop-tags" *ngIf="label.show">
      <div class="searchbar-tag" (click)="removeTag(label.name)">
        <span>{{ label.name }}</span>
        <i class="m-xs-l fa fa-times-circle-o" aria-hidden="true" [style.color]="colors.primary"></i>
      </div>
    </span>
  </ng-container>
</div>
