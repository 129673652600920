// Modules
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

 // Services
import { MeetService } from '../meet.service';

// Components
import { MeetPageComponent } from './meet-page.component';
import { MeetBannerComponent } from './header/meet-banner.component';
import { PrimaryButtonComponent } from './header/primary-button.component';
import { MeetDescriptionComponent } from './main/information/meet-description.component';
import { MeetPlaceComponent } from './main/information/meet-place.component';
import { MeetContributionComponent } from './main/information/meet-contribution.component';
import { SnippetShareComponent } from './snippets/snippet-share.component';
import { SnippetPriceComponent } from './snippets/snippet-price.component';
import { SnippetGroupComponent } from './snippets/snippet-group.component';
import { SnippetFormatComponent } from './snippets/snippet-format.component';
import { SnippetParticipantComponent } from './snippets/snippet-participant.component';
import { SnippetTagsComponent } from './snippets/snippet-tags.component';
import { SnippetLoaderComponent } from './snippets/snippet-loader.component';
import { ConfirmParticipationComponent } from '../confirm-participation.component';
import { MeetLeafletMapComponent } from './meet-leaflet-map.component';
import { MeetCommentsComponent } from './main/contribution/meet-comments.component';
import { VisioComponent } from './visio/visio.component';
import { OnBoardingParticipantComponent } from './visio/onboarding-participant.component';
import { OnBoardingOrganizatorComponent } from './visio/onboarding-organizator.component';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    MeetPageComponent,
    MeetBannerComponent,
    PrimaryButtonComponent,
    MeetDescriptionComponent,
    MeetPlaceComponent,
    MeetContributionComponent,
    SnippetShareComponent,
    SnippetPriceComponent,
    SnippetGroupComponent,
    SnippetFormatComponent,
    SnippetParticipantComponent,
    SnippetTagsComponent,
    SnippetLoaderComponent,
    ConfirmParticipationComponent,
    MeetLeafletMapComponent,
    MeetCommentsComponent,
    VisioComponent,
    OnBoardingParticipantComponent,
    OnBoardingOrganizatorComponent
  ],
  exports: [
    MeetPageComponent,
    ConfirmParticipationComponent,
  ],
  providers: [
    MeetService,
  ],
  entryComponents: [
    ConfirmParticipationComponent,
  ]
})
export class MeetPageModule { }
