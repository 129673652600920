
import { pluck } from 'rxjs/operators';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ITheme, Meet, Tag } from '../../../shared/index';
import { TrackingService } from 'src/app/core/tracking.service';

@Component({
  selector: 'snippet-share',
  templateUrl: './snippet-share.component.html'
})

export class SnippetShareComponent {

  @Input() meet:     Meet;
  @Input() viewType: string = 'desktop';

  showIcons: boolean = false; // Used and muted in template, in mobile view.
  theme:     ITheme;

  constructor(
    @Inject(DOCUMENT)
    private _document:        any,
    private _trackingService: TrackingService,
    private _route:           ActivatedRoute
  ) {
    this._route.data.pipe(pluck('theme')).subscribe((res: ITheme) => { this.theme = res; });
  }

  shareOnFacebook(): void {
    this._trackingService.trackEvent('Meet Page', 'share', 'facebook');

    const actualUrl: string = this._document.location.href;
    const facebookUrl: string = 'https://www.facebook.com/dialog/share';

    const sharingUrl: string = facebookUrl
      + '?app_id=438367446349748'
      + '&display=popup'
      + '&href=' + encodeURI(actualUrl);

    // TODO : add meet hashtags
    window.open(sharingUrl, '', 'height=420,width=670');
  }

  shareOnTwitter(): void {
    this._trackingService.trackEvent('Meet Page', 'share', 'twitter');

    const actualUrl: string = this._document.location.href;
    const hashtags: string = this.meet.tags.map((t: Tag) => t.name.replace('#', '')).join(',');

    let sharingUrl = 'https://twitter.com/intent/tweet'
      + '?text=' + encodeURI(this.meet.name)
      + '&url=' + encodeURI(actualUrl)
      + '&via=kawaa_co';

    if (hashtags && hashtags != '') {
      sharingUrl += `&hashtags=${hashtags}`
    }

    // TODO : add meet hashtags
    window.open(sharingUrl, '', 'height=420,width=670');
  }

  shareByMail(): void {
    this._trackingService.trackEvent('Meet Page', 'share', 'email');

    const actualUrl = this._document.location.href;
    const subject = this._document.title;

    const mailUrl = 'mailto:?subject=' + subject + '&body=' + actualUrl;
    window.open(mailUrl);
  }

  copyLinkToClipboard(invisibleInput: HTMLInputElement): void {
    const actualUrl = this._document.location.href;

    invisibleInput.value = actualUrl;
    invisibleInput.select();
    this._document.execCommand('copy');
  }
}
