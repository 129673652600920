import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
//import {
  //ActivatedRoute,
  //Router
//} from '@angular/router';
//import {
  //Observable
//}  from 'rxjs/Observable';

import {
  //ITheme,
  IColors,
  AnimationFormat
  //Group
} from '../shared/';
import {
  AppStore,
  Collection
} from '../store/';

@Component({
  selector: 'formats-list',
  templateUrl: '../../../src/app/formats/formats-list.component.html'
})

export class FormatsListComponent implements OnInit  {

  //theme:          ITheme = {
    //group_id:       null,
  //}
  //collection:     Collection<AnimationFormat>;
  @Input() colors:            IColors = { primary: '' };
  @Input('formatsCollection') collection:        Collection<AnimationFormat>;

  @Output() select:           EventEmitter<AnimationFormat> = new EventEmitter<AnimationFormat>();
  @Output() loadNext:         EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    //private _route:           ActivatedRoute,
    //private _router:          Router,
    private _appStore:        AppStore,
  ) {
  }

  ngOnInit() {
    //this._route.data.pluck('theme')
      //.subscribe((theme: ITheme) => {
        //this.theme = theme;

        //if (theme.group_id) {
          //this.collection = this._appStore.load('GroupAnimationFormats', theme.group_id.toString());
        //}
        //else {
          //this.collection = this._appStore.load('AnimationFormats', null);
        //}
      //})
  }

  onSelectFormat(format: AnimationFormat) {
    this.select.emit(format);
    //this._router.navigate(['/admin', 'formats', format.id], { queryParamsHandling: 'preserve' });
  }

  loadNextFormats(): void {
    this.loadNext.emit();
    //this.collection.collectNext();
  }

  hexToRgbaColor(color: string, opacity: number): string {
    if (color == '')
      return '';

    const tranformToHex = (n: string) => parseInt('0x' + n);

    const first = tranformToHex(color.substr(1, 2)).toString();
    const second = tranformToHex(color.substr(3, 2)).toString();
    const third = tranformToHex(color.substr(5, 2)).toString();

    return first + ',' + second + ',' + third + ',' + opacity.toString();
  }
}
