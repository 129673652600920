
import { Component, OnChanges, SimpleChanges, Input }  from '@angular/core';
import { Group, GroupLink, ITranslatedValue, IColors }  from '../shared/definitions';
import { AppStore }  from '../store/';

interface IGroupLinksConfig {
  member_stats: IStatField;
  meet_stats: IStatField;
  group_link_titles: {
    hide: boolean
  };
  filter_by_categories: {
    hide: boolean;
  };
  filter_by_status: 'active'|'pending'|'refused';
  color: string;
  filter_limit: number;
}

interface IStatField {
  hide?:    boolean;
  naming?:  ITranslatedValue;
}

interface IGroupLinkSelection extends GroupLink {
  show: boolean;
}

interface IGroupLinksCategory {
  title: string;
  color: string;
  symbol: string;
  selected: boolean;
}

@Component({
  selector: 'group-links',
  templateUrl: './group-links.component.html'
})

export class GroupLinksComponent implements OnChanges {

  @Input() group:         	  Group;
  @Input() sectionTitle: 		  ITranslatedValue;
  @Input() flags:             string;
  @Input() config:            IGroupLinksConfig;

  colors:              IColors = { primary: '' };

  groupLinks:          IGroupLinkSelection[] = [];
  groupLinkCategories: IGroupLinksCategory[] = [];

  groupLinkTitles:    { hide: boolean } = { hide: false };
  filterByCategories: { hide: boolean } = { hide: false };

  meetStats: IStatField = {
    hide:   false,
    naming: { fr: 'Rencontre', en: 'Event' }
  };
  memberStats: IStatField = {
    hide:   false,
    naming: { fr: 'Membre', en: 'Member' }
  };

  filterLimit: number = Infinity;

  constructor(
    private _appStore:   AppStore
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.group && this.flags && this.config.filter_by_status) {
      this.setGroupLinks(this.group.id.toString(), this.flags, this.config.filter_by_status);
    }
    else if (this.group && this.flags) {
      this.setGroupLinks(this.group.id.toString(), this.flags, 'active');
    }
    if (this.config && this.config.member_stats) {
      this.memberStats = this.config.member_stats;
    }
    if (this.config && this.config.meet_stats) {
      this.meetStats = this.config.meet_stats;
    }
    if (this.config && this.config.group_link_titles) {
      this.groupLinkTitles = this.config.group_link_titles;
    }
    if (this.config && this.config.filter_by_categories) {
      this.filterByCategories = this.config.filter_by_categories;
    }
    if (this.config && this.config.filter_limit) {
      this.filterLimit = this.config.filter_limit;
    }
    if (this.group && this.group.options && this.group.options.colors) {
      this.colors = this.group.options.colors;
    }
  }

  setGroupLinks(id: string, flags: string, status: string) {
    this._appStore.load('GroupLinks', id, { as: 'target', status, visible: true, flags }, true)
      .entities
      .subscribe((groupLinks: GroupLink[]) => {
        this.groupLinks = groupLinks
          .map((gl: GroupLink, i: number) => ({ ...gl, show: this.filterLimit > i }))
          .sort(gl => Math.random() > 0.5 ? 1 : -1);

        groupLinks.forEach((gl: GroupLink) => {
          if (this.groupLinkCategories.map(_ => _.symbol).indexOf(gl.symbol) == -1) {
            this.groupLinkCategories.push({ title: gl.title, color: gl.color, symbol: gl.symbol, selected: false });
          }
        });
      });
  }

  onSelectGroupLink(groupLink: GroupLink) {
    const originGroup: Group = groupLink.origin_group;

    if (originGroup.options && originGroup.options.own_url) {
      window.open(originGroup.options.own_url, '_blank');
    }
    else {
      window.open(`/home/${groupLink.target_group_id}/${groupLink.flag}/${originGroup.id}`, '_blank');
    }
  }

  onSelectCategory(category: IGroupLinksCategory) {
    category.selected = !category.selected;

    const showAll: boolean = this.groupLinkCategories.every(_ => !_.selected);

    if (showAll) {
      this.groupLinks.forEach((gl: IGroupLinkSelection, i: number) => gl.show = this.filterLimit > i);
    }
    else {
      this.groupLinks.forEach((gl: IGroupLinkSelection) => {
        const i: number = this.groupLinkCategories.map(_ => _.symbol).indexOf(gl.symbol);
        gl.show = this.groupLinkCategories[i].selected;
      });
    }
  }

  checkIfSelected(groupLink: GroupLink) {
    const i: number = this.groupLinkCategories.map(_ => _.symbol).indexOf(groupLink.symbol);
    return this.groupLinkCategories[i].selected;
  }
}
