<!-- Aside widget -->
<div *ngIf="viewType == 'desktop'" class="row">
  <div class="col col-12 aside-meet-article share-meet">
    <h3 class="section-title" [style.border-bottom-color]="theme?.colors?.primary">
      {{ 'MEET_PAGE.share' | translate }}
    </h3>
    <div class="aside-meet-content">
      <a href="javascript:void(0)" (click)="shareOnFacebook()" class="facebook-square">
        <i class="fab fa-facebook-f" aria-hidden="true"></i>
      </a>

      <a href="javascript:void(0)" (click)="shareOnTwitter()" class="twitter-square">
        <i class="fab fa-twitter" aria-hidden="true"></i>
      </a>

      <a href="javascript:void(0)" (click)="shareByMail()" class="mail-square">
        <i class="fas fa-envelope" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>

<!-- Mobile widget -->
<div *ngIf="viewType == 'mobile'" class="row share-meet-widget">
  <button
    (click)="showIcons = true"
    class="share-meet-widget-button"
  >
    <i class="far fa-share-alt" aria-hidden="true"></i>
  </button>

  <div
    *ngIf="showIcons"
    class="share-meet-widget-overlay"
    (touchstart)="showIcons = false"
    (click)="showIcons = false"
  ></div>

  <div class="col col-12">
    <div
      *ngIf="showIcons"
      class="share-meet-widget-content"
    >
      <a href="javascript:void(0)" (click)="shareOnFacebook()" class="facebook-square round-icon">
        <i class="fab fa-facebook-f" aria-hidden="true"></i>
      </a>

      <a href="javascript:void(0)" (click)="shareOnTwitter()" class="twitter-square round-icon">
        <i class="fab fa-twitter" aria-hidden="true"></i>
      </a>

      <a href="javascript:void(0)" (click)="shareByMail()" class="mail-square round-icon">
        <i class="fas fa-envelope" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>
