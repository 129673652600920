<div class="row align-middle main-content-static content-width map-container-discover">
  <div class="w100 map-view m-m-b">
    <group-links-map
     [colors]="{ primary: '#D97F8E' }"
     [config]="{ search_bar: { by_tag: true, by_city: true } }"
     [fitBounds]="false"
     (selectMeet)="onSelectMeet($event)"
     (createMeet)="onCreateMeet($event)"
    >
    </group-links-map>
  </div>
</div>

