
import { first } from 'rxjs/operators';
// Angular imports
import { OnInit, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// Services
import { AppStore, Collection } from '../../store/';
import { MeetService }  from '../meet.service';
import { MetaService } from '../../core';
import { ViewTypeService, IViewType } from '../../shared/view-type.service';
import { AuthRedirectService } from '../../auth/auth-redirect.service';

// Interfaces
import { Meet, Participant, Group, Tag } from '../../shared/';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth';
import { NotificationService } from '../../core/notification/notification.service';
import { TrackingService } from 'src/app/core/tracking.service';

@Component({
  selector: 'meet-page',
  templateUrl: './meet-page.component.html',
})

export class MeetPageComponent implements OnInit {
  meet:                   Meet;
  meetId:                 string;
  principalGroup:         Group;

  isMeetFetching:         boolean = true;
  isMeetPaid:             boolean = false;

  isOrganizatorsFetching: boolean = true;
  organizators:           Participant[];

  isParticipantsFetching: boolean = true;
  participants:           Participant[];
  participantCollection:  Collection<Participant>;

  viewType$:              Observable<IViewType>; // mobile || desktop

  userAsParticipant:      Participant;

  snippets:               any;

  action: string;

  constructor (
    @Inject(DOCUMENT) private _document: any,
    private _route:               ActivatedRoute,
    private _router:              Router,
    private _appStore:            AppStore,
    private _authService:         AuthService,
    private _meetService:         MeetService,
    private _notificationService: NotificationService,
    private _metaService:         MetaService,
    private _viewTypeService:     ViewTypeService,
    private _authRedirectService: AuthRedirectService,
    private _trackingService:     TrackingService,
  ) {}

  ngOnInit(): void {
    this.meetId = this._route.snapshot.params['id'];

    // Getting meet info
    this._appStore.load('Meet', this.meetId)
      .entity
      .pipe(first())
      .subscribe(
        (meet: Meet) => {
          this.setMeetValues(meet);
          this.setPrincipalGroupAndSnippets(meet);
          this.setMetaTags(meet);
        },
        (error) => {
          if (error == 'id is invalid')
            this._router.navigate(['../../page-not-found'], { relativeTo: this._route });
        }
      );

    this.setParticipants();

    // Setting listener for viewType
    this.viewType$ = this._viewTypeService.get();

    // Getting userAsParticipant
    this._meetService.loadMyParticipant(this.meetId)
      .subscribe(
        (participant: Participant) => this.userAsParticipant = participant,
        ({ error }) => console.log(error.error)
      );

    // Setting params
    this._route.params.subscribe(params => {
      this.action = params.action;

      if (params['action'] == 'not_going') {
        this.quitMeet();
      }
      else if (params['invitation'] == 'accept') {
        this.action = 'invitation';
        this._trackingService.trackEvent('Meet Page', 'accept_invitation', this.meetId);
      }
      else if (params['invitation'] == 'refuse') {
        this.refuseInvitation(params['token']);
        this._trackingService.trackEvent('Meet Page', 'refuse_invitation', this.meetId);
      }
    });
  }

  ngOnDestroy() {
    this._metaService.removeStructuredData('single-meet');
  }

  setParticipants(): void {
    this.participantCollection = this._appStore.load('Participants', this.meetId, { by_role: 'DESC', status: 'active' });
    this.participantCollection.entities.subscribe(
        (res) => {
          if (res.length) {
            this.participants = res.filter(p => p.role !== 'organizator');
            this.organizators = res.filter(p => p.role === 'organizator');
            this.isOrganizatorsFetching = false;
            this.isParticipantsFetching = false;
          }
        },
        (error) => console.log(error),
      );
  }

  setMeetValues(meet: Meet): void {
    this.meet = meet;
    this.isMeetFetching = false;
    if (meet.options && meet.options.display_price) {
      this.isMeetPaid = true;
    }
  }

  setMetaTags(meet: Meet) {
    this._metaService.setTitle(meet.name);

    if (meet.description) {
      // remove html tags and slice
      const desc: string = meet.description.replace(/<.*?>/g, '').slice(0, 140) + '...';

      this._metaService.setTag('description', desc);
      this._metaService.setTag('og:description', desc);
      this._metaService.setTag('twitter:description', desc);
    }

    if (meet.photos && meet.photos.length > 0) {
      this._metaService.setTag('og:image', meet.photos[0].slug);
      this._metaService.setTag('twitter:image', meet.photos[0].slug);
    }

    if (meet.tags) {
      const keywords: string = meet.tags.map((tag: Tag) => tag.name.replace('#', '')).join(',');
      this._metaService.setTag('keywords', keywords);
    }

    if (meet.status == 'pending' || meet.status == 'published') {
      this._metaService.setSingleMeetStructedData(meet);
    }
  }

  setPrincipalGroupAndSnippets(meet: Meet) {
    let principalGroup: Group;

    if (meet.groups && meet.groups.length > 0) {
      principalGroup = meet.groups[0];
    }
    else if (meet.place) {
      principalGroup = meet.place;
    }

    this.principalGroup = principalGroup;

    let showParticipantsNb = false;

    if (meet.options && meet.options.participants_counter && meet.options.participants_counter !== 'default') {
      showParticipantsNb = meet.options.participants_counter === 'show';
    }
    else if (meet.participants_count && meet.options && meet.options.max_participants) {
      const count = meet.participants_count;
      const max = meet.options.max_participants;
      showParticipantsNb = count / max > 0.3;
    }
    else if (meet.participants_count >= 3) {
      showParticipantsNb = true;
    }

    if (principalGroup && principalGroup.options && principalGroup.options.meet_snippets) {
      this.snippets = principalGroup.options.meet_snippets;
    }
    else {
      // set default snippets
      this.snippets = [
        { name: 'share', display: true },
        { name: 'price', display: true },
        { name: 'group', display: true, title: { fr: 'Communauté', en: 'Community' } },
        { name: 'format', display: true },
        { name: 'participant', display: showParticipantsNb },
        { name: 'tags', display: true },
      ];
    }
  }

  /**
   * Actions
   */
  closeConfirm(): void {
    this._trackingService.trackEvent('Meet Page', 'inscription_canceled', this.meetId);
    this.resetUrl();
  }

  /**
   * Registration process
   */
  onJoinMeet(participant: Participant) {
    this.userAsParticipant = participant;

    if (participant.status == 'active') {
      this.meet.participants_count++;
    }

    this._appStore.reload('Participants', this.meetId);
    this.closeDialog();
    this._trackingService.trackEvent('Meet Page', 'inscription_completed', this.meetId);
  }

  quitMeet(): void {
    if (!this._authService.isLoggedIn) {
      this._authRedirectService.authenticateThenGoBack({}, this._route.snapshot.queryParams['_']);
      return ;
    }

    this._appStore.delete('Participants', this.meetId)
      .subscribe(
        _ => {
          this.userAsParticipant = null;
          this.meet.participants_count--;
          this.closeDialog();
        },
        err => {
          this.closeDialog();
        });
  }

  refuseInvitation(token: string) {
    this._meetService.manageInvitations('refuse', token)
      .subscribe(_ => {
        this.resetUrl();
        this._notificationService.setNotification({
          type: 'success',
          content: 'MEET_PAGE.message-saved'
        });
      });
  }

  closeDialog() {
    this.action = null;
    this.resetUrl();
  }

  private resetUrl(): void {
    const matrixPath = this._router.url.split('?')[0];
    const path = matrixPath.split(';')[0];
    this._router.navigate(['.', {}], { relativeTo: this._route, replaceUrl: true });
  }
}
