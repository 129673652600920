import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IViewType, ViewTypeService } from 'src/app/shared/view-type.service';
import { HelpscoutService } from 'src/app/core/helpscout.service';
import { Meet } from 'src/app/shared';


@Component({
  templateUrl: './onboarding-organizator.component.html',
  selector: 'onboarding-organizator'
})

export class OnBoardingOrganizatorComponent {
  @Input() step: string;
  @Input() meet: Meet;

  isIphone:      boolean;
  conf_name:     string;
  viewType$:     Observable<IViewType> = this._viewType.get();

  constructor(
    private _viewType: ViewTypeService,
    private _helpscout: HelpscoutService
  ) { }

  ngOnInit() {
    this.conf_name = 'e' + this.meet.id;
    this.isIphone = navigator.userAgent.indexOf('iPhone') != -1;
  }

  contactUs() {
    this._helpscout.open();
  }
}
