<div
  *ngIf="coverPost"
  [ngClass]="coverPostClasses"
>
  <div
    [ngClass]="coverResourceClasses"
    [ngStyle]="{'background-image': 'url(' + coverPostImgSlug + ')'}"
  >
  </div>
  <div
    *ngIf="coverPostShowContent"
    class="banner-content row gutter auto borders-all border-radius-b-l border-radius-b-r align-middle p-t-10 p-b-10 p-l-20 p-r-20 m-m-b"
  >
    <div class="col" [ngClass]="coverPostAlignment">
      <h1
        *ngIf="coverPost?.options?.title != 'hide'"
        class="text-dark text-very-large"
        [ngClass]="{ 'text-very-large': (viewType$ | async) == 'desktop', 'text-large': (viewType$ | async) == 'mobile' }"
      >
        {{ coverPost.title }}
      </h1>
      <p *ngIf="coverPost?.content" class="dynamic-content p-static" [innerHtml]="coverPost.content | linky | sanitizeHtml"></p>
      <a 
        class="button m-s-t"
        *ngFor="let action of coverPost.options?.actions" 
        [href]="action.url" 
        [target]="action.open_in_new_tab ? '_blank' : '_self'" 
        [style.background-color]="colors.primary"
      >
        {{ action.text }}
      </a>
    </div>
  </div>
</div>

<div class="w100">
  <div *ngFor="let post of posts" class="row align-middle flex">

    <block-post-article *ngIf="post.category == 'article'" [post]="post" [colors]="colors"></block-post-article>

    <block-post-banner *ngIf="post.category == 'banner'" [post]="post" [colors]="colors"></block-post-banner>

    <div class="w100" *ngIf="post.category == 'custom'" [innerHtml]="post.content | sanitizeHtml"></div>

  </div>

  <div #dynamicContent></div>
</div>
