<button
  class="button-circle button-circle-big btn-grey outline"
  (click)="this.showIcons = !this.showIcons"
>
  <i class="far fa-share-alt"></i>
</button>

<div
  *ngIf="showIcons"
  class="share-meet-widget-overlay"
  (touchstart)="showIcons = false"
  (click)="showIcons = false"
></div>

<div
  *ngIf="showIcons"
  class="share-meet-widget-content"
  style="position: absolute; right: 1px"
  [style.top]="(viewType$ | async) == 'mobile' ? '60px' : '-225px'"
>
  <a href="javascript:void(0)" (click)="shareOnFacebook()" class="facebook-square round-icon">
    <i class="fab fa-facebook-f" aria-hidden="true"></i>
  </a>

  <a href="javascript:void(0)" (click)="shareOnTwitter()" class="twitter-square round-icon">
    <i class="fab fa-twitter" aria-hidden="true"></i>
  </a>

  <a href="javascript:void(0)" (click)="shareByMail()" class="mail-square round-icon">
    <i class="fa fa-envelope" aria-hidden="true"></i>
  </a>

  <a href="javascript:void(0)" (click)="copyLinkToClipboard(invisibleInput)" class="link-square round-icon">
    <input #invisibleInput style="position: absolute; left: 100px; opacity: 0" />
    <i class="far fa-link" aria-hidden="true"></i>
  </a>
</div>